<style>
    .post-order-services .section-sub-title a {
    color: var(--white);
    display: block;
    font-size: 22px;
    line-height: 1.75;
}
.post-order-services{
    background-image: linear-gradient(to left, rgba( 0, 0, 0, 0.25), rgba(0, 0, 0, .5)), url("../../assets/img/young-man-working-warehouse-with-boxes 1(1).png");
    /*background: url("../img/young-man-working-warehouse-with-boxes 1(1).png") no-repeat center;*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 704px;
    width: 100%;
}
.post-order-services .section-title h1{
    color: var(--white);
    font-weight: var(--f-weight-seven);
}
</style>

<section class="post-order-services section" id="post-order-services">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="section-content">
                    <div class="section-title">
                        <h1>Post-Order Services</h1>
                    </div>
                    <!-- <div class="section-sub-title-head ml-md-4">
                        <ul>
                            <li><a href="" class="active">Pre-Order Services</a></li>
                            <li class="ml-md-3"><a href="">Post-Order Services</a></li>
                        </ul>
                    </div> -->
                    <div class="section-sub-title">
                        <a [routerLink]="['/our-services']" routerLinkActive="active">Delivery Monitoring</a>
                        <a [routerLink]="['/our-services']" routerLinkActive="active">Inspection</a>
                        <a [routerLink]="['/our-services']" routerLinkActive="active">Quality Control</a>
                        <a [routerLink]="['/our-services']" routerLinkActive="active">Consolidation of Shipment</a>
                        <a [routerLink]="['/our-services']" routerLinkActive="active">Logistics</a>
                        <a [routerLink]="['/our-services']" routerLinkActive="active">Shipping Coordination</a>
                        <!-- <a [routerLink]="['/our-services']" routerLinkActive="active">Delivery Monitoring</a> -->
                        <!-- <a [routerLink]="['/our-services']" routerLinkActive="active">Inspection</a> -->
                    </div>	
                </div>
            </div>
        </div>
    </div>
</section>