import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { WebapiService } from './services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ActivateGuard implements CanActivate {
  constructor(private service:WebapiService,private router: Router){
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      if(this.service.isLogedIn()){
        return true;
      //  alert(this.service.isLogedIn());
      }else{
        alert("Please login first");
         this.router.navigate(['/']);
        return false;
      }
  }
  
}
