<section>
    <div class="teamHeading">
       <div class="container">
          <div class="row">
             <div class="col-12 text-center text-white">
                <!-- <h6 class="">TEAM EXPERT</h6> -->
                <h2>Meet Our Advisory Team</h2>
                <!-- <h2 class="text-white">Team</h2> -->
                <ul class="breadcrumb">
                   <li><a href="">Home</a></li>
                   <li><a href="#">TEAM</a></li>
                </ul>
             </div>
          </div>
       </div>
    </div>
 </section>
 <section class="teamSlide"></section>
 <section class="section">
    <div>
       <div class="container">
          <div class="row">
             <div class="col-12">
                <div class="text-center">
                   <p class="mb-0 mt-4" style="font-size: 18px;">We strongly believe that Expert services can be provided only by a person who specialises in a niche area of service and obtain indepth knowledge in such given area of service over a period of time.</p>
                </div>
             </div>
          </div>
       </div>
    </div>
 </section>
 <section class="teamImg">
    <div class="container">
       <div class="row text-center section">
          
          <div class="col-12 col-md-3" *ngFor="let data of teamList">
             <div class="py-0 teamName rounded">
                <a  routerLink="/teamdetails"  routerLinkActive="active" ><img src="http://onlinesoftware360.com/vmr/demo/admin/images/team_image/{{data.image}}" class="img-fluid"></a>
                <div class="py-3 px-4 text-white text-left teamText position-relative">
                   <h6 class="">{{data.lname}}</h6>
                   <h2 style="font-size: 20px;">{{data.fname}}</h2>
                   <!-- <div class="small mb-1">
                      <i class="lni lni-phone"></i>
                      +322 12345678
                      <i class="lni lni-envelope"></i>
                      bbrony@nifty.com
                      </div> -->
                   <div class="text-white my-2">
                      <i class="lni lni-map-marker"></i> {{data.city}}  
                   </div>
                   <div class="socials d-flex justify-content-between">
                      <div>
                         <!-- <a href="#" class="lni lni-twitter-original"></a>
                           https://www.linkedin.com/in/ca-vikas-chauhan-51860964/" -->
                         <a href="{{data.state}}" class="lni lni-linkedin-original" target="_blank"></a>   
                      </div>
                      <div class="small">
                         <!-- <a href="" class="text-white fullProfile" target="_blank">FULL PROFILE <i class="lni lni-arrow-right-circle"></i> </a>  -->
                      </div>
                   </div>
                </div>
             </div>
          </div>
                        
       </div>
    </div>
 </section>