<section class="" style="padding-bottom: 50px">
    <div class="serviceHeading">
       <div class="container">
          <div class="row">
             <div class="col-12 text-center">
                <h2 class="text-white">Insights</h2>
                <ul class="breadcrumb">
                     <li><a href="/">Home</a></li>
                     <li><a href="#">Insights - Blog</a></li>
                   </ul> 
             </div>
          </div>
       </div>
    </div>
 </section>
 <section class="aboutSlide"></section>
<section class="section  animate__animated animate__fadeIn animate__delay-1s" style="padding: 100px 0;">
    <!--blog-->
    <div class="container">
       
    
         <div class="row">
            <div class="col-sm-4 col-4 rounded text-center" *ngFor="let data of blogList">
               <!-- [queryParams]="{'slug':data.slug}" -->
                <a class="text-dark bg-white singleblog" routerLink="/blog-detail/{{data.slug}}"  >
                    <img src="http://onlinesoftware360.com/vmr/demo/admin/images/blog_image/{{data.image}}" class="img-fluid">
                    <div class="blog_text">
                        <h4 class="">{{data.title}}</h4>
                        <div class="mb-4"  innerHtml="{{data.short_desc}}"></div>
                    </div>
                </a>
            </div> <!-- single blog -->
             
         </div>
    </div>
</section>