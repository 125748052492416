<style>
    input[type="number"] {
       -moz-appearance: textfield;
       appearance: textfield;
       margin: 0;
   }
   input[type="number"]::-webkit-inner-spin-button,
   input[type="number"]::-webkit-outer-spin-button {
       -webkit-appearance: none;
       margin: 0;
   }
   .sign-in a {
    color: #fff !important;
}
.sign-in img {
    width: 30px;
}
.sign-in ul > li {
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
}
.sign-in ul > li a {
    color: var(--white);
    margin-left: 0.25rem;
}
.search-box img {
    transform: translate(-6%, -50%);
    position: absolute;
    top: 50%;
    right: 6%;
}
.vendor-details {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../assets/img/aerial-view.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
}

a {
    color: #fff;
}

.dropdown-item {
    color: #212529 !important;
}

.r-25 {
    right: 25px;
}
.you-deal-in {
    flex-wrap: wrap;
}
.you-deal-in > div {
    flex: 0 1 380px;
}
</style>

<section class="vendor-details section" id="vendorDetails">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title mb-md-4 col-md-12  float-md-left">
                    <div class="col-md-6 float-md-left">
                        <h1 class="mb-md-0">Commercial</h1>
                        <h2 style="color: #fff;font-size: 20px;margin-top: 10px;">{{companyName}}</h2>
                    </div>
                    <div *ngIf="empId" class="sign-in col-md-6 float-md-left text-right">
                        <ul class="text-md-center">
                            <li *ngIf="userLogin" >
                                <a (click)="myAccount(loginStatus)"><img src="assets/img/user.png" alt="" class="w-18">{{loginName}}</a>
                            </li>
                            <li  *ngIf="!userLogin">
                                <a [routerLink]="['/login']" class="signinbtn" routerLinkActive="active">Sign In</a>
                            </li>  
                            <li><span class="separator">|</span></li>
                            <li *ngIf="!userLogin" >
                                <a class="signupbtn" [routerLink]="['/signup']" routerLinkActive="active">Join Us</a>
                            </li>
                            <li *ngIf="userLogin">
                                <a (click)="logout()" href=""><img src="assets/img/logout.png" alt="" class="w-18">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div><!-- row -->
        <div class="row">
            <div class="col-12">
                <div class="step-menu-wrapper">
                    <ul class="d-flex">
                        <li class="flex-fill"><a [routerLink]="['/step-one']" routerLinkActive="active">Basic Information</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-two']" routerLinkActive="active">Company Profile</a></li>
                        <li class="flex-fill"><a class="active">Commercial</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-four']" routerLinkActive="active" >Quality Management & Certifications</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-five']" routerLinkActive="active" >Staffing</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-six']" routerLinkActive="active" >Legal & Financial</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-seven']" routerLinkActive="active" >Social Compliance</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-eight']" routerLinkActive="active" >Infrastructure</a></li>
                    </ul>
                </div>
            </div>
        </div>
<div class="" id="ven-comm" role="tabpanel" aria-labelledby="vcomm-tab">
    <div class="section-content bg-white p-md-3 h-auto clearfix">
        <div class="section-title mb-md-4">
            <!-- <h1 class="mb-md-0">Vendor Registration</h1> -->
        </div>
        <div class="section-form">
            <form #commVen="ngForm" (ngSubmit)="commVen.valid && onVendorCommForm(vencomm)" class="row">
                <div class="col-md-12">
                    <label for="">
                        PAYMENT TERMS ACCEPTABLE TO YOU
                    </label>
                </div>
                <div class="col-md-2">
                    <label for="">
                        L/C at sight
                    </label>
                </div>
                <div class="col-md-1">
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="checkbox" value="L/C at sight" name="payterm1" (change)="onCheckPayTerm($event)" />
                        </label>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="">
                        L/C 30 days
                    </label>
                </div>
                <div class="col-md-1">
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="checkbox" name="payterm2" value="L/C 30 days" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                        </label>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="">
                        L/C 60 days
                    </label>
                </div>
                <div class="col-md-1">
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="checkbox" name="payterm3" value="L/C 60 days" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                        </label>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="">
                        Advance and Balance by T/T
                    </label>
                </div>
                <div class="col-md-1">
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="checkbox" name="payterm4" value="Advance and Balance by T/T" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                        </label>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="">
                        D/P with Advance
                    </label>
                </div>
                <div class="col-md-1">
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="checkbox" name="payterm5" value="D/P with Advance" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                        </label>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="">
                        D/P without Advance
                    </label>
                </div>
                <div class="col-md-1">
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="checkbox" name="payterm6" value="D/P without Advance" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                        </label>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="">
                        D/A 30 Days
                    </label>
                </div>
                <div class="col-md-1">
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="checkbox" name="payterm7" value="D/A 30 Days" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                        </label>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="">
                        D/A 60 Days
                    </label>
                </div>
                <div class="col-md-1">
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="checkbox" name="payterm8" value="D/A 60 Days" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                        </label>
                    </div>
                </div>

                <!-- <div class="col-md-2">
                    <label for="">
                        Escrow Account
                    </label>
                </div>
                <div class="col-md-1">
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="checkbox" name="payterm9" value="Escrow Account" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                        </label>
                    </div>
                </div> -->

                <div class="col-12 my-3"></div>
                <div class="form-group col-md-4">
                    <label for="">
                        RESPONSIBILITY OF QUALITY AND QUANTITY OF SHIPMENT SENT FROM THE DATE OF B/L
                    </label>
                </div>
                <div class="form-group col-md-6">
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="radio" aria-label="Radio button for following text input" value="none" name="optradio"  [(ngModel)]="vencomm.shipmentfromdate" class="form-check-input" />None 
                        </label>
                    </div>
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                             <input type="radio" aria-label="Radio button for following text input" value="3 months" name="optradio" [(ngModel)]="vencomm.shipmentfromdate" class="form-check-input"/>3 Months
                        </label>
                    </div>
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="radio" aria-label="Radio button for following text input" value="6 months" name="optradio" [(ngModel)]="vencomm.shipmentfromdate" class="form-check-input" />6 Months 
                        </label>
                    </div>
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="radio" aria-label="Radio button for following text input" value="9 months" name="optradio" [(ngModel)]="vencomm.shipmentfromdate" class="form-check-input" />9 Months 
                        </label>
                    </div>
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="radio" aria-label="Radio button for following text input" value="12 months" name="optradio" [(ngModel)]="vencomm.shipmentfromdate" class="form-check-input" />12 Months 
                        </label>
                    </div>
                </div>


                <!-- <div class="col-md-2 float-md-left">
                        <div class="form-check">
                            <label class="form-check-label">
                                3 Months <input type="radio" aria-label="Radio button for following text input" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.rdOne" name="optradio">
                            </label>
                        </div>
                    </div>
                    <div class="col-md-2 float-md-left">
                        6 Months <input type="radio" aria-label="Radio button for following text input" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.rdTwo">
                    </div>
                    <div class="col-md-2 float-md-left">
                        9 Months <input type="radio" aria-label="Radio button for following text input" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.rdThree">
                    </div>
                    <div class="col-md-2 float-md-left">
                        12 Months <input type="radio" aria-label="Radio button for following text input" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.rdFour">
                    </div> -->

                <!-- <input type="radio" aria-label="Radio button for following text input" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.rdFive"> -->

                <div class="form-group col-md-4">
                    <label for="">
                        DO YOU KEEP RECORD FOR PHYSICAL MASTER SAMPLES:
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="radio" class="form-check-input" value="yes" (change)="recordCheck($event)" [(ngModel)]="vencomm.keeprecord" name="optradiotwo" /> Yes
                        </label>
                    </div>
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="radio" class="form-check-input"  value="no" (change)="recordCheck($event)" [(ngModel)]="vencomm.keeprecord" name="optradiotwo" /> No
                        </label>
                    </div>
                </div>

                <!-- Yes
                    <input type="radio" aria-label="Radio button for following text input" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.rdSix"> No
                    <input type="radio" aria-label="Radio button for following text input" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.rdSeven"> -->

                <div class="form-group col-md-6" *ngIf="recordFlag">
                    <label for="" class="col-md-2 float-md-left">If Yes, How?</label>
                    <input type="text" [(ngModel)]="vencomm.desc" class="form-control col-md-10 float-md-left" name="desc" id="exampleInputPerson" aria-describedby="person" placeholder="" />
                </div>

                <div class="form-group col-md-6 pl-md-0">
                    <label for="" class="col-md-10 float-md-left">
                        DELIVERY: WHAT % OF SHIPMENT WAS? DELIVERED ON DUE DATE: (IN LAST 12 MONTHS)
                    </label>
                    <input type="number" [(ngModel)]="vencomm.avgship" name="avgship" class="form-control col-md-2 float-md-left"  placeholder="%" #avgship="ngModel" [class.is-invalid]="avgship.invalid && commVen.submitted" required="required"/>
                    <div class="invalid-feedback">
                        Shipment percentage is required.
                      </div>
                </div>

                <div class="form-group col-md-6">
                    <label for="" class="col-md-9 float-md-left pl-0">
                        MIN SHIPMENT INVOICE VALUE ACCEPTABLE US$:
                    </label>
                    <input type="number" [(ngModel)]="vencomm.minship" name="minship" class="form-control col-md-3 float-md-left" #minship="ngModel" [class.is-invalid]="minship.invalid && commVen.submitted" required="required" placeholder="$" />
                    <div class="invalid-feedback">
                        Shipment invoice value is required.
                      </div>
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        MIN SHIPMENT VOLUME ACCEPTABLE:
                    </label>
                </div>

                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="vencomm.minshipvol" value="LCL" class="form-check-input" name="optradiothree" />LCL </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="vencomm.minshipvol" value="FCL" class="form-check-input" name="optradiothree" />FCL </label>
                    </div>
                </div>

                <div class="form-group col-md-4">
                    WILL YOU SIGN A PENALTY CLAUSE FOR LATE DELIVERY?
                </div>

                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="vencomm.latedelivery" class="form-check-input" name="optradiofour" value="yes" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="vencomm.latedelivery" class="form-check-input" name="optradiofour" value="no" />No </label>
                    </div>
                </div>

                <div class="w-100" style="margin-bottom: 32px;"></div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4 text-center mx-auto">
                            <button [routerLink]="['/step-two']" routerLinkActive="active" class="btn btn-secondary d-md-block mx-auto"><i class="fa fa-angle-left"></i> Previous</button>
                        </div>
                        <div class="col-md-4 text-center mx-auto">
                            <button type="submit" class="btn btn-info d-md-block mx-auto">Submit</button>
                        </div>
                        <div class="col-md-4 text-center mx-auto">
                            <button [routerLink]="['/step-four']" routerLinkActive="active" class="btn btn-primary d-md-block mx-auto">Next <i class="fa fa-angle-right"></i></button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</div>
</section>