<section style="padding-bottom: 50px">
   <div class="serviceHeading">
      <div class="container">
         <div class="row">
            <div class="col-12 text-center">
               <h1 class="text-white">{{serviceList.service_name}}</h1>
               <ul class="breadcrumb">
                  <li><a href="/">Home</a></li>
                  <li><a href="#">{{serviceList.service_name}}</a></li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="serviceSlide"></section>
<section class="section">
   <div>
      <div class="container">
         <div class="row">
            <div class="col-2" style="display: none">
               <div class="serviceSidebar bg-white" style="padding-bottom: 0.1rem!important">
                  <ul class="list-unstyled">
                     <li class="nav-item">
                        <h6 class="nav-link active orange2 text-white">START A BUSINESS</h6>
                        <ul class="list-unstyled innerList pt-1 pb-2">
                           <li class="nav-item d-flex align-item-center ml-3
                              ml-3
                              ml-3
                              ml-3
                              ml-3">
                              <a href="#home" class="nav-link small text-dark"  style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                           <li class="nav-item d-flex align-item-center ml-3
                              ml-3
                              ml-3
                              ml-3
                              ml-3">
                              <a href="#home" class="nav-link small text-dark"  style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                           <li class="nav-item d-flex align-item-center ml-3
                              ml-3
                              ml-3
                              ml-3
                              ml-3">
                              <a href="#home" class="nav-link small text-dark"  style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                           <li class="nav-item d-flex align-item-center ml-3
                              ml-3
                              ml-3
                              ml-3
                              ml-3">
                              <a href="#home" class="nav-link small text-dark"  style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                           <li class="nav-item d-flex align-item-center ml-3
                              ml-3
                              ml-3
                              ml-3
                              ml-3">
                              <a href="#home" class="nav-link small text-dark"  style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                        </ul>
                     </li>
                     <li class="nav-item">
                        <h6 class="nav-link orange2 text-white">GST REGISTRATION</h6>
                        <ul class="list-unstyled innerList pt-1 pb-2">
                           <li class="nav-item d-flex align-item-center ml-3
                              ml-3
                              ml-3
                              ml-3
                              ml-3">
                              <a href="#menu1" class="nav-link small text-dark" style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                           <li class="nav-item d-flex align-item-center ml-3
                              ml-3
                              ml-3
                              ml-3
                              ml-3">
                              <a href="#menu1" class="nav-link small text-dark" style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                           <li class="nav-item d-flex align-item-center ml-3
                              ml-3
                              ml-3
                              ml-3
                              ml-3">
                              <a href="#menu1" class="nav-link small text-dark" style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                           <li class="nav-item d-flex align-item-center ml-3
                              ml-3
                              ml-3
                              ml-3
                              ml-3">
                              <a href="#menu1" class="nav-link small text-dark" style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                           <li class="nav-item d-flex align-item-center ml-3
                              ml-3
                              ml-3
                              ml-3
                              ml-3">
                              <a href="#menu1" class="nav-link small text-dark" style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                        </ul>
                     </li>
                     <li class="nav-item">
                        <h6 class="nav-link orange2 text-white">SERVICE </h6>
                        <ul class="list-unstyled innerList pt-1 pb-0">
                           <li class="nav-item d-flex align-item-center ml-3">
                              <a href="#menu2" class="nav-link small text-dark" style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                           <li class="nav-item d-flex align-item-center ml-3">
                              <a href="#menu2" class="nav-link small text-dark" style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                           <li class="nav-item d-flex align-item-center ml-3">
                              <a href="#menu2" class="nav-link small text-dark" style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                           <li class="nav-item d-flex align-item-center ml-3">
                              <a href="#menu2" class="nav-link small text-dark" style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                           <li class="nav-item d-flex align-item-center ml-3">
                              <a href="#menu2" class="nav-link small text-dark" style="padding-top: 0.1rem; padding-bottom: 0.1rem;">Loremip sum is dummy text</a>
                           </li>
                        </ul>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="col-9">
               <div class="sidebar-content">
                  <div id="home" class="pb-4">
                      <div class="col-sm-6 col-12 mb-0 mb-sm-5 text-center text-sm-left">
                     <div class="" style="background: linear-gradient(#3f96fd 0%,#3999ff 100%);width: 150px;height: 5px;margin-bottom: 15px;">
                     </div>                  
                  <!-- <h6 class="mb-1 ">OUR AWESOME WORK</h6> -->
                  <h2 class="">Overview</h2>
               </div>
                     <p>{{serviceList.short_desc}}</p>
                     <a  href="/get-a-quote" class="btn get-quote orange2 text-white btn-sm">Get a Quote <i class="fa fa-angle-right"></i></a>
                     
                  </div>
                  
               </div>
            </div>
            <div class="col-3">
               <div class="enquire-form py-3 px-3 border-0 bg-white rounded" style="z-index: 0;">
                  <form action="#" #LeadForm ngForm="LeadForm">
                     <h5 class="text-center mb-3">Get a Callback</h5>
                     <div class="form-group inputWithIcon inputIconBg" aria-hidden="true">
                        <input type="text" class="form-control" placeholder="Enter Name" id="name" [ngModelOptions]="{standalone: true}"  [(ngModel)]="inquery.name">
                        <i class="lni lni-user"></i>
                     </div>
                     <div class="form-group inputWithIcon inputIconBg" aria-hidden="true">
                        <input type="email" class="form-control" placeholder="Enter Email" id="email" [ngModelOptions]="{standalone: true}"  [(ngModel)]="inquery.email">
                        <i class="lni lni-envelope"></i>
                     </div>
                     <div class="form-group inputWithIcon inputIconBg" aria-hidden="true">
                        <input type="number" class="form-control" placeholder="Enter Phone" id="phone" [ngModelOptions]="{standalone: true}"  [(ngModel)]="inquery.phone">
                        <i class="lni lni-phone"></i>
                     </div>
                     <div class="form-group">
                        <textarea class="form-control" rows="3" id="message" placeholder="Message" [ngModelOptions]="{standalone: true}"  [(ngModel)]="inquery.msg"></textarea>
                     </div>
                     <div class="text-center">
                        <a (click)="insertInquery(); LeadForm.reset();" class="btn btn-sm orange2 text-white font-weight-bold w-100"><i class="fas fa-phone-alt"></i> Call Us</a>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>

<section class="section gray2 " style="padding: 100px 0;">
   <!--blog-->
   <div class="container">
      
       <div class="row">
           <div class="col text-left">
               <div class="mr-auto" style="background: linear-gradient(#3f96fd 0%, #3999ff 100%); width: 150px; height: 5px; margin-bottom: 20px;"></div>
               <h6 class="">Knowledge Base For You</h6>
               <h2 class="mb-4"><b>Related Blogs</b></h2>
           </div>
       </div>
        <div class="row">
           <div class="col-sm-4 col-4 rounded text-center ml-1 mx-auto">
               <a class="text-dark bg-white singleblog" href="/blog-detail/international-financial-reporting-standards-ifrs">
                   <img src="http://onlinesoftware360.com/vmr/demo/admin/images/blog_image/blog1.jpg" class="img-fluid">
                   <div class="blog_text">
                       <h4 class="">International Financial Reporting Standards (IFRS)</h4>
                       <p class="mb-4">As the name suggests, IFRS is a set of standards suggesting how to report financial statement at international level. While maintaining company’s account, preparing and reporting financial statement....</p>
                   </div>
               </a>
           </div> <!-- single blog -->
            <div class="col-sm-4 col-4 rounded text-center ml-1 mx-auto">
               <a class="text-dark bg-white singleblog" href="/blog-detail/equalization-levy-or-google-tax">
                   <img src="http://onlinesoftware360.com/vmr/demo/admin/images/blog_image/blog2.png" class="img-fluid">
                   <div class="blog_text">
                       <h4 class="">Equalization Levy or Google Tax</h4>
                       <p class="mb-4">E-commerce companies provide a platform where supply and procurement of goods and services takes place digitally......</p>
                   </div>
               </a>
           </div> <!-- single blog -->
            <div class="col-sm-4 col-4 rounded text-center ml-1 mx-auto">
               <a class="text-dark bg-white singleblog" href="/blog-detail/transfer-of-share">
                   <img src="http://onlinesoftware360.com/vmr/demo/admin/images/blog_image/blog31.png" class="img-fluid">
                   <div class="blog_text">
                       <h4 class="">Transfer of Shares</h4>
                       <p class="mb-4">Transfer of shares refers to the intentional transfer of title (rights as well as duties) of shares by one person to another.....</p>
                   </div>
               </a>
           </div> <!-- single blog -->
        </div>
   </div>
</section>
<script>
   $(document).ready(function(){
    $(this).scrollTop(0);
});
</script>