import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { WebapiService } from '../services/webapi.service';
@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {
   verifyId:any
  constructor(private arouter: ActivatedRoute,
    private WebapiService:WebapiService) { }

  ngOnInit(): void {
 this.verifyId = this.arouter.snapshot.queryParams.id;
  }

  verifyEmail(){
    let value={
      "id":this.verifyId,
    }
    this.WebapiService.verifyEmail(value).subscribe((res:any)=>{
    console.log(res);
    });
  }

}
