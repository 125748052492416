<style>
    .product-entry{
        background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url(../../assets/img/aerial-view.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 900px;
         padding-top: 150px;
    }
    .section-form{
        background: #fff;
    }
    .pull-left{
        float: left;
    }
    .card img{
        width: 50px;
    }
    .card{
        display: flex;
        align-items: center;
        padding: 40px;
        color: #212121;
    }
    .card:hover {
    background: #161616;
    color: #fff;
}
</style>

<section class="product-entry section" id="productEntry">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 offset-md-3">
                <div class="section-content">
                    <div class="section-title mb-md-4">
                        <h1 class="mb-md-0">{{prdtName}}</h1>
                    </div>
                    
                    <div class="col-md-12  bg-white">
                        <table class="table table-bordered">
                            <tr>
                                
                                <th>Code</th>
                                <th>Industry</th>
                                <th>Material</th>
                                <th>Description</th>
                            </tr>
                            <tr *ngFor="let product of productList">
                                
                                <td>{{product.item_name}}</td>
                                <td>{{product.industry_name}}</td>
                                <td>{{product.material_name}}</td>
                                <td>{{product.product_desc}}</td>
                            </tr>
                        </table>
                    </div> 

                    </div> 
 
                    </div> 
    
                    </div>
                    </div>
</section>