import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CategoryComponent } from './category/category.component';
import { AboutComponent} from './about/about.component';
import { ActivateGuard } from './activate.guard';
import { CheckoutComponent } from './checkout/checkout.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { ProductsComponent } from './products/products.component';
import { TicketComponent } from './tickets/ticket/ticket.component';
import { BlogComponent } from './blog/blog.component';
import { BlogdetailsComponent } from './blogdetails/blogdetails.component';
import { TeamComponent } from './team/team.component';
import { TeamdetailsComponent } from './team/teamdetails/teamdetails.component';
import { CareerComponent } from './career/career.component';
import { IndustryComponent } from './industry/industry.component';
//import { TeamMemComponent } from './team-mem/team-mem.component';
import { IndustrydetailComponent } from './industry/industrydetail/industrydetail.component';
import { GetAQuoteComponent } from './get-a-quote/get-a-quote.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { ServicesTriburgComponent } from './services-triburg/services-triburg.component';
import { PostOrderServicesComponent } from './post-order-services/post-order-services.component';
import { VendorRegisterComponent } from './vendor-register/vendor-register.component';
import { VendorContactComponent } from './vendor-contact/vendor-contact.component';
import { VendorDetailsComponent } from './vendor-details/vendor-details.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { ProductEntryComponent } from './product-entry/product-entry.component';
import { FilterComponent } from './filter/filter.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { EmpVendorListComponent } from './emp-vendor-list/emp-vendor-list.component';
import { LoginComponent } from './login/login.component';
import { VendorProfileComponent } from './vendor-profile/vendor-profile.component';
import { VendorProductsComponent } from './vendor-products/vendor-products.component'
import { VendorAccountComponent } from './vendor-account/vendor-account.component';
import {SteponeComponent} from './vendor-reg/stepone/stepone.component'
import { SteptwoComponent } from './vendor-reg/steptwo/steptwo.component';
import { StepthreeComponent } from './vendor-reg/stepthree/stepthree.component';
import { StepfourComponent} from './vendor-reg/stepfour/stepfour.component';
import { StepfiveComponent} from './vendor-reg/stepfive/stepfive.component';
import { StepsixComponent} from './vendor-reg/stepsix/stepsix.component';
import { StepsevenComponent} from './vendor-reg/stepseven/stepseven.component';
import { StepeightComponent} from './vendor-reg/stepeight/stepeight.component';
import { SignupComponent } from './signup/signup.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import {VerifyEmailComponent} from './verify-email/verify-email.component'
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';

// import { FilterComponent } from './filter/filter.component';


const routes: Routes = [
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent},
  { path: 'home', component: HomeComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'ticket', component: TicketComponent },
  { path: 'service/:id', component: ProductsComponent },
  { path: 'category', component: CategoryComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog-detail/:id', component: BlogdetailsComponent },
  { path: 'team', component: TeamComponent },
 { path: 'teamdetails', component: TeamdetailsComponent },
  { path: 'career', component: CareerComponent },
  { path: 'industry', component: IndustryComponent },
  { path: 'industrydetail/:id', component: IndustrydetailComponent },
  { path: 'get-a-quote', component: GetAQuoteComponent },
  { path: 'our-services', component: OurServicesComponent },
  { path: 'services-triburg', component: ServicesTriburgComponent },
  { path: 'post-order-services', component: PostOrderServicesComponent },
  { path: 'vendor-register', component: VendorRegisterComponent },
  { path: 'vendor-contact', component: VendorContactComponent },
  { path: 'step-one', component: SteponeComponent },
  { path: 'step-one/:id', component: SteponeComponent },
  { path: 'step-two', component: SteptwoComponent },
  { path: 'step-three', component: StepthreeComponent },
  { path: 'step-four', component: StepfourComponent },
  { path: 'step-five', component: StepfiveComponent },
  { path: 'step-six', component: StepsixComponent },
  { path: 'step-seven', component: StepsevenComponent },
  { path: 'step-eight', component: StepeightComponent },

  { path: 'vendor-details', component: VendorDetailsComponent},
  { path: 'add-customer', component: AddCustomerComponent },
  { path: 'product-entry', component: ProductEntryComponent },
  { path: 'product-entry/:id', component: ProductEntryComponent },
  { path: 'filter', component: FilterComponent },
  { path: 'filter/:prdId', component: FilterComponent },
  { path: 'my-account', component: MyAccountComponent, canActivate:[ActivateGuard] },
  { path: 'vendor-list', component: EmpVendorListComponent },
  { path:'vendor-profile', component:VendorProfileComponent, canActivate:[ActivateGuard]},
  { path:'vendor-product', component:VendorProductsComponent, canActivate:[ActivateGuard]},
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'vendor-account', component: VendorAccountComponent },
  { path: 'product-detail', component: ProductDetailComponent },
  { path: 'verify-email/:id', component: VerifyEmailComponent },

  { path: 'admin', component: AdminDashboardComponent },


  { path: '', component: HomeComponent },

  { path: '',   redirectTo: '/home', pathMatch: 'full' }

];

@NgModule({
  // imports: [RouterModule.forRoot(routes, { useHash: false})],
  imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: "enabled",
  scrollOffset: [0, 0], anchorScrolling: "enabled", })],
  
  exports: [RouterModule]
})
export class AppRoutingModule { }
