<style>
    input[type="number"] {
       -moz-appearance: textfield;
       appearance: textfield;
       margin: 0;
   }
   input[type="number"]::-webkit-inner-spin-button,
   input[type="number"]::-webkit-outer-spin-button {
       -webkit-appearance: none;
       margin: 0;
   }

   .sign-in a {
  color: #fff !important;
}
.sign-in img {
  width: 30px;
}
.sign-in ul>li{
     display: inline-block;
     vertical-align: -webkit-baseline-middle;
 }
 .sign-in ul>li a{
     color: var(--white);
     margin-left: .25rem;
 }
 .search-box img{
      transform: translate(-6%, -50%);
      position: absolute;
      top: 50%;
      right: 6%;
 }
 .vendor-details {
      background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url(../../assets/img/aerial-view.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
  }
  
  a {
      color: #fff;
  }
  
  .dropdown-item {
      color: #212529 !important
  }
  
  .r-25 {
      right: 25px;
  }
  .you-deal-in{
      flex-wrap: wrap;
  }
  .you-deal-in>div{
      flex: 0 1 380px;
  }
</style>
<section class="vendor-details section" id="vendorDetails">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title mb-md-4 col-md-12  float-md-left">
                    <div class="col-md-6 float-md-left">
                        <h1 class="mb-md-0">Legal & Finance</h1>
                        <h2 style="color: #fff;font-size: 20px;margin-top: 10px;">{{companyName}}</h2>
                    </div>
                    <div *ngIf="empId" class="sign-in col-md-6 float-md-left text-right">
                        <ul class="text-md-center">
                            <li *ngIf="userLogin" >
                                <a (click)="myAccount(loginStatus)"><img src="assets/img/user.png" alt="" class="w-18">{{loginName}}</a>
                            </li>
                            <li  *ngIf="!userLogin">
                                <a [routerLink]="['/login']" class="signinbtn" routerLinkActive="active">Sign In</a>
                            </li>  
                            <li><span class="separator">|</span></li>
                            <li *ngIf="!userLogin" >
                                <a class="signupbtn" [routerLink]="['/signup']" routerLinkActive="active">Join Us</a>
                            </li>
                            <li *ngIf="userLogin">
                                <a (click)="logout()" href=""><img src="assets/img/logout.png" alt="" class="w-18">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div><!-- row -->
        <div class="row">
            <div class="col-12">
                <div class="step-menu-wrapper">
                    <ul class="d-flex">
                        <li class="flex-fill"><a [routerLink]="['/step-one']" routerLinkActive="active">Basic Information</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-two']" routerLinkActive="active">Company Profile</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-three']" routerLinkActive="active">Commercial</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-four']" routerLinkActive="active">Quality Management & Certifications</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-five']" routerLinkActive="active">Staffing</a></li>
                        <li class="flex-fill"><a class="active">Legal & Financial</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-seven']" routerLinkActive="active" >Social Compliance</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-eight']" routerLinkActive="active" >Infrastructure</a></li>
                    </ul>
                </div>
            </div>
        </div>
<div class="" id="leg-fin" role="tabpanel" aria-labelledby="lfin-tab">
    <div class="section-content bg-white p-md-3 h-auto clearfix">
        <div class="section-title mb-md-4">
            <!-- <h1 class="mb-md-0">Vendor Registration</h1> -->
        </div>
        <div class="section-form">
            <form #VendorForm ngForm="VendorForm" class="row" (ngSubmit)="legalData(lfData)">
                <div class="form-group col-md-4">
                    <label for="">Type of Company</label>
                    <select [(ngModel)]="lfData.company_type" name="company_type" class="custom-select">
                        
                        <option value="Public Limited Company">Public Limited Company</option>
                        <option value="Private Limited Company">Private Limited Company</option>
                        <option value="Joint-Venture Company">Joint-Venture Company</option>
                        <option value="Partnership Firm">Partnership Firm</option>
                        <option value="One Person Company">One Person Company</option>
                        <option value="Sole Proprietorship">Sole Proprietorship</option>

                    </select>
                </div>

                <div class="form-group col-md-4">
                    <label for="">Year of Establishment</label>
                    <input type="text" [(ngModel)]="lfData.year_of_establishment" name="year_of_establishment" class="form-control"  placeholder="Year of Establishment" />
                    
                </div>

                <div class="col-md-4 float-md-left">
                    <label for="">Year Export Started</label>
                    <div class="form-group">
                        <input type="text" [(ngModel)]="lfData.export" name="export" class="form-control"  placeholder="Year Export Started " />
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="col-md-3 float-md-left">
                        <label for="">Mode of Sale</label>
                    </div>
                    <div class="col-md-3 float-md-left form-check">
                        <label class="form-check-label"> <input type="checkbox" class="form-check-input" value="" />Export </label>
                    </div>
                    <div class="col-md-6 float-md-left form-check">
                        <label class="form-check-label"> <input type="checkbox" class="form-check-input" value="" />Domestic within India </label>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="lfData.tin" name="tin"  placeholder="TIN #" />
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <input type="text" class="form-control"  [(ngModel)]="lfData.rcmc_code" name="rcmc_code" placeholder="RCMC Code #" />
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <input type="text" class="form-control"  [(ngModel)]="lfData.council_name" name="council_name" placeholder="Council Name" />
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <input type="text" class="form-control"  [(ngModel)]="lfData.ie_code" name="ie_code" placeholder="IE Code #" />
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <input type="text" class="form-control"  [(ngModel)]="lfData.pan_no" name="pan_no" placeholder="PAN No. #" />
                    </div>
                </div>
                <div class="col-12"></div>
                <div class="col-md-8">
                    <div class="form-group">
                        <label for="">
                            Banker's Name & Address
                        </label>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <input type="text" [(ngModel)]="lfData.banker_name_and_address" name="banker_name_and_address" placeholder="Banker's Name" class="form-control">
                                </div>
                            </div>
                            <div class="w-100"></div>
                            <div class="col-6">
                                <div class="form-group">
                                    <input type="text"  [(ngModel)]="lfData.banker_address" name="banker_address" placeholder="Banker's Address" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group">
                        <input type="text" class="form-control"  [(ngModel)]="lfData.banker_name_and_address" name="banker_name_and_address" placeholder="Banker's Name & Address" />
                    </div> -->
                </div>
                <!-- <div class="col-12"></div> -->
                <div class="w-100"></div>
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="lfData.street_lf" name="street"  placeholder="Street" />
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" class="form-control"  [(ngModel)]="lfData.city_lf" name="city" placeholder="City" />
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <input type="number" class="form-control"  [(ngModel)]="lfData.pincode_lf" name="pincode" placeholder="Pincode" />
                    </div>
                </div>

                <div class="form-group col-md-3">
                    <input type="state" class="form-control" id="" [(ngModel)]="lfData.state_lf" name="state" placeholder="State" />
                    <!-- <select name="state" [(ngModel)]="lfData.state" class="custom-select">
                        <option selected>State</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Noida">Noida</option>
                    </select> -->
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <input type="number" class="form-control" name="phone" [(ngModel)]="lfData.phone_lf"   placeholder="Phone" />
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <input type="number" class="form-control" placeholder="Bank Swift No.">
                        <!-- <input type="number" class="form-control" name="fax" [(ngModel)]="lfData.fax"   placeholder="Fax" /> -->
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" class="form-control" name="banker_ad_code" [(ngModel)]="lfData.banker_ad_code"   placeholder="Bankers A.D. Code No" />
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <input type="number" name="ac_no" [(ngModel)]="lfData.ac_number"  class="form-control"  placeholder="A/C No." />
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group">
                        <input type="text" class="form-control" name="usa_routing_bank_name" [(ngModel)]="lfData.usa_routing_bank_name"   placeholder="USA Routing Bank Name" />
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <input type="text" class="form-control"  name="bank_ac_no" [(ngModel)]="lfData.bank_ac_number"  placeholder="Routing Bank A/c No." />
                            </div>
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <input type="text" class="form-control" name="bank_swift_no" [(ngModel)]="lfData.bank_swift_number"   placeholder="Bank Swift No" />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-md-3">
                    <div class="form-group">
                        <input type="number" class="form-control" name="bank_swift_no" [(ngModel)]="lfData.bank_swift_no"   placeholder="Bank Swift No." />
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <input type="number" class="form-control" name="iban_no" [(ngModel)]="lfData.iban_no"   placeholder="IBAN #" />
                    </div>
                </div> -->

                <!-- <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control"  name="euro_routing_bank_name" [(ngModel)]="lfData.euro_routing_bank_name"  placeholder="Euro Routing Bank Name" />
                    </div>
                </div> -->

                <div class="col-md-5">
                    <div class="form-group">
                        <label for="comment">Routing Bank Address</label>
                        <textarea class="form-control" rows="3" name="routing_bank_address" [(ngModel)]="lfData.routing_bank_address"  id="comment"></textarea>
                    </div>
                </div>

                <!-- <div class="col-md-4">
                    <div class="form-group">
                        <input type="text" class="form-control" name="routing_bank_iban" [(ngModel)]="lfData.routing_bank_iban"   placeholder="Routing Bank IBAN" />
                    </div>
                </div> -->

                <!-- <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" class="form-control"  name="bank_ac_no" [(ngModel)]="lfData.bank_ac_no"  placeholder="Routing Bank A/c No." />
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <input type="number" class="form-control" name="bank_swift_no" [(ngModel)]="lfData.bank_swift_no"   placeholder="Bank Swift No" />
                    </div>
                </div> -->

                <div class="col-md-12"></div>

                <!-- <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" name="usa_routing_bank_name" [(ngModel)]="lfData.usa_routing_bank_name"   placeholder="USA Routing Bank Name" />
                    </div>
                </div> -->

                <!-- <div class="col-md-5">
                    <div class="form-group">
                        <label for="comment">Routing Bank Address</label>
                        <textarea class="form-control" name="usa_routing_bank_address" [(ngModel)]="lfData.usa_routing_bank_address"  rows="3" id="comment"></textarea>
                    </div>
                </div> -->

                <!-- <div class="col-md-4">
                    <div class="form-group">
                        <input type="text" class="form-control" name="usa_routing_bank_iban" [(ngModel)]="lfData.usa_routing_bank_iban"   placeholder="Routing Bank IBAN" />
                    </div>
                </div> -->

                <!-- <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" class="form-control" name="usa_routing_bank_ac_no" [(ngModel)]="lfData.usa_routing_bank_ac_no"   placeholder="Routing Bank A/c No." />
                    </div>
                </div> -->

                <!-- <div class="col-md-3">
                    <div class="form-group">
                        <input type="number" class="form-control" name="usa_bank_swift_no" [(ngModel)]="lfData.usa_bank_swift_no"   placeholder="Bank Swift No" />
                    </div>
                </div> -->

                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control"  name="reg_with_custom" [(ngModel)]="lfData.reg_with_custom"  placeholder="Registered With Customs (Port / Container Depot) At" />
                        <small>THIS IS CONFIDENTIAL INFORMATION AND WILL NOT BE SHARED WITH ANY ONE EXCEPT A VERIFIED CUSTOMER WHO IS SEEKING TO WORK WITH YOU. </small>
                    </div>
                </div>
                <div class="col-12"></div>

                <!-- <div class="col-md-6">
                    <div class="">Wish to Disclose</div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="lfData.disclose" value="yes" class="form-check-input" name="discradio" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="lfData.disclose" value="no" class="form-check-input" name="discradio" />No </label>
                    </div>
                </div> -->

                <div class="col-md-4">
                    <div class="form-group">
                        <input type="text" class="form-control"  name="export_turnover" [(ngModel)]="lfData.export_turnover"  placeholder="Export Turnover" />
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <input type="number" class="form-control"  name="total_capital_invested" [(ngModel)]="lfData.total_capital_invested" placeholder="TOTAL CAPITAL INVESTED" />
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <input type="number" class="form-control"  name="current_net_worth" [(ngModel)]="lfData.current_net_worth" placeholder="CURRENT NET WORTH" />
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <input type="number" class="form-control"  name="growth" [(ngModel)]="lfData.growth" placeholder="GROWTH" />
                    </div>
                </div>

                <div class="col-md-8">
                    <label for="">Debt/Equity</label>
                    <input (change)="debt($event)" type="range" min="1" max="10000000" value="50000" steps="100000" class="w-100">
                    <p>{{debtValue}}</p>
                </div>

                <div class="w-100" style="margin-bottom: 32px;"></div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4 text-center mx-auto">
                            <button [routerLink]="['/step-five']" routerLinkActive="active" class="btn btn-secondary d-md-block mx-auto"><i class="fa fa-angle-left"></i> Previous</button>
                        </div>
                        <div class="col-md-4 text-center mx-auto">
                            <button type="submit" class="btn btn-info d-md-block mx-auto">Submit</button>
                        </div>
                        <div class="col-md-4 text-center mx-auto">
                            <button [routerLink]="['/step-seven']" routerLinkActive="active" class="btn btn-primary d-md-block mx-auto">Next <i class="fa fa-angle-right"></i></button>
                        </div>
                    </div>
                </div>

                <!-- <button type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button> -->
            </form>
        </div>
    </div>
</div>

</div>
</section>