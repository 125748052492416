import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
function _window() : any {
  // return the global native browser window object
  return window;
}
@Injectable({
  providedIn: 'root'
})
export class WebapiService {
  get nativeWindow() : any {
    return _window();
 }

  private url = 'https://mila.co.in/admin/App/';
  
  constructor(private http:HttpClient) { }

  isLogedIn(){
    return localStorage.getItem('loginId');
  }

  getUserData(id=""){
   return this.http.get(this.url+"getUser/"+id);
  }
  getCategory(id=""){
   return this.http.get(this.url+"getCategory/"+id);
  }
  getSubCategory(id=""){
    return this.http.get(this.url+"getSubCategory/"+id);
   }
  getCustomer(id=""){
    return this.http.get(this.url+"getCustomer/"+id);
   }
  getCountry(id=""){
    return this.http.get(this.url+"getCountry/"+id);
   }
  getTeam(id=""){
    return this.http.get(this.url+"getTeam/"+id);
   }
   getIndustry(id=""){
    return this.http.get(this.url+"getIndustry/"+id);
   }
   getStyle(id=""){
    return this.http.get(this.url+"getStyle/"+id);
   }
   getShape(id=""){
    return this.http.get(this.url+"getShape/"+id);
   }
   getColor(id=""){
    return this.http.get(this.url+"getColor/"+id);
   }
   getFinish(id=""){
    return this.http.get(this.url+"getFinish/"+id);
   }
   getCoating(id=""){
    return this.http.get(this.url+"getCoating/"+id);
   }
   getUsage(id=""){
    return this.http.get(this.url+"getUsage/"+id);
   }
   getVendor(id=""){
    return this.http.get(this.url+"getVendor/"+id);
   }

   getProductByFilter(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    }
   return this.http.post(this.url+"getProductByFilter/",form_data);
   }
   
  
  getAllBlogs(id=""){
    return this.http.get(this.url+"getAllBlogs/"+id);
   }
   getAllProduct(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    }
   return this.http.post(this.url+"getAllProduct/",form_data);
   }

   getCategoryByIndustry(data :any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    }
   return this.http.post(this.url+"getCategoryByIndustry/",form_data);
  }
  getSubCatByCategory(data :any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    }
   return this.http.post(this.url+"getSubCatByCategory/",form_data);
  }

  getProdBySubCategory(data :any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    }
   return this.http.post(this.url+"getProdBySubCategory/",form_data);
  }
  deleteImageById(data :any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    }
   return this.http.post(this.url+"deleteImageById/",form_data);
  }

  getCategoryBySlug(data :any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    }
   return this.http.post(this.url+"getCategoryBySlug/",form_data);
  }
  getImagesByProductId(data :any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    }
   return this.http.post(this.url+"getImagesByProductId/",form_data);
  }
  getServices(id=""){
   
   return this.http.get(this.url+"getServices/"+id);
  }
  getAllServices(id=""){
   
    return this.http.get(this.url+"getAllServices/"+id);
   }
  getServiceBySlug(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getServiceBySlug/",form_data);
   }
   getIndustryBySlug(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getIndustryBySlug/",form_data);
   }

   getProductByIndustry(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getProductByIndustry/",form_data);
   }

   getProductByMaterial(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getProductByMaterial/",form_data);
   }
   verifyProductById(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"verifyProductById/",form_data);
   }
   getProductByPrdtId(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getProductByPrdtId/",form_data);
   }
   getProductByStyle(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getProductByStyle/",form_data);
   }
   getProductByColor(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getProductByColor/",form_data);
   }
   getProductByShape(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getProductByShape/",form_data);
   }
   getProductByFinish(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getProductByStyle/",form_data);
   }
   getProductByCoating(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getProductByCoating/",form_data);
   }

   getRelatedSearch(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    }
      return this.http.post(this.url+"getRelatedSearch/",form_data);
   }
   
  getSlider(id=""){
   
    return this.http.get(this.url+"getSlider/"+id);
   }
   getMaterial(id=""){
   
    return this.http.get(this.url+"getMaterial/"+id);
   }
   getMaterialNames(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getMaterialNames/", form_data);
  }
  
  deleteUser(id: string | number){
    //const url ='https://www.pahadilala.com/admin/App2/getUser';
   return this.http.get(this.url+"deleteUser/"+id);
  }
  insertFormData(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertUser/", form_data);
  }
  insertContactData(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertContactData/", form_data);
  }
  insertInquery(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertLead/", form_data);
  }
  submitQuote(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertLeads/", form_data);
  }
  
  getLocation(id=""){
   
    return this.http.get(this.url+"getLocation/"+id);
   }
   insertVendor(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertVendor/", form_data);
  }
  registerVendor(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"registerVendor/", form_data);
  }
  insertVendorContact(data:any){
    var form_data= new FormData();
    // for(var key in data){
    //   form_data.append(key, data['file']);
    // } 
    form_data.append('data',JSON.stringify(data));
      form_data.append('file', data['img']);
      form_data.append('file2', data['factoryPhotos']);
    return this.http.post(this.url+"insertVendorContact/",form_data);
  }

  insertVendorCommercial(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertVendorCommercial/", form_data);
  }
  insertQc(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertQc/", form_data);
  }

  getEmailCheck(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getEmailCheck/", form_data);
  }
  updateVendorStatus(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"updateVendorStatus/", form_data);
  }
  verifyEmail(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"verifyEmail/", form_data);
  }
  updateProductStatus(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"updateProductStatus/", form_data);
  }
  uploadFile(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"uploadFile/", form_data);
  }
  doLogin(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"doLogin/", form_data);
  }
  insertProduct(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertProduct/", form_data);
  }
  getVendorByEmpId(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getVendorByEmpId/", form_data);
   }
   getVendorById(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getVendorById/", form_data);
   }
   getProductNames(id=""){
    return this.http.get(this.url+"getProduct/"+id);
   }

   getProductByVendorId(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getProductByVendorId/", form_data);
   }
   getProductById(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getProductById/", form_data);
   }

   insertQualityData(data:any){
    
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertQualityData/", form_data);
   }
   insertStaffingData(data:any){
    
    var form_data= new FormData();
    // for(var key in data){
      form_data.append('data', JSON.stringify(data));
      form_data.append('venderId', data['venderId']);
    // } 
    return this.http.post(this.url+"insertStaffingData/", form_data);
   }
   insertLegalData(data:any){
    
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertLegalData/", form_data);
   }
   insertSocialCompliance(data:any){
    
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertSocialCompliance/", form_data);
   }
   insertInfrastructureData(data:any){
    
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertInfrastructureData/", form_data);
   }
   getCountryById(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getCountryById/", form_data);
   }

   getPreServicesByStatus(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getPreServicesByStatus/", form_data);
   }
   getPostServicesByStatus(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getPostServicesByStatus/", form_data);
   }
   getServiceById(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getServiceById/", form_data);
   }
   getUserById(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getUserById/", form_data);
   }
   uploadPhotos(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"uploadPhotos/", form_data);
   }
   uploadFacPhotos(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"uploadFacPhotos/", form_data);
   }
   
}
