import { Component, OnInit,ViewChild,Renderer2,ElementRef} from '@angular/core';
import { WebapiService } from '../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-product-entry',
  templateUrl: './product-entry.component.html',
  styleUrls: ['./product-entry.component.css']
})
export class ProductEntryComponent implements OnInit {
  @ViewChild('div') div!: ElementRef;

  prdData:any={};
  admData:any={};
  userId:any;
  industry: any;
  material: any=[];
  style:any;
  shape:any;
  finish:any;
  coating:any;
  color:any;
  categoryList: any;
  loginStatus:any
  venderList: any;
  counter:any
  productPhotosArr:any=[];
  productImgData:any=[];
  materialData:any=[];
  empId: any;
  userLogin: any;
  counterFlag: any=0;
  usage: any;
  subCategoryList: any;
  product: any;
  cartonLen:any;
  cartonWid:any;
  cartonHei:any;
  minPrice:any;
  premCounter:boolean = false;
  standCounter: boolean = false;
  loginName:any;
  productId: any;
  imageList: any;
  actCounter: boolean=false;
  vendorId:any

  constructor(private router: Router,
    private WebapiService:WebapiService,private activated_Route: ActivatedRoute,private sanitizer: DomSanitizer,private renderer: Renderer2) { 

    }

  ngOnInit(): void {
    this.getMaterial();
    this.productId = this.activated_Route.snapshot.queryParams.id;
    this.vendorId = this.activated_Route.snapshot.queryParams.vd;

    if(this.productId){
      setTimeout(() => {
        this.getProductById(this.productId);
      }, 300);
  
      this.getImagesByProductId(this.productId);
    }
    this.getStyle();
    this.getColor();
    this.getFinish();
    this.getShape();
    this.getCoating();
    this.getIndustry();
    this.getUsage();
    this.empId = localStorage.getItem('loginId');
    this.userLogin = localStorage.getItem('loginId');
    this.loginStatus = localStorage.getItem('loginStatus');
    this.loginName=localStorage.getItem('name');
    this.getVendor();
    // console.log(this.venderId);  

  }

  productForm(data:any){
  // console.log(data);
  // this.uploadPhotos(1);
  if(this.productId){
    data.id = this.productId;
  }

  for(let val of this.material){
    if(val.selected){

      this.materialData.push(val.id);
     }
     data.material = this.materialData;
   }

  if(data.stock_price && !data.stock_quantity){
    alert("Stock Quantity must be specified");

  }
  else if(!data.stock_price && data.stock_quantity){
    alert("Stock Price must be specified");
  }
  else if(data.material.length<1){
     alert("material selection is required");
  }
  else{

    console.log(data);
      this.WebapiService.insertProduct(data).subscribe((res:any)=>{
      // console.log(res);
      if(res.status == 1){
        alert(res.msg);
        this.prdData = {};
        this.uploadPhotos(res.last_id);
      }
      else if(res.status == 2){
        alert(res.msg);
        this.uploadPhotos(res.last_id);
      }
      else{
        alert(res.msg);
        location.reload();
      }
    });

  }
  }
  deleteImageById(id:any){
    let val={
      "id":id
    }

    this.WebapiService.deleteImageById(val).subscribe((res:any)=>{
      if(res.status == 1){
        alert(res.msg);
        location.reload();
      }
      else{
        alert(res.msg)
        location.reload();
      }
    });

  }
  


   checkMaterial(id){
      if(this.materialData.length>0){
        let exist = this.materialData.filter(x => x == id);
        return exist.length > 0;
      }else{
        return false;
      }
  }

  logout(){
    localStorage.removeItem('loginId');
    localStorage.removeItem('loginStatus');
    this.router.navigate(['/']);
  }

  getStyle(){
    this.WebapiService.getStyle().subscribe((res:any)=>{
      // console.log(res);
      this.style =res;
    });
   }
   getUsage(){
    this.WebapiService.getUsage().subscribe((res:any)=>{
      // console.log(res);
      this.usage =res;
    });
   }
   getShape(){
    this.WebapiService.getShape().subscribe((res:any)=>{
      // console.log(res);
      this.shape =res;
    });
   }
   getColor(){
    this.WebapiService.getColor().subscribe((res:any)=>{
      // console.log(res);
      this.color =res;
    });
   }
   getFinish(){
    this.WebapiService.getFinish().subscribe((res:any)=>{
      // console.log(res);
      this.finish =res;
    });
   }
   getCoating(){
    this.WebapiService.getCoating().subscribe((res:any)=>{
      // console.log(res);
      this.coating =res;
    });
   }

   getIndustry(){
    this.WebapiService.getIndustry().subscribe((res:any)=>{
      // console.log(res);
      this.industry =res;
    });
   }
   getCategory(){
    this.WebapiService.getCategory().subscribe((res:any)=>{
      // console.log(res);
      this.categoryList =res;
    });
   }
   getSubCategory(){
    this.WebapiService.getSubCategory().subscribe((res:any)=>{
      // console.log(res);
      this.subCategoryList =res;
    });
   }
   getProductNames(){
    this.WebapiService.getProductNames().subscribe((res:any)=>{
      // console.log(res);
      this.product =res;
    });
   }

   getMaterial(){
    this.WebapiService.getMaterial().subscribe((res:any)=>{
      // console.log(res);
      this.material = res;
      
      
      for(let key of this.material){
        key.selected = false;
      }
    });
  }
  getVendor(){
    this.WebapiService.getVendor().subscribe((res:any)=>{
      // console.log(res);
      this.venderList =res;
    });
  }
  myAccount(e:any){
    //  console.log(e);
     if(e==2){
       this.router.navigate(['/vendor-account'])
     }
     else if(e==3){
       this.router.navigate(['/my-account'])
     }
     else if(e==4){
       this.router.navigate(['/admin'])
     }
     else{
       console.log("hi there");
       
     }
    }

  onFileChange(event:any) {
      let ext = event.target.files[0];

      console.log(ext.type);
      

    if(ext.type == "image/jpeg" || ext.type == "image/png"){
      let value = {
                      "tmpImg":event.target.files[0],
                     "shw":this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(event.target.files[0]))
                   }
                   this.productPhotosArr.push(value);
                   this.productImgData.push(event.target.files[0]);
    }
    else{
      alert("Please select JPG or PNG file");
      event.target.value =""
    }
       
}
removeImage(url:any){
  this.productPhotosArr = this.productPhotosArr.filter(image => (image.shw != url));
  for(let i=0; i<this.productPhotosArr.length; i++){
    this.productImgData = [];
    this.productImgData.push(this.productPhotosArr[i].tmpImg);
  }

}
addRow(a:any){
  // console.log(a); 
  if(this.counterFlag < 4){
  const p: HTMLInputElement = this.renderer.createElement('input');
  const button1:HTMLAnchorElement= this.renderer.createElement('a');
    this.renderer.addClass(p,"form-control");
    this.renderer.addClass(button1,`btn`);
    this.renderer.addClass(button1,`btn-remove`);
    this.renderer.setProperty(p,"type","file");
    this.renderer.setProperty(p,"id",`row${this.counterFlag}`);
    this.renderer.setProperty(button1,"innerHTML","Remove");
    this.renderer.listen(p,"change",(e:any)=>{
      let ext = e.target.files[0];
      if(ext.type == "image/jpeg" || ext.type == "image/png"){
        let value = {
                        "tmpImg":e.target.files[0],
                       "shw":this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(e.target.files[0]))
                     }
                     this.productPhotosArr.push(value);
                     for(let i=1; i<this.productPhotosArr.length; i++){
                      this.productImgData.push(this.productPhotosArr[i].tmpImg);
                    }
                    // console.log(this.productImgData);
                    
           }
           else{
             alert("Please select JPG file");
             e.target.value =""

           }
        
    })
    this.renderer.listen(button1,"click",(e:any)=>{
      this.counterFlag--;
      // console.log(this.counterFlag);
      
      // console.log(e.path[1].childNodes);
      this.renderer.removeChild(e.path[1],e.path[1].childNodes[0]);
      this.renderer.removeChild(e.path[1],e.path[1].childNodes[0]);
      
  })
    this.renderer.appendChild(this.div.nativeElement, p);
    this.renderer.appendChild(this.div.nativeElement, button1);
    this.counterFlag++;
  }
  else{
    alert("only five images can be selected");
  }
}
uploadPhotos(id:any){
  // console.log(this.productImgData);
  

  for(let i=0; i<this.productImgData.length; i++){
    let val = {"product_id":id, "file":this.productImgData[i]};
    console.log(val);
    
    this.WebapiService.uploadPhotos(val).subscribe((res:any)=>{
         console.log(res);
         if(res.status == 200){
           alert(res.msg);
           location.reload();
         }
         else if(res.status == 400){
           alert(res.msg);
           location.reload();
         }
         else{
           alert(res.msg);
           location.reload();
         }
    });
  }
  
}

 convertToLbs(e:any){
if(e.target.value){
 let lb = (e.target.value/0.45359237).toFixed(2);
//  console.log(lb);
 this.prdData.weight_in_lbs = lb;
}
 
 
 }
 convertToKg(e:any){
if(e.target.value){
 let kg = (e.target.value*0.45359237).toFixed(2);
// console.log(lb);
this.prdData.weight_in_kg = kg;
}

 }

 convertLenToCm(e:any){
  if(e.target.value){
    let cm = e.target.value * 2.54;
  //  console.log(cm);
   this.prdData.length_in_cm = cm;
   }
 }
 convertWidToCm(e:any){
  if(e.target.value){
    let cm = e.target.value * 2.54;
  //  console.log(cm);
      this.prdData.width_in_cm = cm;

   }
 }
 convertHeiToCm(e:any){
  if(e.target.value){
    let cm = e.target.value * 2.54;
  //  console.log(cm);
      this.prdData.height_in_cm = cm;

   }
 }
 convertDiaToCm(e:any){
  if(e.target.value){
    let cm = e.target.value * 2.54;
  //  console.log(cm);
      this.prdData.diameter_in_cm = cm;

   }
 }

 convertLenToInch(e:any){
  if(e.target.value){
    let inch = (e.target.value *  0.3937).toFixed(2);
  //  console.log(cm);
      this.prdData.length_in_inch = inch;

   }
 }

 convertWidToInch(e:any){
  if(e.target.value){
    let inch = (e.target.value *  0.3937).toFixed(2);
  //  console.log(cm);
      this.prdData.width_in_inch = inch;

   }
 }

 convertHeiToInch(e:any){
  if(e.target.value){
    let inch = (e.target.value *  0.3937).toFixed(2);
  //  console.log(cm);
      this.prdData.height_in_inch = inch;

   }
 }

 convertDiaToInch(e:any){
  if(e.target.value){
    let inch = (e.target.value *  0.3937).toFixed(2);
  //  console.log(cm);
      this.prdData.diameter_in_inch = inch;

   }
 }
 onCartonLength(e:any){
   this.cartonLen = e.target.value;
 }
 onCartonWidth(e:any ){
  this.cartonWid = e.target.value;
   
}
onCartonHeight(e:any){
  this.cartonHei = e.target.value;
  let vol = (( this.cartonLen * this.cartonWid * this.cartonHei ) / 1000000).toFixed(4);
  this.prdData.vol_per_price = vol; 
}

//  onCheckMaterial(e:any){
//   if (e.target.checked == true) {
//     this.materialData.push(e.target.value);
//   }else{
//     var index = this.materialData.indexOf(e.target.value);
//     this.materialData.splice(index, 1);
//   }
//   console.log(this.materialData);
// this.prdData.material = this.materialData;
// }

getCatByIndustry(e:any){
    // console.log("hi");
    
    let id ={
      "id": e.target.value
    };
    this.WebapiService.getCategoryByIndustry(id).subscribe((res:any)=>{
    //  console.log(res);
     this.categoryList =res;
   }); 
   
}
getSubCatByCategory(e:any){
    let id ={
      "id": e.target.value
    };
   this.WebapiService.getSubCatByCategory(id).subscribe((res:any)=>{
    // console.log(res);
    this.subCategoryList =res;
  });

}
getProdBySubCategory(e:any){
    let id ={
      "id": e.target.value
    };
   this.WebapiService.getProdBySubCategory(id).subscribe((res:any)=>{
    // console.log(res);
    this.product =res;
  });
 
}
getImagesByProductId(id){
  let val ={
    "id": id
  };
 this.WebapiService.getImagesByProductId(val).subscribe((res:any)=>{
  // console.log(res);
  this.imageList =res;
});

}
getProductByVendorId(id:any){
  let val = {
    'id':id
  }
  this.WebapiService.getProductByVendorId(val).subscribe((res:any)=>{
    console.log(res);
  //  console.log(this.prdData);
  });
}
priceMin(e:any){
//  console.log(e.target.value);
 if(e.target.value){
   this.minPrice = e.target.value;
 }
 
}
onPriceChange(e:any){
   let avg = this.minPrice * 0.7;
  //  console.log(avg);
   
   if(e.target.value > avg){
     this.prdData.stock_price = "";
    alert("STOCK PRICE SHOULD BE 30% LOWER THEN MOQ1 PRICE VALUE");
   } 

}

adminVerification(data:any){
  // console.log(data);
  data.id = this.productId;
  this.WebapiService.verifyProductById(data).subscribe((res:any)=>{
    // console.log(res);
 if(res.status == 1){
   this.getUpdatedProduct(res.id);
 }
 else{
   alert("error");
 }
});
  
}
onChangeMarket(e:any){
  if(e.target.value == "Premium"){
    this.premCounter = true;
    this.standCounter = false;
  }
  else if(e.target.value == "Standard"){
    this.standCounter = true;
    this.premCounter = false;
  }
  else{
    this.premCounter = false;
    this.standCounter = false;
  }

}

onApprove(e:any){
  // console.log(e.target.value);
  if(e.target.value){
    this.admData.admPrdtStatus = e.target.value;
  }
}

getProductById(id:any){
  let val ={
      "id": id
    };
   this.WebapiService.getProductById(val).subscribe((res:any)=>{
  //  console.log(res);
   if(res.length > 0 ){
     this.prdData = res[0];
     this.materialData = res[0]?.material.split(',');
     for(let mat of this.material){
       for(let getMat of this.materialData){
        if(getMat == mat.id){
          mat.selected=true;
        }
      }
     }
     
     this.admData.verifydprdt = res[0].verifydprdt;
     this.admData.bestsell = res[0].bestsell;
     this.admData.market = res[0].market;
     this.admData.moqpriceadmin = res[0].moqpriceadmin;
     this.admData.moqadmin = res[0].moqadmin;
     this.admData.moqpriceadmin2 = res[0].moqpriceadmin2;
     this.admData.moqadmin2 = res[0].moqadmin2;
     this.admData.moqpriceadmin3 = res[0].moqpriceadmin3;
     this.admData.moqadmin3 = res[0].moqadmin3;
     this.admData.retailprice = res[0].retailprice;
     this.admData.moqpriceadmin2 = res[0].moqpriceadmin2;
     this.admData.moqpriceadmin2 = res[0].moqpriceadmin2;
     this.admData.moqpriceadmin2 = res[0].moqpriceadmin2;
     this.getCategory();
     this.getSubCategory();
     this.getProductNames();
     this.actCounter=true;
   }
   
  });
 
 }
 negativeAlert(e:any){
   if(e.target.value < 0){
     alert("Number cannot be neagtive");
     e.target.value = "";
   }
 }
 decimalAlert(e:any){
  let val = e.target.value.indexOf(".");
  // console.log(val);
  
  if(val > 0){
    alert("Cannot enter Decimal Value");
    e.target.value = "";

  }
  
}
twoDecimal(e:any){
  // console.log(e.target.value);
  
 let sp =  e.target.value.split(".");
 let size  = sp[1]?.length;
 if(size > 2){
   alert("Please select a valid number");
   e.target.value = "";
 }
  
}

 getUpdatedProduct(id:any){
  let val ={
    "id": id
  };
 this.WebapiService.getProductById(val).subscribe((res:any)=>{
  // console.log(res);
  if(res[0].admPrdtStatus == 1){
    alert("Product Approved");
    this.router.navigate(['/vendor-product'], { queryParams: { id: this.vendorId} });
  }
  else if(res[0].admPrdtStatus == 2){
    alert("Product Rejected");
            this.router.navigate(['/vendor-product'], { queryParams: { id: this.vendorId} });

  }
  else{
    alert("Product Pending");
            this.router.navigate(['/vendor-product'], { queryParams: { id: this.vendorId} });

  }
 });
 }
}
