<footer>
    <section class="footer-section section p-md-0">
        <div class="copyright py-3 p-md-3 text-md-center clearfix">
            <!-- <h6 class="mb-0 text-center">Copyright © 2021. All Rights Reserved.</h6> -->
            <div class="copyright-title col-md-5 float-left offset-md-1">
                <h6 class="mb-0 text-left">Copyright © 2021. All Rights Reserved. MILA MERCHANDIZING CORPORATION</h6>
            </div>
            <div class="copyright-links col-md-5 float-left d-md-flex justify-content-md-end">
                <h6 class="mb-0"><a href="" class="text-white">PRIVACY POLICY</a></h6>
                <h6 class="mb-0 ml-md-3"><a href="" class="text-white">TERMS & CONDITIONS</a></h6>
            </div>
        </div>
    </section>
</footer>