<style>
     input[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .sign-in a {
  color: #fff !important;
}
.sign-in img {
  width: 30px;
}
.sign-in ul>li{
     display: inline-block;
     vertical-align: -webkit-baseline-middle;
 }
 .sign-in ul>li a{
     color: var(--white);
     margin-left: .25rem;
 }
 .search-box img{
      transform: translate(-6%, -50%);
      position: absolute;
      top: 50%;
      right: 6%;
 }
 .vendor-details {
      background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url(../../assets/img/aerial-view.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
  }
  
  a {
      color: #fff;
  }
  
  .dropdown-item {
      color: #212529 !important
  }
  
  .r-25 {
      right: 25px;
  }
  .you-deal-in{
      flex-wrap: wrap;
  }
  .you-deal-in>div{
      flex: 0 1 380px;
  }
</style>

<section class="vendor-details section" id="vendorDetails">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title mb-md-4 col-md-12  float-md-left">
                    <div class="col-md-6 float-md-left">
                        <h1 class="mb-md-0">Basic Information</h1>
                    </div>
                    <div *ngIf="empId" class="sign-in col-md-6 float-md-left text-right">
                        <ul class="text-md-center">
                            <li *ngIf="userLogin" >
                                <a (click)="myAccount(loginStatus)"><img src="assets/img/user.png" alt="" class="w-18">{{loginName}}</a>
                            </li>
                            <li  *ngIf="!userLogin">
                                <a [routerLink]="['/login']" class="signinbtn" routerLinkActive="active">Sign In</a>
                            </li>  
                            <li><span class="separator">|</span></li>
                            <li *ngIf="!userLogin" >
                                <a class="signupbtn" [routerLink]="['/signup']" routerLinkActive="active">Join Us</a>
                            </li>
                            <li *ngIf="userLogin">
                                <a (click)="logout()" href=""><img src="assets/img/logout.png" alt="" class="w-18">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div><!-- row -->
        <div class="row">
            <div class="col-12">
                <div class="step-menu-wrapper">
                    <ul class="d-flex">
                        <li class="flex-fill"><a class="active">Basic Information</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-two']" routerLinkActive="active" >Company Profile</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-three']" routerLinkActive="active" >Commercial</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-four']" routerLinkActive="active" >Quality Management & Certifications</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-five']" routerLinkActive="active" >Staffing</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-six']" routerLinkActive="active" >Legal & Financial</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-seven']" routerLinkActive="active" >Social Compliance</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-eight']" routerLinkActive="active" >Infrastructure</a></li>
                    </ul>
                </div>
            </div>
        </div>
              
<!-- *ngIf="loginStatus == 3" -->
<div class="" id="ven-reg" role="tabpanel" aria-labelledby="vreg-tab">  
    <div class="section-content bg-white p-md-3 h-auto clearfix">
        <div class="section-title mb-md-4">
        </div>
        <div class="section-form">
            <form #RegVen="ngForm" (ngSubmit)="RegVen.valid && insertVendor(venreg)">
                <div class="">
                    <div class="form-group col-md-4">
                        <input type="text" #cname="ngModel" name="cname" [(ngModel)]="venreg.cname" class="form-control" placeholder="Company Name" [class.is-invalid]="cname.invalid && RegVen.submitted" required="required"/>
                        <div class="invalid-feedback">
                            Valid Company name is required.
                          </div>
                    </div>
                    <div class="col-12"></div>
                    <div class="form-group col-md-1 float-left">
                        <select class="custom-select" name="title"  [(ngModel)]="venreg.title">
                            <option *ngFor="let title of titleList" value="{{title}}">{{title}}</option>
                            <!-- <option value="Mrs">Mrs.</option>
                            <option value="Ms">Ms.</option> -->
                        </select>
                    </div>
                    <div class="form-group col-md-3 float-left">
                        <input type="text" #fname="ngModel" name="fname" [(ngModel)]="venreg.fname" class="form-control" [class.is-invalid]="fname.invalid && RegVen.submitted" required="required" placeholder="First Name" />
                        <div class="invalid-feedback">
                            Valid first name is required.
                          </div>
                    </div>

                    <div class="form-group col-md-3 float-left">
                        <input type="text" #lname="ngModel" name="lname" [(ngModel)]="venreg.lname" class="form-control" required="required"  placeholder="Last Name" [class.is-invalid]="lname.invalid && RegVen.submitted" />
                        <div class="invalid-feedback">
                            Valid last name is required.
                          </div>
                    </div>
                </div>
                
                <!-- <div class="form-group col-md-6 float-left">
                    <input type="text"  [(ngModel)]="venreg.wname" class="form-control" id="exampleInputWebsite" aria-describedby="website" placeholder="Website" />
                </div> -->
                
                <div class="form-group col-md-6 float-left">
                    <input type="number" name="mob" #mob="ngModel"  [(ngModel)]="venreg.mob" class="form-control" pattern="^((\\+91-?)|0)?[0-9]{10}$" min="10" max="10" placeholder="Mobile"  required="required"   />
                    <div *ngIf="!mob?.valid && (mob?.dirty || mob?.touched)">
                        <div style="/* position: absolute; */bottom: -21px;left: 19%;width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="mob.errors?.required">
                          Mobile number is required
                        </div>
                      <div style="/* position: absolute; */bottom: -21px;left: 19%;width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="mob.errors?.pattern">
                         please enter valid mobile number
                      </div>
                      </div>
                </div>
                <div class="form-group col-md-6 float-left">
                    <input type="text" name="offnum" #offnum="ngModel" [(ngModel)]="venreg.offnum" class="form-control" placeholder="Office Number"  required="required"  />
                    <div *ngIf="!offnum?.valid && (offnum?.dirty || offnum?.touched)">
                        <div style="/* position: absolute; */bottom: -21px;left: 19%;width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="offnum.errors?.required">
                         office number is required
                        </div>
                      
                      </div>
                </div>
                <div class="form-group col-md-6 float-left">
                    <!-- <textarea class="form-control"  [(ngModel)]="venreg.offadd" id="exampleFormOffAddress" rows="1" placeholder="Office Address"></textarea> -->
                    <input type="text" name="offadd" #offadd="ngModel" [(ngModel)]="venreg.offadd" class="form-control" [class.is-invalid]="offadd.invalid && RegVen.submitted" required="required" placeholder="Office Address" />
                    <div class="invalid-feedback">
                        Valid office address is required.
                      </div>
                </div>
                <div class="form-group col-md-6 float-left">
                    <input type="text" name="street" #street="ngModel" [(ngModel)]="venreg.street" class="form-control" [class.is-invalid]="street.invalid && RegVen.submitted" required="required"  placeholder="Street / Area" />
                    <div class="invalid-feedback">
                        Valid street is required.
                      </div>
                </div>
                <div class="form-group col-md-3 float-left">
                    <!-- <select class="custom-select"  [(ngModel)]="venreg.country">
                        <option selected>India</option>
                        <option value="1">Nepal</option>
                        <option value="2">Bhutan</option>
                    </select> -->
                    <input type="text" name="country" #country="ngModel" [(ngModel)]="venreg.country" class="form-control" [class.is-invalid]="country.invalid && RegVen.submitted" required="required" placeholder="Country"/>
                    <div class="invalid-feedback">
                        Valid country is required.
                      </div>
                </div>
                
                <div class="form-group col-md-3 float-left">
                    <!-- <select class="custom-select"  [(ngModel)]="venreg.state">
                        <option selected>Mumbai</option>
                        <option value="1">Kolkata</option>
                        <option value="2">Chennai</option>
                    </select> -->
                    <input type="text" name="state" #state="ngModel"  [(ngModel)]="venreg.state" class="form-control" [class.is-invalid]="state.invalid && RegVen.submitted" required="required" placeholder="State" />
                    <div class="invalid-feedback">
                        Valid state is required.
                      </div>
                </div>
                <div class="form-group col-md-3 float-left">
                    <!-- <select class="custom-select"  [(ngModel)]="venreg.city">
                        <option selected>New Delhi</option>
                        <option value="1">New Delhi</option>
                        <option value="2">New Delhi</option>
                    </select> -->
                    <input type="text" name="city" #city="ngModel" [(ngModel)]="venreg.city" class="form-control" [class.is-invalid]="city.invalid && RegVen.submitted" required="required" placeholder="City" />
                    <div class="invalid-feedback">
                        Valid City is required.
                      </div>
                </div>
                <div class="form-group col-md-3 float-left">
                    <input type="number" name="pin" #pin="ngModel" [(ngModel)]="venreg.pin" class="form-control" [class.is-invalid]="pin.invalid && RegVen.submitted" required="required" placeholder="Pincode" />
                    <div class="invalid-feedback">
                        Valid pin is required.
                      </div>
                </div>
                <!-- <div class="form-group col-md-4 float-left">
                    <div class="dropdown">
                        <button class="btn bg-white border dropdown-toggle w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Material
                        </button>
                        <div class="dropdown-menu w-75" aria-labelledby="dropdownMenuButton">
                            <label for="" class="dropdown-item" *ngFor = "let mat of material; let i =index">
                                {{mat.material_name}}
                                <input type="checkbox" (change)="onSelectMaterial($event,mat.material_name)" value="{{mat.material_name}}" class="form-check-input r-25">
                            </label>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="form-group col-md-4 float-left" style="margin-bottom: 32px;">
                    &nbsp;<br>
                </div> -->
                <div class="form-group col-md-3 float-left">
                    
                    <input type="email" name="email" #email="ngModel" [(ngModel)]="venreg.email" class="form-control" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required="required" placeholder="Email ID"  />
                    <div *ngIf="!email?.valid && (email?.dirty || email?.touched)">
                        <div style="position: absolute;bottom: -31px;left: 19%;width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="email.errors?.required">
                          Email is required
                        </div>
                      <div style="position: absolute;bottom: -31px;left: 19%;width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="email.errors?.pattern">
                        Valid email required
                      </div>
                      </div>
                </div>
                <div class="form-group col-md-3 float-left">
               
                    <input type="password" name="password" #password="ngModel"  [(ngModel)]="venreg.password" class="form-control" [class.is-invalid]="password.invalid && RegVen.submitted" required="required" placeholder="Password"  autocomplete="new-password" />
                    <div class="invalid-feedback">
                        Valid Password is required
                      </div>
                </div>
                <div class="form-group col-md-3 float-left">
                    <input type="password" name="conpass" #conpass="ngModel" [(ngModel)]="venreg.conpass" class="form-control" [class.is-invalid]="conpass.invalid && RegVen.submitted" required="required" placeholder="Confirm Password"  autocomplete="off" />
                </div>
                <!-- <div class="form-group col-md-6 mx-auto">
                    <div class="form-check-inline mt-3">
                        <label class="col-md-8 mx-auto float-md-left form-check-label">I am a Buyer (outside India) <input type="radio" class="form-check-input" value="yes" name="radio-btn-ind" /></label>
                        <label class="col-md-8 mx-auto float-md-left form-check-label">I am a Vendor (inside India) <input type="radio" class="form-check-input" value="no"  name="radio-btn-ind" /></label>
                    </div>
                </div> -->
                <div class="form-group col-md-12 float-left" style="margin-bottom: 0px;">
                    &nbsp;
                </div>
                <div class="col-md-4 text-center float-left mx-auto">
                    <!-- <button type="" class="btn btn-secondary d-md-block mx-auto">Previous</button> -->
                </div>
                <div class="col-md-4 text-center float-left mx-auto">
                    <button type="submit" class="btn btn-info d-md-block mx-auto">Submit</button>
                </div>
                <div class="col-md-4 text-center float-left mx-auto">
                    <button [routerLink]="['/step-two']" routerLinkActive="active" class="btn btn-primary d-md-block mx-auto">Next <i class="fa fa-angle-right"></i></button>
                </div>
            </form>
        </div>
    </div>
</div>
</div>
</section>