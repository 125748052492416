import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.component.html',
  styleUrls: ['./vendor-details.component.css']
})
export class VendorDetailsComponent implements OnInit {
  venreg:any={};
  venderId:any
  temp:any=[];
  vencon:any={};
  qcData:any={};
  vencomm:any={};
  qualityChck:any=[];
  woodTypeCheck:any=[];
  typeUnitCheck:any=[];
  payterm:any=[];
  fileArr:any=[];
  isIso:boolean=true;
  empId:any;
  staffData:any={};
  lfData:any={};
  socialData:any={};
  loginStatus:any;
  infrastructureData:any={};
  woodList:any;
  hgSandCastingCheck:any=[];
  hgForgingCheck:any=[];
  hgLowWaxCastingCheck:any=[];
  hgPressureDieCastingCheck:any=[];
  glassprocessCheck:any=[];
  glassManufacturerCheck:any=[];
  glassColorCheck:any=[];
  steelGradeCheck:any=[];
  constructor(private router: Router,
    private WebapiService:WebapiService) { }
  material:any;
  ngOnInit(): void {

    this.woodList=["Mango","Sheesham","Accacia","teak","oak","Cherry","Mahagony","Walnut","MDF","HDF","PLY","Other"
    ];

    this.getMaterial();
   
    this.venderId = localStorage.getItem('venderId');
    this.empId = localStorage.getItem('loginId');
    this.loginStatus = localStorage.getItem('loginStatus');

      this.vencon.venderId = this.venderId;

    this.vencon.noddetails = [{
      'dir_title':'',
    }];
    this.vencon.noofdetails = [{
      'dir_title':'',
    }];
    this.vencon.nofdetails = [{
      'dir_title':'',
    }];
    this.staffData.salesManager = [{
      'name':'',
    }];
    this.staffData.purchaseManager = [{
      'name':'',
    }];
    this.staffData.prodManager = [{
      'name':'',
    }];
    this.staffData.prodSupervisor = [{
      'name':'',
    }];
    this.staffData.qaManager = [{
      'name':'',
    }];
    this.staffData.qaSupervisor = [{
      'name':'',
    }];
    this.staffData.rdDesigner = [{
      'name':'',
    }];
    this.staffData.packingSupervisor = [{
      'name':'',
    }];
    this.staffData.docStaff = [{
      'name':'',
    }];
    this.staffData.accStaff = [{
      'name':'',
    }];
    this.staffData.adminHr = [{
      'name':'',
    }];
  }

  getMaterial(){
    this.WebapiService.getMaterial().subscribe((res:any)=>{
      // console.log(res);
      this.material =res;
    });
  }

  insertVendor(){
    if(this.venreg.password != this.venreg.conpass){
      alert("please enter same password");
    }
    else{
      console.log(this.venreg);
      this.venreg.submBy = this.empId;
      this.WebapiService.registerVendor(this.venreg).subscribe((res:any)=>{
         console.log(res);
         if(res.status == 1){
           alert(res.msg);
           localStorage.setItem('venderId',res.last_id);
           this.venreg={};
         }
         else{
           alert(res.msg);
         }
      });
    }

  }
  myAccount(e:any){
  //  console.log(e);
   if(e==2){
     this.router.navigate(['/vendor-account'])
   }
   else if(e==3){
     this.router.navigate(['/my-account'])
   }
   else{
     console.log("hi there");
     
   }
  }
  logout(){
    localStorage.removeItem('loginId');
    localStorage.removeItem('loginStatus');
    this.router.navigate(['/']);
  }

  onSelectMaterial(e:any,mat:any){
    // console.log(mat);
    if(e.target.checked){
      this.temp.push(mat);
    }
    else {
      let i: number = 0;
      this.temp.forEach((item: any) => {
        // console.log(item);
        if (item == mat) {
          this.temp.splice(i, 1);
          return;
        }
        i++;
      });
    }
    if(this.temp.length>0){
       this.infrastructureData.material = this.temp;
    }
  }

  onSelectRowNod(e:any){
    //  console.log(e.target.value);
    let value = e.target.value;
     this.vencon.noddetails = [];
     for (let index = 0; index < value; index++) {
          
      this.vencon.noddetails.push({});
       
     }
  }

  onSelectRowNoof(e:any){
    let value = e.target.value;
     this.vencon.noofdetails = [];
     for (let index = 0; index < value; index++) {
          
      this.vencon.noofdetails.push({});
       
     }
  }
  onSelectRowNof(e:any){
    let value = e.target.value;
     this.vencon.nofdetails = [];
     for (let index = 0; index < value; index++) {
          
      this.vencon.nofdetails.push({});
       
     }
  }
  onSelectSalesManager(e:any){
    let value = e.target.value;
    this.staffData.salesManager = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.salesManager.push({});
      
    }
  }
  onSelectPurchaseManager(e:any){
    let value = e.target.value;
    this.staffData.purchaseManager = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.purchaseManager.push({});
      
    }
  }
  onSelectProductionManager(e:any){
    let value = e.target.value;
    this.staffData.prodManager = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.prodManager.push({});
      
    }
  }
  onSelectProductionSupervisor(e:any){
    let value = e.target.value;
    this.staffData.prodSupervisor = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.prodSupervisor.push({});
      
    }
  }
  onSelectQaManager(e:any){
    let value = e.target.value;
    this.staffData.qaManager = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.qaManager.push({});
      
    }
  }
  onSelectQaSupervisor(e:any){
    let value = e.target.value;
    this.staffData.qaSupervisor = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.qaSupervisor.push({});
      
    }
  }
  onSelectRDDesigner(e:any){
    let value = e.target.value;
    this.staffData.rdDesigner = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.rdDesigner.push({});
      
    }
  }
  onSelectPackingSupervisor(e:any){
    let value = e.target.value;
    this.staffData.packingSupervisor = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.packingSupervisor.push({});
      
    }
  }
  onSelectDocStaff(e:any){
    let value = e.target.value;
    this.staffData.docStaff = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.docStaff.push({});
      
    }
  }
  onSelectAccStaff(e:any){
    let value = e.target.value;
    this.staffData.accStaff = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.accStaff.push({});
      
    }
  }
  onSelectAdmin(e:any){
    let value = e.target.value;
    this.staffData.adminHr = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.adminHr.push({});
      
    }
  }
  onContactFile(e:any){
    // console.log(e.target.files[0]);
    if(e.target.files){
      this.vencon.img = e.target.files[0];
    }
    
  }
  onVendorContactForm(data:any){  
    if(this.loginStatus == 2){
      data.venderId = this.empId;
    }
    else{
      data.venderId = this.venderId;
    }    
      this.WebapiService.insertVendorContact(data).subscribe((res:any)=>{
      console.log(res);

      if(res.status == 1){
        alert(res.msg);
        this.vencon={};
      }
      else{
        alert(res.msg);
      }

      });
  }
  onVendorCommForm(data:any){
    console.log(data);
    if(this.loginStatus == 2){
      data.venderId = this.empId;
    }
    else{
      data.venderId = this.venderId;
    }
    this.WebapiService.insertVendorCommercial(data).subscribe((res:any)=>{
      console.log(res);
      if(res.status == 1){
        alert(res.msg);
        this.vencomm={};
      }
      else{
        alert(res.msg);
      }
    });
  }

  onCheckPayTerm(e:any){
    if (e.target.checked == true) {
      this.payterm.push(e.target.value);
    }else{
      var index = this.payterm.indexOf(e.target.value);
      this.payterm.splice(index, 1);
    }
    // console.log(this.payterm);
    this.vencomm.payterm = this.payterm;

  }
  onCheckIso(e:any){
   if(e.target.value == 'no'){
     this.isIso = false;
   }
   else if(e.target.value = 'yes'){
   this.isIso = true;
  }
  else{
     this.isIso =true;
  }
}
onFileUpload(e:any,name:any){
  //  console.log(e.target.files);
  let file= {
      "name":name,
      "file":e.target.files[0],
      "venderId":this.venderId
    }
    this.WebapiService.uploadFile(file).subscribe((res:any)=>{
        // console.log(res);
        if(res.status == 1){
          alert(res.msg);
        }
        else{
          alert(res.msg);
        }
    });

}
qcCheck(e:any){
  if (e.target.checked == true) {
    this.qualityChck.push(e.target.value);
  }else{
    var index = this.qualityChck.indexOf(e.target.value);
    this.qualityChck.splice(index, 1);
  }
  console.log(this.qualityChck);
  this.qcData.qcCheck = this.qualityChck;
}
qualityCertification(data:any){
  if(this.loginStatus == 2){
    data.venderId = this.empId;
  }
  else{
    data.venderId = this.venderId;
  }
  this.WebapiService.insertQualityData(data).subscribe((res:any)=>{
    console.log(res);
    if(res.status == 1){
      alert(res.msg);
      this.qcData = {};
    }
    else{
      alert(res.msg);
    }
  });
}

staffing(data:any){
  if(this.loginStatus == 2){
    data.venderId = this.empId;
  }
  else{
    data.venderId = this.venderId;
  }
  this.WebapiService.insertStaffingData(data).subscribe((res:any)=>{
    console.log(res);
    if(res.status == 1){
      alert(res.msg);
      // localStorage.setItem('venderId',res.last_id);
      this.staffData={};
    }
    else{
      alert(res.msg);
    }
  });
}
legalData(data:any){
  console.log(this.venderId);
  
  if(this.loginStatus == 2){
    data.venderId = this.empId;
  }
  else{
    data.venderId = this.venderId;
  }
  console.log(data);
  
  this.WebapiService.insertLegalData(data).subscribe((res:any)=>{
    console.log(res);
    if(res.status == 1){
      alert(res.msg);
      // localStorage.setItem('venderId',res.last_id);
      this.lfData={};
    }
    else{
      alert(res.msg);
    }
  });
}
debt(e:any){
  // console.log(e.target.value);
  if(e.target.value){
    this.lfData.debt = e.target.value;
  }
}
socialCompliance(data:any){
  console.log(data);
  if(this.loginStatus == 2){
    data.venderId = this.empId;
  }
  else{
    data.venderId = this.venderId;
  }
  this.WebapiService.insertSocialCompliance(data).subscribe((res:any)=>{
    console.log(res);
    if(res.status == 1){
      alert(res.msg);
      this.lfData={};
    }
    else{
      alert(res.msg);
    }
  });
}
woodType(e:any){
//  console.log(e.target.value);
 if (e.target.checked == true) {
  this.woodTypeCheck.push(e.target.value);
}else{
  var index = this.woodTypeCheck.indexOf(e.target.value);
  this.woodTypeCheck.splice(index, 1);
}
console.log(this.woodTypeCheck);
this.infrastructureData.wood_Type = this.woodTypeCheck;
 
}
typeUnit(e:any){
 
  if (e.target.checked == true) {
    this.typeUnitCheck.push(e.target.value);
  }else{
    var index = this.typeUnitCheck.indexOf(e.target.value);
    this.typeUnitCheck.splice(index, 1);
  }
  console.log(this.typeUnitCheck);
this.infrastructureData.glass_type_unit = this.typeUnitCheck;

}
hgSandcasting(e:any){
  if (e.target.checked == true) {
    this.hgSandCastingCheck.push(e.target.value);
  }else{
    var index = this.hgSandCastingCheck.indexOf(e.target.value);
    this.hgSandCastingCheck.splice(index, 1);
  }
  console.log(this.hgSandCastingCheck);
this.infrastructureData.hg_sand_casting = this.hgSandCastingCheck;

}
hgPressureDieCasting(e:any){

  if (e.target.checked == true) {
    this.hgPressureDieCastingCheck.push(e.target.value);
  }else{
    var index = this.hgPressureDieCastingCheck.indexOf(e.target.value);
    this.hgPressureDieCastingCheck.splice(index, 1);
  }
  console.log(this.hgPressureDieCastingCheck);
this.infrastructureData.hg_pressure_die_casting = this.hgPressureDieCastingCheck;

}
hgLowWaxCasting(e:any){

  if (e.target.checked == true) {
    this.hgLowWaxCastingCheck.push(e.target.value);
  }else{
    var index = this.hgLowWaxCastingCheck.indexOf(e.target.value);
    this.hgLowWaxCastingCheck.splice(index, 1);
  }
  console.log(this.hgLowWaxCastingCheck);
this.infrastructureData.hg_low_wax_casting = this.hgLowWaxCastingCheck;

}
hgForging(e:any){

  if (e.target.checked == true) {
    this.hgForgingCheck.push(e.target.value);
  }else{
    var index = this.hgForgingCheck.indexOf(e.target.value);
    this.hgForgingCheck.splice(index, 1);
  }
  console.log(this.hgForgingCheck);
this.infrastructureData.hg_forging = this.hgForgingCheck;

}
glassProcess(e:any){
  if (e.target.checked == true) {
    this.glassprocessCheck.push(e.target.value);
  }else{
    var index = this.glassprocessCheck.indexOf(e.target.value);
    this.glassprocessCheck.splice(index, 1);
  }
  console.log(this.glassprocessCheck);
this.infrastructureData.glass_process = this.glassprocessCheck;

}
glassManufacturer(e:any){
  if (e.target.checked == true) {
    this.glassManufacturerCheck.push(e.target.value);
  }else{
    var index = this.glassManufacturerCheck.indexOf(e.target.value);
    this.glassManufacturerCheck.splice(index, 1);
  }
  console.log(this.glassManufacturerCheck);
this.infrastructureData.glass_manufacturer = this.glassManufacturerCheck;

}
glassColorType(e:any){
  if (e.target.checked == true) {
    this.glassColorCheck.push(e.target.value);
  }else{
    var index = this.glassColorCheck.indexOf(e.target.value);
    this.glassColorCheck.splice(index, 1);
  }
  console.log(this.glassColorCheck);
this.infrastructureData.glass_color_type = this.glassColorCheck;
}
steelGradeQuality(e:any){
  if (e.target.checked == true) {
    this.steelGradeCheck.push(e.target.value);
  }else{
    var index = this.steelGradeCheck.indexOf(e.target.value);
    this.steelGradeCheck.splice(index, 1);
  }
  console.log(this.steelGradeCheck);
this.infrastructureData.steel_grade_quality = this.steelGradeCheck;
}
insertInfrastructure(data:any){
  console.log(data);
  
}

}
