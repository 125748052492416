<style>
    input[type="number"] {
       -moz-appearance: textfield;
       appearance: textfield;
       margin: 0;
   }
   input[type="number"]::-webkit-inner-spin-button,
   input[type="number"]::-webkit-outer-spin-button {
       -webkit-appearance: none;
       margin: 0;
   }
   .sign-in a {
  color: #fff !important;
}
.sign-in img {
  width: 30px;
}
.sign-in ul>li{
     display: inline-block;
     vertical-align: -webkit-baseline-middle;
 }
 .sign-in ul>li a{
     color: var(--white);
     margin-left: .25rem;
 }
 .search-box img{
      transform: translate(-6%, -50%);
      position: absolute;
      top: 50%;
      right: 6%;
 }
 .vendor-details {
      background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url(../../assets/img/aerial-view.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
  }
  
  a {
      color: #fff;
  }
  
  .dropdown-item {
      color: #212529 !important
  }
  
  .r-25 {
      right: 25px;
  }
  .you-deal-in{
      flex-wrap: wrap;
  }
  .you-deal-in>div{
      flex: 0 1 380px;
  }
</style>

<section class="vendor-details section" id="vendorDetails">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title mb-md-4 col-md-12  float-md-left">
                    <div class="col-md-6 float-md-left">
                        <h1 class="mb-md-0">Staffing</h1>
                        <h2 style="color: #fff;font-size: 20px;margin-top: 10px;">{{companyName}}</h2>
                    </div>
                    <div *ngIf="empId" class="sign-in col-md-6 float-md-left text-right">
                        <ul class="text-md-center">
                            <li *ngIf="userLogin" >
                               <a (click)="myAccount(loginStatus)"><img src="assets/img/user.png" alt="" class="w-18">{{loginName}}</a></li>
                               <li  *ngIf="!userLogin">
                                <a [routerLink]="['/login']" class="signinbtn" routerLinkActive="active">Sign In</a>
                                </li>  
                                 <li><span class="separator">|</span></li>
                              <li *ngIf="!userLogin" >
                                   <a class="signupbtn" [routerLink]="['/signup']" routerLinkActive="active">Join Us</a></li>
                         
                            <li *ngIf="userLogin">
                                
                                <a (click)="logout()" href=""><img src="assets/img/logout.png" alt="" class="w-18">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div><!-- row -->
        <div class="row">
            <div class="col-12">
                <div class="step-menu-wrapper">
                    <ul class="d-flex">
                        <li class="flex-fill"><a [routerLink]="['/step-one']" routerLinkActive="active">Basic Information</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-two']" routerLinkActive="active">Company Profile</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-three']" routerLinkActive="active">Commercial</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-four']" routerLinkActive="active">Quality Management & Certifications</a></li>
                        <li class="flex-fill"><a class="active">Staffing</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-six']" routerLinkActive="active" >Legal & Financial</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-seven']" routerLinkActive="active" >Social Compliance</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-eight']" routerLinkActive="active" >Infrastructure</a></li>
                    </ul>
                </div>
            </div>
        </div>
<div class="" id="staff-ing" role="tabpanel" aria-labelledby="staff-tab">
    <div class="section-content bg-white p-md-3 h-auto clearfix">
        <div class="section-title mb-md-4">
            <!-- <h1 class="mb-md-0">Vendor Registration</h1> -->
        </div>
        <div class="section-form">
            <form #VendorForm ngForm="VendorForm" class="row" (ngSubmit)="staffing(staffData)">
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" class="form-control" name="nof_factory" [(ngModel)]="staffData.no_of_factory" placeholder="TOTAL NO. OF FACTORY UNITS">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <input type="text" class="form-control" name="factory_land_area" [(ngModel)] = "staffData.total_fac_land_area" placeholder="TOTAL FACTORIES LAND AREA (SQ. MTS.)">
                    </div>
                </div>
                <div class="col-md-3">
                    <label for="">DO YOU HAVE A SHOWROOM</label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" [(ngModel)]="staffData.have_showroom" value="yes" name="optradioShowR">Yes
                        </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" [(ngModel)]="staffData.have_showroom" value="no" name="optradioShowR">No
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <label for="">BUILT UP AREA* (SQ. FT.):</label>
                </div>
                <div class="col-md-9">
                    <div class="form-check-inline col-md-2">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" [(ngModel)]="staffData.built_up_area" value="<=3000" name="optradioBuilt"><=3000
                        </label>
                    </div>
                    <div class="form-check-inline col-md-2">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" [(ngModel)]="staffData.built_up_area" value="3001 to 5000" name="optradioBuilt">3001 to 5000
                        </label>
                    </div>
                    <div class="form-check-inline col-md-2">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" [(ngModel)]="staffData.built_up_area" value="5001 to 10000" name="optradioBuilt">5001 to 10000
                        </label>
                    </div>
                    <div class="form-check-inline col-md-2">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" [(ngModel)]="staffData.built_up_area" value="10001 to 25000" name="optradioBuilt">10001 to 25000
                        </label>
                    </div>
                    <div class="form-check-inline col-md-2">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" [(ngModel)]="staffData.built_up_area" value=">25000" name="optradioBuilt">>25000
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" class="form-control" name="area_of_showroom" [(ngModel)]="staffData.area_of_showroom" placeholder="AREA OF SHOWROOM (SQ. FT.)">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <input type="text" class="form-control" name="no_of_item" [(ngModel)]="staffData.no_of_item" placeholder="NO. OF ITEMS DISPLAYED (APPROX.)">
                    </div>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-md-3">SENIOR STAFF STRENGTH</h4>
                </div>
                <div class="col-md-10">
                    <label for="">SALES MANAGER/MERCHANDIZER*:</label>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <select class="form-control" (change)="onSelectSalesManager($event)">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>YEAR(S) IN POSITON</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sales of staffData.salesManager">
                                <td><div class="form-group">
                                    <input type="text"  name="name" [(ngModel)]="sales.name"  class="form-control" placeholder="" />
                                </div></td>
                                <td><div class="form-group">
                                    <input type="text"  name="year" [(ngModel)]="sales.year_in_position"  class="form-control" placeholder="" />
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-10">
                    <label for="">PURCHASE MANAGER:</label>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <select class="form-control" (change)="onSelectPurchaseManager($event)" id="sel1">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>YEAR(S) IN POSITON</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of staffData.purchaseManager">
                                <td><div class="form-group">
                                    <input type="text"  name="name1" [(ngModel)]="p.name"  class="form-control" placeholder="" />
                                </div></td>
                                <td><div class="form-group">
                                    <input type="text"  name="year1" [(ngModel)]="p.year_in_position"  class="form-control" placeholder="" />
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-10">
                    <label for="">PRODUCTION MANAGERS:</label>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <select class="form-control" (change)="onSelectProductionManager($event)" id="sel1">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>YEAR(S) IN POSITON</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pro of staffData.prodManager">
                                <td><div class="form-group">
                                    <input type="text"  name="name2" [(ngModel)]="pro.name"  class="form-control" placeholder="" />
                                </div></td>
                                <td><div class="form-group">
                                    <input type="text"  name="year2" [(ngModel)]="pro.year_in_position"  class="form-control" placeholder="" />
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-10">
                    <label for="">PRODUCTION SUPERVISOR:</label>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <select class="form-control" (change)="onSelectProductionSupervisor($event)" id="sel1">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>YEAR(S) IN POSITON</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let prod of staffData.prodSupervisor">
                                <td><div class="form-group">
                                    <input type="text"  name="name3" [(ngModel)]="prod.name"  class="form-control" placeholder="" />
                                </div></td>
                                <td><div class="form-group">
                                    <input type="text"  name="year3" [(ngModel)]="prod.year_in_position"  class="form-control" placeholder="" />
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-10">
                    <label for="">Q.A. MANAGER:</label>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <select class="form-control" (change)="onSelectQaManager($event)" id="sel1">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>YEAR(S) IN POSITON</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let q of staffData.qaManager">
                                <td><div class="form-group">
                                    <input type="text"  name="name4" [(ngModel)]="q.name"  class="form-control" placeholder="" />
                                </div></td>
                                <td><div class="form-group">
                                    <input type="text"  name="year4" [(ngModel)]="q.year_in_position"  class="form-control" placeholder="" />
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-10">
                    <label for="">Q.A. SUPERVISOR:</label>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <select class="form-control" (change)="onSelectQaSupervisor($event)" id="sel1">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>YEAR(S) IN POSITON</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let a of staffData.qaSupervisor">
                                <td><div class="form-group">
                                    <input type="text"  name="name5" [(ngModel)]="a.name"  class="form-control" placeholder="" />
                                </div></td>
                                <td><div class="form-group">
                                    <input type="text"  name="year5" [(ngModel)]="a.year_in_position"  class="form-control" placeholder="" />
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-10">
                    <label for="">R & D / DESIGNERS:</label>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <select class="form-control" (change)="onSelectRDDesigner($event)" id="sel1">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>YEAR(S) IN POSITON</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of staffData.rdDesigner">
                                <td><div class="form-group">
                                    <input type="text"  name="name6" [(ngModel)]="r.name"  class="form-control" placeholder="" />
                                </div></td>
                                <td><div class="form-group">
                                    <input type="text"  name="year6" [(ngModel)]="r.year_in_position"  class="form-control" placeholder="" />
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-10">
                    <label for="">PACKING SUPERVISOR:</label>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <select class="form-control" (change)="onSelectPackingSupervisor($event)" id="sel1">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>YEAR(S) IN POSITON</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pack of staffData.packingSupervisor">
                                <td><div class="form-group">
                                    <input type="text"  name="name7" [(ngModel)]="pack.name"  class="form-control" placeholder="" />
                                </div></td>
                                <td><div class="form-group">
                                    <input type="text"  name="year7" [(ngModel)]="pack.year_in_position"  class="form-control" placeholder="" />
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-10">
                    <label for="">DOCUMENTATION STAFF:</label>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <select class="form-control" (change)="onSelectDocStaff($event)" id="sel1">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>YEAR(S) IN POSITON</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let doc of staffData.docStaff">
                                <td><div class="form-group">
                                    <input type="text"  name="name8" [(ngModel)]="doc.name"  class="form-control" placeholder="" />
                                </div></td>
                                <td><div class="form-group">
                                    <input type="text"  name="year8" [(ngModel)]="doc.year_in_position"  class="form-control" placeholder="" />
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-10">
                    <label for="">ACCOUNTS STAFF:</label>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <select class="form-control" (change)="onSelectAccStaff($event)" id="sel1">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>YEAR(S) IN POSITON</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let acc of staffData.accStaff">
                                <td><div class="form-group">
                                    <input type="text"  name="name9" [(ngModel)]="acc.name"  class="form-control" placeholder="" />
                                </div></td>
                                <td><div class="form-group">
                                    <input type="text"  name="year9" [(ngModel)]="acc.year_in_position"  class="form-control" placeholder="" />
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-10">
                    <label for="">ADMINISTRATION / HR / MAINTENANCE:</label>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <select class="form-control" (change)="onSelectAdmin($event)" id="sel1">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>YEAR(S) IN POSITON</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let adm of staffData.adminHr">
                                <td><div class="form-group">
                                    <input type="text"  name="name10" [(ngModel)]="adm.name"  class="form-control" placeholder="" />
                                </div></td>
                                <td><div class="form-group">
                                    <input type="text"  name="year10" [(ngModel)]="adm.year_in_position"  class="form-control" placeholder="" />
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Production staff</th>
                                <th colspan="2">On Salary</th>
                                <th colspan="2">On Contract</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td><b>Supervisor</b></td>
                                <td><b>Worker</b></td>
                                <td><b>Supervisor</b></td>
                                <td><b>Worker</b></td>
                            </tr>
                            <tr>
                                <td>Sampling</td>
                                <td> 
                                    <input type="text"  name="supsal_sampling" [(ngModel)]="staffData.sup_sampling"  class="form-control" placeholder="" />
                                </td>
                                <td>
                                    <input type="text"  name="worksal_sampling" [(ngModel)]="staffData.worksal_sampling"  class="form-control" placeholder="" />
                                </td>
                                <td>
                                    <input type="text"  name="supcon_sampling" [(ngModel)]="staffData.supcon_sampling"  class="form-control" placeholder="" />
                                </td>
                                <td>
                                    <input type="text"  name="workcon_samp" [(ngModel)]="staffData.workcon_samp"  class="form-control" placeholder="" />
                                </td>
                            </tr>
                            <tr>
                                <td>Production</td>
                                <td> <input type="text"  name="supsal_prod" [(ngModel)]="staffData.sup_prod"  class="form-control" placeholder="" /></td>
                                <td>
                                    <input type="text"  name="worksal_prod" [(ngModel)]="staffData.worksal_prod"  class="form-control" placeholder="" />
                                </td>
                                <td>  
                                    <input type="text"  name="supcon_prod" [(ngModel)]="staffData.supcon_prod"  class="form-control" placeholder="" />
                                </td>
                                <td>
                                    <input type="text"  name="workcon_prod" [(ngModel)]="staffData.workcon_prod"  class="form-control" placeholder="" />
                                </td>
                            </tr>
                            <tr>
                                <td>QC department</td>
                                <td>
                                    <input type="text"  name="supsal_qc" [(ngModel)]="staffData.sup_qc"  class="form-control" placeholder="" />
                                </td>
                                <td>
                                    <input type="text"  name="worksal_qc" [(ngModel)]="staffData.worksal_qc"  class="form-control" placeholder="" /></td>
                                <td>
                                    <input type="text"  name="supcon_qc" [(ngModel)]="staffData.supcon_qc"  class="form-control" placeholder="" />
                                </td>
                                <td>
                                    <input type="text"  name="workcon_qc" [(ngModel)]="staffData.workcon_qc"  class="form-control" placeholder="" />
                                </td>
                            </tr>
                            <tr>
                                <td>Packaging</td>
                                <td>
                                    <input type="text"  name="supsal_pack" [(ngModel)]="staffData.sup_pack"  class="form-control" placeholder="" />
                                </td>
                                <td>
                                    
                                    <input type="text"  name="worksal_pack" [(ngModel)]="staffData.worksal_pack"  class="form-control" placeholder="" />
                                </td>
                                <td>
                                    <input type="text"  name="supcon_pack" [(ngModel)]="staffData.supcon_pack"  class="form-control" placeholder="" />
                                </td>
                                <td>
                                    <input type="text"  name="workcon_pack" [(ngModel)]="staffData.workcon_pack"  class="form-control" placeholder="" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-2">
                    <label for="">TOTAL FACTORY WORKERS*: </label>
                </div>
                <div class="col-md-2">
                    <input type="text" name="permanent_worker" [(ngModel)]="staffData.permanent_worker" placeholder="PERMANENT" class="form-control">
                </div>
                <div class="col-md-2">
                    <input type="text" name="contract_worker" [(ngModel)]="staffData.contract_worker" placeholder="CONTRACT" class="form-control">
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        <strong>DO YOU EMPLOY LABOUR BELOW 18 YEAR AGE ** </strong>
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="radio" class="form-check-input" value="yes" [(ngModel)]="staffData.labour_below_18" name="optradioFactoWrkrs">
                            Yes
                        </label>
                    </div>
                    <div class="form-check-inline">
                        <label for="" class="form-check-label">
                            <input type="radio" class="form-check-input" value="no" [(ngModel)]="staffData.labour_below_18" name="optradioFactoWrkrs">
                            No
                        </label>
                    </div>
                </div>

                <div class="col-md-6">
                    <input type="text" class="form-control" name="prod_capacity_pieces" [(ngModel)]="staffData.prod_capacity_pieces" placeholder="PRODUCTION CAPACITY (MONTHLY) PIECES">
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" name="prod_capaity_value" [(ngModel)]="staffData.prod_capacity_value" placeholder="PRODUCTION CAPACITY (MONTHLY) VALUES US$">
                    </div>
                </div>
                <div class="col-md-10">
                    <label for="">NO. OF GENERATORS/POWER BACKUP</label>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                       
                        <select class="form-control" (change)="onSelectGenerator($event)" id="sel1">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                          </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>CAPACITY</th>
                                <th>QUANTITY</th>
                                <th>YEAR MADE</th>
                                <th>WORKING OR IDLE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let gen of staffData.noofgenerator">
                                <td><div class="form-group">
                                    <input type="text"  name="capacity" [(ngModel)]="gen.capacity"  class="form-control" placeholder="Capacity" />
                                </div></td>
                                <td><div class="form-group">
                                    <input type="text"  name="quantity" [(ngModel)]="gen.quantity"  class="form-control" placeholder="quantity" />
                                </div></td>
                                <td><div class="form-group">
                                    <input type="text"  name="year" [(ngModel)]="gen.year"  class="form-control" placeholder="year" />
                                </div></td>
                                <td><div class="form-group">
                                    <input type="text"  name="status" [(ngModel)]="gen.status"  class="form-control" placeholder="status" />
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group d-flex">
                                <label for="" class="col-5">No. of plant & Machinery</label>
                                <input type="text"  name="no_of_plant_and_machinery" [(ngModel)]="staffData.no_of_plant_and_machinery" class="form-control flex-fill">
                            </div>
                        </div>
                        <div class="w-100"></div>
                        <div class="col-6">
                            <div class="form-group d-flex">
                                <label for="" class="col-5">No. of contractor / associate</label>
                                <input type="text"  name="no_of_contractor_and_associate" [(ngModel)]="staffData.no_of_contractor_and_associate" class="form-control flex-fill">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-100" style="margin-bottom: 32px;"></div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4 text-center mx-auto">
                            <button [routerLink]="['/step-four']" routerLinkActive="active" class="btn btn-secondary d-md-block mx-auto"><i class="fa fa-angle-left"></i> Previous</button>
                        </div>
                        <div class="col-md-4 text-center mx-auto">
                            <button type="submit" class="btn btn-info d-md-block mx-auto">Submit</button>
                        </div>
                        <div class="col-md-4 text-center mx-auto">
                            <button [routerLink]="['/step-six']" routerLinkActive="active" class="btn btn-primary d-md-block mx-auto">Next <i class="fa fa-angle-right"></i></button>
                        </div>
                    </div>
                </div>
                <!-- <button type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button> -->
            </form>
        </div>
    </div>
</div>
</div>
</section>