import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
@Component({
  selector: 'app-stepfive',
  templateUrl: './stepfive.component.html',
  styleUrls: ['./stepfive.component.css']
})
export class StepfiveComponent implements OnInit {

  venderId:any;
  userLogin:any;
  loginStatus: any;
  empId:any;
  staffData:any={};
  companyName: any;
  loginName:any

  constructor(private router: Router,
    private WebapiService:WebapiService) { }

  ngOnInit(): void {
    this.venderId = localStorage.getItem('venderId');
    this.userLogin = localStorage.getItem('loginId');
    this.empId = localStorage.getItem('loginId');
    this.loginStatus = localStorage.getItem('loginStatus');
    this.loginName = localStorage.getItem('name');

    if(this.venderId){
      this.getUserById(this.venderId);
      }
      else{
        this.getUserById(sessionStorage.getItem('editVenId'));
      }

    this.staffData.salesManager = [{
      'name':'',
    }];
    this.staffData.purchaseManager = [{
      'name':'',
    }];
    this.staffData.prodManager = [{
      'name':'',
    }];
    this.staffData.prodSupervisor = [{
      'name':'',
    }];
    this.staffData.qaManager = [{
      'name':'',
    }];
    this.staffData.qaSupervisor = [{
      'name':'',
    }];
    this.staffData.rdDesigner = [{
      'name':'',
    }];
    this.staffData.packingSupervisor = [{
      'name':'',
    }];
    this.staffData.docStaff = [{
      'name':'',
    }];
    this.staffData.accStaff = [{
      'name':'',
    }];
    this.staffData.adminHr = [{
      'name':'',
    }];
    this.staffData.noofgenerator = [{
      'capacity':'',
    }];
  }

  logout(){
    localStorage.removeItem('loginId');
    localStorage.removeItem('loginStatus');
    sessionStorage.removeItem('editVenId');
    this.router.navigate(['/']);
  }
  getUserById(id:any){
    let val ={
      "id":id
    }
    this.WebapiService.getUserById(val).subscribe((res:any)=>{
      console.log(res);
      if(res.length > 0){
        this.companyName = res[0].company_name;
        this.staffData.no_of_factory = res[0].no_of_factory_unit;
        this.staffData.total_fac_land_area = res[0].total_factory_land_area;
        this.staffData.have_showroom = res[0].have_showroom;
        this.staffData.built_up_area = res[0].built_up_area;
        this.staffData.area_of_showroom = res[0].area_of_showroom;
        this.staffData.no_of_item = res[0].no_of_item;
        this.staffData.salesManager = JSON.parse(res[0].sales_manager);
        this.staffData.purchaseManager = JSON.parse(res[0].purchase_manager);
        this.staffData.prodManager = JSON.parse(res[0].prod_manager);
        this.staffData.prodSupervisor = JSON.parse(res[0].prod_supervisor);
        this.staffData.qaManager = JSON.parse(res[0].qa_manager);
        this.staffData.qaSupervisor = JSON.parse(res[0].qa_supervisor);
        this.staffData.rdDesigner = JSON.parse(res[0].rd_designer);
        this.staffData.packingSupervisor = JSON.parse(res[0].packing_supervisor);
        this.staffData.docStaff = JSON.parse(res[0].doc_staff);
        this.staffData.accStaff = JSON.parse(res[0].acc_staff);
        this.staffData.adminHr = JSON.parse(res[0].admin_hr);
        this.staffData.sup_sampling = res[0].sup_sampling;
        this.staffData.worksal_sampling = res[0].worksal_sampling;
        this.staffData.supcon_sampling = res[0].supcon_sampling;
        this.staffData.workcon_samp = res[0].workcon_samp;
        this.staffData.sup_prod = res[0].sup_prod;
        this.staffData.worksal_prod = res[0].worksal_prod;
        this.staffData.supcon_prod = res[0].supcon_prod;
        this.staffData.workcon_prod = res[0].workcon_prod;
        this.staffData.sup_qc = res[0].sup_qc;
        this.staffData.worksal_qc = res[0].worksal_qc;
        this.staffData.supcon_qc = res[0].supcon_qc;
        this.staffData.workcon_qc = res[0].workcon_qc;
        this.staffData.sup_pack = res[0].sup_pack;
        this.staffData.worksal_pack = res[0].worksal_pack;
        this.staffData.supcon_pack = res[0].supcon_pack;
        this.staffData.workcon_pack = res[0].workcon_pack;
        this.staffData.permanent_worker = res[0].permanent_worker;
        this.staffData.contract_worker = res[0].contract_worker;
        this.staffData.labour_below_18 = res[0].labour_below_18;
        this.staffData.prod_capacity_pieces = res[0].prod_capacity_pieces;
        this.staffData.prod_capacity_value = res[0].prod_capacity_value;
        this.staffData.noofgenerator = JSON.parse(res[0].no_of_generator);
        this.staffData.no_of_plant_and_machinery = res[0].no_of_plant_and_machinery;
        this.staffData.no_of_contractor_and_associate = res[0].no_of_contractor_and_associate;
      }
   
    });
 
   }
  myAccount(e:any){
    // console.log(e);
    if(e==2){
      this.router.navigate(['/vendor-account'])
      sessionStorage.removeItem('editVenId');

    }
    else if(e==3){
      this.router.navigate(['/my-account'])
      sessionStorage.removeItem('editVenId');

    }
    else if(e==4){
      this.router.navigate(['/admin'])
      sessionStorage.removeItem('editVenId');
    }
    else{

    }
  }
  onSelectSalesManager(e:any){
    let value = e.target.value;
    this.staffData.salesManager = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.salesManager.push({});
      
    }
  }
  onSelectPurchaseManager(e:any){
    let value = e.target.value;
    this.staffData.purchaseManager = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.purchaseManager.push({});
      
    }
  }
  onSelectProductionManager(e:any){
    let value = e.target.value;
    this.staffData.prodManager = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.prodManager.push({});
      
    }
  }
  onSelectProductionSupervisor(e:any){
    let value = e.target.value;
    this.staffData.prodSupervisor = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.prodSupervisor.push({});
      
    }
  }
  onSelectQaManager(e:any){
    let value = e.target.value;
    this.staffData.qaManager = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.qaManager.push({});
      
    }
  }
  onSelectQaSupervisor(e:any){
    let value = e.target.value;
    this.staffData.qaSupervisor = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.qaSupervisor.push({});
      
    }
  }
  onSelectRDDesigner(e:any){
    let value = e.target.value;
    this.staffData.rdDesigner = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.rdDesigner.push({});
      
    }
  }
  onSelectPackingSupervisor(e:any){
    let value = e.target.value;
    this.staffData.packingSupervisor = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.packingSupervisor.push({});
      
    }
  }
  onSelectDocStaff(e:any){
    let value = e.target.value;
    this.staffData.docStaff = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.docStaff.push({});
      
    }
  }
  onSelectAccStaff(e:any){
    let value = e.target.value;
    this.staffData.accStaff = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.accStaff.push({});
      
    }
  }
  onSelectAdmin(e:any){
    let value = e.target.value;
    this.staffData.adminHr = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.adminHr.push({});
      
    } 
  }
  onSelectGenerator(e:any){
    let value = e.target.value;
    this.staffData.noofgenerator = [];
    for (let index = 0; index < value; index++) {
         
     this.staffData.noofgenerator.push({});
      
    }
  }

  staffing(data:any){
    if(this.loginStatus == 2){
      data.venderId = this.empId;
    }
    else if(sessionStorage.getItem('editVenId')){
      data.venderId = sessionStorage.getItem('editVenId');
    }
    else{
      data.venderId = this.venderId;
    }
    // console.log(data);
    
    this.WebapiService.insertStaffingData(data).subscribe((res:any)=>{
      console.log(res);
      if(res.status == 1){
        alert(res.msg);
        // localStorage.setItem('venderId',res.last_id);
        this.router.navigate(['/step-six']);
      }
      else{
        alert(res.msg);
      }
    });
  }


}
