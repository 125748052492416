import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-vendor-profile',
  templateUrl: './vendor-profile.component.html',
  styleUrls: ['./vendor-profile.component.css']
})
export class VendorProfileComponent implements OnInit {

  venderId:any
  vendorData: any;
  vendor_name:any

  constructor(private router: Router,
    private WebapiService:WebapiService,private activated_Route: ActivatedRoute) { }

  ngOnInit(): void {

    this.venderId = this.activated_Route.snapshot.queryParams.id;
    this.getVendorById(this.venderId);
  }

  getVendorById(id:any){
    let val = {
      'id':id
    }
    this.WebapiService.getVendorById(val).subscribe((res:any)=>{
      // console.log(res);
      this.vendorData = res;
      this.vendor_name = res[0].contact_person;

    });
  }

}
