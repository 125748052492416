import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service'; 
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.css']
})
export class OurServicesComponent implements OnInit {
  serviceList: any;
  serviceId:any
  serviceData: any;
  constructor(private WebapiService:WebapiService,private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getServices();
    this.serviceId = this.activatedRoute.snapshot.queryParamMap.get('id');
    // console.log(this.serviceId);
     this.getServiceById(this.serviceId);
  }

  getServices(){

    this.WebapiService.getServices().subscribe((res: any) => {
      // console.log(res);
      this.serviceList = res;
    });
  }

  onServiceCLick(id:any){
    let val = {
      'id':id
    } 
    this.WebapiService.getServiceById(val).subscribe((res: any) => {
      // console.log(res);
      this.serviceData=res;
    });
   
  }
  getServiceById(id:any){
    let val = {
      'id':id
    } 
    this.WebapiService.getServiceById(val).subscribe((res: any) => {
      // console.log(res);
      this.serviceData=res;
    });
  }

}
