<style>
    .career .section-title h1 {
        color: var(--white);
        font-weight: var(--f-weight-seven);
    }
    
    .career .section-text p {
        color: var(--white);
    }
    
    .career {
        background-image: linear-gradient(to left, rgba( 0, 0, 0, 0.25), rgba(0, 0, 0, .5)), url("../../assets/img/shipment.png");
        /*background: url("../img/young-man-working-warehouse-with-boxes 1(1).png") no-repeat center;*/
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 704px;
        width: 100%;
    }
    
    .section-sub-title a {
        display: inline-block;
        padding: 1rem;
        background-color: var(--color-one);
        color: var(--white);
    }
</style>

<section class="career section" id="career">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="section-content">
                    <div class="section-title">
                        <h1>Career With Us</h1>
                    </div>
                    <div class="section-text">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore, eveniet! Voluptatibus magnam fugiat, dicta fugit, in architecto, aliquam blanditiis culpa assumenda sed dignissimos accusamus minima vero nemo id ex cumque.</p>
                    </div>
                    <div class="section-sub-title">
                        <a [routerLink]="['/career']" routerLinkActive="active">Current Job Openings</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>