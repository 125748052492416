import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendor-register',
  templateUrl: './vendor-register.component.html',
  styleUrls: ['./vendor-register.component.css']
})
export class VendorRegisterComponent implements OnInit {
  venreg:any={};
  // inquery:any={};
  constructor() { }

  ngOnInit(): void {
  }

  insertVendor(){
    // this.inquery.service_id =   this.serviceList.id;
    console.log(this.venreg);
    // this.WebapiService.insertInquery(this.inquery).subscribe((res: any) => {
    //  alert(res.msg);
    //   console.log(res);
       //this.serviceList = res[0];

// });
  }
  // insert function end...................................

  
}
