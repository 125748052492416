import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-stepsix',
  templateUrl: './stepsix.component.html',
  styleUrls: ['./stepsix.component.css']
})
export class StepsixComponent implements OnInit {
  venderId:any
  loginStatus:any
  userLogin:any;
  empId:any;
  lfData:any={};
  companyName: any;
  debtValue: any;
  loginName:any;
  constructor(private router: Router,
    private WebapiService:WebapiService) { }

  ngOnInit(): void {
    this.venderId = localStorage.getItem('venderId');
    this.userLogin = localStorage.getItem('loginId');
    this.empId = localStorage.getItem('loginId');
    this.loginStatus = localStorage.getItem('loginStatus');
    this.loginName = localStorage.getItem('name');

    if(this.venderId){
      this.getUserById(this.venderId);
      }
      else{
        this.getUserById(sessionStorage.getItem('editVenId'));
      }
  }
  getUserById(id:any){
    let val ={
      "id":id
    }
    this.WebapiService.getUserById(val).subscribe((res:any)=>{
      console.log(res);
      if(res.length > 0){
        this.companyName = res[0].company_name;
        this.debtValue = res[0].debt;
      }
    });
 
   }

  logout(){
    localStorage.removeItem('loginId');
    localStorage.removeItem('loginStatus');
    sessionStorage.removeItem('editVenId');
    this.router.navigate(['/']);

  }
  myAccount(e:any){
    // console.log(e);
    if(e==2){
      this.router.navigate(['/vendor-account'])
      sessionStorage.removeItem('editVenId');

    }
    else if(e==3){
      this.router.navigate(['/my-account'])
      sessionStorage.removeItem('editVenId');

    }
    else if(e==4){
      this.router.navigate(['/admin'])
      sessionStorage.removeItem('editVenId');
    }
    else{
  
    }
  }
  legalData(data:any){
    // console.log(this.venderId);
    
    if(this.loginStatus == 2){
      data.venderId = this.empId;
    }
    else if(sessionStorage.getItem('editVenId')){
      data.venderId = sessionStorage.getItem('editVenId');
    }
    else{
      data.venderId = this.venderId;
    }
    // console.log(data);
    
    this.WebapiService.insertLegalData(data).subscribe((res:any)=>{
      console.log(res);
      if(res.status == 1){
        alert(res.msg);
        // localStorage.setItem('venderId',res.last_id);
        this.lfData={};
      }
      else{
        alert(res.msg);
      }
    });
  }
  debt(e:any){
    this.debtValue = e.target.value;
    // console.log(e.target.value);
    if(e.target.value){
      this.lfData.debt = e.target.value;
    }
  }

}
