<style>
    .product-entry{
        background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url(../../assets/img/aerial-view.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 900px;
         padding-top: 150px;
    }
    .section-form{
        background: #fff;
    }
    .pull-left{
        float: left;
    }
    .card img{
        width: 50px;
    }
    .card{
        display: flex;
        align-items: center;
        padding: 40px;
        color: #212121;
    }
    .card:hover {
    background: #161616;
    color: #fff;
}
</style>

<section *ngIf="loginStatus == 3" class="product-entry section" id="productEntry">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 offset-md-3">
                <div class="section-content">
                    <div class="section-title mb-md-4">
                        <h1 class="mb-md-0">My Account</h1>
                    </div>
                    
                    <div class="col-md-12">
                        <div class="col-md-4 pull-left">
                            <a [routerLink]="['/step-one']" routerLinkActive="active">
                                <div class="card">
                                    <img src="assets/img/add.png" alt=""><br>
                                    Add Vendors
                                </div>
                                </a>
                            </div>
                            <!-- col-md-4 pull-left -->
                            <div class="col-md-4 pull-left">
                            <a [routerLink]="['/vendor-list']" routerLinkActive="active">
                                <div class="card">
                                    <img src="assets/img/added.png" alt=""><br>
                                    My Vendors
                                </div>
                                </a>
                            </div>
                            <!-- col-md-4 pull-left -->
                            <div class="col-md-4 pull-left">
                                <a [routerLink]="['/product-entry']" routerLinkActive="active">
                                    <div class="card">
                                        <img src="assets/img/box.png" alt=""><br>
                                        Add Product
                                    </div>
                                    </a>
                                </div>
                                <!-- col-md-4 pull-left -->
                            
                        </div> 
                <!-- col-md-12 -->
                    </div> 
                    <!-- content -->
                    </div> 
                    <!-- col-md-12 -->
                    </div>
                    </div>
</section>