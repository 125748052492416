<style>
    input[type="number"] {
       -moz-appearance: textfield;
       appearance: textfield;
       margin: 0;
   }
   input[type="number"]::-webkit-inner-spin-button,
   input[type="number"]::-webkit-outer-spin-button {
       -webkit-appearance: none;
       margin: 0;
   }
.sign-in a {
    color: #fff !important;
}
.sign-in img {
    width: 30px;
}
.sign-in ul > li {
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
}
.sign-in ul > li a {
    color: var(--white);
    margin-left: 0.25rem;
}
.search-box img {
    transform: translate(-6%, -50%);
    position: absolute;
    top: 50%;
    right: 6%;
}
.vendor-details {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../assets/img/aerial-view.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
}

a {
    color: #fff;
}

.dropdown-item {
    color: #212529 !important;
}

.r-25 {
    right: 25px;
}
.you-deal-in {
    flex-wrap: wrap;
}
.you-deal-in > div {
    flex: 0 1 380px;
}
</style>

<section class="vendor-details section" id="vendorDetails">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title mb-md-4 col-md-12  float-md-left">
                    <div class="col-md-6 float-md-left">
                        <h1 class="mb-md-0">Company Profile</h1>
                        <h2 style="color: #fff;font-size: 20px;margin-top: 10px;">{{companyName}}</h2>
                    </div>
                    <div *ngIf="empId" class="sign-in col-md-6 float-md-left text-right">
                        <ul class="text-md-center">
                            <li *ngIf="userLogin" >
                                <a (click)="myAccount(loginStatus)"><img src="assets/img/user.png" alt="" class="w-18">{{loginName}}</a>
                            </li>
                            <li  *ngIf="!userLogin">
                                <a [routerLink]="['/login']" class="signinbtn" routerLinkActive="active">Sign In</a>
                            </li>  
                            <li><span class="separator">|</span></li>
                            <li *ngIf="!userLogin" >
                                <a class="signupbtn" [routerLink]="['/signup']" routerLinkActive="active">Join Us</a>
                            </li>
                            <li *ngIf="userLogin">
                                <a (click)="logout()" href=""><img src="assets/img/logout.png" alt="" class="w-18">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div><!-- row -->
        <div class="row">
            <div class="col-12">
                <div class="step-menu-wrapper">
                    <ul class="d-flex">
                        <li class="flex-fill"><a [routerLink]="['/step-one']" routerLinkActive="active">Basic Information</a></li>
                        <li class="flex-fill"><a class="active">Company Profile</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-three']" routerLinkActive="active" >Commercial</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-four']" routerLinkActive="active" >Quality Management & Certifications</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-five']" routerLinkActive="active" >Staffing</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-six']" routerLinkActive="active" >Legal & Financial</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-seven']" routerLinkActive="active" >Social Compliance</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-eight']" routerLinkActive="active" >Infrastructure</a></li>
                    </ul>
                </div>
            </div>
        </div>
<div class="" id="ven-con" role="tabpanel" aria-labelledby="vcon-tab">
    <div class="section-content bg-white p-md-3 h-auto clearfix">
        <div class="section-title mb-md-4">
            <!-- <h1 class="mb-md-0">Vendor Contact</h1> -->
        </div>
        <div class="section-form">
            <form #contactVen="ngForm" (ngSubmit)="contactVen.valid && onVendorContactForm(vencon)">
                <div class="form-group col-md-4 offset-md-8">
                    <label for="exampleInputEmail1">Number of Directors/Partners</label>
                    <select (change)="onSelectRowNod($event)" class="custom-select">
                        <!-- <option value="" selected>Number of Directors/Partners</option> -->
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                </div>
                <table id ="nod" class="table">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Name</th>
                            <th>Mobile No.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let nod of vencon.noddetails">
                            <td>
                                <div class="form-group">
                                    <select class="custom-select" name="dir_title" [(ngModel)]="nod.dir_title">
                                        <option *ngFor="let dtitle of dirTitleList" value="{{dtitle}}">{{dtitle}}</option>
                                        <!-- <option value="Mrs">Mrs.</option>
                                        <option value="Ms.">Ms.</option> -->
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="form-group col-md-6 float-left">
                                    <input type="text" name="dir_fname" [(ngModel)]="nod.dir_fname" class="form-control" placeholder="First Name" #dir_fname="ngModel" [class.is-invalid]="dir_fname.invalid && contactVen.submitted" required="required" />
                                    <div class="invalid-feedback">
                                        Valid director Fname is required.
                                      </div>
                                </div>
                                <!-- <div class="form-group col-md-4 float-left">
                                    <input type="text" name="dir_mname" [(ngModel)]="nod.dir_mname" class="form-control" id="exampleInputMname" aria-describedby="mname" placeholder="Middle Name" />
                                </div> -->
                                <div class="form-group col-md-6 float-left">
                                    <input type="text" name="dir_lname" [(ngModel)]="nod.dir_lname" class="form-control" placeholder="Last Name" #dir_lname="ngModel" [class.is-invalid]="dir_lname.invalid && contactVen.submitted" required="required" />
                                    <div class="invalid-feedback">
                                        Valid director lname is required.
                                      </div>
                                </div>
                            </td>
                            <td>
                                <div class="form-group">
                                    <div class="form-row">
                                        <div class="col-3">
                                            <select class="form-control" name="">
                                                <option value="">+91</option>
                                            </select>
                                        </div>
                                        <div class="col">
                                            <input type="number" name="dir_phone" [(ngModel)]="nod.dir_phone" #dir_phone="ngModel"  class="form-control"  placeholder="Mobile" pattern="^((\\+91-?)|0)?[0-9]{10}$" required="required" />
                                            <div *ngIf="!dir_phone?.valid && (dir_phone?.dirty || dir_phone?.touched)">
                                                <div style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="dir_phone.errors?.required">
                                                  Mobile number is required
                                                </div>
                                              <div style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="dir_phone.errors?.pattern">
                                                 please enter valid mobile number
                                              </div>
                                              </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                                <!-- <div class="form-group col-md-12 float-left">
                                    <div class="form-group">
                                        <select class="" name="dir_title">
                                            <option value="">+91</option>
                                            <option value="">+91</option>
                                            <option value="">+91</option>
                                        </select>
                                    </div>
                                    <input type="number" name="dir_phone" [(ngModel)]="nod.dir_phone"  class="form-control" id="exampleInputMobile" aria-describedby="mobile" placeholder="Mobile" />
                                </div> -->
                            </td>
                        </tr>
                    </tbody>
                </table>

                <label><b>First Point of Contact</b></label>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Email</th>
                            <th>Mobile</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-row">
                                    <div class="col">
                                        <select name="poc_title" [(ngModel)]="vencon.poc_title" class="custom-select">
                                            <option *ngFor="let ptitle of pocTitleList" value="{{ptitle}}">{{ptitle}}</option>
                                            <!-- <option value="Mrs.">Mrs.</option>
                                            <option value="Ms.">Ms.</option> -->
                                        </select>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="form-row">
                                    <div class="col">
                                        <input type="text" name="poc_fname" [(ngModel)]="vencon.poc_fname"  class="form-control" placeholder="First Name" #poc_fname="ngModel" [class.is-invalid]="poc_fname.invalid && contactVen.submitted" required="required">
                                        <div class="invalid-feedback">
                                            Valid fname is required.
                                          </div>
                                    </div>
                                    <div class="col">
                                        <input type="text" name="poc_lname" [(ngModel)]="vencon.poc_lname" class="form-control" placeholder="Last Name" #poc_lname="ngModel" [class.is-invalid]="poc_lname.invalid && contactVen.submitted" required="required">
                                        <div class="invalid-feedback">
                                            Valid lname is required.
                                          </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <input type="text" name="poc_desg" [(ngModel)]="vencon.poc_desg" class="form-control" placeholder="Designation" #poc_desg="ngModel" [class.is-invalid]="poc_desg.invalid && contactVen.submitted" required="required">
                                <div class="invalid-feedback">
                                    Valid designation is required.
                                  </div>
                            </td>
                            <td>
                                <input type="email" name="poc_email" [(ngModel)]="vencon.poc_email" class="form-control" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" #poc_email="ngModel" required="required" placeholder="Email">
                                <div *ngIf="!poc_email?.valid && (poc_email?.dirty || poc_email?.touched)">
                                    <div style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="poc_email.errors?.required">
                                      Email is required
                                    </div>
                                  <div style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="poc_email.errors?.pattern">
                                    Valid email required
                                  </div>
                                  </div>
                            </td>
                            <td>
                                <div class="form-row">
                                    <div class="col-4">
                                        <select class="form-control" name="">
                                            <option value="91">+91</option>
                                           
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input type="number" name="poc_mobile" [(ngModel)]="vencon.poc_mobile" class="form-control"#poc_mobile="ngModel" required="required" pattern="^((\\+91-?)|0)?[0-9]{10}$" placeholder="Mobile" />
                                        <div *ngIf="!poc_mobile?.valid && (poc_mobile?.dirty || poc_mobile?.touched)">
                                            <div style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="poc_mobile.errors?.required">
                                              Mobile number is required
                                            </div>
                                          <div style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="poc_mobile.errors?.pattern">
                                             please enter valid mobile number
                                          </div>
                                          </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="form-group col-md-4 offset-md-8">
                    <label for="exampleInputEmail1">Number of Offices</label>
                    <select (change)="onSelectRowNoof($event)" class="custom-select">
                        <!-- <option value="" selected>Number of Directors/Partners</option> -->
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <!-- <th>Street</th>
                            <th>Country</th>
                            <th>Pincode</th>
                            <th>Office Phone</th>
                            <th>Fax</th> -->
                            <th>Building No. (office address)</th>
                            <th>Street</th>
                            <th>Pincode</th>
                            <th>City</th>
                            <th>State</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let noof of vencon.noofdetails">
                            <td>
                                <div class="form-group">
                                    <input type="text" name="offc_address" [(ngModel)]="noof.offc_address"  class="form-control" #offc_address="ngModel" [class.is-invalid]="offc_address.invalid && contactVen.submitted" required="required" placeholder="Building no." />
                                    <div class="invalid-feedback">
                                        Valid office address is required.
                                      </div>
                                </div>
                            </td>
                            <td>
                                <div class="form-group">
                                    <input type="text" name="offc_street" [(ngModel)]="noof.offc_street"  class="form-control" #offc_street="ngModel" [class.is-invalid]="offc_street.invalid && contactVen.submitted" required="required" placeholder="Street" />
                                    <div class="invalid-feedback">
                                        Valid office street is required.
                                      </div>
                                </div>
                                <!-- <div class="form-group col-md-4 float-left">
                                    <input type="number" name="offc_state" [(ngModel)]="noof.offc_street"  class="form-control" id="exampleInputState" aria-describedby="state" placeholder="Pincode" />
                                </div> -->
                                <!-- <div class="form-group col-md-4 float-left">
                                    <input type="text"  name="offc_city" [(ngModel)]="noof.offc_city"  class="form-control" id="exampleInputCity" aria-describedby="city" placeholder="City" />
                                </div> -->
                            </td>
                            <td>
                                <div class="form-group">
                                    <input type="number" name="offc_pin" [(ngModel)]="noof.offc_pin"  class="form-control" #offc_pin="ngModel" [class.is-invalid]="offc_pin.invalid && contactVen.submitted" required="required" placeholder="Pincode" />
                                    <div class="invalid-feedback">
                                        Valid office pincode is required.
                                      </div>
                                </div>
                            </td>
                            <td>
                                <div class="form-group">
                                    <input type="text"  name="offc_city" [(ngModel)]="noof.offc_city"  class="form-control" #offc_city="ngModel" [class.is-invalid]="offc_city.invalid && contactVen.submitted" required="required" placeholder="City" />
                                    <div class="invalid-feedback">
                                        Valid office city is required.
                                      </div>
                                </div>
                            </td>
                            <td>
                                <div class="form-group">
                                    <input type="text"  name="offc_state" [(ngModel)]="noof.offc_state"  class="form-control" #offc_state="ngModel" [class.is-invalid]="offc_state.invalid && contactVen.submitted" required="required" placeholder="State" />
                                    <div class="invalid-feedback">
                                        Valid office state is required.
                                      </div>
                                </div>
                            </td>
                
                        </tr>
                    </tbody>
                </table>

                <div class="form-group col-md-4 offset-md-8">
                    <!-- <label for="exampleInputEmail1">Number of Offices</label> -->
                    <label for="exampleInputEmail1">Number of Factories</label>
                    <select (change)="onSelectRowNof($event)" class="custom-select">
                        <!-- <option value="" selected>Number of Directors/Partners</option> -->
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                         
                            <th>Building No. (Factory address)</th>
                            <th>Street</th>
                            <th>Pincode</th>
                            <th>City</th>
                            <th>State</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let nof of vencon.nofdetails">
                            <td>
                                <div class="form-group">
                                    <input type="text"  name="factory_Building" [(ngModel)]="nof.factory_Building"  class="form-control" placeholder="Building No." #fac_address="ngModel" [class.is-invalid]="fac_address.invalid && contactVen.submitted" required="required"/>
                                    <div class="invalid-feedback">
                                        Valid factory address is required.
                                      </div>
                                </div>
                            </td>
                            <td>
                                <div class="form-group">
                                    <input type="text"  name="factory_street" [(ngModel)]="nof.factory_factory_street"  class="form-control" id="" aria-describedby="street" placeholder="Street" #fac_street="ngModel" [class.is-invalid]="fac_street.invalid && contactVen.submitted" required="required" />
                                    <div class="invalid-feedback">
                                        Valid factory street is required.
                                      </div>
                                </div>
                                <!-- <div class="form-group col-md-4 float-left">
                                    <label for="">Country</label> -->
                                    <!-- <select class="custom-select" (change)="venreg.country=$event.target.value"> 
                -->
                                    <!-- <select class="custom-select" name="factory_country" [(ngModel)]="nof.factory_country">
                                        <option selected>India</option>
                                        <option value="1">Nepal</option>
                                        <option value="2">Bhutan</option>
                                    </select>
                                </div> -->
                                <!-- <div class="form-group col-md-4 float-left">
                                    <label for="">State</label>
                                    <select class="custom-select" name="factory_state" [(ngModel)]="nof.factory_state" >
                                        <option selected>New Delhi</option>
                                        <option value="1">New Delhi</option>
                                        <option value="2">New Delhi</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4 float-left">
                                    <label for="">City</label>
                                    <select class="custom-select" name="factory_city" [(ngModel)]="nof.factory_country">
                                        <option selected>Mumbai</option>
                                        <option value="1">Kolkata</option>
                                        <option value="2">Chennai</option>
                                    </select>
                                </div> -->
                            </td>
                            <td>
                                <div class="form-group">
                                    <input type="number" name="factory_pincode" [(ngModel)]="nof.factory_pincode" class="form-control" id=""  placeholder="Pincode" #fac_pin="ngModel" [class.is-invalid]="fac_pin.invalid && contactVen.submitted" required="required"/>
                                    <div class="invalid-feedback">
                                        Valid factory pincode is required.
                                      </div>
                                </div>
                                <!-- <div class="form-group">
                                    <input type="number" name="factory_pincode" [(ngModel)]="nof.factory_pincode" class="form-control" id="exampleInputFactoryPin" aria-describedby="facpin" placeholder="" />
                                </div> -->
                            </td>
                            <td>
                              <div class="form-group">
                                    <input type="text"  name="factory_city" [(ngModel)]="nof.factory_city"  class="form-control" #factory_city="ngModel" [class.is-invalid]="factory_city.invalid && contactVen.submitted" required="required" placeholder="City" />
                                    <div class="invalid-feedback">
                                        Valid office city is required.
                                      </div>
                                </div>  
                              
                                <div class="invalid-feedback">
                                    Select Factory city
                                  </div>
                                <!-- <div class="form-group">
                                    <input type="number" name="factory_phone" [(ngModel)]="nof.factory_phone" class="form-control" id="exampleInputFactoryNum" aria-describedby="facnum" placeholder="" />
                                </div> -->
                            </td>
                            <td>
                                <div class="form-group">
                                    <input type="text"  name="nof.factory_state" [(ngModel)]="nof.factory_state"  class="form-control" placeholder="State" #fac_state="ngModel" [class.is-invalid]="fac_state.invalid && contactVen.submitted" required="required"/>
                                    <div class="invalid-feedback">
                                        Valid factory address is required.
                                      </div>
                                </div><!-- <select name="factory_state" [(ngModel)]="nof.factory_state" class="custom-select" #fac_state="ngModel" [class.is-invalid]="fac_state.invalid && contactVen.submitted" required="required">
                                    <option value="New Delhi">New Delhi</option>
                                    <option value="New Delhi">New Delhi</option>
                                    <option value="New Delhi">New Delhi</option>
                                </select> -->
                                <div class="invalid-feedback">
                                    Select factory state
                                  </div>
                                <!-- <div class="form-group">
                                    <input type="number" name="factory_fax" [(ngModel)]="nof.factory_fax" class="form-control" id="exampleInputFaxNum" aria-describedby="faxnum" placeholder="" />
                                </div> -->
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="row">
                    <!-- <div class="col-md-6"> -->
                        <!-- <div class="col-md-10 px-0">
                            <div class="form-group">
                                <label for="" class="dropdown-item" *ngFor = "let mat of material; let i =index">
                                    {{mat.material_name}}
                                    <input type="checkbox" (change)="onSelectMaterial($event,mat.material_name)" value="{{mat.material_name}}" class="form-check-input r-25">
                                </label>
                            </div>
                        </div> -->
                        <!-- <div class="col-md-10">
                            <div class="form-group">
                                <label for="">Website URL</label>
                                <input type="url" pattern="https://.*" name="web" [(ngModel)]="vencon.web" placeholder="For ex: https://example.com" class="form-control" #web="ngModel" [class.is-invalid]="web.invalid && contactVen.submitted" required="required">
                                <div *ngIf="!web?.valid && (web?.dirty || web?.touched)">
                                    <div style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="web.errors?.required">
                                      URL is required
                                    </div>
                                  <div style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="web.errors?.pattern">
                                    Valid URL required
                                  </div>
                                  </div>
                             </div>
                        </div>
                    </div> -->
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Landline No.</label>
                            <div class="form-row">
                                <div class="col-2">
                                    <select name="" id="" class="custom-select">
                                        <option value="">+91</option>
                                    </select>
                                </div>
                                <div class="col-2">
                                    <input type="text" name="landline_offccode" [(ngModel)]="vencon.landline_offccode" placeholder="Code" class="form-control">
                                     <!-- <select name="" id="" class="custom-select">
                                        <option value="">+011</option>
                                        <option value="">+011</option>
                                        <option value="">+011</option>
                                    </select> -->
                                </div>
                                <div class="col">
                                    <input type="text" name="landline_offcnumber" [(ngModel)]="vencon.landline_offcnumber" placeholder="Office No." class="form-control" #offc_landline="ngModel" [class.is-invalid]="offc_landline.invalid && contactVen.submitted" required="required">
                                    <div class="invalid-feedback">
                                       Valid Landline number
                                      </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="comment">Company profile :</label>
                            <textarea class="form-control" name="cprofile" [(ngModel)]="vencon.cprofile" #cprofile="ngModel" [class.is-invalid]="cprofile.invalid && contactVen.submitted" required="required" rows="4" id="" min="100" placeholder="Minimum 100 words"></textarea>
                            <div class="invalid-feedback">
                               Company Profile required
                              </div>
                        </div>
                        <div class="form-group d-flex align-items-center">
                            <label for="" class="mb-0">Upload Company Profile</label>
                            <input type="file" value="select" name="doc" (change)="onContactFile($event)" class="btn btn-sm">
                         
                        </div>
                    </div>

                    <!-- <div class="col-md-6">
                        <div class="form-group">
                            <div class="dropdown">
                                <button class="btn bg-white border dropdown-toggle w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Material
                                </button>
                                <div class="dropdown-menu w-75" aria-labelledby="dropdownMenuButton">
                                    <label for="" class="dropdown-item" *ngFor = "let mat of material; let i =index">
                                        {{mat.material_name}}
                                        <input type="checkbox" (change)="onSelectMaterial($event,mat.material_name)" value="{{mat.material_name}}" class="form-check-input r-25">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!-- <div class="col-md-6">
                        <div class="form-group">
                            <label for="comment">Company profile :</label>
                            <textarea class="form-control" name="cprofile" [(ngModel)]="vencon.cprofile" rows="5" id=""></textarea>
                        </div>
                    </div> -->
                    <div class="col-md-12">
                        <div class="d-inline-flex">
                            <label for="" class="col-2 px-0">Main Products you deal in</label>
                            <div class="col-8">
                                <label *ngFor="let data of categoryList" class="form-check-label col-3" for="{{data.id}}">
                                <input class="form-check-input" type="checkbox" id="{{data.id}}" (change)="mainProduct($event)" value=" {{data.category_name}}" /> {{data.category_name}} </label>
                            </div>
                            
                        </div>
                        <!-- <div class="form-group">
                            <div class="form-check-inline">
                                <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="Horeca" />Horeca
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="Flatware" />Flatware
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="Bakware" />Bakware
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="Petware" />Petware
                                </label>
                            </div>
                        </div> -->
                        <!-- <div class="form-check-inline">
                            <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="Tableware" />Tableware
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="Tea/Coffee/Breakfast" />Tea/Coffee/Breakfast
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="Texttile" />Texttile
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="others" />others
                            </label>
                        </div> -->
                        
                        <!-- <div class="form-group">
                            <label for="comment">Main products you deal in: 50 words :</label>
                            <textarea class="form-control" name="productdesc" [(ngModel)]="vencon.productdesc" rows="5" id=""></textarea>
                        </div> -->

                        <a class="btn btn-primary" (click)="addRow(counterFlag+1)">Add</a>
                       
                        <div class="form-group" style="margin-top: 20px;">
                            <div class="d-inline-flex">
                                <label for="">Factory Photos</label><br>
                            <input type="file" multiple (change)="factoryPhotos($event)" class="btn btn-sm">
                            </div>

                        </div>
                        <div #div class="form-group">

                        </div>
                        <div class="preview-wrapper">
                            <div class="img-preview" *ngFor='let url of facPhotosArr'>
                              <img [src]="url.shw" width="200px">
                              <button type="button" class="btn btn-danger btn-sm" (click)="removeImage(url.shw)">Remove</button>
                            </div>
                          </div>
                    </div>
                </div>

                

                <!-- <div class="offset-md-4 col-md-2 float-md-left">
                    <input type="file" class="btn-sm" name="doc" (change)="onContactFile($event)">
                </div> -->

                <!-- <div class="col-md-2 float-md-left ml-md-3">
                    <button class="btn btn-sm">Add More</button>
                </div> -->
                    
                <!-- <div class="col-md-8 clearfix" style="margin-bottom: 32px;"></div> -->
                <!-- <div class="w-100" ></div> -->
                <div class="row" >
                    <div class="col-md-4 text-center mx-auto">
                        <button [routerLink]="['/step-one']" routerLinkActive="active" class="btn btn-secondary d-md-block mx-auto"><i class="fa fa-angle-left"></i> Previous</button>
                    </div>
                    <div class="col-md-4 text-center mx-auto">
                        <button type="submit" class="btn btn-info d-md-block mx-auto">Submit</button>
                    </div>
                    
                    <div class="col-md-4 text-center mx-auto">
                        <button [routerLink]="['/step-three']" routerLinkActive="active" class="btn btn-primary d-md-block mx-auto">Next <i class="fa fa-angle-right"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</div>
</section>