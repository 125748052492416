<style>
    .product-entry{
        background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url(../../assets/img/aerial-view.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 900px;
         padding-top: 150px;
    }
    .bg-white{
        background: #fff;
    }
    .table{
        font-size: 14px;
    }
</style>

<section class="product-entry section" id="">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 offset-md-3">
                <div class="section-content">
                    <div class="section-title mb-md-4">
                        <h1 class="mb-md-0">My Vendors</h1>
                    </div>
                    <div class="col-md-12 bg-white table-responsive">
                        <table class="table">
                            <tr>
                                <th>Vendor Name</th>
                                <th>email</th>
                                <th>Phone</th>
                                <th>Date Added</th>
                                <th>Edit Vendor</th>
                                <th>View Vendor</th>
                                <th>View Products</th>
                                <th>Status(Accept/reject)</th>
                            </tr>
                            <tr *ngFor="let data of vendorData | paginate: { itemsPerPage: 5, currentPage: p }">
                                <td>{{data.company_name}}</td>
                                <td>{{data.email}}</td>
                                <td>{{data.mobile}}</td>
                                <td>{{data.date_created}}</td>
                                <td>
                                    <a [routerLink]="['/step-one',data.id]" routerLinkActive="active" class="btn">
                                        <i class="fa fa-edit"></i>
                                    </a></td>
                                
                                    <td>
                                    <a [routerLink]="['/vendor-profile']" [queryParams]="{ id:data.id }"  routerLinkActive="active" class="btn">
                                        <i class="fa fa-eye"></i>
                                    </a></td>
                                <td>
                                    <a [routerLink]="['/vendor-product']" [queryParams]="{ id:data.id }" routerLinkActive="active" class="btn">
                                        <i class="fa fa-list"></i>
                                    </a>
                                </td>
                                <td>
                                    <select (change)="onCheckStatus($event,data.id,data.email)">
                                        <option disabled [selected]="data.vendorStatus == 0">Select Staus</option>
                                         <option [selected]="data.vendorStatus == 1" value="1">Reject</option>
                                         <option [selected]="data.vendorStatus == 2" value="2">Accept</option>   
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <br>
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        <br>
                    </div>
                </div> 
                    <!-- content -->
                    </div> 
                    <!-- col-md-12 -->
                    </div>
                    </div>
</section>