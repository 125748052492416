import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { WebapiService } from '../services/webapi.service'; 
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
slug:any;
serviceList:any={};
inquery:any={};
//params:any;
  constructor(private router:ActivatedRoute,private WebapiService:WebapiService,
    private urouter:Router) { }

  ngOnInit() {
    let pths= this.urouter.url.split('/');
   // console.log(pths[1]);
   // console.log(this.urouter.url);
    this.router.queryParams.subscribe(params => {
      //  console.log(params); // { order: "popular" 
      this.slug = params.slug;
       // console.log(this.order); // popular
      }
    );
   this.getServiceBySlug(pths[2])
  }

  getCategoryBySlug(slug:any){

    this.WebapiService.getCategoryBySlug(slug).subscribe((res: any) => {
      console.log(res);


    });
  }

  getServiceBySlug(id:any){

    const input  = {slug:id}
    //console.log(categoryList);
     // for (let category of categoryList) {
        //console.log(category.id);
        
        this.WebapiService.getServiceBySlug(input).subscribe((res: any) => {
          console.log(res);
           this.serviceList = res[0];
    
    });
  //}
  }
  insertInquery(){
    this.inquery.service_id =   this.serviceList.id;
    console.log(this.inquery);
    this.WebapiService.insertInquery(this.inquery).subscribe((res: any) => {
     alert(res.msg);
      console.log(res);
       //this.serviceList = res[0];

});
  }
}