import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
@Component({
  selector: 'app-stepone',
  templateUrl: './stepone.component.html',
  styleUrls: ['./stepone.component.css']
})
export class SteponeComponent implements OnInit {
  venderId:any;
  empId: any;
  loginStatus: any;
  userLogin:any;
  venreg:any={};
  titleList:any;
  idVen:any;
  loginName:any

  constructor(private router: Router,
    private WebapiService:WebapiService,private _activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.titleList=["Mr","Mrs","Ms"];
    this.venreg.title = this.titleList[0];
    this.idVen = this._activatedRoute.snapshot.params.id;
    this.userLogin = localStorage.getItem('loginId');
    this.venderId = localStorage.getItem('venderId');
    this.empId = localStorage.getItem('loginId');
    this.loginStatus = localStorage.getItem('loginStatus');
    this.loginName = localStorage.getItem('name');
    // console.log(this.idVen);
    if(this.idVen){
      sessionStorage.setItem('editVenId',this.idVen);
      this.router.navigate(['/step-one']);
    }
    if(sessionStorage.getItem('editVenId')){
      this.getUserById(sessionStorage.getItem('editVenId'));
    }
    
  }

  logout(){
    localStorage.removeItem('loginId');
    localStorage.removeItem('loginStatus');
    sessionStorage.removeItem('editVenId');
    this.router.navigate(['/']);
  }

  insertVendor(data:any){
    if(data.password == data.conpass){
      data.submBy = this.empId;
      // console.log(data);
      if(this.idVen){
      data.id = this.idVen;
      }
      // console.log(data);
      
      this.WebapiService.registerVendor(data).subscribe((res:any)=>{
         console.log(res);
         if(res.status == 1){
           alert(res.msg);
           localStorage.setItem('venderId',res.last_id);
           this.getUserById(res.last_id);
           this.router.navigate(['/step-two']);
         }
         else{
           alert(res.msg);
         }
      });
    }
    else{
      alert("please enter same password");
    }

  }

  getUserById(id:any){
   let val ={
     "id":id
   }
   this.WebapiService.getUserById(val).subscribe((res:any)=>{
     console.log(res);
     if(res.length > 0){
       this.venreg.title = res[0].title;
       this.venreg.cname = res[0].company_name;
       this.venreg.fname = res[0].fname;
       this.venreg.lname = res[0].lname;
       this.venreg.mob = res[0].mobile;
       this.venreg.offnum =res[0].office_phone;
       this.venreg.offadd = res[0].office_address;
       this.venreg.street = res[0].street;
       this.venreg.country = res[0].country;
       this.venreg.state = res[0].state;
       this.venreg.city =res[0].city;
       this.venreg.pin = res[0].pin;
       this.venreg.email = res[0].email;
       this.venreg.password = res[0].password;
       this.venreg.conpass = res[0].password;
     }
   });

  }

  myAccount(e:any){
    //  console.log(e);
     if(e==2){
       this.router.navigate(['/vendor-account'])
       sessionStorage.removeItem('editVenId');
     }
     else if(e==3){
       this.router.navigate(['/my-account'])
       sessionStorage.removeItem('editVenId');

     }
     else if(e==4){
      this.router.navigate(['/admin']);
      sessionStorage.removeItem('editVenId');
     }
     else{
       
     }
    }
}
