<header>
    <nav>
        <a href="" class="d-inline-block d-md-inline-block">
            <img src="assets/img/logo.png" alt="logo" class="bg-white img-fluid">
        </a>
        <ul class="pt-md-3 mainMenu text-center text-md-left" id="mainMenu">
            <li class="active"><a  [routerLink]="['/']" class="active">Home</a></li>
            <!-- <li><a href="about" id="aboutLink">About</a></li> -->
            <li><a href="#whoWeAre">Who We Are</a></li>
            <li><a href="#whatWeDo">What We Do</a></li>
            <!-- <li><a href="#whatWeDo">Our Services</a></li> -->
            <li><a href="#ourProducts">We Deal In</a></li>
            <li><a href="#manCapable">Manufacturing Capabilities</a></li>
            <li><a href="#addCust">Customers</a></li>
              <li><a href="#testimonial">Testimonial</a></li>
              <li><a href="#career">Career</a></li>
          
            <!-- <li><a [routerLink]="['/career']" routerLinkActive="active">Career</a></li> -->
            <li><a href="#contactUs">Contact Us</a></li>
        </ul>
        <button class="btn d-md-none" onclick="showNav()">
         <i class="fas fa-bars"></i>
      </button>
    </nav>
</header>

<!-- <script>
    $(document).ready(function() {
        var targetElement = $(".mainMenu li a");
        targetElement.click(function() {
            $(this).siblings().removeClass("c-one");
            $(this).addClass("c-one");
        });
    });
</script> -->
