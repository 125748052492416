<section style="padding-bottom: 50px">
    <div class="serviceHeading">
       <div class="container">
          <div class="row">
             <div class="col-12 text-center">
                <h1 class="text-white">Audit and Assurance</h1>
                <ul class="breadcrumb">
                   <li><a href="/">Home</a></li>
                   <li><a href="#">Audit and Assurance</a></li>
                </ul>
             </div>
          </div>
       </div>
    </div>
 </section>
 <section class="serviceSlide"></section>
 <section class="section  animate__animated animate__fadeIn animate__delay-1s">
    <div class="container">
     <!-- Services -->
        <div class="row">
            <div class="col-sm-3 col-6 rounded text-center mr-1 mx-auto" *ngFor="let data of serviceList">
                <div class="text-dark bg-white vmrService">
                    <div style="display: inline-block; position: relative;">
                        <img src="assets/img/services-shape.svg" class="shape1">
                        <img src="assets/img/services-shape-2.svg" class="shape2">
                        <i class="lni lni-apartment text-white service_icon"></i>
                    </div>
                    <h4 class="">{{data.service_name}} </h4>
                    <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                    <!-- routerLink="/service"  [queryParams]="{'slug':data.slug}" -->
                    <a href="/service/{{data.slug}}">Know More <i class="lni lni-chevron-right"></i></a>
                </div>
            </div>

<!-- 

            <div class="col-sm-3 col-6 rounded text-center ml-1 mx-auto">
                <div class="text-dark bg-white vmrService">
                    <div style="display: inline-block; position: relative;">
                        <img src="assets/img/services-shape.svg" class="shape1">
                        <img src="assets/img/services-shape-2.svg" class="shape2">
                        <i class="lni lni-invest-monitor text-white service_icon"></i>
                    </div>
                    <h4 class="">Tax &amp; Legal</h4>
              
                    <a href="#">Know More <i class="lni lni-chevron-right"></i></a>
                </div>
            </div>
            <div class="col-sm-3 col-6 rounded text-center mr-1 mx-auto">
                <div class="text-dark bg-white vmrService">
                    <div style="display: inline-block; position: relative;">
                        <img src="assets/img/services-shape.svg" class="shape1">
                        <img src="assets/img/services-shape-2.svg" class="shape2">
                        <i class="lni lni-library text-white service_icon"></i>
                    </div>
                    <h4 class="">Advisory</h4>
                   
                    <a href="#">Know More <i class="lni lni-chevron-right"></i></a>
                </div>
            </div>
            <div class="col-sm-3 col-6 rounded text-center ml-1 mx-auto">
                <div class="text-dark bg-white vmrService">
                    <div style="display: inline-block; position: relative;">
                        <img src="assets/img/services-shape.svg" class="shape1">
                        <img src="assets/img/services-shape-2.svg" class="shape2">
                        <i class="lni lni-handshake text-white service_icon"></i>
                    </div>
                    <h4 class="">Business Services &amp; Outsourcing</h4>
                 
                    <a href="#">Know More <i class="lni lni-chevron-right"></i></a>
                </div>
            </div> -->
        </div>
        
    </div>
</section>

