<style>
    .product-entry{
        background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url(../../assets/img/aerial-view.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 900px;
         padding-top: 130px;
    }
    .custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
    border-color: #dc3545;
    box-shadow: 0px 0px 1px 2px inset red;
}
    .section-form{
        background: #fff;
    }
    .form-control{
        font-size: 14px !important;
        
    }
    .form-group{
        /* margin-bottom: 2px; */
    }
  
                                  .white{
    color: #fff;
}
                                .signupform{
                                    display: flex;
    width: 100%;
    background: #052c7d;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    border-radius: 8px;
                                }
                                .signin{
                                    width: 100%;
    background: #ffffff00;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    border-radius: 8px;
                                }
                                .countrycode{
                                    position: absolute;
    top: 6px;
    left: 10px;
    font-size: 14px;
    color: #495057;
                                }
                                .pl-26{
                                    padding-left: 26px;
                                }
                                label{
                                    cursor: pointer;
                                }
                                /* .invalid-feedback{
                                    display: block;
                                    color: rgb(253, 55, 55);
                                } */
                              .vali_status{
                                /* color: #ff1e1e;position: absolute;bottom: 0px;left: 5%;font-size: 12px;text-transform: capitalize;font-weight: 600; */
                                
    color: #ff1e1e;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 600;
    position: absolute;
    bottom: -21px;

                                }
                                
                            </style>

<section class="product-entry section" id="productEntry">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 offset-md-3">
                <div class="section-content">
                    
                   
                            <div class="section-title mb-md-4">
                                <h1 class="mb-md-0">Create a new account</h1>
                            </div>
                            
                            <div class="col-md-12 ">
                                <form class="signupform" #loginForm="ngForm" (ngSubmit)="loginForm.valid && insertVendor(vendorData)">
                                    <div class="col-md-12 p-0">
                                        <div class="form-group col-md-6 float-left ">
                                           
                                            <label style="color:#fff" for="buyer">
                                                 <input type="radio" id="buyer" (change)="onChangeBuyer($event)" class="" value="1" name="type" #buyer="ngModel" [class.is-invalid]="buyer.invalid && loginForm.submitted" required="required" [(ngModel)]="vendorData.loginStatus"> I am a Buyer (Outside India)
                                                </label>
                                        </div>
                                        
                                        <div class="form-group col-md-6 float-left ">
                                            <label style="color:#fff" for="vendor">
                                                <input type="radio" id="vendor" (change)="onChangeVendor($event)" class="" value="2" name="type" #vendor="ngModel" [class.is-invalid]="vendor.invalid && loginForm.submitted" required="required" [(ngModel)]="vendorData.loginStatus"> I am a Vendor (inside India)
                                            </label>
                                        </div>
                                        <!-- <div class="invalid-feedback col-md-12 float-left">
                                            Type is required.
                                          </div> -->
                                          
                                    </div>
                                    <!-- .... -->
                                    <div *ngIf="countryFlag" class="col-md-12 float-left  p-none">
                                        <div class="form-group">
                                            <input list="country" class="form-control" name="country" [(ngModel)]="vendorData.country" (change)="getCode(vendorData.country)" autocomplete="off" #country="ngModel" [class.is-invalid]="country.invalid && loginForm.submitted" required="required" placeholder="Search Country">
                                            <!-- <div class="invalid-feedback">
                                                Valid country is required.
                                              </div> -->
                                              <div class="col-md-12 float-left p-0 p-none" *ngIf="!country?.valid && (country?.dirty || country?.touched)">
                                                <div class="vali_status" *ngIf="country.errors?.required">
                                                  country is required
                                                </div>
                                             
                                              </div>
                                            <datalist id="country">
                                                <option *ngFor="let country of countryList" value="{{country.name}}">
                                                
                                              </datalist>
                                             
                                        </div>
                              </div>
                                    <div class="col-md-12 float-left  p-none">
                                      <div class="form-group">
                                            <input type="text" class="form-control text-capitalize" #cname="ngModel" [class.is-invalid]="cname.invalid && loginForm.submitted" required="required" placeholder="Company Name" name="cname" [(ngModel)]="vendorData.cname">
                                            <div  class="col-md-12 float-left p-0 p-none"  *ngIf="!cname?.valid && (cname?.dirty || cname?.touched)">
                                                <div class="vali_status" *ngIf="cname.errors?.required">
                                                  Company name is required
                                                </div>
                                        </div>
                                    <!-- <div class="invalid-feedback">
                                        Valid company name is required.
                                      </div> -->
                                      
                                 
                                      </div>
                                </div>
                                    <div class="col-md-12 float-left p-none">
                                    <div class="form-group col-md-2 p-0 float-left ">
                                        
                                        <select class="form-control p-2" name="title" [(ngModel)]="vendorData.title">
                                            
                                            <option *ngFor="let title of titleList" value="{{title}}">{{title}}</option>
                                            
                                        </select>
                        
                                    </div> 
                                   
                                        <div class="form-group  col-md-5  px-2  float-left">
                                            <input type="text" class="form-control text-capitalize" placeholder="First Name" name="fname" #fname="ngModel" [class.is-invalid]="fname.invalid && loginForm.submitted" required="required" [(ngModel)]="vendorData.fname">

                                            <div  class="col-md-12 float-left p-0 p-none"  *ngIf="!fname?.valid && (fname?.dirty || fname?.touched)">
                                                <div class="vali_status" *ngIf="fname.errors?.required">
                                                  first name is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-5  px-1  float-left">
                                            <input type="text" class="form-control text-capitalize" placeholder="Last Name" #lname="ngModel" [class.is-invalid]="lname.invalid && loginForm.submitted" required="required" name="lname" [(ngModel)]="vendorData.lname">
                                            <div  class="col-md-12 float-left p-0 p-none" *ngIf="!lname?.valid && (lname?.dirty || lname?.touched)">
                                                <div class="vali_status" *ngIf="lname.errors?.required">
                                                  last name is required
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <!-- <div class="invalid-feedback">
                                            Valid last name is required.
                                          </div> -->
                                        
                                        
                                </div>
                                <div class="col-md-12 float-left   px-2 ">
                                    <div  *ngIf="countryFlag"  class="form-group float-left  px-2 col-md-3">
                                        <span class="form-control">+{{phoneCode}}</span> 
                                    </div>
                                    
                                    <div [ngClass]="{'col-md-9': countryFlag,'col-md-12':!countryFlag}" class="form-group float-left  px-2">
                                        
                                        <input type="number" class="form-control text-capitalize" #mob="ngModel" [class.is-invalid]="mob.invalid && loginForm.submitted"  pattern="^((\\+91-?)|0)?[0-9]{10}$"  required="required" max="10" min="10" placeholder="Mobile Number" name="mob" [(ngModel)]="vendorData.mob">
                                        
                                        <div  class="col-md-12 float-left p-0 p-none" *ngIf="!mob?.valid && (mob?.dirty || mob?.touched)">
                                            <div class="vali_status" *ngIf="mob.errors?.required">
                                              Mobile is required
                                            </div>
                                            <div class="vali_status" *ngIf="mob.errors?.pattern">
                                                Valid Mobile Number required
                                              </div>
                                        </div>
                                    </div>
                                    <!-- <div class="invalid-feedback">
                                        Valid last name is required.
                                      </div> -->
                                  </div>
                                  <div class="col-md-12 float-left">
                                    <div class="form-group">
                                        <input type="text" class="form-control text-capitalize" #web="ngModel" [class.is-invalid]="web.invalid && loginForm.submitted" required="required" name="website" [(ngModel)]="vendorData.website" placeholder="Website">
                                    </div>
                                  </div>
                                  <div class="col-md-12 float-left">
                                    <div class="form-group">
                                        <select name="business_type" id="" [(ngModel)]="vendorData.business_type" class="form-control">
                                            <option *ngFor="let b of business" value="{{b}}">{{b}}</option>
                                            <!-- <option value="Wholesaler">Wholesaler</option>
                                            <option value="Retailer">Retailer</option>
                                            <option value="Buying Agent">Buying Agent</option>
                                            <option value="Chain Store">Chain Store</option>
                                            <option value="Corporate Buyer">Corporate Buyer</option> -->
                                        </select>
                                    </div>
                                </div>
                                  <div class="col-md-12 float-left   px-2 ">
                                    <div class="form-group px-2">
                                        <input type="email" class="form-control text-capitalize" #email="ngModel" [class.is-invalid]="email.invalid && loginForm.submitted" required="required" placeholder="Email ID" name="email" [(ngModel)]="vendorData.email"  pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" >
                                        <div  class="col-md-12 float-left p-0 p-none" *ngIf="!email?.valid && (email?.dirty || email?.touched)">
                                            <div class="vali_status" *ngIf="email.errors?.required">
                                              Email is required
                                            </div>
                                          <div class="vali_status" *ngIf="email.errors?.pattern">
                                            Valid email required
                                          </div>
                                          </div>
                                    </div>
                                    
                                    <!-- <div class="invalid-feedback">
                                        Valid last name is required.
                                      </div> -->
                                    
                                </div>
                                <div class="col-md-12  px-2 ">
                                    <div class="form-group px-2  col-md-6 float-left ">
                                        <input type="password" class="form-control text-capitalize" #password="ngModel" [class.is-invalid]="password.invalid && loginForm.submitted" required="required" placeholder="Password" name="password" [(ngModel)]="vendorData.password">
                                        <div  class="col-md-12 float-left p-0 p-none" *ngIf="!password?.valid && (password?.dirty || password?.touched)">
                                            <div class="vali_status" *ngIf="password.errors?.required">
                                              Password is required
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <!-- <div class="invalid-feedback">
                                        Valid last name is required.
                                      </div> -->
                                    
                                    <div class="form-group  px-2  col-md-6  float-left ">
                                        <input type="text" class="form-control text-capitalize" #conpass="ngModel" [class.is-invalid]="conpass.invalid && loginForm.submitted" required="required" placeholder="Confirm Password" name="cpassword" [(ngModel)]="vendorData.cpassword">
                                        <!-- <div *ngIf="!cpassword?.valid && (cpassword?.dirty || cpassword?.touched)">
                                            <div class="vali_status" *ngIf="cpassword.errors?.required">
                                              Confirm Password is required
                                            </div>
                                        </div> -->
                                    </div>
                                   
                                    <!-- <div class="invalid-feedback">
                                        Valid last name is required.
                                      </div> -->
                                </div>
                                
                                    
                                <div class="col-md-12 text-center">
                                    <div class="form-group  ">
                                        <input type="submit" value="Register" class="btn btn-info" >
                                        <br>
                                        <br>
                                        <a [routerLink]="['/login']" class="white" routerLinkActive="active">Already have an account?</a>
                                    </div>
                                </div>
                                </form>
                            </div>
                            </div> 
                            <!-- content -->
                            </div> 
                    </div>
                    </div>
</section>