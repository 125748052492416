import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
@Component({
  selector: 'app-stepthree',
  templateUrl: './stepthree.component.html',
  styleUrls: ['./stepthree.component.css']
})
export class StepthreeComponent implements OnInit {
  venderId:any
  empId: any
  userLogin:any;
  loginStatus:any
  vencomm:any={};
  payterm:any=[];
  recordFlag:boolean=false;
  companyName: any;
  loginName:any
  constructor(private router: Router,
    private WebapiService:WebapiService) { }

  ngOnInit(): void {
    this.venderId = localStorage.getItem('venderId');
    this.userLogin = localStorage.getItem('loginId');
    this.empId = localStorage.getItem('loginId');
    this.loginStatus = localStorage.getItem('loginStatus');
    this.loginName = localStorage.getItem('name');

    if(this.venderId){
      this.getUserById(this.venderId);
      }
      else{
        this.getUserById(sessionStorage.getItem('editVenId'));
      }
  }
  getUserById(id:any){
    let val ={
      "id":id
    }
    this.WebapiService.getUserById(val).subscribe((res:any)=>{
      console.log(res);
      if(res.length > 0){
        this.companyName = res[0].company_name;
        this.vencomm.payterm = (res[0].payment_term)
        this.vencomm.shipmentfromdate = res[0].ship_from_date;
        this.vencomm.keeprecord = res[0].keep_record;
        this.vencomm.desc = res[0].keep_record_desc;
        this.vencomm.avgship = res[0].shipment_last_12month;
        this.vencomm.minship = res[0].min_ship_invoice_acceptable;
        this.vencomm.minshipvol = res[0].min_ship_vol;
        this.vencomm.latedelivery = res[0].late_delivery;
      }

    });
 
   }
  logout(){
    localStorage.removeItem('loginId');
    localStorage.removeItem('loginStatus');
    sessionStorage.removeItem('editVenId');
    this.router.navigate(['/']);
  }

  onVendorCommForm(data:any){
    // console.log(data);
    if(this.loginStatus == 2){
      data.venderId = this.empId;
    }
    else if(sessionStorage.getItem('editVenId')){
      data.venderId = sessionStorage.getItem('editVenId');
    }
    else{
      data.venderId = this.venderId;
    }
    // console.log(data);
    
    this.WebapiService.insertVendorCommercial(data).subscribe((res:any)=>{
      console.log(res);
      if(res.status == 1){
        alert(res.msg);
        this.router.navigate(['/step-four']);
      }
      else{
        alert(res.msg);
      }
    });
  }
  recordCheck(e:any){
   if(e.target.value == "yes"){
     this.recordFlag=true;
   }
   else{
     this.recordFlag = false;
   }
  }

  onCheckPayTerm(e:any){
    if (e.target.checked == true) {
      this.payterm.push(e.target.value);
    }else{
      var index = this.payterm.indexOf(e.target.value);
      this.payterm.splice(index, 1);
    }
    // console.log(this.payterm);
    this.vencomm.payterm = this.payterm;

  }
  myAccount(e:any){
    //  console.log(e);
     if(e==2){
       this.router.navigate(['/vendor-account'])
       sessionStorage.removeItem('editVenId');

     }
     else if(e==3){
       this.router.navigate(['/my-account'])
       sessionStorage.removeItem('editVenId');

     }
     else if(e==4){
      this.router.navigate(['/admin'])
      sessionStorage.removeItem('editVenId'); 
     }
     else{

     }
    }
}
