import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-emp-vendor-list',
  templateUrl: './emp-vendor-list.component.html',
  styleUrls: ['./emp-vendor-list.component.css']
})
export class EmpVendorListComponent implements OnInit {
  venderId: any;
  userId:any;
  vendorData:any;
  p:any=1;

  constructor(private router: Router,
    private WebapiService:WebapiService) { }

  ngOnInit(): void {
    this.userId = localStorage.getItem('loginId');
    // this.getVendorByEmpId(this.userId);
    this.getVendor();
  }

  // getVendorByEmpId(id:any){
  //   let val = {
  //     'id':id
  //   }
  //   this.WebapiService.getVendorByEmpId(val).subscribe((res:any)=>{
  //     // console.log(res);
  //     this.vendorData = res;
  //     this.venderId = res[0].id;
  //   });
  // }
  getVendor(){
    this.WebapiService.getVendor().subscribe((res:any)=>{
      console.log(res);
      this.vendorData = res;
      // this.venderId = res[0].id;
    });
  }
  onCheckStatus(e:any,id:any,email:any){
    console.log(e.target.value,id);
    let con = confirm("Are you sure you want to update status");
    if(con == true){
    let value = {
      "vendorStatus":e.target.value,
      "id":id,
      "email":email
    }
    this.WebapiService.updateVendorStatus(value).subscribe((res:any)=>{
      console.log(res);
      if(res.status == 1){
        alert(res.msg);
        location.reload();
      }     
      else{
        alert(res.msg);
      }
    });
  }
  else{
    location.reload();
  }
  }

}
