import { Component, OnInit,ViewChild,Renderer2,ElementRef } from '@angular/core';
import { WebapiService } from '../../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-steptwo',
  templateUrl: './steptwo.component.html',
  styleUrls: ['./steptwo.component.css']
})
export class SteptwoComponent implements OnInit {
  @ViewChild('div') div!: ElementRef;
  vencon:any={};
  venderId: any;
  empId: any;
  userLogin:any;
  loginStatus: any;
  temp: any;
  material: any;
  mainProductchck:any=[];
  imageSrc:any;
  dirTitleList:any;
  categoryList:any;
  pocTitleList:any;
  facPhotosArr: any=[];
  facImgData: any=[];
  counterFlag: any=0;
  companyName:any;
  loginName:any;
  constructor(private router: Router,
    private WebapiService:WebapiService,
    private sanitizer: DomSanitizer,private renderer: Renderer2) { }

  ngOnInit(): void {
    this.getCategory();
    this.dirTitleList=["Mr","Mrs","Ms"];
    this.pocTitleList=["Mr","Mrs","Ms"];
    this.vencon.poc_title = this.pocTitleList[0];
    this.userLogin = localStorage.getItem('loginId');
    this.venderId = localStorage.getItem('venderId');
    this.empId = localStorage.getItem('loginId');
    this.loginStatus = localStorage.getItem('loginStatus');
    this.loginName = localStorage.getItem('name');

    if(this.venderId){
    this.getUserById(this.venderId);
    }
    else{
      this.getUserById(sessionStorage.getItem('editVenId'));
    }

    this.vencon.noddetails = [{
      'dir_title':this.dirTitleList[0],
    }];
    this.vencon.noofdetails = [{
      'offc_address':'',
    }];
    this.vencon.nofdetails = [{
      'factory_Building':'',
    }];
  }
  getUserById(id:any){
    let val ={
      "id":id
    }
    this.WebapiService.getUserById(val).subscribe((res:any)=>{
      console.log(res);
      if(res.length>0){
      this.companyName = res[0]?.company_name;
      this.vencon.noddetails = JSON.parse(res[0].no_of_directors);
      this.vencon.poc_title = res[0].poc_title;
      this.vencon.poc_fname = res[0].poc_fname;
      this.vencon.poc_lname = res[0].poc_lname;
      this.vencon.poc_email = res[0].poc_email;
      this.vencon.poc_desg = res[0].poc_desg;
      this.vencon.poc_email = res[0].poc_email;
      this.vencon.poc_mobile = res[0].poc_mobile;
      this.vencon.noofdetails = JSON.parse(res[0].no_of_office);
      this.vencon.nofdetails = JSON.parse(res[0].no_of_factory);
      this.vencon.landline_offcnumber = res[0].office_phone;
      this.vencon.cprofile = res[0].company_profile;
      this.vencon.img = res[0].image;
      this.vencon.productdesc = res[0].product_desc;
      }
    });
 
   }

  getCategory(){

    this.WebapiService.getCategory().subscribe((res: any) => {
      // console.log(res);
      this.categoryList = res;
    });
  }
  onSelectRowNod(e:any){
    //  console.log(e.target.value);
    let value = e.target.value;
     this.vencon.noddetails = [];
     for (let index = 0; index < value; index++) {
          
      this.vencon.noddetails.push({});
       
     }
  }

  onSelectRowNoof(e:any){
    let value = e.target.value;
     this.vencon.noofdetails = [];
     for (let index = 0; index < value; index++) {
          
      this.vencon.noofdetails.push({});
       
     }
  }
  onSelectRowNof(e:any){
    let value = e.target.value;
     this.vencon.nofdetails = [];
     for (let index = 0; index < value; index++) {
          
      this.vencon.nofdetails.push({});
       
     }
  }

  mainProduct(e:any){
    if (e.target.checked == true) {
      this.mainProductchck.push(e.target.value);
    }else{
      var index = this.mainProductchck.indexOf(e.target.value);
      this.mainProductchck.splice(index, 1);
    }
    console.log(this.mainProductchck);
    this.vencon.productdesc = this.mainProductchck;
  }

  onContactFile(e:any){
    // console.log(e.target.files[0]);
    let ext = e.target.files[0];
    if(ext.type == "image/jpeg" || ext.type =="application/pdf"){
      this.vencon.img = e.target.files[0];
    }
    else{

      alert("please select JPG or PDF Files");
      e.target.value = null;
    }
  }
  deleteImage(){
    this.imageSrc = null;
  }

factoryPhotos(event:any) {
  let ext = event.target.files[0];

if(ext.type == "image/jpeg"){
  let value = {
                  "tmpImg":event.target.files[0],
                 "shw":this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(event.target.files[0]))
               }
               this.facPhotosArr.push(value);
               this.facImgData.push(event.target.files[0]);
}
else{
  alert("Please select JPG file");
}
}
removeImage(url:any){
  this.facPhotosArr = this.facPhotosArr.filter(image => (image.shw != url));
  for(let i=0; i<this.facPhotosArr.length; i++){
    this.facImgData = [];
    this.facImgData.push(this.facPhotosArr[i].tmpImg);
  }

}

addRow(a:any){
  // console.log(a); 
  if(this.counterFlag < 4){
  const p: HTMLInputElement = this.renderer.createElement('input');
  const button1:HTMLAnchorElement= this.renderer.createElement('a');
    this.renderer.addClass(p,"form-control");
    this.renderer.addClass(button1,`btn`);
    this.renderer.addClass(button1,`btn-danger`);
    this.renderer.setProperty(p,"type","file");
    this.renderer.setProperty(p,"id",`row${this.counterFlag}`);
    this.renderer.setProperty(button1,"innerHTML","Remove");
    this.renderer.listen(p,"change",(e:any)=>{
      let ext = e.target.files[0];
      if(ext.type == "image/jpeg"){
        let value = {
                        "tmpImg":e.target.files[0],
                       "shw":this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(e.target.files[0]))
                     }
                     this.facPhotosArr.push(value);
                     for(let i=1; i<this.facPhotosArr.length; i++){
                      this.facImgData.push(this.facPhotosArr[i].tmpImg);
                    }
                    // console.log(this.facImgData); 
           }
           else{
             alert("Please select JPG file");
           }
        
    })
    this.renderer.listen(button1,"click",(e:any)=>{
      this.counterFlag--;
      // console.log(this.counterFlag);
      
      // console.log(e.path[1].childNodes);
      this.renderer.removeChild(e.path[1],e.path[1].childNodes[0]);
      this.renderer.removeChild(e.path[1],e.path[1].childNodes[0]);
      
  })
    this.renderer.appendChild(this.div.nativeElement, p);
    this.renderer.appendChild(this.div.nativeElement, button1);
    this.counterFlag++;
  }
  else{
    alert("only five images can be selected");
  }
}

uploadFacPhotos(id:any){

  //  console.log(this.facImgData);
   
  for(let i=0; i<this.facImgData.length; i++){
    let val = {"vendorId":id, "file":this.facImgData[i]};
    // console.log(val);
    
    this.WebapiService.uploadFacPhotos(val).subscribe((res:any)=>{
         console.log(res);
    });
  }
  
}

  onSelectMaterial(e:any,mat:any){
    // console.log(mat);
    if(e.target.checked){
      this.temp.push(mat);
    }
    else {
      let i: number = 0;
      this.temp.forEach((item: any) => {
        // console.log(item);
        if (item == mat) {
          this.temp.splice(i, 1);
          return;
        }
        i++;
      });
    }
    if(this.temp.length>0){
       this.vencon.material = this.temp;
    }
  }
  getMaterial(){
    this.WebapiService.getMaterial().subscribe((res:any)=>{
      // console.log(res);
      this.material =res;
    });
  }

  logout(){
    localStorage.removeItem('loginId');
    localStorage.removeItem('loginStatus');
    sessionStorage.removeItem('editVenId');
    this.router.navigate(['/']);
  }
  onVendorContactForm(data:any){  
    if(this.loginStatus == 2){
      data.venderId = this.empId;
      this.uploadFacPhotos(this.empId);
    }
    else if(sessionStorage.getItem('editVenId')){
      data.venderId = sessionStorage.getItem('editVenId');
      this.uploadFacPhotos(sessionStorage.getItem('editVenId'));
    }
    else{
      data.venderId = this.venderId;
      this.uploadFacPhotos(this.venderId);
    } 
    // console.log(data);
       
      this.WebapiService.insertVendorContact(data).subscribe((res:any)=>{
      console.log(res);

      if(res.status == 1){
        alert(res.msg);
        this.router.navigate(['/step-three']);
      }
      else{
        alert(res.msg);
      }

      });
  }
  myAccount(e:any){
    //  console.log(e);
     if(e==2){
       this.router.navigate(['/vendor-account'])
       sessionStorage.removeItem('editVenId');

     }
     else if(e==3){
       this.router.navigate(['/my-account'])
       sessionStorage.removeItem('editVenId');

     }
     else if(e==4){
      this.router.navigate(['/admin'])
      sessionStorage.removeItem('editVenId');       
     }
     else{

     }
    }

}
