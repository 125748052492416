<style>
    .service-triburg .section-title ul>li{
        display: inline-block;
        font-size: 14px;
    }
    
</style>

<section class="service-triburg section" id="service-triburg">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 offset-md-3">
                <div class="section-content">
                    <div class="section-title">
                        <h1 class="d-md-inline-block">What We Do</h1>
                        <ul class="d-md-inline-block">
                            <li class="mx-md-3"><a href="#services-triburg">Services</a></li>
                            <li class="mx-md-3"><a href="#tooling-triburg">Tooling</a></li>
                            <li class="mx-md-3"><a href="#factory-evaluation-triburg">Factory Evaluation</a></li>
                            <li class="mx-md-3"><a href="#sourcing-triburg">Sourcing</a></li>
                            <li class="mx-md-3"><a href="#price-negotiation-triburg">Price Negotiation</a></li>
                            <li class="mx-md-3"><a href="#sampling-and-product-development-triburg">Sampling and Product Development</a></li>
                            <li class="mx-md-3"><a href="delivery-monitoring-triburg-triburg">Delivery Monitoring</a></li>
                            <li class="mx-md-3"><a href="inspection-triburg">Inspection</a></li>
                            <li class="mx-md-3"><a href="quality-control-triburg">Quality Control</a></li>
                            <li class="mx-md-3"><a href="logistics-triburg">Logistics</a></li>
                            <li class="mx-md-3"><a href="shipping-coordination-triburg">Shipping Coordination</a></li>
                            <li class="mx-md-3"><a href="consolidation-of-shipments-triburg">Consolidation Of Shipments</a></li>
                        </ul>
                    </div>
                    <div class="section-text col-12 pull-left" id="services-triburg">
                        <p>
                            From concept to design brief, the design process translates trends into products that meet the needs of today's demanding consumer.
                        </p>    
                        <p>Mila has a well-qualified team of designers who can design based on requirements. Our team of designers travel research continuously for design inspirations and novel ideas that breathe new designs to our customers specification. We add value to our customers’ inhouse design teams and enhance their product while keeping true to their brand identity.</p>
                    </div>
                    <div class="section-text col-12 pull-left" id="tooling-triburg">
                        <p>
                            The quality of a finished part, its properties, the speed and accuracy with which it can be produced and the repeatability of manufacture in high volume production runs, all depend on the precision and characteristics of the tooling. To achieve this, Mila works very closely with the team of designers and engineers to achieve tooling to its best precision.
                        </p>
                    </div>
                    <div class="section-text col-12 pull-left" id="factory-evaluation-triburg">
                        <p>
                            Before placing an order, Mila performs an initial factory evaluation. A factory evaluation includes an assessment of the working conditions, facilities, machinery, financial strength, management system, quality control system and production flow in the factory. Mila will also brief the vendor regarding the buyer's quality requirements. Mila's professional factory evaluation enables foreign buyers to be confident in quality control, production flow and delivery.
                        </p>    
                        <p><b>We find Environment friendly and modern factories having complete Infrastructure including modern technology, latest plant & machinery with all compliance and social certifications.</b></p>
                    </div>
                    <div class="section-text col-12 pull-left" id="sourcing-triburg">
                        <p>
                            Finding the right materials and factories that share our commitment to quality, compliance and sustainability is a fundamental activity.
                        </p>    
                        <p>It is our constant endeavors to source best quality products always at lowest possible prices. We meet and visit factories all through the year. Evaluation of each factory is done to assess their capabilities and strengths to meet all requirements on the way for quality products and  timely delivery.</p>
                    </div>
                    <div class="section-text col-12 pull-left" id="price-negotiation-triburg">
                        <p>
                            Before costing to buyer, we discuss with factory for all the cost related factory and do detailed costing for all components, process and minimal profits to help factory and buyer both. We quote buyer a product with the best lowest prices, can offer from India.
                        </p>
                    </div>
                    <div class="section-text col-12 pull-left" id="sampling-and-product-development-triburg">
                        <p>
                            Merchandisers, Quality Assurance Engineers, Quality Controllers work closely with factories to ensure products meet the stipulated requirements. Product development is done under strict control and supervision of our quality controllers, wherein they review each sample for aesthetics and performance.
                        </p>
                        <p>Our Technical Support Teams ensure that samples are created and developed safely according to customers’ product required specification. Merchandisers, designers, quality engineers and product development experts work with factories to ensure products meet the requirements.</p>
                    </div>
                    <div class="section-text col-12 pull-left" id="delivery-monitoring-triburg">
                        <p>
                            We follow up with factories on timely basis to see the various activities involved in production for timely deliveries. We help factories in finding solutions for problems occurred during sampling, tooling, product development, production and in packaging
                        </p>
                    </div>
                    <div class="section-text col-12 pull-left" id="inspection-triburg">
                        <p>
                            To ensure quality products, Mila performs inspection at various stages i.e. when raw material received, under sampling, under production, under packaging. We also perform inline inspection, mid-line inspection and final inspection at the time of dispatch.
                        </p>
                    </div>
                    <div class="section-text col-12 pull-left" id="quality-control-triburg">
                        <p>
                            With our experienced and skilled on-site Quality Control teams, we can provide our partners with the transparency and controls needed to measure the quality of each order.
                        </p>
                        <p>We assure Safe, quality assured products manufactured in accordance to industry minimum standards and best practices. We work with factory for Intensive pre-production planning to reduce rejection rates and increase efficiency.
                            Skilled teams to monitor, coordinate and control all processes. We work for closely planned and monitored manufacturing processes that assures quality output in a timely manner.</p>
                        <p><b>We follow Highly advanced Inspection and Quality Control Procedure based on client's specification.</b></p>
                    </div>
                    <div class="section-text col-12 pull-left" id="consolidation-of-shipments-triburg">
                        <p>
                            We assist our clients with documentation and consolidating of cargo. Container planning in a regular feature and is coordinated between merchandising and shipping teams. We work on a day-to-day basis with Consolidators and Shipping Lines for smooth flow of cargo. Also, Pre & Post shipping advises are sent to clients as may be their requirements.
                        </p>
                        <p>
                            We take care of the entire custom clearance, from documentation to stuffing and DE stuffing of goods at various locations like , Delhi / Moradabad / Jaipur / Jodhpur / Mumbai / Chennai / etc. Our entire procedure is listed below-
                        </p>
                        <ul>
                            <li>Custom Documentation</li>
                            <li>Carting / Receiving Goods</li>
                            <li>Examination Of Shipments.</li>
                            <li>Handling of stuffing & de-stuffing at Ports, ICD's and Customers warehouse</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

