import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-vendor-products',
  templateUrl: './vendor-products.component.html',
  styleUrls: ['./vendor-products.component.css']
})
export class VendorProductsComponent implements OnInit {
  venderId: any;
  prdData: any;
  prdName:any;
  industryName: any;
  materialName: any;
  arr: any=[];
  loginStatus: any;
  p:any=1;

  constructor(private router: Router,
    private WebapiService:WebapiService,private activated_Route: ActivatedRoute) { }

  ngOnInit(): void {
    this.venderId = this.activated_Route.snapshot.queryParams.id;
    this.getProductByVendorId(this.venderId);
    this.loginStatus = localStorage.getItem('loginStatus');

  }

  getProductByVendorId(id:any){
    let val = {
      'id':id
    }
    this.WebapiService.getProductByVendorId(val).subscribe((res:any)=>{
      console.log(res);
      if(res.length > 0){
     this.prdData = res;
      }
    });
  }

  onStatusCheck(e:any,id:any){
    // console.log(e.target.value,id);
    let con = confirm("Are you sure you want to update status");
    if(con ==  true){
      // console.log("hi");
      
      let value = {
        "admPrdtStatus":e.target.value,
        "id":id,
      }
      this.WebapiService.updateProductStatus(value).subscribe((res:any)=>{
        console.log(res);
        // if(res.status == 1){
        //   alert(res.msg);
        //   location.reload();
        // }     
        // else{
        //   alert(res.msg);
        // }
      });
    }
    else{
      location.reload();
    }
  }

}
