import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
@Component({
  selector: 'app-stepfour',
  templateUrl: './stepfour.component.html',
  styleUrls: ['./stepfour.component.css']
})
export class StepfourComponent implements OnInit {
  isIso: boolean=true;
  venderId:any
  userLogin:any;
  empId: any
  loginStatus:any
  qualityChck:any=[];
  qcData:any={};
  imageSrc:any;
  companyName: any;
  venId: any;
  loginName:any;

  constructor(private router: Router,
    private WebapiService:WebapiService) { }

  ngOnInit(): void {
    this.venderId = localStorage.getItem('venderId');
    if(this.venderId){
      this.venId = this.venderId;
    }
    else{
      this.venId = sessionStorage.getItem('editVenId');
    }
    this.userLogin = localStorage.getItem('loginId');
    this.empId = localStorage.getItem('loginId');
    this.loginStatus = localStorage.getItem('loginStatus');
    this.loginName = localStorage.getItem('name');

    if(this.venderId){
      this.getUserById(this.venderId);
      }
      else{
        this.getUserById(sessionStorage.getItem('editVenId'));
      }
  }
  getUserById(id:any){
    let val ={
      "id":id
    }
    this.WebapiService.getUserById(val).subscribe((res:any)=>{
      console.log(res);
      if(res.length > 0){
        this.companyName = res[0].company_name;
        this.qcData.anyerp = res[0].any_erp;
        this.qcData.anysoftware = res[0].any_software;
        this.qcData.inspectiontool = res[0].inspection_tool;
        this.qcData.detail = res[0].inspection_tool_detail;
        this.qcData.docrecord = res[0].keep_doc_record;
        this.qcData.qcCheck = res[0].stage_quality_check;
        this.qcData.inspectorindependent = res[0].inspector_independent_production;
        this.qcData.qaTotal = res[0].qa_qa_total;
        this.qcData.qaReport = res[0].qa_qc_total;
        this.qcData.functest = res[0].test_product_functionality;
        this.qcData.typeofinspection = res[0].type_of_inspection;
        this.qcData.staffEvaluation = res[0].any_staff_evaluation;
        this.qcData.evaluation_param =res[0].staff_eval_param;
        this.qcData.anyperiodictraining = res[0].any_perodic_training;
        this.qcData.verifyinvoice = res[0].verify_all_data_invoice;
        this.qcData.notifybeforedispatch = res[0].notify_buyer_before_dispatch;
        this.qcData.notifybeforesailing = res[0].notify_buyer_before_sailing;
        this.qcData.noofyear = res[0].ship_record_maintain_time;
        this.qcData.other_record_maintain = res[0].other_record_maintain;
        this.qcData.maintainrecord = res[0].record_of_customer_sample;
        this.qcData.howmaintain = res[0].how_sample_record;
        this.qcData.samplesentbuyer = res[0].physical_counter_sample;
        this.qcData.howlong = res[0].sample_record_maintain;
        this.qcData.custcomplain = res[0].complain_record_maintain;
        this.qcData.maintainrecordofcomplain = res[0].manage_customer_complain;
        this.qcData.honorclaim = res[0].honor_claim;
        this.qcData.ifyes = res[0].honor_claim_yes;
        this.qcData.anydocfordelivery = res[0].any_doc_delivery;
        this.qcData.toolfortaskmgmt = res[0].tool_for_internal_task_mgmnt;
        this.qcData.controlonincomingandoutgoing = res[0].sys_for_in_and_out_material;
        this.qcData.reportingshortages = res[0].report_shortages;
      }
    });
 
   }
  onCheckIso(e:any){
    if(e.target.value == 'no'){
      this.isIso = false;
    }
    else if(e.target.value = 'yes'){
    this.isIso = true;
   }
   else{
      this.isIso =true;
   }
 }
 onFileUpload(e:any,name:any){
   let file= {
       "name":name,
       "file":e.target.files[0],
       "venderId":this.venId
     }
     let temp = e.target.files[0];
    //  console.log(temp.type);
     
     if(temp.type == "application/pdf"){

      // const reader = new FileReader();
      // reader.onload = e => this.imageSrc = reader.result;

      // reader.readAsDataURL(temp);
      
        this.WebapiService.uploadFile(file).subscribe((res:any)=>{
         // console.log(res);
         if(res.status == 1){
           alert(res.msg);
         }
         else{
           alert(res.msg);
         }
     });
     }
     else{
       alert("please select PDF file");
       e.target.value = null;
     }
 }
 deleteImage(){
  this.imageSrc = null;
}
 logout(){
  localStorage.removeItem('loginId');
  localStorage.removeItem('loginStatus');
  sessionStorage.removeItem('editVenId');
  this.router.navigate(['/']);
}
 qcCheck(e:any){
  if (e.target.checked == true) {
    this.qualityChck.push(e.target.value);
  }else{
    var index = this.qualityChck.indexOf(e.target.value);
    this.qualityChck.splice(index, 1);
  }
  console.log(this.qualityChck);
  this.qcData.qcCheck = this.qualityChck;
}
qualityCertification(data:any){
  if(this.loginStatus == 2){
    data.venderId = this.empId;
  }
  else if(sessionStorage.getItem('editVenId')){
    data.venderId = sessionStorage.getItem('editVenId');
  }
  else{
    data.venderId = this.venderId;
  }
  // console.log(data);
  
  this.WebapiService.insertQualityData(data).subscribe((res:any)=>{
    console.log(res);
    if(res.status == 1){
      alert(res.msg);
      this.router.navigate(['/step-five']);
    }
    else{
      alert(res.msg);
    }
  });
}
myAccount(e:any){
  //  console.log(e);
   if(e==2){
     this.router.navigate(['/vendor-account'])
     sessionStorage.removeItem('editVenId');

   }
   else if(e==3){
     this.router.navigate(['/my-account'])
     sessionStorage.removeItem('editVenId');

   }
   else if(e==4){
    this.router.navigate(['/admin'])
    sessionStorage.removeItem('editVenId');
   }
   else{

   }
  }

}
