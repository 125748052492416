import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  final: any;
  url:any;
  route:any
  // counter: boolean = false;

  constructor(public router:Router,private activatedRoute: ActivatedRoute,location: Location){

    // console.log(window.location.href,'url testin');
    
    var splitted = window.location.href.split("/");
   this.final = splitted[3].split("?");
    // console.log(this.final,'test');
    this.url = this.final[0];
    // console.log(this.url);

  }

  ngOnInit():void {
    
  }
    
  }

