import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../../services/webapi.service'; 
import { ActivatedRoute,Router } from '@angular/router';
@Component({
  selector: 'app-teamdetails',
  templateUrl: './teamdetails.component.html',
  styleUrls: ['./teamdetails.component.css']
})
export class TeamdetailsComponent implements OnInit {

  teamList:any={};
  id:any;
  constructor(private router:ActivatedRoute,private WebapiService:WebapiService,
    private urouter:Router) { }

  ngOnInit(): void {
  //   this.router.queryParams.subscribe(params => {
  //     console.log(params); // { order: "popular" }

  //     this.id = params.id;
  //    // console.log(this.order); // popular
  //   }
  // );
  let pths= this.urouter.url.split('/');
    
    this.getTeam(pths[2]);
  }
  getTeam(id:any){
    //const input  = {id:id};
        this.WebapiService.getTeam(id).subscribe((res: any) => {
        console.log(res);

         this.teamList = res[0];
    
    });
  }

}
