<style>
    .filter{
        background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url(../../assets/img/aerial-view.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: auto;
    }
    .text-white{
        color: #fff;
    }
    .section-form{
        background: #fff;
    }
    .pull-left{
        float: left;
    }
    .single{
        background: #f9f9f9;
        margin-bottom: 20px;  
        padding: 15px 0;    
        box-shadow: 0px 2px 5px -2px #212121;   
        border-radius: 4px;
    }
    .single p{
        margin-bottom: 5px;
    }
    .single i{
        margin-right: 5px;    
        }
        .sign-in a {
    color: #fff !important;
}
.sign-in img {
    /* width: 30px; */
}
.white{
    color: #fff;
}
.sign-in ul>li{
       display: inline-block;
       vertical-align: -webkit-baseline-middle;
   }
   .sign-in ul>li a{
       color: var(--white);
       margin-left: .25rem;
   }
   .search-box img{
        transform: translate(-6%, -50%);
        position: absolute;
        top: 50%;
        right: 6%;
   }
   .separator{
    color: #fff;
    margin: 0 4px 0px 10px;
   }
   .signupbtn {
    border: 2px solid #0068e9;
    padding: 2px 10px;
    background: #0068e9;
    border-radius: 2px;
    transition: all 0.25s ease-in;
   }
   .signupbtn:hover{
    border: 2px solid transparent;
    background: #052c7d;
    transition: all 0.25s ease-in;
   }
   .signinbtn{
    padding: 2px 10px;
    border: 2px solid transparent;
    transition: all 0.25s ease-in;
   }
   .signinbtn:hover{
    border: 2px solid #0068e9;
    transition: all 0.25s ease-in;

    background: #0068e9;
    border-radius: 2px;
   }
   .filter-heading{
                                        float: left;
                                        border-bottom: 1px solid #212121 !important;
                                        margin-bottom: 10px;
                                        }
                                        .btn-reset {
                                    padding: 5px;
                                    font-size: 12px;
                                    float: right;
                                    color: #fff;
                                    background: #0575ff;
                                    border-radius: 20px;
                                }
                                .industryaccord{
                                    margin-bottom: 50px;
                                    padding: 0;
                                }
                                .filter-body .card{
                                    border: 0;
                                }
                                .filter-body .card .card-header {
                                    padding: 0;
                                    background: #fff;
                                }
                                .filter-body .card .card-header .btn-link{
                                    padding: 0 3px;
                                    width: 100%;
                                    text-align: left;
                                }
                                .filter-body .card .card-body{
                                    padding: 0;
                                }
                                .product-ul{
                                    padding: 0 12px;
                                }
                                .product-ul li{
                                    line-height: 1.4rem;
                                }
                                .product-ul li a{
                                    font-size: 14px;
                                    width: 100%;
                                    display: block;
                                }
                                .industry-btn{
                                    font-size: 16px;
                                    color: #212121;
                                    font-weight: 600;
                                }
                                .btn-category{
                                    font-size: 16px;
                                    color: #212121;
                                    font-weight: 500;
                                    padding: 0 5px !important;
                                }
                                .btn-subcategory{
                                    font-size: 16px;
                                    color: #212121;
                                    font-weight: 400;
                                    padding: 0 8px !important;
                                }
</style>

<section class="filter section" id="filter">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title mb-md-4 col-md-12  float-md-left">
                    <div class="col-md-6 float-md-left">
                        <h1 class="mb-md-0">Search Results</h1>
                        
                    </div>
                    <div *ngIf="empId" class="sign-in col-md-6 float-md-right text-right">
                        <ul class="text-md-right">
                            <li *ngIf="userLogin" >
                                <a (click)="myAccount(loginStatus)" class="text-white"><img src="assets/img/user.png" alt="" class="w-18 "> My Account</a>
                            </li>
                            <li  *ngIf="!userLogin">
                                <a [routerLink]="['/login']" class="signinbtn" routerLinkActive="active">Sign In</a>
                            </li>  
                            <li><span class="separator">|</span></li>
                            <li *ngIf="!userLogin" >
                                <a class="signupbtn" [routerLink]="['/signup']" routerLinkActive="active">Join Us</a>
                            </li>
                            <li *ngIf="userLogin">
                                <a (click)="logout()" href=""><img src="assets/img/logout.png" alt="" class="w-18">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div><!-- row -->
        <div class="row">
            <div class="col-md-12 ">
                <div class="section-content">
                    <!-- <div class="section-title mb-md-4">
                        <h1 class="mb-md-0">Search Results</h1>
                    </div> -->
                    <div class="section-form">
                        <form >
                            <!-- #VendorForm ngForm="VendorForm" -->
                            <div class="row p-md-3">
                                  
                                <div class="col-md-2 filter-body">
                                    <div class="col-md-12 p-0 filter-heading">
                                        <div class="col-md-6 float-left">
                                            <h5>Filter</h5>
                                        </div>
                                        <div class="col-md-6 float-left">
                                            <button class="btn btn-reset" (click)="resetFilter()" type="reset" >Reset Filters</button>
                                        </div>
                                    </div>
                                    <div class="accordion col-md-12 float-left industryaccord" id="industryaccorrd" *ngFor="let ind of industryList">
                                        <div class="card col-md-12 p-0">
                                          <div class="card-header" id="industry">
                                            <h2 class="mb-0">
                                              <button class="btn btn-link industry-btn" (click)="getCatByIndustry(ind.id);getProductByFilter('industry',ind.id,$event)" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                {{ind.industry_name}}
                                              </button>
                                            </h2>
                                          </div>
                                      
                                          <div id="collapseOne" class="collapse" aria-labelledby="industry" data-parent="#industryaccorrd">
                                            <div class="card-body">
                                                <div class="accordion" id="Category" *ngFor="let cat of categoryList">
                                                    <div class="card">
                                                      <div class="card-header" id="industry">
                                                        <h2 class="mb-0">
                                                          <button class="btn btn-link btn-category" (click)="getSubCatByCategory(cat.id);getProductByFilter('category',cat.id,$event)" type="button" data-toggle="collapse" data-target="#collapsecategory" aria-expanded="true" aria-controls="collapsecategory">
                                                            {{cat.category_name}}
                                                          </button>
                                                        </h2>
                                                      </div>
                                                  
                                                      <div id="collapsecategory" class="collapse" aria-labelledby="headingOne" data-parent="#Category">
                                                        <div class="card-body">
                                                            <div class="accordion" id="subCategory" *ngFor="let sub of subCategoryList">
                                                                <div class="card">
                                                                  <div class="card-header" id="headingOne">
                                                                    <h2 class="mb-0">
                                                                      <button (click)="getProdBySubCategory(sub.id);getProductByFilter('subCategory',sub.id,$event)" class="btn btn-link btn-subcategory" type="button" data-toggle="collapse" data-target="#collapsesubcategory" aria-expanded="true" aria-controls="collapsesubcategory">
                                                                       {{sub.subcategory_name}}
                                                                      </button>
                                                                    </h2>
                                                                  </div>
                                                              
                                                                  <div id="collapsesubcategory" class="collapse" aria-labelledby="headingOne" data-parent="#subCategory">
                                                                    <div class="card-body">
                                                                <ul class="product-ul" *ngFor="let pro of product">
                                                    <li><a href="javascript:void(0);" (click)="getProductByPrdtId(pro.id); getProductByFilter('product',pro.id,$event)">{{pro.product_name}}</a></li>
                                              
                                                                      </ul>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                 <!-- one subcategory card -->
                                                            </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                     <!-- one category card -->
                                                </div>
                                                <!-- accordian category end -->
                                            </div>
                                          </div>
                                        </div>
                                        <!-- main industry -->
                                        
                                        
                                      </div>
                                      
                                  
                                    <div class="">

                                        <div class="form-group p-0 col-md-12 float-left">
                                            <div class="col-md-8 float-left p-0">Premium Quality</div>
                                            <div class="col-md-4 float-left text-right p-0">
                                                <input type="checkbox" value="premium" (change)="changePremium($event); getProductByFilter('premium','yes',$event);">
                                            </div>
                                        </div>
                                        <div class="form-group col-md-12 p-0 float-left">
                                            <div class="col-md-8 float-left p-0">Exclusive Design</div>
                                            <div class="col-md-4 float-left text-right p-0">
                                                <input type="checkbox" value="premium" (change)="getProductByFilter('exclusive','yes',$event);">
                                            </div>
                                        </div> 
                                       
                                        
                                        
                                         <div class="form-group">
                                            <select #material class="form-control" (change)="getProductByFilter('material',material.value,$event)">
                                                <option value="">Material</option>
                                              <option *ngFor="let mat of materialList" value="{{mat.id}}">{{mat.material_name}}</option>
                                            </select>
                                        </div> 
                                        <div class="form-group">
                                            <select #style class="form-control" (change)="getProductByFilter('style',style.value,$event)">
                                                <option value="">Style</option>
                                              <option *ngFor="let sty of styleList" value="{{sty.id}}">{{sty.style_name}}</option>
                                            </select>
                                        </div> 
                                        <div class="form-group">
                                            <select #color class="form-control" (change)="getProductByFilter('color',color.value,$event)">
                                                <option value="">Color</option>
                                              <option *ngFor="let col of colorList" value="{{col.id}}">{{col.color_name}}</option>
                                            </select>
                                        </div> 
                                        <div class="form-group">
                                            <select #shape class="form-control" (change)="getProductByFilter('shape',shape.value,$event)">
                                                <option value="">Shape</option>
                                              <option *ngFor="let sha of shapeList" value="{{sha.id}}">{{sha.shape_name}}</option>
                                            </select>
                                        </div> 
                                        <div class="form-group">
                                            <select #finish class="form-control" (change)="getProductByFilter('finish',finish.value,$event)">
                                                <option value="">Finish</option>
                                              <option *ngFor="let fin of finishList" value="{{fin.id}}">{{fin.finish_name}}</option>
                                            </select>
                                        </div> 
                                        <div class="form-group">
                                            <select #coating class="form-control" (change)="getProductByFilter('coating',coating.value,$event)">
                                                <option value="">Coating</option>
                                              <option *ngFor="let coa of coatingList" value="{{coa.id}}">{{coa.coating_name}}</option>
                                            </select>
                                        </div>
                                        <div *ngIf="preCounter" class="col-md-12 p-0 filter-heading">  
                                                <h5>Retail Price</h5>  
                                        </div>
                                        <div *ngIf="preCounter">
                                        <div *ngFor="let ret of retailArr" class="form-group p-0 col-md-12 float-left">
                                            <div class="col-md-8 float-left p-0">$ {{ret}} Retail</div>
                                            <div class="col-md-4 float-left text-right p-0">
                                                <input #retail type="checkbox" value="{{ret}}" (change)="getProductByFilter('retailprice',retail.value,$event)">
                                            </div>
                                        </div>
                                    </div>
                                     
                                        <hr class="col-md-10 float-left">
                                        <div class="form-group p-0 col-md-12 float-left" *ngFor="let us of usageList">
                                            <div class="col-md-8 float-left p-0">{{us.usage_name}}</div>
                                            <div class="col-md-4 float-left text-right p-0">
                                                <input #usage type="checkbox" value="{{us.usage_name}}" (change)="getProductByFilter('prdusage',usage.value,$event)" value="{{us.id}}">
                                            </div>
                                        </div>
                                        <hr class="col-md-10 float-left">
                                        <div class="form-group p-0 col-md-12 float-left">
                                            <div class="col-md-8 float-left p-0">Best Sellers</div>
                                            <div class="col-md-4 float-left text-right p-0">
                                                <input type="checkbox" value="bestsell" (change)="getProductByFilter('bestsell','yes',$event)">
                                            </div>
                                        </div>
                                        
                                        <div class="form-group p-0 col-md-12 float-left">
                                            <div class="col-md-8 float-left p-0">Stock Sales</div>
                                            <div class="col-md-4 float-left text-right p-0">
                                                <input type="checkbox" value="stocksale" (change)="getProductByFilter('stocksale','yes',$event)">
                                            </div>
                                        </div>
                                        <hr class="col-md-10 float-left">
                                         
                                        <div class="form-group p-0 col-md-12 float-left">
                                            <div class="col-md-8 float-left p-0">Eco-Friendly</div>
                                            <div class="col-md-4 float-left text-right p-0">
                                                <input type="checkbox" value="ecoFriendly" (change)="getProductByFilter('ecoFriendly','yes',$event)">
                                            </div>
                                        </div>
                                        
                                        <div class="form-group p-0 col-md-12 float-left">
                                            <div class="col-md-8 float-left p-0">Food Grade Test</div>
                                            <div class="col-md-4 float-left text-right p-0">
                                                <input type="checkbox" value="food_grade_test" (change)="getProductByFilter('food_grade_test','yes',$event)">
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <style>
                                    .filtered{
                                        font-weight: 400;
                                    }
                                    .filtered span{
                                        margin: 0 4px;
                                        font-weight: 400;
                                    }
                                    .sorting{

                                    }
                                    .sorting .btn{
                                        padding: 0 8px;
                                        font-size: 14px;
                                    }
                                    .sorting .dropdown-meu{
                                        
                                    }
                                </style>
                            <div class="col-md-10">
                                <div class="col-md-12 p-0 filter-heading">
                                    <div class="col-md-11 p-0 float-left">
                                        
                                        <h6 class="filtered"> 
                                            Applied for: 
                                            <span *ngFor="let f of filterNameArr" class="badge badge-info">{{f}}</span>
                                        </h6>
                                    </div>
                                    <div class="col-md-1 float-left text-right">
                                        <div class="btn-group sorting">
                                            <button type="button" class="btn btn-outline-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                              Sort by
                                            </button>
                                            <div class="dropdown-menu">
                                              <a class="dropdown-item" href="#">Action</a>
                                              <a class="dropdown-item" href="#">Action</a>
                                              <a class="dropdown-item" href="#">Action</a>
                                              <a class="dropdown-item" href="#">Action</a>
                                            </div>
                                          </div>
                                    </div>
                                </div>
                                 <div class="col-md-12 single pull-left" *ngFor = "let prdt of productList">
                                    <div class="col-md-2 float-md-left">
                                        <img src="../../assets/img/cook-ware.jpg" alt="" class="img-fluid">
                                    </div>
                                    <div class="col-md-3 float-md-left">
                                        <table class="table">
                                            <tr><th>Vendor code</th><td></td></tr>
                                            <tr><th>Item #</th><td>{{prdt.product_details.item_name}}</td></tr>
                                            <tr><th>Product Name</th><td>{{prdt.product_name}}</td></tr>
                                            <tr><th>Product description</th><td>{{prdt.product_details.product_desc}}</td></tr>
                                            <tr><th>Dimensions</th><td>{{prdt.product_details.length_in_inch}} * {{prdt.product_details.width_in_inch}} * {{prdt.product_details.height_in_inch}}</td></tr>
                                            <tr><th>Material</th><td>
                                                <span *ngFor="let mat of prdt.material_detail">
                                                    <span>{{mat.material_name}}</span>,
                                                </span>
                                            </td></tr>
                                            <tr><th>Sample Lead Time</th><td>{{prdt.product_details.sample_lead_time}}</td></tr>
                                            <tr><th>Shipment Lead Time</th><td></td></tr>
                                            <tr><th>MOQ - {{prdt.product_details.moq}} pcs </th><td>Price - US $ {{prdt.product_details.moq_price1}} </td></tr>
                                            <tr><th>MOQ - {{prdt.product_details.moq2}} pcs</th><td>Price - US $ {{prdt.product_details.moq_price2}} </td></tr>
                                            <tr><th>MOQ - {{prdt.product_details.moq3}} pcs</th><td>Price - US $ {{prdt.product_details.moq_price3}}</td></tr>
                                            <tr><th>Uploaded Date</th><td>{{prdt.product_details.created_date}}</td></tr>
                                            <tr><th>Product Verified</th><td>
                                                <!-- if approved, however only approved product will show to the user, but when admin logs in he can filter out approved unapproved-->
                                                <i *ngIf="prdt.product_details.admPrdtStatus == 1" class="fa fa-check"></i>
                                            </td></tr>
                                        </table>
                                        <!-- <div class="form-group">
                                            <p>{{prdt.product_name}}</p>
                                            <p>{{prdt.item_name}}</p>
                                            <p>Vendor Code</p>
                                            <p>{{prdt.product_name}}</p>
                                            <p>{{prdt.category_name}}</p>
                                            <p>{{prdt.product_details.length_in_cm}}cm * {{prdt.product_details.width_in_cm}}cm * {{prdt.product_details.height_in_cm}}cm * {{prdt.product_details.diameter_in_cm}}cm</p>
                                            <p>{{prdt.product_details.length_in_inch}}inch * {{prdt.product_details.width_in_inch}}inch * {{prdt.product_details.height_in_inch}}inch * {{prdt.product_details.diameter_in_inch}}inch</p>
                                            <p>{{prdt.material_name}}</p>
                                            <p>{{prdt.product_details.sample_lead_time}}</p>
                                            <p>Shipment Lead Time</p>
                                            <p>{{prdt.product_details.design}}</p>
                                            <p>{{prdt.product_details.moq_price}}</p>
                                            <p>Min Invoice Value $</p>
                                        </div> -->
                                    </div>
                                    <div class="col-md-5 float-md-left">
                                        <div class="form-group">
                                            <label for=""><strong>Sort on</strong></label>
                                        </div>
                                        <div class="form-group col-md-7 float-md-left">
                                            <p>Vendor rating by MILA</p>
                                            <p>MOQ 1200 Price US$</p>
                                            <p>Quantity 10000 price US$</p>
                                            <p>Quantity 50000 price US$</p>
                                            <p></p>
                                            <p>Product verified</p>
                                            <p>Add To Wishlist</p>
                                            <p>Ask For Free Sample</p>
                                        </div>
                                        <div class="form-group col-md-5 float-md-left">
                                            <p>
                                                <i class="far fa-star"></i>
                                                <i class="far fa-star"></i>
                                                <i class="far fa-star"></i>
                                                <i class="far fa-star"></i>
                                                <i class="far fa-star"></i>
                                            </p>
                                            <p>$99.00</p>
                                            <p>$85.00</p>
                                            <p>$80.00</p>
                                            <p></p>
                                            <p>#</p>

                                            <p>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                            </p>
                                            <p>
                                            <select class="form-control">
                                                <option>Yes</option>
                                                <option>No</option>
                                              </select>
                                        </div>
                                    </div>
                                    <div class="col-md-2 float-md-left">
                                        <div class="form-group">
                                            <label for=""><strong>Verified supplier <i class="fas fa-check"></i></strong></label>
                                        </div>
                                        <div class="form-group">
                                            <label for="">Infrastructure</label>
                                            <label for="">
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                            </label>
                                            <label for="">
                                                Financial Health
                                            </label>
                                            <label for="">
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                            </label>
                                            <label for="">
                                                Social Responsibility
                                            </label>
                                            <label for="">
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div> 
                                <!-- single -->
                                <!-- <div class="col-md-12 single pull-left">
                                    <div class="col-md-2 float-md-left">
                                        <img src="../../assets/img/cook-ware.jpg" alt="" class="img-fluid">
                                    </div>
                                    <div class="col-md-3 float-md-left">
                                        <div class="form-group">
                                            <p>Product Name</p>
                                            <p>Item #</p>
                                            <p>Supplier Code</p>
                                            <p>Product Name</p>
                                            <p>Category</p>
                                            <p>Dimension</p>
                                            <p>Material</p>
                                            <p>Sample Lead Time</p>
                                            <p>Shipment Lead Time</p>
                                            <p>General / Exclusive</p>
                                            <p>MOQ</p>
                                            <p>Min Invoice Value $</p>
                                        </div>
                                    </div>
                                    <div class="col-md-5 float-md-left">
                                        <div class="form-group">
                                            <label for=""><strong>Sort on</strong></label>
                                        </div>
                                        <div class="form-group col-md-7 float-md-left">
                                            <p>Vendor rating by MILA</p>
                                            <p>MOQ 1200 Price US$</p>
                                            <p>Quantity 10000 price US$</p>
                                            <p>Quantity 50000 price US$</p>
                                            <p>Uploaded date</p>
                                            <p>Product verified</p>
                                            <p>Add To Wishlist</p>
                                            <p>Ask For Free Sample</p>
                                        </div>
                                        <div class="form-group col-md-5 float-md-left">
                                            <p>
                                                <i class="far fa-star"></i>
                                                <i class="far fa-star"></i>
                                                <i class="far fa-star"></i>
                                                <i class="far fa-star"></i>
                                                <i class="far fa-star"></i>
                                            </p>
                                            <p>$99.00</p>
                                            <p>$85.00</p>
                                            <p>$80.00</p>
                                            <p>16-May-11</p>
                                            <p>#</p>

                                            <p>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                            </p>
                                            <p>
                                            <select class="form-control">
                                                <option>Yes</option>
                                                <option>No</option>
                                              </select>
                                        </div>
                                    </div>
                                    <div class="col-md-2 float-md-left">
                                        <div class="form-group">
                                            <label for=""><strong>Verified supplier <i class="fas fa-check"></i></strong></label>
                                        </div>
                                        <div class="form-group">
                                            <label for="">Infrastructure</label>
                                            <label for="">
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                            </label>
                                            <label for="">
                                                Financial Health
                                            </label>
                                            <label for="">
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                            </label>
                                            <label for="">
                                                Social Responsibility
                                            </label>
                                            <label for="">
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                                <i class="far fa-heart"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>  -->
                                <!-- single -->
                            </div>
                                
                               
                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
