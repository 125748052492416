
 <style>
    .product-entry{
        background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url(../../assets/img/aerial-view.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 900px;
         padding-top: 150px;
    }
    .bg-white{
        background: #fff;
    }

    .section-form{
        background: #fff;
    }
    .pull-left{
        float: left;
    }
    .card img{
        width: 50px;
    }
    .card{
        display: flex;
        align-items: center;
        padding: 40px;
        color: #212121;
    }
    .card:hover {
    background: #161616;
    color: #fff;
}
</style>

<section class="product-entry section" id="productEntry">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 offset-md-3">
                <div class="section-content">
                    <div class="section-title mb-md-4">
                        <h1 class="mb-md-0">{{vendor_name}}</h1>
                    </div>
                    
                    <div class="col-md-12  bg-white">
                        <table class="table table-bordered">
                            <tr>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Address</th>
                                <th>Company</th>
                            </tr>
                            <tr *ngFor="let data of vendorData">
                                <td>{{data.email}}</td>
                                <td>{{data.mobile}}</td>
                                <td>{{data.office_address}}</td>
                                <td>{{data.company_name}}</td>
                            </tr>
                        </table>
                    </div> 
                <!-- col-md-12 -->
                    </div> 
                    <!-- content -->
                    </div> 
                    <!-- col-md-12 -->
                    </div>
                    </div>
</section>