import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendor-contact',
  templateUrl: './vendor-contact.component.html',
  styleUrls: ['./vendor-contact.component.css']
})
export class VendorContactComponent implements OnInit {
  vencon:any={};
  constructor() { }

  ngOnInit(): void {
  }

  vendorContact(){
    console.log(this.vencon);
  }
}
