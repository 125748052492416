<style>
      .vendor-details{
        min-height: 800px;
    }
    input[type="number"] {
       -moz-appearance: textfield;
       appearance: textfield;
       margin: 0;
   }
   input[type="number"]::-webkit-inner-spin-button,
   input[type="number"]::-webkit-outer-spin-button {
       -webkit-appearance: none;
       margin: 0;
   }
   .sign-in a {
  color: #fff !important;
}
.sign-in img {
  width: 30px;
}
.sign-in ul>li{
     display: inline-block;
     vertical-align: -webkit-baseline-middle;
 }
 .sign-in ul>li a{
     color: var(--white);
     margin-left: .25rem;
 }
 .search-box img{
      transform: translate(-6%, -50%);
      position: absolute;
      top: 50%;
      right: 6%;
 }
 .vendor-details {
      background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url(../../assets/img/aerial-view.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
  }
  
  a {
      color: #fff;
  }
  
  .dropdown-item {
      color: #212529 !important
  }
  
  .r-25 {
      right: 25px;
  }
  .you-deal-in{
      flex-wrap: wrap;
  }
  .you-deal-in>div{
      flex: 0 1 380px;
  }
  .col-md-3, .col-md-2, .col-md-4, .col-md-12{
      float: left;
  }
</style>
<section class="vendor-details section" id="vendorDetails">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title mb-md-4 col-md-12  float-md-left">
                    <div class="col-md-6 float-md-left">
                        <h1 class="mb-md-0">Infrastructure</h1>
                        <h2 style="color: #fff;font-size: 20px;margin-top: 10px;">{{companyName}}</h2>
                    </div>
                    <div *ngIf="empId" class="sign-in col-md-6 float-md-left text-right">
                        <ul class="text-md-center">
                            <li *ngIf="userLogin" >
                                <a (click)="myAccount(loginStatus)"><img src="assets/img/user.png" alt="" class="w-18">{{loginName}}</a>
                            </li>
                            <li  *ngIf="!userLogin">
                                <a [routerLink]="['/login']" class="signinbtn" routerLinkActive="active">Sign In</a>
                            </li>  
                            <li><span class="separator">|</span></li>
                            <li *ngIf="!userLogin" >
                                <a class="signupbtn" [routerLink]="['/signup']" routerLinkActive="active">Join Us</a>
                            </li>
                            <li *ngIf="userLogin">
                                <a (click)="logout()" href=""><img src="assets/img/logout.png" alt="" class="w-18">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div><!-- row -->
        <div class="row">
            <div class="col-12">
                <div class="step-menu-wrapper">
                    <ul class="d-flex">
                        <li class="flex-fill"><a [routerLink]="['/step-one']" routerLinkActive="active">Basic Information</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-two']" routerLinkActive="active">Company Profile</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-three']" routerLinkActive="active">Commercial</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-four']" routerLinkActive="active">Quality Management & Certifications</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-five']" routerLinkActive="active">Staffing</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-six']" routerLinkActive="active">Legal & Financial</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-seven']" routerLinkActive="active">Social Compliance</a></li>
                        <li class="flex-fill"><a class="active">Infrastructure</a></li>
                    </ul>
                </div>
            </div>
        </div>
<div class="" id="infra-str" role="tabpanel" aria-labelledby="infra-tab">
    <div class="section-content bg-white p-md-3 h-auto clearfix">
        <h4 class="mb-md-3">
           Material
        </h4>
                        <div class="form-group">
                            <div class="form-check-inline" *ngFor = "let mat of material; let i =index">
                                <label for="{{mat.id}}" class="form-check-label">
                                   
                                    <input type="checkbox" id="{{mat.id}}" (change)="onSelectMaterial($event)" value="{{mat.material_name}}" class="form-check-input r-25"> 
                                    {{mat.material_name}}
                                </label>
                            </div>
                            </div>
        <div class="section-title mb-md-4">
            <!-- <h1 class="mb-md-0">Vendor Registration</h1> -->
        </div>
        <div class="section-form">
            <form #VendorForm ngForm="VendorForm" class="row" (ngSubmit)="insertInfrastructure(infrastructureData)">
                <div class="row" *ngIf="woodFlag" style="padding: 0 15px;">
                <div class="col-md-12">
                    <h4 class="mb-md-3">
                        Factory Information - Wooden Furniture/Artware (Life Like Art)
                    </h4>
                </div>

                <div class="col-md-2">
                    <label for="">Type of Factory</label>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <!-- <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Rented" name="woodfacradio" [(ngModel)]="infrastructureData.wood_factory_type">
                                Rented
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Owned" name="woodfacradio" [(ngModel)]="infrastructureData.wood_factory_type">
                                Owned
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Outsourced" name="woodfacradio" [(ngModel)]="infrastructureData.wood_factory_type">
                                Outsourced
                            </label>
                        </div> -->
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Inhouse" name="woodfacradio" [(ngModel)]="infrastructureData.wood_factory_type">
                                Inhouse Production
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Outsourced" name="woodfacradio" [(ngModel)]="infrastructureData.wood_factory_type">
                                Outsourced
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <label for="">Wood chemical treatment</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="woodradio" [(ngModel)]="infrastructureData.wood_treatment">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="woodradio" [(ngModel)]="infrastructureData.wood_treatment">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Seasoning Chamber</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="seasonradio" [(ngModel)]="infrastructureData.wood_seasoning_chamber">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="seasonradio" [(ngModel)]="infrastructureData.wood_seasoning_chamber">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Dry chamber</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="dryradio" [(ngModel)]="infrastructureData.wood_dry_chamber">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="dryradio" [(ngModel)]="infrastructureData.wood_dry_chamber">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Rip saw</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="ripradio" [(ngModel)]="infrastructureData.wood_rip_saw">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="ripradio" [(ngModel)]="infrastructureData.wood_rip_saw">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Double Surface Plainer</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="doubleradio" [(ngModel)]="infrastructureData.wood_double_face_plainer">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="doubleradio" [(ngModel)]="infrastructureData.wood_double_face_plainer">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Tenoning machine</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="tenoningradio" [(ngModel)]="infrastructureData.wood_tenoning_machine">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="tenoningradio" [(ngModel)]="infrastructureData.wood_tenoning_machine">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Mortising machine</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="mortiser" [(ngModel)]="infrastructureData.wood_mortiser_machine">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="mortiser" [(ngModel)]="infrastructureData.wood_mortiser_machine">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Copy turning lathe</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="latheradio" [(ngModel)]="infrastructureData.wood_copy_turning_lathe">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="latheradio" [(ngModel)]="infrastructureData.wood_copy_turning_lathe">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Pneumatic clamp carrier</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="carrierradio" [(ngModel)]="infrastructureData.wood_pneumatic_clamp_carrier">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="carrierradio" [(ngModel)]="infrastructureData.wood_pneumatic_clamp_carrier">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Wide belt sander</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="beltradio" [(ngModel)]="infrastructureData.wood_wide_belt_sander">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="beltradio" [(ngModel)]="infrastructureData.wood_wide_belt_sander">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Sliding panel saw</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="sawradio" [(ngModel)]="infrastructureData.wood_sliding_panel_saw">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="sawradio" [(ngModel)]="infrastructureData.wood_sliding_panel_saw">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Auto copy shaper</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="shaperradio" [(ngModel)]="infrastructureData.wood_auto_copy_shaper">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="shaperradio" [(ngModel)]="infrastructureData.wood_auto_copy_shaper">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Modern spinder moulder</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="molderradio" [(ngModel)]="infrastructureData.wood_modern_spindle_molder">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="molderradio" [(ngModel)]="infrastructureData.wood_modern_spindle_molder">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Dovetail machine</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="doveradio" [(ngModel)]="infrastructureData.wood_dovetail_machine">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="doveradio" [(ngModel)]="infrastructureData.wood_dovetail_machine">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Oscillating belt sander</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="oscillateradio" [(ngModel)]="infrastructureData.wood_oscillationg_belt_sander">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="oscillateradio" [(ngModel)]="infrastructureData.woood_oscillationg_belt_sander">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Horizontal & vertical sponge sanders</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="hvradio" [(ngModel)]="infrastructureData.wood_hv_sponge_sander">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="hvradio" [(ngModel)]="infrastructureData.wood_hv_sponge_sander">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Please indicate other equipment present in above factory premises</label>
                        <input type="text" class="form-control" name="other_equipment" [(ngModel)]="infrastructureData.wood_other_equipment">
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Inhouse carving/inlay work</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="carvingradio" [(ngModel)]="infrastructureData.wood_inhouse_carving" >
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="carvingradio" [(ngModel)]="infrastructureData.wood_inhouse_carving">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Inhouse caning</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="caningradio" [(ngModel)]="infrastructureData.wood_inhouse_caning">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="caningradio" [(ngModel)]="infrastructureData.wood_inhouse_caning">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Inhouse spray painting dustproof chamber</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="sprayradio" [(ngModel)]="infrastructureData.wood_inhouse_spray">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="sprayradio" [(ngModel)]="infrastructureData.wood_inhouse_spray">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Inhouse upholstery</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="upholsteryradio" [(ngModel)]="infrastructureData.wood_inhouse_upholstery">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="upholsteryradio" [(ngModel)]="infrastructureData.wood_inhouse_upholstery">
                                No
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-12"></div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Which type of wood do you use</label><br>
                        <div class="form-check-inline" *ngFor="let wood of woodList; let i=index">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="{{wood}}" name="wood_type" [(ngModel)]="infrastructureData.wood_type">
                               {{wood}}
                            </label>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-6">
                    <div class="form-group">
                        <div class="form-check-inline" *ngFor="let wood of woodList; let i=index">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="{{wood}}" name="wood_type" [(ngModel)]="infrastructureData.wood_type">
                               {{wood}}
                            </label>
                        </div> -->
                        <!-- <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                Sheesham
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                Accacia
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                Teak
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                Oak
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                Cherry
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                Mahagony
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                Walnut
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                MDF
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                HDF
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                PLY
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                Other
                            </label>
                        </div> -->
                    <!-- </div>
                </div> -->

                <div class="col-md-5">
                    <div class="form-group">
                        <label for="">If others, please specify</label>
                    <input type="text" class="form-control" name="other_wood" [(ngModel)]="infrastructureData.other_wood">
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">Do you import Cork?</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" [(ngModel)]="infrastructureData.import_cork" name="corkradio">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" [(ngModel)]="infrastructureData.import_cork" name="corkradio">
                                No
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12"  *ngIf="hardFlag">
                <div class="col-md-12">
                    <h4 class="mt-md-3">
                        Factory Information - Hard Goods
                    </h4>
                </div>

                <div class="col-md-2">
                    <label for="">Type of Factory</label>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input"  value="Rented" name="hardgoodfacradio" [(ngModel)]="infrastructureData.hg_factory_type">
                                Rented
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Owned" name="hardgoodfacradio" [(ngModel)]="infrastructureData.hg_factory_type">
                                Owned
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Outsourced" name="hardgoodfacradio" [(ngModel)]="infrastructureData.hg_factory_type">
                                Outsourced
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-12"></div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">
                            No. of polishing machines
                        </label>
                        <select class="form-control" id="" name="hard_good_no_of_polishing_machine" [(ngModel)]="infrastructureData.hg_no_of_polishing_machine">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                        </select>
                    </div>
                </div>

                <div class="col-12"></div>

                <div class="col-md-3">
                    <label for="">Ultrasonic cleaing machine</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Yes" name="hardgoodultraradio" [(ngModel)]="infrastructureData.hg_ultrasonic_cleaning_machine" >
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="hardgoodultraradio" [(ngModel)]="infrastructureData.hg_ultrasonic_cleaning_machine">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Regular chemical cleaning</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="hardgoodchemicalradio" [(ngModel)]="infrastructureData.hg_regular_chemical_cleaning">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="hardgoodchemicalradio" [(ngModel)]="infrastructureData.hg_regular_chemical_cleaning">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Copper plating tank</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="copperradio" [(ngModel)]="infrastructureData.hg_copper_plating_tank">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="copperradio" [(ngModel)]="infrastructureData.hg_copper_plating_tank">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Nickel plating tank</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="nickelradio" [(ngModel)]="infrastructureData.hg_nickel_plating_tank">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="nickelradio" [(ngModel)]="infrastructureData.hg_nickel_plating_tank">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Chrome plating tank</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="chromeradio" [(ngModel)]="infrastructureData.hg_chrome_plating_tank">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="chromeradio" [(ngModel)]="infrastructureData.hg_chrome_plating_tank">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Strike silver tank</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="strikeradio" [(ngModel)]="infrastructureData.hg_strike_silver_tank">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="strikeradio" [(ngModel)]="infrastructureData.hg_strike_silver_tank">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Micron silver tank</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="micronradio" [(ngModel)]="infrastructureData.hg_micron_silver_tank">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="micronradio" [(ngModel)]="infrastructureData.hg_micron_silver_tank">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Anti cyanide/anti-tarnish tank</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="cyanideradio" [(ngModel)]="infrastructureData.hg_anti_cyanide_tank">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="cyanideradio" [(ngModel)]="infrastructureData.hg_anti_cyanide_tank">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">
                            Oven with blower - nos.
                        </label>
                        <select class="form-control" id="" name="hard_good_nos_oven_with_blower" [(ngModel)]="infrastructureData.hg_nos_oven_with_blower">
                            <option value="1">1</option>
                        </select>
                    </div>
                </div>

                <div class="col-md-12"></div>

                <div class="col-md-3">
                    <label for="">Lacquering plant</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="hardgoodlacqueringradioplant" [(ngModel)]="infrastructureData.hg_lacquering_plant">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="hardgoodlacqueringradioplant" [(ngModel)]="infrastructureData.hg_lacquering_plant">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Powder coating plant</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="powderradio" [(ngModel)]="infrastructureData.hg_powder_coating_plant">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="powderradio" [(ngModel)]="infrastructureData.hg_powder_coating_plant">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">P U Coating plant</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="puradio" [(ngModel)]="infrastructureData.hg_p_u_coating_plant">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="puradio" [(ngModel)]="infrastructureData.hg_p_u_coating_plant">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Do you have inhouse casting facility?</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="castingradio" [(ngModel)]="infrastructureData.hg_inhouse_casting">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="castingradio" [(ngModel)]="infrastructureData.hg_inhouse_casting">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Do you have inhouse metal pressing unit?</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="metalradio" [(ngModel)]="infrastructureData.hg_inhouse_metal_pressing">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="metalradio" [(ngModel)]="infrastructureData.hg_inhouse_metal_pressing">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Do you have inhouse coloring unit?</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="colorradio" [(ngModel)]="infrastructureData.hg_inhouse_color">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="colorradio" [(ngModel)]="infrastructureData.hg_inhouse_color">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Do you do antique colour finish?</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="antiqueradio" [(ngModel)]="infrastructureData.hg_antique_color">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="antiqueradio" [(ngModel)]="infrastructureData.hg_antique_color">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Do you have working electrophoretic plant?</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="electrophorecticradio" [(ngModel)]="infrastructureData.hg_electrophoretic_plant">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="electrophorecticradio" [(ngModel)]="infrastructureData.hg_electrophoretic_plant">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Sand casting</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Inhouse"  name="sandradio" [(ngModel)]="infrastructureData.hg_sand_casting">
                                Inhouse
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Outsourced" name="sandradio" [(ngModel)]="infrastructureData.hg_sand_casting">
                                Outsourced
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Pressure die casting</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Inhouse" name="pressuredieradio" [(ngModel)]="infrastructureData.hg_pressure_die_casting">
                                Inhouse
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Outsourced" name="pressuredieradio" [(ngModel)]="infrastructureData.hg_pressure_die_casting">
                                Outsourced
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Low wax casting</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Inhouse" name="lowwaxradio" [(ngModel)]="infrastructureData.hg_low_wax_casting">
                                Inhouse
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Outsourced" name="lowwaxradio" [(ngModel)]="infrastructureData.hg_low_wax_casting">
                                Outsourced
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Forging</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Inhouse" name="forgingradio" [(ngModel)]="infrastructureData.hg_forging">
                                Inhouse
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Outsourced" name="forgingradio" [(ngModel)]="infrastructureData.hg_forging">
                                Outsourced
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12" *ngIf="glassFlag">
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="mt-md-3">Factory Information - Glass</h4>
                    </div>

                    <div class="col-md-2">
                        <label for="">Type of Factory</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <!-- <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" value="Rented" name="glassfacradio" [(ngModel)]="infrastructureData.glass_factory_type">
                                    Rented
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" value="Owned" name="glassfacradio" [(ngModel)]="infrastructureData.glass_factory_type">
                                    Owned
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" value="Outsourced" name="glassfacradio" [(ngModel)]="infrastructureData.glass_factory_type">
                                    Outsourced
                                </label>
                            </div> -->
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" value="Inhouse" name="glassfacradio" [(ngModel)]="infrastructureData.glass_factory_type">
                                    Inhouse Production
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" value="Outsourced" name="glassfacradio" [(ngModel)]="infrastructureData.glass_factory_type">
                                    Outsourced
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">
                                <strong>Furnance Capacity</strong>
                            </label>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="">No. of pots (ton/day)</label>
                            <!-- <select id="" class="form-control" name="no_of_pots" [(ngModel)]="infrastructureData.glass_no_of_pots">
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select> -->
                            <input type="number" class="form-control" name="no_of_pots" [(ngModel)]="infrastructureData.glass_no_of_pots">
                        </div>
                    </div>

                    <div class="col-md-12"></div>

                    <div class="col-md-2">
                        <label for="">Type of Unit</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="checkbox" value="Coal" class="form-check-input" (change)="typeUnit($event)">
                                    Coal
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="checkbox" class="form-check-input" value="Diesel" (change)="typeUnit($event)">
                                    Diesel
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="checkbox" class="form-check-input"  value="Gas" (change)="typeUnit($event)">
                                    Gas
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="checkbox" class="form-check-input"  value="Electric" (change)="typeUnit($event)">
                                    Electric
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Type of glass process</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="checkbox" class="form-check-input" value="BLowing" (change)="glassProcess($event)">
                                    Blowing
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="checkbox" class="form-check-input" value="Pressed" (change)="glassProcess($event)">
                                    Pressed
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label for="">Type of glass manufactured</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="checkbox" class="form-check-input" value="Tank" (change)="glassManufacturer($event)">
                                    Tank
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="checkbox" class="form-check-input" value="Pot"  (change)="glassManufacturer($event)">
                                    Pot
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="checkbox" class="form-check-input" value="Crystal" (change)="glassManufacturer($event)">
                                    Crystal
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">
                                Color of glass specify
                            </label>
                            <select class="form-control" id="" name="glass_color" [(ngModel)]="infrastructureData.glass_color">
                                <option value="Blue">Blue</option>
                                <option value="Blue">Blue</option>
                                <option value="Blue">Blue</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label for="">Color Type</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="checkbox" class="form-check-input" value="printed" (change)="glassColorType($event)">
                                    Printed
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="checkbox" class="form-check-input" value="Pot" (change)="glassColorType($event)">
                                    Pot
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">
                                No. of glass grinding machine
                            </label>
                            <select name="" id="" class="form-control" name="no_of_glass_griding_machine" [(ngModel)]="infrastructureData.no_of_glass_griding_machine">
                                <option value="1">1</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">
                                No. of glass cutting machine
                            </label>
                            <select name="" id="" class="form-control" name="no_of_glass_cutting_machine" [(ngModel)]="infrastructureData.no_of_glass_cutting_machine">
                                <option value="1">1</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Frosting</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="checkbox" class="form-check-input" value="Chemical" name="check-ten" (change)="frostingCheck($event)">
                                    Chemical
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="checkbox" class="form-check-input" value="Sand" name="check-ten" (change)="frostingCheck($event)">
                                    Sand
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label for="">Crackle</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="crckl" value="yes" [(ngModel)]="infrastructureData.glass_crackle">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="crckl" value="no" [(ngModel)]="infrastructureData.glass_crackle">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Deals in Coloured Glass</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="colglssradio" value="yes" [(ngModel)]="infrastructureData.col_glass">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="colglssradio" value="no" [(ngModel)]="infrastructureData.col_glass">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label for="">Mosaic</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="msac" value="yes" [(ngModel)]="infrastructureData.glass_mosaic">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="msac" value="no" [(ngModel)]="infrastructureData.glass_mosaic">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">No. of ovens</label>
                            <select name="" id="" class="form-control" name="no_of_ovens" [(ngModel)]="infrastructureData.glass_no_of_ovens">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Ovens Capacity</label>
                            <select name="" id="" class="form-control" name="oven_capacity" [(ngModel)]="infrastructureData.glass_oven_capacity">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Type of other machines</label>
                            <input type="text" class="form-control" placeholder="" name="other_machine" [(ngModel)]="infrastructureData.glass_other_machine">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12" *ngIf="MelamineFlag || SiliconFlag || PlasticFlag || RubberFlag">
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="mt-md-3">
                            Factory Information
                        </h4>
                        <p>(Melamine / Plastic / Silicon / Rubber)</p>
                    </div>
    
                    <div class="col-md-2">
                        <label for="">Type of Factory</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="melafacradio" value="Rented" [(ngModel)]="infrastructureData.mpsr_factory_type">
                                    Inhouse Production
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="melafacradio" value="Owned" [(ngModel)]="infrastructureData.mpsr_factory_type">
                                    Outsourced
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label for="">Type of machine</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="typemmach" value="automatic" [(ngModel)]="infrastructureData.mpsr_type_machine">
                                    Automatic
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="typemmach" value="semiauto" [(ngModel)]="infrastructureData.mpsr_type_machine">
                                    Semi Automatic
                                </label>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="">No. of injection moulding machine</label>
                            <select name="" id="" class="form-control" name="mould_mach" [(ngModel)]="infrastructureData.mpsr_injec_mould_mach">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </div>
                    </div>
    
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="">No. of cladding machine</label>
                            <select name="" id="" class="form-control" name="cladd_mach" [(ngModel)]="infrastructureData.mpsr_cladd_mach">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </div>
                    </div>
    
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="">No. of silicon/rubber moulding machine</label>
                            <select name="" id="" class="form-control" name="silicon_mach" [(ngModel)]="infrastructureData.mpsr_silicon_mach">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </div>
                    </div>
    
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="">No. of melamine moulding machine</label>
                            <select name="" id="" class="form-control" name="mela_mould_mach" [(ngModel)]="infrastructureData.mpsr_mela_mould_mach">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12"  *ngIf="TeracottaFlag || BonechinaFlag || ProcelainFlag || CeramicFlag">
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="mt-md-3">
                            Factory Information
                        </h4>
                        <p>(Ceramic/Procelain/Bone china / Teracotta)</p>
                    </div>
    
                    <div class="col-md-2">
                        <label for="">Type of Factory</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="cerafacradio" value="Inhouse" [(ngModel)]="infrastructureData.cpbt_factory_type">
                                    Inhouse Production
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="cerafacradio" value="Outsourced" [(ngModel)]="infrastructureData.cpbt_factory_type">
                                    Outsourced
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="">Inhouse furnace</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="infurradio" value="yes" [(ngModel)]="infrastructureData.cpbt_inhouse_fur">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="infurradio" value="no" [(ngModel)]="infrastructureData.cpbt_inhouse_fur">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">If yes, Furnace Capacity</label>
                            <input type="text" name="fur_cap" class="form-control" [(ngModel)]="infrastructureData.cpbt_fur_cap">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="">Die made</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="dieradio" value="yes" [(ngModel)]="infrastructureData.cpbt_die_made">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="dieradio" value="no" [(ngModel)]="infrastructureData.cpbt_die_made">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Coloured teracotta</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="col_tera" value="yes" [(ngModel)]="infrastructureData.cpbt_col_teracotta">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="col_tera" value="no" [(ngModel)]="infrastructureData.cpbt_col_teracotta">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Type of clay used</label>
                            <input type="text" class="form-control" name="clay_type" [(ngModel)]="infrastructureData.cpbt_clay_type">
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Printed teracotta</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="col_tera" value="yes" [(ngModel)]="infrastructureData.cpbt_printed_tera">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="col_tera" value="no" [(ngModel)]="infrastructureData.cpbt_printed_tera">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Can you do multicolors?</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="multi_col_radio" value="yes" [(ngModel)]="infrastructureData.cpbt_multi_col">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="multi_col_radio" value="no" [(ngModel)]="infrastructureData.cpbt_multi_col">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Can you do patterns?</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="pattern_radio" value="yes" [(ngModel)]="infrastructureData.cpbt_pattern">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="pattern_radio" value="no" [(ngModel)]="infrastructureData.cpbt_pattern">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">
                                Please indicate other equipment present in above factory premises.
                            </label>
                            <input type="text" class="form-control" name="other_equip" [(ngModel)]="infrastructureData.cpbt_other_equip">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12" *ngIf="PaperFlag">
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="mt-md-3">
                            Factory Information
                        </h4>
                        <p>(Paper)</p>
                    </div>
    
                    <div class="col-md-2">
                        <label for="">Type of Factory</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="paperradio" value="Inhouse" [(ngModel)]="infrastructureData.paper_factory_type">
                                    Inhouse Production
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="paperradio" value="Outsourced" [(ngModel)]="infrastructureData.paper_factory_type">
                                    Outsourced
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label for="">Do you have cutting machine?</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="paperradio" value="yes" [(ngModel)]="infrastructureData.paper_cut_mach">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="paperradio" value="no" [(ngModel)]="infrastructureData.paper_cut_mach">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label for="">Do you have pulp processing machine?</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="pulpradio" value="yes" [(ngModel)]="infrastructureData.paper_pulp_mach">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="pulpradio" value="no" [(ngModel)]="infrastructureData.paper_pulp_mach">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label for="">Do you deal in handmade paper?</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="handmradio" value="yes" [(ngModel)]="infrastructureData.paper_hand_made">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="handmradio" value="no" [(ngModel)]="infrastructureData.paper_hand_made">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label for="">Do you deal in die cutting?</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="die_cut_radio" value="yes" [(ngModel)]="infrastructureData.paper_die_cutting">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="die_cut_radio" value="no" [(ngModel)]="infrastructureData.paper_die_cutting">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label for="">Do you have eyelet punching machine?</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="eyeletradio" value="yes" [(ngModel)]="infrastructureData.paper_eyelet_punch">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="eyeletradio" value="no" [(ngModel)]="infrastructureData.paper_eyelet_punch">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label for="">Do you have paper calendering machine?</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="paper_cal_radio" value="yes" [(ngModel)]="infrastructureData.paper_cal_mach">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="paper_cal_radio" value="no" [(ngModel)]="infrastructureData.paper_cal_mach">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label for="">Do you have paper drying facility?</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="paper_dry_radio" value="yes" [(ngModel)]="infrastructureData.paper_dry_fac">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="paper_dry_radio" value="no" [(ngModel)]="infrastructureData.paper_dry_fac">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12" *ngIf="BoneFlag || HornFlag || MOPFlag">
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="mt-md-3">
                            Factory Information
                        </h4>
                        <p>(Bone/Horn/MOP)</p>
                    </div>

                    <div class="col-md-2">
                        <label for="">Type of Factory</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="boneradio" value="Inhouse" [(ngModel)]="infrastructureData.bhm_factory_type">
                                    Inhouse Production
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="boneradio" value="Outsourced" [(ngModel)]="infrastructureData.bhm_factory_type">
                                    Outsourced
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">No. of cutting machine?</label>
                            <input type="text" class="form-control" name="cut_mach" [(ngModel)]="infrastructureData.bhm_cutting_mach">
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">No. of polishing machine?</label>
                            <input type="text" class="form-control" name="polis_mach" [(ngModel)]="infrastructureData.bhm_polish_mach">
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">No. of grinding machine?</label>
                            <input type="text" class="form-control" name="grind_mch" [(ngModel)]="infrastructureData.bhm_griding_mach">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12" *ngIf="steelFlag || brassFlag || epnsFlag || almFlag || copperFlag">
                <div class="row">
                <div class="col-md-12">
                    <h4 class="mt-md-3">
                        Factory Information - Metals
                    </h4>
                    <p>(Aluminium, stainless steel, Brass, Bronze, Zinc, Copper, EPNS, Iron)</p>
                </div>

                <div class="col-md-2">
                    <label for="">Type of Factory</label>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <!-- <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="steelfacradio" value="Rented" [(ngModel)]="infrastructureData.steel_factory_type">
                                Rented
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="steelfacradio" value="Owned" [(ngModel)]="infrastructureData.steel_factory_type">
                                Owned
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="steelfacradio" value="Outsourced" [(ngModel)]="infrastructureData.steel_factory_type">
                                Outsourced
                            </label>
                        </div> -->
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="steelfacradio" value="Rented" [(ngModel)]="infrastructureData.steel_factory_type">
                                Inhouse Production
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="steelfacradio" value="Owned" [(ngModel)]="infrastructureData.steel_factory_type">
                                Outsourced
                            </label>
                        </div>
                        <!-- <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="steelfacradio" value="Outsourced" [(ngModel)]="infrastructureData.steel_factory_type">
                                Outsourced
                            </label>
                        </div> -->
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Grade Quality of steel used</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="AIS203" (change)="steelGradeQuality($event)">
                                AIS203
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="AIS304" (change)="steelGradeQuality($event)">
                                AIS304
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="Other" (change)="steelGradeQuality($event)">
                                Other
                            </label>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" name="other_steel_quality" [(ngModel)]="infrastructureData.other_steel_quality" placeholder="If other, please specify">
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Pressing machine</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="pressradio" value="yes" [(ngModel)]="infrastructureData.steel_pressing_machine">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="pressradio" value="no" [(ngModel)]="infrastructureData.steel_pressing_machine">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Copper plating tank</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="copperplatradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="copperplatradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-3">
                    <label for="">Chrome plating tank</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="chromeplatradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="chromeplatradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Micron silver tank</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="micronsilradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="micronsilradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Anticyanide / anti-tarnish tank</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="anticyradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="anticyradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Lacquering tank</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="lacqradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="lacqradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">PU Coating tank</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="pucoatradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="pucoatradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Do you have metal pressing unit?</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="metalproradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="metalproradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Do you have antique color finish?</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="antiqueradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="antiqueradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Sand Casting</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="sandcasradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="sandcasradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Pressure Die Casting</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="pressdieradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="pressdieradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Galvanising Process</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="galvanradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="galvanradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Spray painting process dustproof chamber</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="spraypainradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="spraypainradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Artistic hand painting in your premises?</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="arthandradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="arthandradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Regular chemical cleaning</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="regcheradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="regcheradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Nickel plating tank</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="nickplaradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="nickplaradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Strike silver tank</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="strsilradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="strsilradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">
                            Max. micron which can be plated
                        </label>
                        <input type="number" class="form-control" name="" placeholder="Ex: 10">
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Powder coating plant</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="powcoaradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="powcoaradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Do you have inhouse casting?</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="inhouseradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="inhouseradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Do you have inhouse coloring unit?</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="incolradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="incolradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Do you have electrophoratic plant?</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="elecradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="elecradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Lost wax casting</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="lowradio" value="yes">
                                Inhouse
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="lowradio" value="no">
                                Outsourced
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Forging</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="forgradio" value="yes">
                                Inhouse
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="forgradio" value="no">
                                Outsourced
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Phosphating Process</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="phospharadio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="phospharadio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Sand blasting process</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="sandblaradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="sandblaradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">
                            No. of polishing machines
                        </label>
                        <input type="number" class="form-control" name="no_of_polishing_machine" [(ngModel)]="infrastructureData.no_of_polishing_machine" placeholder="Ex: 10">
                         <!-- <select name="" id="" class="form-control" name="no_of_polishing_machine" [(ngModel)]="infrastructureData.steel_no_of_polishing_machine">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                        </select> -->
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Ultrasonic cleaning machine</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="ultraradio" value="yes" [(ngModel)]="infrastructureData.steel_ultrasonic_cleaning_machine">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="ultraradio" value="no" [(ngModel)]="infrastructureData.steel_ultrasonic_cleaning_machine">
                                No
                            </label>
                        </div>
                    </div>
                </div>
            
                <div class="col-md-3">
                    <label for="">Shearing machine</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="shearradio" value="yes" [(ngModel)]="infrastructureData.steel_shearing_machine">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="shearradio" value="no" [(ngModel)]="infrastructureData.steel_shearing_machine">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Cup rolling machine</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="cupradio" value="yes" [(ngModel)]="infrastructureData.steel_cup_rollig_machine">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="cupradio" value="no" [(ngModel)]="infrastructureData.steel_cup_rollig_machine">
                                No
                            </label>
                        </div>
                    </div>
                </div>
            
                <div class="col-md-3">
                    <label for="">Chemical machine</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="chemicalradio" value="yes" [(ngModel)]="infrastructureData.steel_chemical_machine">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="chemicalradio" value="no" [(ngModel)]="infrastructureData.steel_chemical_machine">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">
                            Oven with blower - nos.
                        </label>
                        <input type="number" class="form-control" name="nos_oven_with_blower" [(ngModel)]="infrastructureData.nos_oven_with_blower" placeholder="Ex: 10">
                        <!-- <select name="" id="" class="form-control" name="nos_oven_with_blower" [(ngModel)]="infrastructureData.steel_nos_oven_with_blower">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                        </select> -->
                    </div>
                </div>
               
                <div class="col-md-3">
                    <label for="">Welding process used</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" name="check-twelve" value="Argon" (change)="weldingProcess($event)">
                                Argon
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" name="check-twelve" value="Normal" (change)="weldingProcess($event)">
                                Normal
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Please indicate other equipment present in above factory premises</label>
                        <select name="" id="" class="form-control" name="other_equipment" [(ngModel)]="infrastructureData.steel_other_equipment">
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Do you make items in combination with brass or other metal</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="yes" name="comboradio" [(ngModel)]="infrastructureData.steel_item_combination">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="no" name="comboradio" [(ngModel)]="infrastructureData.steel_item_combination">
                                No
                            </label>
                        </div>
                        <input type="text" name="steel_combo_detail" [(ngModel)]="infrastructureData.steel_combo_detail" class="form-control" placeholder="if yes, please specify">
                    </div>
                </div>
                </div>
            </div>

            <!-- STAINLESS STEEL START -->
            <div class="row" style="padding: 0 15px;">
                <div class="col-md-12" *ngIf="steelFlag" >
                    <h4 class="mt-md-3">
                        Factory Information - Stainless Steel
                    </h4>
                </div>
                
                <div class="col-md-2">
                    <label for="">Type of Factory</label>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Inhouse" name="factradio">
                                Inhouse Production
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" value="Outsourced" name="factradio">
                                Outsourced
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Shearing machine</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="shearmachradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="shearmachradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="">Grade Quality of steel used</label>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="AIS203" name="ssbox">
                                AIS203
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="AIS304" name="ssbox">
                                AIS304
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="Other" name="ssbox">
                                Other
                            </label>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" name="oth_stl_qlty" placeholder="If other, please specify">
                        </div>
                    </div>
                </div>

                

                <div class="col-md-3">
                    <label for="">Cup rolling machine</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="cuprollradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="cuprollradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Welding process used</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" name="weldprobox" value="Argon">
                                Argon
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="checkbox" class="form-check-input" name="weldprobox" value="Normal">
                                Normal
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="">Do you make items in combination with Brass or other metals</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="dymradio" value="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label for="" class="form-check-label">
                                <input type="radio" class="form-check-input" name="dymradio" value="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Please indicate other equipment present in above factory premises</label>
                        <input type="text" class="form-control" name="oth_equip" />
                    </div>
                </div>
            </div>
            <!-- STAINLESS STEEL END -->

            <div  *ngIf="ironFlag">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <h4 class="mt-md-3">
                                Factory Information - Iron & Wrought Iron Goods & Furniture
                            </h4>
                        </div>

                        <div class="col-md-2">
                            <label for="">Type of Factory</label>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="Rented" name="ironfacradio" [(ngModel)]="infrastructureData.iron_factory_type">
                                        Rented
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="Owned" name="ironfacradio" [(ngModel)]="infrastructureData.iron_factory_type">
                                        Owned
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="Outsourced" name="ironfacradio" [(ngModel)]="infrastructureData.iron_factory_type">
                                        Outsourced
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">No. of polishing machines</label>
                                <select id="" class="form-control" name="no_of_polishing_machines" [(ngModel)]="infrastructureData.iron_no_of_polishing_machines">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="">Regular chemical cleaing</label>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="yes" name="chemicalradio" [(ngModel)]="infrastructureData.iron_regular_chemical_cleaning">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="no" name="chemicalradio" [(ngModel)]="infrastructureData.iron_regular_chemical_cleaning">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="">Ultrasonic cleaing machine</label>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="yes" name="ultraradio" [(ngModel)]="infrastructureData.iron_ultrasonic_cleaning_machine">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="no" name="ultraradio" [(ngModel)]="infrastructureData.iron_ultrasonic_cleaning_machine">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">
                                    Oven with blower - nos.
                                </label>
                                <select class="form-control" id="" name="nos_oven_with_blower" [(ngModel)]="infrastructureData.iron_nos_oven_with_blower">
                                    <option value="1">1</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-12"></div>

                        <div class="col-md-4">
                            <label for="">Iron casting</label>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="yes" name="ironradio" [(ngModel)]="infrastructureData.iron_casting">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="no" name="ironradio" [(ngModel)]="infrastructureData.iron_casting">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="">Iron Forging</label>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="yes" name="ironforgingradio" [(ngModel)]="infrastructureData.iron_forging">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="no" name="ironforgingradio" [(ngModel)]="infrastructureData.iron_forging">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="">Galvanizing process</label>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="yes" name="galradio" [(ngModel)]="infrastructureData.iron_galvanizing_process">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="no" name="galradio" [(ngModel)]="infrastructureData.iron_galvanizing_process">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="">Phosphating process</label>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="yes" name="phosradio" [(ngModel)]="infrastructureData.iron_phosphating_process">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="no" name="phosradio" [(ngModel)]="infrastructureData.iron_phosphating_process">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="">Electroplating process</label>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="yes" name="electroradio" [(ngModel)]="infrastructureData.iron_electroplating_plant">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="no" name="electroradio" [(ngModel)]="infrastructureData.iron_electroplating_plant">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="">Powder coating process</label>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="yes" name="ironpowderradio" [(ngModel)]="infrastructureData.iron_powder_coating_process">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="no" name="ironpowderradio" [(ngModel)]="infrastructureData.iron_powder_coating_process">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="">Spray painting process</label>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="yes" name="ironsprayradio" [(ngModel)]="infrastructureData.iron_spray_painting_process">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="no" name="ironsprayradio" [(ngModel)]="infrastructureData.iron_spray_painting_process">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="">Lacquering process</label>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="yes" name="ironlacqueringradioprocess" [(ngModel)]="infrastructureData.iron_lacquering_process" >
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="no" name="ironlacqueringradioprocess" [(ngModel)]="infrastructureData.iron_lacquering_process">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="">Sand blasting process</label>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="yes" name="ironsandradio" [(ngModel)]="infrastructureData.iron_sand_blasting">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="no" name="ironsandradio" [(ngModel)]="infrastructureData.iron_sand_blasting">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="">Artistic hand painting is done in your premises?</label>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="yes" name="artisticradio" [(ngModel)]="infrastructureData.iron_artistic_hand_painting">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="no" name="artisticradio" [(ngModel)]="infrastructureData.iron_artistic_hand_painting">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="">Do you have your own sheet metal pressing unit?</label>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="yes" name="sheetradio" [(ngModel)]="infrastructureData.iron_sheet_metal_pressing">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="" class="form-check-label">
                                        <input type="radio" class="form-check-input" value="no" name="sheetradio" [(ngModel)]="infrastructureData.iron_sheet_metal_pressing">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12"   *ngIf="textileFlag">
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="mb-md-3">
                            Factory Information - Textiles
                        </h4>
                    </div>

                    <div class="col-md-2">
                        <label for="">Type of Factory</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" value="Inhouse" name="facradio" [(ngModel)]="infrastructureData.fac_info_factory_type">
                                    Inhouse Production
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" value="Outsourced" name="facradio" [(ngModel)]="infrastructureData.fac_info_factory_type">
                                    Outsourced
                                </label>
                            </div>
                            <!-- <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" value="Rented" name="facradio" [(ngModel)]="infrastructureData.fac_info_factory_type">
                                    Rented
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" value="Owned" name="facradio" [(ngModel)]="infrastructureData.fac_info_factory_type">
                                    Owned
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" value="Outsourced" name="facradio" [(ngModel)]="infrastructureData.fac_info_factory_type">
                                    Outsourced
                                </label>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Do you manufacture your own fabric?</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" value="yes" name="fabricradio" [(ngModel)]="infrastructureData.fac_info_fabric_manufacture">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" value="no" name="fabricradio" [(ngModel)]="infrastructureData.fac_info_fabric_manufacture">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label for="">Do you do printing?</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-thirteen" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_printing">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-thirteen" value="Outsource" [(ngModel)]="infrastructureData.fac_info_printing">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Which dye/colour you use?</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-fourteen" value="Vegetable" [(ngModel)]="infrastructureData.fac_info_dye_or_color_use">
                                    Vegetable
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-fourteen" value="Chemical" [(ngModel)]="infrastructureData.fac_info_dye_or_color_use">
                                    Chemical
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-fourteen" value="Other" [(ngModel)]="infrastructureData.fac_info_dye_or_color_use">
                                    Other
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label for="">Fabrication</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-fifteen" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_fabrication">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-fifteen" value="Outsource" [(ngModel)]="infrastructureData.fac_info_fabrication">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">No. of stitching machine</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <input type="number" class="form-control" placeholder="Inhouse" name="inhouse_stitching_machine" [(ngModel)]="infrastructureData.fac_info_inhouse_stitching_machine">
                        </div>
                        <div class="form-group">
                            <input type="number" class="form-control" placeholder="Outsource" name="outsource_stitching_machine" [(ngModel)]="infrastructureData.fac_info_outsource_stitching_machine ">
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">No. of inter lock machine</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <input type="number" class="form-control" placeholder="Inhouse" name="inhouse_interlock_machine" [(ngModel)]="infrastructureData.fac_info_inhouse_interlock_machine">
                        </div>
                        <div class="form-group">
                            <input type="number" class="form-control" placeholder="Outsource" name="outsource_interlock_machine" [(ngModel)]="infrastructureData.fac_info_outsource_interlock_machine">
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">No. of over lock machine</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <input type="number" class="form-control" placeholder="Inhouse" name="inhouse_overlook_machine" [(ngModel)]="infrastructureData.fac_info_inhouse_overlook_machine">
                        </div>
                        <div class="form-group">
                            <input type="number" class="form-control" placeholder="Outsource" name="outsource_overlook_machine" [(ngModel)]="infrastructureData.fac_info_outsource_overlook_machine">
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Other machines, please specify</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="" name="other_machine" [(ngModel)]="infrastructureData.fac_info_other_machine">
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">No. of other machine</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <input type="number" class="form-control" placeholder="Inhouse" name="inhouse_other_machine" [(ngModel)]="infrastructureData.fac_info_inhouse_other_machine">
                        </div>
                        <div class="form-group">
                            <input type="number" class="form-control" placeholder="Outsource" name="outsource_other_machine" [(ngModel)]="infrastructureData.fac_info_outsource_other_machine">
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label for="">Finishing Unit</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-sixteen" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_finishing_unit">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-sixteen" value="Outsource" [(ngModel)]="infrastructureData.fac_info_finishing_unit">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Details of Finishing machinery</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <textarea class="form-control" name="detail_finishing_machinery" [(ngModel)]="infrastructureData.fac_info_detail_finishing_machinery" rows="4" id=""></textarea>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <label for="">
                            <strong>Do you have cushion's filler?</strong>
                        </label>
                    </div>

                    <div class="col-md-2">
                        <label for="">Vaccum packed arrangement</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-seventeen" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_vaccum_packed_arrangement">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-seventeen" value="Outsource" [(ngModel)]="infrastructureData.fac_info_vaccum_packed_arrangement">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Computer Embroidery</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-eighteen" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_computer_embroidery">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-eighteen" value="Outsource" [(ngModel)]="infrastructureData.fac_info_computer_embroidery">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label for="">Hand Embroidery</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-nineteen" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_hand_embroidery">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-nineteen" value="Outsource" [(ngModel)]="infrastructureData.fac_info_hand_embroidery">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <label for=""><strong>For carpet/rugs manufacture</strong></label>
                    </div>

                    <div class="col-md-2">
                        <label for="">Roller beam loom</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-twenty" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_roller_beam_loom">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-twenty" value="Outsource" [(ngModel)]="infrastructureData.fac_info_roller_beam_loom">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Hand loom frame</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-one" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_hand_loom_frame">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-one" value="Outsource" [(ngModel)]="infrastructureData.fac_info_hand_loom_frame">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label for="">Loom dobby</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-two" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_loom_dobby">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-two" value="Outsource" [(ngModel)]="infrastructureData.fac_info_loom_dobby">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Hand loom jacquard</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-three" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_hand_loom_jacquard">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-three" value="Outsource" [(ngModel)]="infrastructureData.fac_info_hand_loom_jacquard">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label for="">Pit Loom</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-four" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_pit_loom">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-four" value="Outsource" [(ngModel)]="infrastructureData.fac_info_pit_loom">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Punja loom</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-five" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_punja_loom">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-five" value="Outsource" [(ngModel)]="infrastructureData.fac_info_punja_loom">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label for="">Power loom dobby</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-six" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_power_loom_dobby">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-six" value="Outsource" [(ngModel)]="infrastructureData.fac_info_power_loom_dobby">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="">Power loom jacquard</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-seven" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_power_loom_jacquard">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-seven" value="Outsource" [(ngModel)]="infrastructureData.fac_info_power_loom_jacquard">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label for="">Braiding machine</label>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-eight" value="Inhouse" [(ngModel)]="infrastructureData.fac_info_braiding_machine">
                                    Inhouse
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label for="" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="check-tw-eight" value="Outsource" [(ngModel)]="infrastructureData.fac_info_braiding_machine">
                                    Outsource
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                <div class="col-md-12"></div>
                <div class="w-100" style="margin-bottom: 32px;"></div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4 text-center mx-auto">
                            <button [routerLink]="['/step-seven']" routerLinkActive="active" class="btn btn-secondary d-md-block mx-auto"><i class="fa fa-angle-left"></i> Previous</button>
                        </div>
                        <div class="col-md-4 text-center mx-auto">
                            <button type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button>
                        </div>
                        <div class="col-md-4 text-center mx-auto">
                            <!-- <button type="" class="btn btn-success d-md-block mx-auto">Next</button> -->
                        </div>
                    </div>
                </div>
                <!-- <button  type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button> -->

            </form>
        </div>
    </div>
</div>



</div>
</section>