import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formData:any={};
  vendorData:any={};

  constructor(private router: Router,
    private WebapiService:WebapiService) { }

  ngOnInit(): void {
  }

  doLogin(data:any){
    // console.log(data);
    this.WebapiService.doLogin(data).subscribe((res:any)=>{
      console.log(res);
      if(res.status == 1 && res.data[0].loginStatus == 2){
        // alert(res.msg);
        localStorage.setItem('loginId',res.data[0].id);
        localStorage.setItem('loginStatus',res.data[0].loginStatus);
        localStorage.setItem('name',res.data[0].fname);
        this.router.navigate(['/step-two']);
      }
      else if(res.status == 1 && res.data[0].loginStatus == 3){
        // alert(res.msg);
        localStorage.setItem('loginId',res.data[0].id);
        localStorage.setItem('loginStatus',res.data[0].loginStatus);
        localStorage.setItem('name',res.data[0].fname);
        this.router.navigate(['/my-account']);
      }
      else if(res.status == 1 && res.data[0].loginStatus == 4){
        // alert(res.msg);
        localStorage.setItem('loginId',res.data[0].id);
        localStorage.setItem('loginStatus',res.data[0].loginStatus);
        localStorage.setItem('name',res.data[0].fname);
        this.router.navigate(['/admin']);
      }
      else{
        alert(res.msg);
      }

    });
  }

  insertVendor(data:any){

    if(data.password == data.cpassword){
      this.WebapiService.insertVendor(data).subscribe((res:any)=>{
        console.log(res);
        if(res.status == 1){
          alert(res.msg);
        }
        else{
          alert(res.msg);
        }
     });
    }
    else{
      alert("please enter same password");
    }

  }
}
