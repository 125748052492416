<!-- <style>
    .our-service-section{
        background-image: linear-gradient(to left, rgba( 0, 0, 0, 0.55), rgba(0, 0, 0, .55)), url("../../assets/img/mfacture.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: auto;
        width: 100%;
    }
    .our-service-section .nav-tabs{
        border-bottom: none;
    }
    .our-service-section .nav-tabs .nav-item.show .nav-link, .our-service-section .nav-tabs .nav-link.active{
        border-color: transparent;
    }
    .our-service-section a.nav-link.active{
        color: var(--color-one);
    }
    .our-service-section a.nav-link{
        color: var(--white);
        border-radius: 0;
    }
    .our-service-section .nav-tabs .nav-link:focus, .our-service-section .nav-tabs .nav-link:hover{
        border-color: transparent;
        background-color: var(--white);
        color: var(--color-one) !important;
        transition: all 200ms ease-in;
    }
    .our-service-section .section-title h1{
        color: var(--white);
        font-weight: var(--f-weight-seven);
    }
    .our-service-section .section-content #myTabContent .tab-pane{
        color: var(--white);
    }
    .our-service-section #logistics ul>li:before{
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Free"; 
        font-weight: 900; 
        content: "\f105";
        margin-right: 10px;
    }
</style> -->

<section class="our-service-section section" id="our-service">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 offset-md-3">
                <div class="section-content">
                    <div class="section-title">
                        <h1>What We Do</h1>
                    </div>

                    <ul class="nav nav-tabs flex-column col-md-4 float-md-left">
                        <li class="nav-item" *ngFor="let service of serviceList">
                            <a (click)="onServiceCLick(service.id)" class="nav-link">{{service.service_name}}</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" id="two-tab" data-toggle="tab" href="#tooling" role="tab" aria-controls="tooling" aria-selected="false">Tooling</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="three-tab" data-toggle="tab" href="#factory-evaluation
                            " role="tab" aria-controls="factory-evaluation" aria-selected="false">Factory Evaluation
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="four-tab" data-toggle="tab" href="#sourcing" role="tab" aria-controls="sourcing" aria-selected="false">Sourcing</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="five-tab" data-toggle="tab" href="#price-negotiation
                            " role="tab" aria-controls="price-negotiation" aria-selected="false">Price Negotiation
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="six-tab" data-toggle="tab" href="#sampling-and-product-development
                            " role="tab" aria-controls="sampling-and-product-development" aria-selected="false">Sampling and Product Development
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="seven-tab" data-toggle="tab" href="#delivery-monitoring
                            " role="tab" aria-controls="delivery-monitoring" aria-selected="false">Delivery Monitoring
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="eight-tab" data-toggle="tab" href="#inspection" role="tab" aria-controls="inspection" aria-selected="false">Inspection & Testing</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="nine-tab" data-toggle="tab" href="#quality-control" role="tab" aria-controls="quality-control" aria-selected="false">Quality Control
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="ten-tab" data-toggle="tab" href="#logistics" role="tab" aria-controls="logistics" aria-selected="false">Logistic/Consolidation & Coordination</a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a class="nav-link" id="eleven-tab" data-toggle="tab" href="#shipping-coordination
                            " role="tab" aria-controls="shipping-coordination" aria-selected="false">Shipping Coordination
                            </a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a class="nav-link" id="twelve-tab" data-toggle="tab" href="#consolidation-of-shipments
                            " role="tab" aria-controls="consolidation-of-shipments" aria-selected="false">Consolidation Of Shipments
                            </a>
                        </li> -->
                    </ul>
                    <div class="tab-content col-md-8 float-md-left">
                        <div *ngFor="let data of serviceData" class="tab-pane active">
                            <p class="bg-white p-2">
                              {{data.description}}
                            </p>
                            <!-- <p>
                                Mila’s design team is led by a leading designer, Educated from Parson’s New York supported by designers and design engineers. Our team of designers travel research continuously for design inspirations and novel ideas that breathe new designs to our customers
                                specification. We add value to our customers’ inhouse design teams and enhance their product while keeping true to their brand identity.
                            </p> -->
                            <div class="tab-pane-img text-md-center">
                                <img src="https://onlinesoftware360.com/mila/admin/images/service_image/{{data.image}}" alt="" class="w-75">
                            </div>
                        </div>
                        <!-- <div class="tab-pane fade" id="tooling" role="tabpanel" aria-labelledby="tooling-tab">
                            <p>The quality of a finished part, its properties, the speed and accuracy with which it can be produced and the repeatability of manufacture in high volume production runs, all depend on the precision and characteristics of the
                                tooling. To achieve this, Mila works very closely with the team of designers and engineers to achieve tooling to its best precision.</p>
                            <div class="tab-pane-img text-md-center">
                                <img src="../../assets/img/tooling.jpg" alt="" class="w-75">
                            </div>
                        </div>
                        <div class="tab-pane fade" id="factory-evaluation" role="tabpanel" aria-labelledby="factory-evaluation-tab">
                            <p>
                                Before placing an order, Mila performs an initial factory evaluation. A factory evaluation includes an assessment of the working conditions, facilities, machinery, financial strength, management system, quality control system, production flow in the factory
                                & socially complaint certifications. Mila also briefs the vendor regarding the buyer's quality requirements. Mila's professional factory evaluation enables foreign buyers to be confident in quality control, production flow
                                and delivery.
                            </p> -->
                            <!-- <p><b>We find Environment friendly and modern factories having complete Infrastructure including modern technology, latest plant & machinery with all compliance and social certifications.</b></p> -->
                            <!-- <div class="tab-pane-img text-md-center">
                                <img src="../../assets/img/factory-eval.jpg" alt="" class="w-75">
                            </div>
                        </div>
                        <div class="tab-pane fade" id="sourcing" role="tabpanel" aria-labelledby="sourcing-tab">
                            <p>
                                We source Environment friendly and modern factories having complete Infrastructure including modern technology, latest plant & machinery with all compliance and social certifications.
                            </p>
                            <p>Finding the right materials and factories that share our commitment to quality, compliance and sustainability is a fundamental activity.</p>
                            <p>It is our constant endeavors to source best quality products always at lowest possible prices. We meet and visit factories all through the year. Evaluation of each factory is done to assess their capabilities and strengths
                                to meet all requirements on the way for quality products and timely delivery.</p>
                            <div class="tab-pane-img text-md-center">
                                <img src="../../assets/img/sourcing.jpg" alt="" class="w-75">
                            </div>
                        </div>
                        <div class="tab-pane fade" id="price-negotiation" role="tabpanel" aria-labelledby="price-negotiation-tab">
                            <p>
                                Before costing to buyer, we discuss with factory for all the cost related factory and do detailed costing for all components, process and minimal profits to help factory and buyer both. We work on cost plus method & then quote buyer a product with the
                                lowest prices, which can be offered from India for large volume buyers.
                            </p>
                            <div class="tab-pane-img text-md-center">
                                <img src="../../assets/img/price-nego.jpg" alt="" class="w-75">
                            </div>
                        </div>
                        <div class="tab-pane fade" id="sampling-and-product-development" role="tabpanel" aria-labelledby="sampling-and-product-development-tab">
                            <p>
                                Merchandisers, Quality Assurance Inspectors & Quality Controllers work closely with factories to ensure products meet the stipulated requirements. Product development is done under strict control and supervision of our quality controllers, wherein they
                                review each sample for aesthetics and performance.
                            </p>
                            <p>Our Technical Support Teams ensure that samples are created and developed safely according to customers’ product required specification. Merchandisers, designers, quality inspectors and product development experts work with
                                factories to ensure products meet the requirements.</p>
                            <div class="tab-pane-img text-md-center">
                                <img src="../../assets/img/product-dev.jpg" alt="" class="w-75">
                            </div>
                        </div>
                        <div class="tab-pane fade" id="delivery-monitoring" role="tabpanel" aria-labelledby="delivery-monitoring-tab">
                            <p>
                                We follow up with factories on timely basis to see the various activities involved in production for timely deliveries. We help factories in finding solutions for problems occurred during sampling, tooling, product development ,production and in packaging.
                                All production timelines are updated on google sheets & monitored on weekly basis.
                            </p>
                            <div class="tab-pane-img text-md-center">
                                <img src="../../assets/img/delivery-moni.jpg" alt="" class="w-75">
                            </div>
                        </div>
                        <div class="tab-pane fade" id="inspection" role="tabpanel" aria-labelledby="inspection-tab">
                            <p>
                                To ensure quality products, Mila performs inspection at various stages i.e. when raw material received, initial manufacturing, final finishing, under packaging & before final dispatch. Products are tested to meet stringent US FDA / CA-Prop 65 standards.
                                We also create highly detailed quality inspection sheets, mentioning all possible future defects to work on prevention of any defect.
                            </p>
                            <div class="tab-pane-img text-md-center">
                                <img src="../../assets/img/inspection.jpg" alt="" class="w-75">
                            </div>
                        </div>
                        <div class="tab-pane fade" id="quality-control" role="tabpanel" aria-labelledby="quality-control-tab">
                            <p>
                                With our experienced and skilled on-site Quality Control teams, we can provide our partners with the transparency and controls needed to measure the quality of each order.
                            </p>
                            <p>We assure Safe, quality assured products manufactured in accordance to industry minimum standards and best practices. We work with factory for Intensive pre-production planning to reduce rejection rates and increase efficiency.</p>
                            <p>
                                Skilled teams to monitor, coordinate and control all processes. We work for closely planned and monitored manufacturing processes that assures quality output in a timely manner. We follow Highly advanced Inspection and Quality Control Procedure based
                                on client's specification.
                            </p>
                            <div class="tab-pane-img text-md-center">
                                <img src="../../assets/img/quality-con.jpg" alt="" class="w-75">
                            </div>
                        </div>
                        <div class="tab-pane fade" id="logistics" role="tabpanel" aria-labelledby="logistics-tab">
                            <p>
                                We take care of the entire custom clearance, from documentation to stuffing of goods at various locations like , Delhi / Moradabad / Dadri / Jaipur / Jodhpur / Mumbai / Chennai / etc. Our entire procedure is listed below-
                            </p>
                            <ul class="mb-md-3 section-list">
                                <li>Cargo Pick up facility round the clock by nominated fleets of close body trucks.</li>
                                <li>Custom Documentation.</li>
                                <li>Carting / Receiving Goods.</li>
                                <li>Examination of Shipments through reliable custom clearing agents.</li>
                                <li>Handling of stuffing & de-stuffing at Ports, ICD's and Customers warehouse</li>
                                <li>Offer "door to door" delivery of cargo.</li>
                            </ul>
                            <p>
                                We Checks shipping documents, opening of LC and their transfers, freight consolidations. We follow up with forwards to track containers for timely delivery at buyer’s end.
                            </p>
                            <div class="tab-pane-img text-md-center">
                                <img src="../../assets/img/logistic.jpg" alt="" class="w-75">
                            </div>
                        </div>
                        <div class="tab-pane fade" id="shipping-coordination" role="tabpanel" aria-labelledby="shipping-coordination-tab">
                            <p>
                                We Checks shipping documents, opening of LC and their transfers, freight consolidations. We follow up with forwards to track containers for timely delivery at buyer’s end.
                            </p>
                            <div class="tab-pane-img text-md-center">
                                <img src="../../assets/img/ship-coor.jpg" alt="" class="w-75">
                            </div>
                        </div>
                        <div class="tab-pane fade" id="consolidation-of-shipments" role="tabpanel" aria-labelledby="consolidation-of-shipments-tab">
                            <p>
                                We assist our clients with documentation and consolidating of cargo. Container planning in a regular feature and is coordinated between merchandising and shipping teams. We work on a day-to-day basis with Consolidators and Shipping Lines for smooth flow
                                of cargo. Also, Pre & Post shipping advises are sent to clients as may be their requirements.
                            </p>
                            <p>
                                We take care of the entire custom clearance, from documentation to stuffing and DE stuffing of goods at various locations like , Delhi / Moradabad / Jaipur / Jodhpur / Mumbai / Chennai / etc. Our entire procedure is listed below-
                            </p>
                            <ul>
                                <li>Custom Documentation</li>
                                <li>Carting / Receiving Goods</li>
                                <li>Examination Of Shipments.</li>
                                <li>Handling of stuffing & de-stuffing at Ports, ICD's and Customers warehouse</li>
                            </ul>
                            <div class="tab-pane-img text-md-center">
                                <img src="../../assets/img/ship-coor.jpg" alt="" class="w-75">
                            </div>
                        </div> -->
                    </div>

                    <!-- <div class="section-sub-content col-md-4 float-left">
                        <div class="section-sub-title">
                            <h3>Design Services</h3>
                        </div>
                        <div class="section-text">
                            <p>
                                From concept to design brief, the design process translates trends into products that meet the needs of today's demanding consumer.
                            </p>
                            <details>
                                <summary>Read More</summary>
                                <p>Mila has a well-qualified team of designers who can design based on requirements. Our team of designers travel research continuously for design inspirations and novel ideas that breathe new designs to our customers specification. We add value to our customers’ inhouse design teams and enhance their product while keeping true to their brand identity.</p>
                            </details>
                        </div>
                    </div> -->

                    <!-- <div class="section-sub-content col-md-4 float-left">
                        <div class="section-sub-title">
                            <h3>Tooling</h3>
                        </div>
                        <div class="section-text">
                            <p>
                                The quality of a finished part, its properties, the speed and accuracy with which it can be produced and the repeatability of manufacture in high volume production runs, all depend on the precision and characteristics of the tooling. To achieve this, Mila works very closely with the team of designers and engineers to achieve tooling to its best precision.
                            </p>
                        </div>
                    </div> -->

                    <!-- <div class="section-sub-content col-md-4 float-left">
                        <div class="section-sub-title">
                            <h3>Factory Evaluation</h3>
                        </div>
                        <div class="section-text">
                            <p>
                                Before placing an order, Mila performs an initial factory evaluation. A factory evaluation includes an assessment of the working conditions, facilities, machinery, financial strength, management system, quality control system and production flow in the factory. Mila will also brief the vendor regarding the buyer's quality requirements. Mila's professional factory evaluation enables foreign buyers to be confident in quality control, production flow and delivery.
                            </p>
                            <details>
                                <summary>Read More</summary>
                                <p><b>We find Environment friendly and modern factories having complete Infrastructure including modern technology, latest plant & machinery with all compliance and social certifications.</b></p>
                            </details>
                        </div>
                    </div> -->

                    <!-- <div class="section-sub-content col-md-4 float-left">
                        <div class="section-sub-title">
                            <h3>Sourcing</h3>
                        </div>
                        <div class="section-text">
                            <p>
                                Finding the right materials and factories that share our commitment to quality, compliance and sustainability is a fundamental activity.
                            </p>
                            <details>
                                <summary>Read More</summary>
                                <p>It is our constant endeavors to source best quality products always at lowest possible prices. We meet and visit factories all through the year. Evaluation of each factory is done to assess their capabilities and strengths to meet all requirements on the way for quality products and  timely delivery.</p>
                            </details>
                        </div>
                    </div> -->

                    <!-- <div class="section-sub-content col-md-4 float-left">
                        <div class="section-sub-title">
                            <h3>Price Negotiation</h3>
                        </div>
                        <div class="section-text">
                            <p>
                                Before costing to buyer, we discuss with factory for all the cost related factory and do detailed costing for all components, process and minimal profits to help factory and buyer both. We quote buyer a product with the best lowest prices, can offer from India.
                            </p>
                        </div>
                    </div> -->

                    <!-- <div class="section-sub-content col-md-4 float-left">
                        <div class="section-sub-title">
                            <h3>Sampling and Product Development</h3>
                        </div>
                        <div class="section-text">
                            <p>
                                Merchandisers, Quality Assurance Engineers, Quality Controllers work closely with factories to ensure products meet the stipulated requirements. Product development is done under strict control and supervision of our quality controllers, wherein they review each sample for aesthetics and performance.
                            </p>
                            <details>
                                <summary>Read More</summary>
                                <p>Our Technical Support Teams ensure that samples are created and developed safely according to customers’ product required specification. Merchandisers, designers, quality engineers and product development experts work with factories to ensure products meet the requirements.</p>
                            </details>
                        </div>
                    </div> -->

                    <!-- <div class="section-sub-content col-md-4 float-left">
                        <div class="section-sub-title">
                            <h3>Delivery Monitoring</h3>
                        </div>
                        <div class="section-text">
                            <p>
                                We follow up with factories on timely basis to see the various activities involved in production for timely deliveries. We help factories in finding solutions for problems occurred during sampling, tooling, product development, production and in packaging
                            </p>
                        </div>
                    </div> -->

                    <!-- <div class="section-sub-content col-md-4 float-left">
                        <div class="section-sub-title">
                            <h3>Inspection</h3>
                        </div>
                        <div class="section-text">
                            <p>
                                To ensure quality products, Mila performs inspection at various stages i.e. when raw material received, under sampling, under production, under packaging. We also perform inline inspection, mid-line inspection and final inspection at the time of dispatch.
                            </p>
                        </div>
                    </div> -->


                    <!-- <div class="section-sub-content col-md-4 float-left">
                        <div class="section-sub-title">
                            <h3>Quality Control</h3>
                        </div>
                        <div class="section-text">
                            <p>
                                With our experienced and skilled on-site Quality Control teams, we can provide our partners with the transparency and controls needed to measure the quality of each order.
                            </p>
                            <details>
                                <summary>Read More</summary>
                                <p>We assure Safe, quality assured products manufactured in accordance to industry minimum standards and best practices. We work with factory for Intensive pre-production planning to reduce rejection rates and increase efficiency.
                                    Skilled teams to monitor, coordinate and control all processes. We work for closely planned and monitored manufacturing processes that assures quality output in a timely manner.</p>
                                <p><b>We follow Highly advanced Inspection and Quality Control Procedure based on client's specification.</b></p>
                            </details>
                        </div>
                    </div> -->

                    <!-- <div class="section-sub-content col-md-4 float-left">
                        <div class="section-sub-title">
                            <h3>Consolidation Of Shipments</h3>
                        </div>
                        <div class="section-text">
                            <p>
                                We assist our clients with documentation and consolidating of cargo. Container planning in a regular feature and is coordinated between merchandising and shipping teams. We work on a day-to-day basis with Consolidators and Shipping Lines for smooth flow of cargo. Also, Pre & Post shipping advises are sent to clients as may be their requirements.
                            </p>
                            <details>
                                <summary>Read More</summary>
                                <p>
                                    We take care of the entire custom clearance, from documentation to stuffing and DE stuffing of goods at various locations like , Delhi / Moradabad / Jaipur / Jodhpur / Mumbai / Chennai / etc. Our entire procedure is listed below-
                                </p>
                                <ul>
                                    <li>Custom Documentation</li>
                                    <li>Carting / Receiving Goods</li>
                                    <li>Examination Of Shipments.</li>
                                    <li>Handling of stuffing & de-stuffing at Ports, ICD's and Customers warehouse</li>
                                </ul>
                            </details>
                        </div>
                    </div> -->

                    <!-- <div class="section-sub-content col-md-4 float-left">
                        <div class="section-sub-title">
                            <h3>Logistics</h3>
                        </div>
                        <div class="section-text">
                            <p>
                                We undertake transportation of goods via various means and ensure utmost care and work according to your conveniences.
                            </p>
                            <details>
                                <summary>Read More</summary>
                                <ul>
                                    <li>Cargo Pick up facility round the clock by own fleets of close body trucks.</li>
                                    <li>Transportation of air cargo, custom cleared in hinterlands to international air port.</li>
                                    <li>Transportation of goods from port of discharge to hinterland destinations in conjunction with our overseas partners"</li>
                                    <li>Offer "door to door" delivery of cargo.</li>
                                </ul>
                            </details>
                        </div>
                    </div> -->

                    <!-- <div class="section-sub-content col-md-4 float-left">
                        <div class="section-sub-title">
                            <h3>Shipping Coordination</h3>
                        </div>
                        <div class="section-text">
                            <p>
                                We Checks shipping documents, opening of LC and their transfers, freight consolidations. We follow up with forwards to track containers for timely delivery at buyer’s end.
                            </p>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>