import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendor-account',
  templateUrl: './vendor-account.component.html',
  styleUrls: ['./vendor-account.component.css']
})
export class VendorAccountComponent implements OnInit {

   venderId:any
  constructor() { }

  ngOnInit(): void {
    this.venderId = localStorage.getItem('loginId');
  }

}
