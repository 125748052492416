<section class="vendor-regis section" id="vendorRegis">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 offset-md-3">
                <div class="section-content">
                    <div class="section-title mb-md-4">
                        <h1 class="mb-md-0">Vendor Registration</h1>
                    </div>
                    <div class="section-form">
                        <form #VendorForm ngForm="VendorForm">
                            <div class="form-group col-md-6 float-left">
                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.cname"  class="form-control" id="company" aria-describedby="company" placeholder="Company Name" />
                            </div>
                            <div class="form-group col-md-6 float-left">
                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.wname" class="form-control" id="exampleInputWebsite" aria-describedby="website" placeholder="Website" />
                            </div>
                            <div class="form-group col-md-4 float-left">
                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.conpername" class="form-control" id="exampleInputPerson" aria-describedby="person" placeholder="Contact Person" />
                            </div>
                            <div class="form-group col-md-4 float-left">
                                <select class="custom-select">
                                    <option selected>Mr.</option>
                                    <option value="1">Mrs.</option>
                                    <option value="2">Ms.</option>
                                </select>
                            </div>
                            <div class="form-group col-md-4 float-left">
                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.uname" class="form-control" id="exampleInputUser" aria-describedby="user" placeholder="Username" />
                            </div>
                            <div class="form-group col-md-6 float-left">
                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.mob" class="form-control" id="exampleInputMobile" aria-describedby="mobile" placeholder="Mobile" />
                            </div>
                            <div class="form-group col-md-6 float-left">
                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.offnum" class="form-control" id="exampleInputOffice" aria-describedby="office" placeholder="Office Number" />
                            </div>
                            <div class="form-group col-md-6 float-left">
                                <textarea class="form-control" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.offadd" id="exampleFormOffAddress" rows="3" placeholder="Office Address"></textarea>
                            </div>
                            <div class="form-group col-md-3 float-left">
                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.street" class="form-control" id="exampleInputStreet" aria-describedby="street" placeholder="Street" />
                            </div>
                            <div class="form-group col-md-3 float-left">
                                <select class="custom-select">
                                    <option selected>India</option>
                                    <option value="1">Nepal</option>
                                    <option value="2">Bhutan</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6 float-left">
                                <select class="custom-select">
                                    <option selected>New Delhi</option>
                                    <option value="1">New Delhi</option>
                                    <option value="2">New Delhi</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6 float-left">
                                <select class="custom-select">
                                    <option selected>Mumbai</option>
                                    <option value="1">Kolkata</option>
                                    <option value="2">Chennai</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6 float-left">
                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.pin" class="form-control" id="exampleInputPin" aria-describedby="pin" placeholder="Pincode" />
                            </div>
                            <div class="form-group col-md-4 float-left">
                                <!-- <label for="exampleInputEmail1">Email address</label> -->
                                <input type="email" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.mail" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email ID" />
                                <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                            </div>
                            <div class="form-group col-md-4 float-left">
                                <!-- <label for="exampleInputPassword1">Password</label> -->
                                <input type="password" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.pass" class="form-control" id="exampleInputPassword" placeholder="Password" />
                            </div>
                            <div class="form-group col-md-4 float-left">
                                <!-- <label for="exampleInputPassword1">Password</label> -->
                                <input type="password" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.conpass" class="form-control" id="exampleInputConPassword" placeholder="Confirm Password" />
                            </div>
                            <!-- <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                <label class="form-check-label" for="exampleCheck1">Check me out</label>
                            </div> -->
                            <button (click)="insertVendor(); " type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button>
                        </form>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
