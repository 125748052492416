<!-- <style>
    .w-6{
        width: 6%;
    }
    .w-18{
        width: 18%;
    }
    #hero{
        position: relative;
        animation: slideLeft .5s ease -100ms;
    }
    @keyframes slideLeft{
        from{left: -100%}
        to{left: 0%}
    }
   .pre-header{
    background: transparent;
    padding: 1.5rem 0 1.5rem 1.25rem;
    position: fixed;
    top: 0;
    left: 50%;
    width: 50%;
    z-index: 1;
    transition: all 200ms ease;
   }
   .sticky{
    background: var(--color-one);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
   }
   .hero-section .search-box img{
        transform: translate(-6%, -50%);
        position: absolute;
        top: 50%;
        right: 6%;
   }
   .hero-section .sign-in ul>li{
       display: inline-block;
       vertical-align: -webkit-baseline-middle;
   }
   .hero-section .sign-in ul>li a{
       color: var(--white);
       margin-left: .25rem;
   }

   .section-two .section-title, .section-two .section-sub-title-head, .section-two .section-sub-title-head ul>li {
       display: inline-block;
   }
   .section-two .section-sub-title-head ul>li a{
       color: var(--white);
       font-size: 16px;
       border: none;
       padding: .25rem .5rem;
       border-bottom: 3px solid transparent;
   }
   .section-two .section-sub-title-head ul>li{
       vertical-align: super;
    }
    .section-two .section-sub-title-head ul>li a.active{
        color: var(--white);
        background: var(--color-one);
        border-bottom: 3px solid var(--color-three);
    }
    .section-two .section-sub-title-head ul>li a:focus, .section-two .section-sub-title-head ul>li a:hover{
        color: var(--white);
        transition: all 200ms ease-in;
    }
    .section-two .nav-tabs{
        border-bottom: none;
    }
    .section-two a.nav-link{
        color: var(--white);
        border-radius: 0;
    }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
        border-color: transparent;
    }
    .section-four .section-content{
        padding-bottom: 100px;
        height: auto;
    }
    .section-five .section-text ul{
        padding-left: 1rem;
    }
    .support{
        margin-top: 10rem;
    }
</style> -->

<main>
    <section class="hero-section section" id="hero">
        <div class="container-fluid">
            <div class="row">
               
                <div class="col-md-9 offset-md-3">
                    <!-- <div class="col-md-6 float-md-left">
                         <div class="sign-in">
                             <ul class="text-md-center">
                                 <li>
                                    <img src="assets/img/user.png" alt="" class="w-18"> 
                                    <a href="">Sign In</a></li>
                                 <li>
                                    <img src="assets/img/logout.png" alt="" class="w-18">  
                                    <a href="">Sign Up</a></li>
                             </ul>
                         </div>
                     </div> -->
                    <div class="section-content px-md-5">
                        <div class="section-title">
                            <!-- <h1>MILA MERCHANDIZING CORPORATION</h1> -->
                            <h1>Delivering the future of Manufacturing and Sourcing</h1>
                        </div>
                        <div class="section-text">
                            <!-- <p>A Buying House, Established in 1992.</p> -->
                            <ul class="section-list my-md-3">
                                <li>Housewares & Food Service Products From India.</li>
                                <li>Specialize Search Engine With Advance Filters.</li>
                                <li>High Quality Manufacturing.</li>
                                <li>Competitive Prices For Mass Merchandising</li>
                                <!-- <li>COMPETITIVE PRICES, SPECIALIZE IN $1 TO $10 RETAIL PRODUCTS.</li> -->
                                <li>Fast Delivery Lead Times.</li>
                            </ul>
                            <h4>Complete business managed from sourcing to delivery.</h4>

                            <!-- <a [routerLink]="['/product-entry']" routerLinkActive="active" onclick="slideLeft()" class="btn btn-primary mx-5 mt-md-4">Product Entry
                                <img src="assets/img/right-arrow.png" alt="" class="ml-1" width="20">
                            </a>
                            <a [routerLink]="['/filter']" routerLinkActive="active" onclick="slideLeft()" class="btn btn-primary mt-md-4">Search Page
                                <img src="assets/img/right-arrow.png" alt="" class="ml-1" width="20">
                            </a> -->
                        </div>
                        <div class="section-img">
                            <!-- <img src="assets/img/dnv.png" alt="dnv" class="img-fluid mb-md-3"> -->
                            <!-- <p class="mt-3 mt-md-0">ISO 9001-2008 certified from DNV, Netherlands</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-one section" id="whoWeAre">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-8 offset-md-3">
                    <div class="section-content p-3 py-md-4 px-md-5">
                        <div class="section-title">
                            <h1>Who We Are</h1>
                        </div>
                        <div class="section-text">
                            <p>We are highly specialized sourcing & new product development organization. Our skills lie in offering the lowest price from India, by increasing efficiency through improving process & lean management. We work with multiple
                                socially complaint factories on cost+ method.
                            </p>

                            <ul class="section-list">
                                <li>Guaranteed lowest price from India, else will credit the difference.</li>
                                <li>99% shipment delivered on time.</li>
                                <li>Zero claim from customers since last 5 years.</li>
                                <li>Shipped by large socially complaint factories with strong production capabilities up to 1 million pieces per month per item.</li>
                            </ul>
                        </div>
                        <div class="pt-3 pt-md-0 text-center text-md-left">
                            <a [routerLink]="['/about']" routerLinkActive="active" onclick="slideLeft()" class="btn border mt-md-4">Know More
                                <img src="assets/img/right-arrow.png" alt="" class="ml-1" width="20">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-two section" id="whatWeDo">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-8 offset-md-3">
                    <div class="section-content py-md-4 px-md-5">
                        <!-- <div class="section-title col-md-6 pull-left"> -->
                        <div class="section-title col-md-12">
                            <h1>What We Do</h1>
                        </div>
                        <div class="section-sub-title-head">
                            <ul class="nav nav-tabs text-md-center mt-md-4" id="">
                                <li class="nav-item col-md-6">
                                    <a class="nav-link" href="#pre-order">Pre-Order <span class="d-none d-md-inline">Services</span></a>
                                </li>
                                <li class="nav-item col-md-6">
                                    <a class="nav-link" href="#post-order">Post-Order <span class="d-none d-md-inline">Services</span></a>
                                </li>
                            </ul>
                        </div>
                        <div class="section-sub-title clearfix">
                            <ul class="nav nav-tabs section-list flex-md-column col-md-6 float-left pl-md-5">
                                <li *ngFor="let pre of preServiceList" class="nav-item"><a [routerLink]="['/our-services']" [queryParams]="{id:pre.id}"class="nav-link">{{pre.service_name}}</a></li>
                                <!-- <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Tooling</a></li>
                                <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Factory Evaluation</a></li>
                                <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Sourcing</a></li>
                                <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Price Negotitation</a></li> -->
                                <!-- <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Sampling and Product Development</a></li> -->
                            </ul>
                            <ul class="nav nav-tabs section-list flex-md-column col-md-6 float-left pl-md-5">
                                <li *ngFor="let post of postServiceList" class="nav-item"><a [routerLink]="['/our-services']" [queryParams]="{id:post.id}" class="nav-link">{{post.service_name}}</a></li>
                                <!-- <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Delivery Monitoring</a></li>
                                <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Inspection & Testing</a></li>
                                <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Quality Control</a></li> -->
                                <!-- <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Consolidation of Shipment</a></li> -->
                                <!-- <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Logistic/Consolidation & Coordination</a></li> -->
                                <!-- <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Shipping Coordination</a></li> -->
                            </ul>
                        </div>
                        <!-- <div class="section-sub-title-head col-md-6 pull-left">
                            <ul class="nav nav-tabs justify-content-md-start flex-nowrap" id="myTabTwo" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#pre-order" role="tab" aria-controls="pre-order" aria-selected="true">Pre-Order <span class="d-none d-md-inline">Services</span></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#post-order" role="tab" aria-controls="post-order" aria-selected="false">Post-Order <span class="d-none d-md-inline">Services</span></a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <div class="section-sub-title">
                            <div class="tab-content" id="myTabContentTwo">
                                <div class="tab-pane fade show active" id="pre-order" role="tabpanel" aria-labelledby="pre-order-tab">
                                    <ul class="nav nav-tabs flex-column">
                                        <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Design Services</a></li>
                                        <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Tooling</a></li>
                                        <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Factory Evaluation</a></li>
                                        <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Sourcing</a></li>
                                        <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Price Negotitation</a></li>
                                        <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Sampling and Product
                                                Development</a></li>
                                    </ul>
                                </div>
                                <div class="tab-pane fade" id="post-order" role="tabpanel" aria-labelledby="post-order-tab">
                                    <ul class="nav nav-tabs flex-column">
                                        <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Delivery Monitoring</a></li>
                                        <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Inspection</a></li>
                                        <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Quality Control</a></li>
                                        <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Consolidation of Shipment</a>
                                        </li>
                                        <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Logistics</a></li>
                                        <li class="nav-item"><a [routerLink]="['/our-services']" routerLinkActive="active" class="nav-link">Shipping Coordination</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-four section" id="ourProducts">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-9 offset-md-3">
                    <div class="section-content px-md-5">
                        <div class="section-title mb-md-4">
                            <h1 class="mb-md-0 d-md-inline-block">We Deal In</h1>
                            <span> Houseware and Food service products & have a specialized search engine with selected products from the best factories, suitable for large volume buyers focusing on $1 to $10 retail price products.</span>
                        </div>
                        <!-- <div class="section-text">
                            <p>We deal in Houseware and Food service products & have a specialized search engine with selected products from the best factories, suitable for large volume buyers focusing on $1 to $10 retail price products.</p>
                        </div> -->
                        <div class="filter-btn">
                            <a>$1</a>
                            <a>$2</a>
                            <a>$3</a>
                            <a>$5</a>
                            <a>$10</a>
                            <a>$>10</a>
                        </div>

                        <div class="section-img row ml-md-0">
                            <!-- <div class="col-6 col-md-3 mb-3 mb-md-3">
                                <div class="section-grid-wrap">
                                    <img src="assets/img/wooden-ware.png" alt="">
                                    <a href="" id="woodenWare">
                                        Woodenware
                                    </a>
                                </div>
                            </div> -->
                            <!-- <div class="col-6 col-md-3 mb-3 mb-md-3">
                                <div class="section-grid-wrap">
                                    <img src="assets/img/glassware.png" alt="">
                                    <a href="" id="glassWare">
                                        Glassware
                                    </a>
                                </div>
                            </div> -->
                            <!-- <div class="col-6 col-md-3 mb-3 mb-md-3">
                                <div class="section-grid-wrap">
                                    <img src="assets/img/metalware.png" alt="">
                                    <a href="" id="metalWare">
                                        Metalware
                                    </a>
                                </div>
                            </div> -->
                            <!-- <div class="col-6 col-md-3 mb-3 mb-md-3">
                                <div class="section-grid-wrap">
                                    <img src="assets/img/food-service.jpg" alt="">
                                    <a href="" id="food-services">
                                        Food Services
                                    </a>
                                </div>
                            </div> -->
                            <div *ngFor="let data of categoryList" class="col-6 col-md-3 mb-3 mb-md-3 h-220">
                                <div class="section-grid-wrap">
                                    <img src="https://mila.co.in/admin/images/category_image/{{data.image}}" alt=" {{data.category_name}}">
                                    <a href="" id="barware" class="btn">
                                        {{data.category_name}}
                                    </a>
                                </div>
                            </div>
                            <!-- <div class="col-6 col-md-3 mb-3 mb-md-3 h-220">
                                <div class="section-grid-wrap">
                                    <img src="assets/img/horeca.jpg" alt="">
                                    <a href="" id="hotelware" class="btn">
                                        Horeca
                                    </a>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 mb-3 mb-md-3 h-220">
                                <div class="section-grid-wrap">
                                    <img src="assets/img/kitchen-ware.jpg" alt="">
                                    <a href="" id="kitchen-ware" class="btn">
                                        Kitchenware
                                    </a>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 mb-3 mb-md-3 h-220">
                                <div class="section-grid-wrap">
                                    <img src="assets/img/table-ware.jpg" alt="">
                                    <a href="" id="table-ware" class="btn">
                                        Tableware
                                    </a>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 mb-3 mb-md-3 h-220">
                                <div class="section-grid-wrap">
                                    <img src="assets/img/flat-ware.jpg" alt="">
                                    <a href="" id="flat-ware" class="btn">
                                        Flatware
                                    </a>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 mb-3 mb-md-3 h-220">
                                <div class="section-grid-wrap">
                                    <img src="assets/img/tea-coffee.jpg" alt="">
                                    <a href="" id="tea-coffee" class="btn">
                                        Tea & Coffee
                                    </a>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 mb-3 mb-md-3 h-220">
                                <div class="section-grid-wrap">
                                    <img src="assets/img/cook-ware.jpg" alt="">
                                    <a href="" id="cookware" class="btn">
                                        Cookware
                                    </a>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 mb-3 mb-md-3 h-220">
                                <div class="section-grid-wrap">
                                    <img src="assets/img/bake-ware.jpg" alt="">
                                    <a href="" id="bakeware" class="btn">
                                        Bakeware
                                    </a>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 mb-3 mb-md-3 h-220">
                                <div class="section-grid-wrap">
                                    <img src="assets/img/storage-ware.jpg" alt="">
                                    <a href="" id="storage" class="btn">
                                        Storageware
                                    </a>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 mb-3 mb-md-3 h-220">
                                <div class="section-grid-wrap">
                                    <img src="assets/img/kitchen-textiles.jpg" alt="">
                                    <a href="" id="kitchen-textiles" class="btn">
                                        Kitchen Textiles
                                    </a>
                                </div>
                            </div> -->
                            <!-- <div class="col-6 col-md-3 mb-3 mb-md-3 h-220">
                                <div class="section-grid-wrap">
                                    <img src="assets/img/accessories.jpg" alt="">
                                    <a href="" id="accessories">
                                        Accessories
                                    </a>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-five section" id="manCapable">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 offset-md-3">
                    <div class="section-content p-3 py-md-4 px-md-3">
                        <div class="section-title mb-md-4">
                            <h1 class="mb-md-0">Manufacturing Capabilities</h1>
                        </div>
                        <div class="section-text">
                            <p>We are working with socially compliant factories having large volume production capabilities. All our partner factories have advance tools for production which results in cost-efficient products.</p>

                            <ul class="list-unstyled section-list">
                                <li>Modern Technology</li>
                                <li>Use of Automation in production.</li>
                                <li>Multiple factories to produce same products parallely for large quantity & faster delivery.
                                </li>
                                <li>Fast turnaround time.</li>
                                <li>Socially complaint & environment friendly factories.</li>
                                <li>Advance procedures for checking quality at every stage.</li>
                                <li>Hygienic packing areas.</li>
                                <li>Advance conveyor belt system for loading.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="section-img">
                        <img src="assets/img/manufacture.jpg" alt="manufacture" class="img-fluid worker-one d-none d-md-inline">
                        <img src="assets/img/workertwo.jpg" alt="manufacture" class="img-fluid worker-two d-none d-md-inline">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-six section" id="addCust">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-9 offset-md-3">
                    <div class="section-content p-3 py-md-0 px-md-5">
                        <div class="section-title">
                            <h1 class="">Customers</h1>
                        </div>
                        <div class="section-text">
                            <p>We are currently working with Importers who distribute to USA large volume buyers.</p>
                        </div>
                        <div class="section-img clearfix d-md-flex flex-md-wrap justify-content-md-center">
                            <div *ngFor="let customer of customerList" class="col-md-3 float-md-left">
                                <div class="customer-logo">
                                    <img src="https://mila.co.in/admin/images/customer_image/{{customer.image}}" alt="" class="img-fluid">
                                </div>
                            </div>
                            <!-- <div class="col-md-3 float-md-left">
                                <div class="customer-logo">
                                    <img src="assets/img/customer/Dollar tree.jpg" alt="" class="img-fluid">
                                </div>
                            </div>
                            <div class="col-md-3 float-md-left">
                                <div class="customer-logo">
                                    <img src="assets/img/customer/family dollar.jpg" alt="" class="img-fluid">
                                </div>
                            </div>
                            <div class="col-md-3 float-md-left">
                                <div class="customer-logo">
                                    <img src="assets/img/customer/five below.jpg" alt="" class="img-fluid">
                                </div>
                            </div>
                            <div class="col-md-3 float-md-left">
                                <div class="customer-logo">
                                    <img src="assets/img/customer/metro.jpg" alt="" class="img-fluid">
                                </div>
                            </div>
                            <div class="col-md-3 float-md-left">
                                <div class="customer-logo">
                                    <img src="assets/img/customer/pound land.jpg" alt="" class="img-fluid">
                                </div>
                            </div>
                            <div class="col-md-3 float-md-left">
                                <div class="customer-logo">
                                    <img src="assets/img/customer/staples.jpg" alt="" class="img-fluid">
                                </div>
                            </div>
                            <div class="col-md-3 float-md-left">
                                <div class="customer-logo">
                                    <img src="assets/img/customer/target.jpg" alt="" class="img-fluid">
                                </div>
                            </div>
                            <div class="col-md-3 float-md-left">
                                <div class="customer-logo">
                                    <img src="assets/img/customer/walmart.jpg" alt="" class="img-fluid">
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section-seven section" id="testimonial">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-9 offset-md-3">
                    <div class="section-content px-md-5">
                        <div class="section-title">
                            <h1>Testimonials</h1>
                        </div>
                        <div class="section-text">
                            <p>Our Customers feedback</p>
                        </div>
                       <div class="testimonial-wrap col-lg-12 pull-left">
                           <div class="col-md-4 pull-left float-md-left">
                                <div class="test-single card">
                                   <p class="text-test">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud"</p>
                                    <p class="reviewer">Jhon Doe | Company Name | USA</p>
                                </div>
                           </div> 
                           <!-- col-md-4 pull-left float-md-left -->
                           <div class="col-md-4 pull-left float-md-left">
                            <div class="test-single card">
                               <p class="text-test">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud"</p>
                                <p class="reviewer">Jhon Doe | Company Name | USA</p>
                            </div>
                       </div> 
                       <!-- col-md-4 pull-left float-md-left -->
                       <div class="col-md-4 pull-left float-md-left">
                        <div class="test-single card">
                           <p class="text-test">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud"</p>
                            <p class="reviewer">Jhon Doe | Company Name | USA</p>
                        </div>
                   </div> 
                   <!-- col-md-4 pull-left float-md-left -->
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-seven section" id="career">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-9 offset-md-3">
                    <div class="section-content px-md-5">
                        <div class="section-title">
                            <h1>Career With Us</h1>
                        </div>
                        <div class="section-text">
                            <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore, eveniet! Voluptatibus magnam fugiat, dicta fugit, in architecto, aliquam blanditiis culpa assumenda sed dignissimos accusamus minima vero nemo id ex cumque.</p> -->
                        </div>
                        <div class="section-sub-title">
                            <!-- [routerLink]="['/career']" routerLinkActive="active" -->
                            <a class="btn border bg-transparent mt-3">Current Job Openings</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-three section" id="contactUs">
        <div class="container-fluid pl-md-0">
            <div class="row">
                <div class="col-md-12 pr-md-0">
                    <div class="section-content clearfix">
                        <div class="col-md-7 float-md-left">
                            <div class="section-title">
                                <h3>GET IN TOUCH</h3>
                            </div>
                            <!-- <div class="section-text">
                                <p>Our Support team is available 24 x 7</p>
                            </div> -->
                            <div>
                                <form #contact="ngForm" (ngSubmit)="contact.valid && queryForm(formData)">
                                    <div class="form-group">
                                        <input type="text" class="form-control" #name="ngModel" [class.is-invalid]="name.invalid && contact.submitted" name="name" [(ngModel)]="formData.name" placeholder="Name" required="required">
                                        <div class="invalid-feedback">
                                               name is required
                                        </div>
                                        <input type="number" class="form-control" #phone="ngModel"  name="phone" [class.is-invalid]="phone.invalid && contact.submitted" [(ngModel)]="formData.phone" placeholder="Phone" required="required">
                                        <div class="invalid-feedback">
                                            mobile is required
                                     </div>
                                        <input type="email" class="form-control" #email="ngModel"  name="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required="required" [(ngModel)]="formData.email" placeholder="Email">
                                        <div *ngIf="!email?.valid && (email?.dirty || email?.touched)">
                                            <div style="position: absolute;bottom: -31px;left: 19%;width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="email.errors?.required">
                                              Email is required
                                            </div>
                                          <div style="position: absolute;bottom: -31px;left: 19%;width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;" *ngIf="email.errors?.pattern">
                                            Valid email required
                                          </div>
                                          </div>
                                        <div class="form-group">
                                            <textarea class="form-control"  #msg="ngModel" [class.is-invalid]="msg.invalid && contact.submitted"  name="msg" [(ngModel)]="formData.msg" rows="3" required="required"></textarea>
                                        </div>
                                        <div class="invalid-feedback">
                                            description is required
                                     </div>
                                        <div class="form-group">
                                            <input type="file" (change)="onFileChange($event)" class="btn-sm">
                                        </div>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </form>
                            </div>
                        </div>
                        <div class="col-md-5 float-md-left">
                            <div class="support text-center text-md-left">
                                <div class="call-supp my-3 mt-md-0 mb-md-4">
                                    <div class="call-icon d-md-inline-block">
                                        <img src="assets/img/phone-call.png" alt="" width="25" class="v-super">
                                    </div>
                                    <div class="call-text mt-2 mt-md-0 d-md-inline-block text-md-right ml-md-3">
                                        <a href="tel:+91-9810006452" class="text-white">+91-971000799</a>
                                        <br>
                                        <a href="tel:+91-11-26389160" class="text-white">+91-11-26389160</a>
                                    </div>
                                </div>
                                <div class="loc-supp my-3 mt-md-0 mb-md-4 d-flex flex-column flex-md-row">
                                    <div class="loc-icon d-md-inline-block">
                                        <img src="assets/img/pin.png" alt="" width="25">
                                    </div>
                                    <div class="loc-text mt-2 mt-md-0 d-md-inline-block ml-md-3">
                                        <p class="mb-0">T-6, 2nd Floor, Okhla Phase II,</p>
                                        <p class="mb-0">New Delhi 110020</p>
                                    </div>
                                </div>
                                <div class="mail-supp my-3 mt-md-0 mb-md-4">
                                    <div class="mail-icon d-md-inline-block">
                                        <img src="assets/img/email.png" alt="" width="25">
                                    </div>
                                    <div class="mail-text mt-2 mt-md-0 d-md-inline-block ml-md-3">
                                        <a href="" class="text-white">info@mila.in</a>
                                    </div>
                                </div>
                                <div class="social-supp">
                                    <div class="social-icon">
                                        <span>Follow Us</span>
                                        <a><img src="assets/img/facebook-circular-logo.png" alt="" width="18"></a>
                                        <a class=""><img src="assets/img/instagram.png" alt="" width="18"></a>
                                        <a><img src="assets/img/twitter.png" alt="" width="18"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>