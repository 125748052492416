<style>
    input[type="number"] {
       -moz-appearance: textfield;
       appearance: textfield;
       margin: 0;
   }
   input[type="number"]::-webkit-inner-spin-button,
   input[type="number"]::-webkit-outer-spin-button {
       -webkit-appearance: none;
       margin: 0;
   }
   .space-no-wrap{
       white-space: nowrap;
   }
   .sign-in a {
    color: #fff !important;
}
.sign-in img {
    width: 30px;
}
.sign-in ul > li {
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
}
.sign-in ul > li a {
    color: var(--white);
    margin-left: 0.25rem;
}
.search-box img {
    transform: translate(-6%, -50%);
    position: absolute;
    top: 50%;
    right: 6%;
}
.vendor-details {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../assets/img/aerial-view.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
}

a {
    color: #fff;
}

.dropdown-item {
    color: #212529 !important;
}

.r-25 {
    right: 25px;
}
.you-deal-in {
    flex-wrap: wrap;
}
.you-deal-in > div {
    flex: 0 1 380px;
}
</style>


<section class="vendor-details section" id="vendorDetails">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title mb-md-4 col-md-12  float-md-left">
                    <div class="col-md-8 float-md-left">
                        <h1 class="mb-md-0">Quality Management & Certifications</h1>
                        <h2 style="color: #fff;font-size: 20px;margin-top: 10px;">{{companyName}}</h2>
                    </div>
                    <div *ngIf="empId" class="sign-in col-md-4 float-md-left text-right">
                        <ul class="text-md-center">
                            <li *ngIf="userLogin" >
                                <a (click)="myAccount(loginStatus)"><img src="assets/img/user.png" alt="" class="w-18">{{loginName}}</a>
                            </li>
                            <li  *ngIf="!userLogin">
                                <a [routerLink]="['/login']" class="signinbtn" routerLinkActive="active">Sign In</a>
                            </li>  
                            <li><span class="separator">|</span></li>
                            <li *ngIf="!userLogin" >
                                <a class="signupbtn" [routerLink]="['/signup']" routerLinkActive="active">Join Us</a>
                            </li>
                            <li *ngIf="userLogin">
                                <a (click)="logout()" href=""><img src="assets/img/logout.png" alt="" class="w-18">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div><!-- row -->
        <div class="row">
            <div class="col-12">
                <div class="step-menu-wrapper">
                    <ul class="d-flex">
                        <li class="flex-fill"><a [routerLink]="['/step-one']" routerLinkActive="active">Basic Information</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-two']" routerLinkActive="active">Company Profile</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-three']" routerLinkActive="active">Commercial</a></li>
                        <li class="flex-fill"><a class="active">Quality Management & Certifications</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-five']" routerLinkActive="active" >Staffing</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-six']" routerLinkActive="active" >Legal & Financial</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-seven']" routerLinkActive="active" >Social Compliance</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-eight']" routerLinkActive="active" >Infrastructure</a></li>
                    </ul>
                </div>
            </div>
        </div>
<div class="" id="qm-cer" role="tabpanel" aria-labelledby="qm-tab">
    <div class="section-content bg-white p-md-3 h-auto clearfix">
        <div class="section-title mb-md-4">
            <!-- <h1 class="mb-md-0">Vendor Registration</h1> -->
        </div>
        <div class="section-form">
            <form #qualityForm="ngForm" class="row" (ngSubmit)="qualityForm.valid && qualityCertification(qcData)">
                <div class="form-group col-md-6 float-md-left">
                    ISO-9001 <br /> (SYSTEM & PROCEDURE / QUALITY MANAGEMENT)
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check-inline">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" value="yes" (change)="onCheckIso($event)" name="optradiofive" />Yes </label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" value="no" (change)="onCheckIso($event)"  name="optradiofive" />No </label>
                        <label class="col-md-2 float-md-left form-check-label">
                       <input (change)="onFileUpload($event,'iso_9001')" type="file" name="iso_9001" class="form-check-input btn-sm">
                       <!-- <img [src]="imageSrc"><span><a *ngIf="imageSrc" (click)="deleteImage()" class="btn btn-danger">Delete</a></span> -->
                        </label>
                    </div>
                </div>

                <div class="col-md-12 float-md-left"></div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">
                        ISO-14001 <br />
                        (ENVIRONMENTAL MANAGEMENT)
                    </label>
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check-inline">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiosix" />Yes </label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiosix" />No </label>
                        <label class="col-md-2 float-md-left form-check-label">
                            <input (change)="onFileUpload($event,'iso14001')" type="file" class="form-check-input btn-sm" />
                        </label>
                    </div>
                </div>

                <div class="col-md-12 float-md-left"></div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">
                        AN OHSAS-18001 <br />
                        (HEALTH AND SAFETY MANAGEMENT)
                    </label>
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check-inline">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioseven" />Yes </label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioseven" />No </label>
                        <label class="col-md-2 float-md-left form-check-label">
                            <input (change)="onFileUpload($event,'ohsas')" type="file" class="form-check-input btn-sm" />
                        </label>
                    </div>
                </div>

                <div class="col-md-12 float-md-left"></div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">
                        SA-8000 <br />
                        (SOCIAL ACCOUNTABILITY MANAGEMENT)
                    </label>
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check-inline">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioeight" />Yes </label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioeight" />No </label>
                        <label class="col-md-2 float-md-left form-check-label">
                            <input (change)="onFileUpload($event,'sa_8000')" type="file" class="form-check-input btn-sm" />
                        </label>
                    </div>
                </div>

                <div class="col-md-12 float-md-left"></div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">FAIR TRADE CERTIFICATE</label>
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check-inline">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradionine" />Yes </label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradionine" />No </label>
                        <label class="col-md-2 float-md-left form-check-label">
                            <input (change)="onFileUpload($event,'ftc')" type="file" class="form-check-input btn-sm" />
                        </label>
                    </div>
                </div>

                <div class="col-md-12 float-md-left"></div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">BRC STANDARD</label>
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check-inline">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioten" />Yes </label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioten" />No </label>
                        <label class="col-md-2 float-md-left form-check-label">
                            <input (change)="onFileUpload($event,'brc')" type="file" class="form-check-input btn-sm" />
                        </label>
                    </div>
                </div>

                <div class="col-md-12 float-md-left"></div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">WRAP</label>
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check-inline">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioelev" />Yes </label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioelev" />No </label>
                        <label class="col-md-2 float-md-left form-check-label">
                            <input (change)="onFileUpload($event,'wrap')" type="file" class="form-check-input btn-sm" />
                        </label>
                    </div>
                </div>

                <div class="col-md-12 float-md-left"></div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">CE</label>
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check-inline">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiotwel" />Yes </label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiotwel" />No </label>
                        <label class="col-md-2 float-md-left form-check-label">
                            <input (change)="onFileUpload($event,'ce')" type="file" class="form-check-input btn-sm" />
                        </label>
                    </div>
                </div>

                <div class="col-md-12 float-md-left"></div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">UL</label>
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check-inline">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiothirt" />Yes </label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiothirt" />No </label>
                        <label class="col-md-2 float-md-left form-check-label">
                            <input (change)="onFileUpload($event,'ul')" type="file" class="form-check-input btn-sm" />
                        </label>
                    </div>
                </div>

                <div class="col-md-12 float-md-left"></div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">ROHS</label>
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check-inline">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiofourt" />Yes </label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiofourt" />No </label>
                        <label class="col-md-2 float-md-left form-check-label">
                            <input (change)="onFileUpload($event,'rohs')" type="file" class="form-check-input btn-sm" />
                        </label>
                    </div>
                </div>

                <div class="col-md-12 float-md-left"></div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">ANY ADDITIONAL CERTIFICATION</label>
                </div>
                <div class="form-group col-md-5 float-md-left">
                    <div class="form-check">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="yes" class="form-check-input" name="optradio-add-cer" />Yes</label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="no" class="form-check-input" name="optradio-add-cer" />No</label>
                    </div>
                </div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">ANY ADDITIONAL CERTIFICATION [IF YES]</label>
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check-inline">
                        <label class="col-md-2 float-md-left form-check-label">
                            <input (change)="onFileUpload($event,'add_c')" type="file" class="form-check-input btn-sm" />
                        </label>
                    </div>
                </div>

                <div class="col-md-12 float-md-left"></div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">ANY SOFTWARE FOR DOCUMENTATION & MARKETING</label>
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.anysoftware" class="form-check-input" name="optradiofif" />Yes </label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.anysoftware" class="form-check-input" name="optradiofif" />No </label>

                        <!-- <label class="col-md-2 float-md-left form-check-label">
                            <input type="file" class="form-check-input btn-sm">
                        </label> -->
                    </div>
                </div>

                <div class="col-md-12 float-md-left"></div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">ANY ERP SYSTEM</label>
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.anyerp" class="form-check-input" name="optradiosixt" />Yes </label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.anyerp" class="form-check-input" name="optradiosixt" />No </label>

                        <!-- <label class="col-md-2 float-md-left form-check-label">
                            <input type="file" class="form-check-input btn-sm">
                        </label> -->
                    </div>
                </div>

                <div class="col-md-12 float-md-left"></div>

                <div class="form-group col-md-6 float-md-left">
                    <label for=""><strong>
                        DO YOU MAINTAIN AN INTERNAL QUALITY MANAGEMENT SYSTEM:
                    </strong></label>
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.iqms" class="form-check-input" name="optradiosevnt" />Yes </label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.iqms" class="form-check-input" name="optradiosevnt" />No </label>

                        <!-- <label class="col-md-2 float-md-left form-check-label">
                            <input type="file" class="form-check-input btn-sm">
                        </label> -->
                    </div>
                </div>

                <div class="col-md-12 float-md-left">
                    <h4 class="mt-md-3">QUALITY CONTROL PROCEDURES AND RECORD :</h4>
                </div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">
                        DO YOU HAVE QUALITY MAINTAINING/INSPECTION TOOLS TO MEET THE REQUIRED QUALITY STANDARD
                    </label>
                </div>
                <div class="form-group col-md-6 float-md-left">
                    <div class="form-check">
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.inspectiontool" class="form-check-input" name="optradioeighteen" />Yes </label>
                        <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.inspectiontool" class="form-check-input" name="optradioeighteen" />No </label>
                    </div>
                </div>

                <div class="form-group col-md-6 float-md-left">
                    <label for="">
                        Give Details if yes
                    </label>
                    <input type="text" name="detail" [(ngModel)]="qcData.detail" class="form-control" #detail="ngModel" [class.is-invalid]="detail.invalid && qualityForm.submitted" required="required">
                    <div class="invalid-feedback">
                        detail is required.
                      </div>
                </div>

                <div class="form-group col-md-4 float-md-left">
                    <label for="">
                        DO YOU KEEP DOCUMENTARY RECORD OF INTERNAL INSPECTION DONE OF PRODUCTS
                    </label>
                </div>
                <div class="form-group col-md-2 float-md-left">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.docrecord" class="form-check-input" name="optradioninteen" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.docrecord" class="form-check-input" name="optradioninteen" />No </label>
                    </div>
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        AT WHAT STAGE IS QUALITY CHECKED:
                    </label>
                </div>
                <div class="form-group col-md-8">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="checkbox" (change)="qcCheck($event)" value="RAW MATERIAL IN HOUSE" class="form-check-input"/>RAW MATERIAL IN HOUSE</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="checkbox" (change)="qcCheck($event)" value="HANDWORK DONE" class="form-check-input"/>HANDWORK DONE</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="checkbox" (change)="qcCheck($event)" value="ASSEMBLY COMPLETE" class="form-check-input"/>ASSEMBLY COMPLETE</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="checkbox" (change)="qcCheck($event)" value="AFTER FINISHING" class="form-check-input"/>AFTER FINISHING</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="checkbox" (change)="qcCheck($event)" value="PACKING & LABELLING CHECK" class="form-check-input"/>PACKING & LABELLING CHECK</label>
                    </div>
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        ARE QA/QC INSPECTORS INDEPENDENT OF PRODUCTION?
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.inspectorindependent" class="form-check-input" name="optradiotwnty" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.inspectorindependent" class="form-check-input" name="optradiotwnty" />No </label>
                    </div>
                </div>

                <div class="col-md-6">
                    <label for="">HOW MANY QA/QC IN TOTAL?</label>
                    <input type="text" name="qaTotal" [(ngModel)]="qcData.qaTotal" class="form-control d-inline-block w-25 ml-3" #qatotal="ngModel" [class.is-invalid]="qatotal.invalid && qualityForm.submitted" required="required">
                    <div class="invalid-feedback">
                        qa number is required.
                      </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group d-flex">
                        <label for="" class="flex-fill">WHAT DOES THE QA/QC MANAGER/SUPERVISOR REPORT TO?</label>
                        <input type="text" name="qaReport" [(ngModel)]="qcData.qaReport" class="form-control col-7" #qareport="ngModel" [class.is-invalid]="qareport.invalid && qualityForm.submitted" required="required">
                        <div class="invalid-feedback">
                            Valid number is required.
                          </div>
                    </div>
                </div>
                
                <div class="form-group col-md-4">
                    <label for="">
                        DO YOU TEST FUNCTIONALITY OF PRODUCT?
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.functest" class="form-check-input" name="optradiotwntyone" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.functest" class="form-check-input" name="optradiotwntyone" />No </label>
                    </div>
                </div>

                <div class="form-group col-md-2">
                    <label for="" class="space-no-wrap">
                        TYPES OF FINAL INSPECTION
                    </label>
                </div>
                <div class="form-group col-md-4">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="100% INSPECTION" [(ngModel)]="qcData.typeofinspection" class="form-check-input" name="optradiotwntytwo" />100% INSPECTION </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="RANDOM INSPECTION" [(ngModel)]="qcData.typeofinspection" class="form-check-input" name="optradiotwntytwo" />RANDOM INSPECTION </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-md-3">
                        STAFF TRAINING & EVALUATION
                    </h4>
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        DO YOU HAVE ANY STAFF EVALUATION PROCESS?
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.staffEvaluation" class="form-check-input" name="optradiotwntythree" />Yes</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.staffEvaluation"class="form-check-input" name="optradiotwntythree" />No</label>
                    </div>
                </div>
                <div class="w-100"></div>
                <div class="form-group col-md-6">
                    <div class="form-group">
                        <label for="">
                            WHAT ARE YOUR EVALUATION PARAMETERS?
                        </label>
                        <input type="text" class="form-control" name="evaluation_param" [ngModel]="qcData.evaluation_param" placeholder="Give Details" #eparam="ngModel" [class.is-invalid]="eparam.invalid && qualityForm.submitted" required="required">
                    </div>
                </div>
                <div class="w-100"></div>
                <div class="form-group col-md-4">
                    <label for="">
                        DO YOU GIVE ANY PERIODIC TRAINING TO THE STAFF?
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.anyperiodictraining" class="form-check-input" name="optradiotwntyfour" />Yes</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.anyperiodictraining" class="form-check-input" name="optradiotwntyfour" />No</label>
                    </div>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-md-3">
                        DOCUMENTATION RECORD
                    </h4>
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        DO YOU VERIFY ALL DATA IN INVOICE / P.LIST /WT.VOL SHEET AS PER ORDER?   
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.verifyinvoice" class="form-check-input" name="optradiotwntyfive" />Yes</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.verifyinvoice" class="form-check-input" name="optradiotwntyfive" />No</label>
                    </div>
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        DO YOU NOTIFY BUYER ON SHIPMENT BEFORE DISPATCH? 	
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.notifybeforedispatch" class="form-check-input" name="optradiotwntysix" />Yes</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.notifybeforedispatch" class="form-check-input" name="optradiotwntysix" />No</label>
                    </div>
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        DO YOU NOTIFY BUYER ON SHIPMENT DISPATCH BEFORE SAILING DISPATCH?	
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.notifybeforesailing" class="form-check-input" name="optradiotwntyseven" />Yes</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.notifybeforesailing" class="form-check-input" name="optradiotwntyseven" />No</label>
                    </div>
                </div>
                <div class="w-100"></div>
                <div class="form-group col-md-10">
                    <label for="">
                        HOW LONG DO YOU TAKE TO SEND ORIGINAL BL/GSP AND ORIGINAL SHIPPING DOCUMENTS AFTER SAILING ?(no. of days)                  	
                    </label>
                    <!-- <input type="text" name="" class="form-control" placeholder=""> -->
                </div>

                <div class="form-group col-md-6">
                    <div class="d-flex">
                        <label for="" class="flex-fill">
                            FOR HOW LONG DO YOU MAINTAIN THE SHIPMENT RECORD?                    	
                        </label>
                        <input type="text" name="noofyear" class="form-control col-3 col-md-3" [(ngModel)]="qcData.noofyear" placeholder="" #noofyear="ngModel" [class.is-invalid]="noofyear.invalid && qualityForm.submitted" required="required">
                    </div>
                </div>

                <div class="form-group col-md-6">
                    <div class="d-flex">
                        <label for="" class="flex-fill">
                            WHAT OTHER RECORDS DO YOU MAINTAIN?                    	
                        </label>
                        <input type="text" name="other_record_maintain" [(ngModel)]="qcData.other_record_maintain" class="form-control col-6" placeholder="" #ormaintain="ngModel" [class.is-invalid]="ormaintain.invalid && qualityForm.submitted" required="required">
                    </div>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-md-3">
                        SAMPLE RECORD
                    </h4>
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        DO YOU MAINTAIN ANY RECORD OF CUSTOMER SAMPLING 
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio"  class="form-check-input" value="yes" [(ngModel)]="qcData.maintainrecord" name="optradiotwntyeht" />Yes</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" class="form-check-input" value="no" [(ngModel)]="qcData.maintainrecord" name="optradiotwntyeht" />No</label>
                    </div>
                </div>

                <div class="form-group col-md-6">
                    <label for="">
                        HOW DO YOU MAINTAIN THE SAMPLE RECORD?                  
                    </label>
                    <input type="text" name="howmaintain" class="form-control" [(ngModel)]="qcData.howmaintain" placeholder="Give Details" #howmaintain="ngModel" [class.is-invalid]="howmaintain.invalid && qualityForm.submitted" required="required">
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        DO YOU RETAIN A PHYSICAL COUNTER SAMPLE OF SAMPLE SENT TO BUYER? 
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.samplesentbuyer" class="form-check-input" name="optradiotwntynne" />Yes</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.samplesentbuyer" class="form-check-input" name="optradiotwntynne" />No</label>
                    </div>
                </div>

                <div class="form-group col-md-6">
                    <label for="">
                        HOW LONG DO YOU MAINTAIN THE SAMPLE RECORD?                  
                    </label>
                    <input type="text" name="howlong" class="form-control" [(ngModel)]="qcData.howlong" placeholder="No. of Years" #howlong="ngModel" [class.is-invalid]="howlong.invalid && qualityForm.submitted" required="required">
                </div>

                <div class="col-md-12">
                    <h4 class="mt-md-3">
                        CUSTOMER COMPLAINTS
                    </h4>
                </div>

                <div class="form-group col-md-6">
                    <label for="">
                        HOW DO YOU MANAGE CUSTOMER COMPLAINTS?                  
                    </label>
                    <input type="text" name="custcomplain" class="form-control" [(ngModel)]="qcData.custcomplain" placeholder="Give Details">
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        DO YOU MAINTAIN RECORD FOR COMPLAINTS?
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.maintainrecordofcomplain" class="form-check-input" name="optradiothrty" />Yes</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.maintainrecordofcomplain" class="form-check-input" name="optradiothrty" />No</label>
                    </div>
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        DO YOU HONOR CLAIMS?       
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.honorclaim" class="form-check-input" name="optradiothrtyone" />Yes</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.honorclaim" class="form-check-input" name="optradiothrtyone" />No</label>
                    </div>
                </div>

                <div class="form-group col-md-1">
                    <label for="">
                        IF YES       
                    </label>
                </div>
                <div class="form-group col-md-5">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="DO YOU REFUND MONEY IN CASE OF CLAIM" [(ngModel)]="qcData.ifyes" class="form-check-input" name="optradiothrtytwo" />DO YOU REFUND MONEY IN CASE OF CLAIM</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="OR ONLY ADJUST/REPLACE IN THE NEXT SHIPMENT" [(ngModel)]="qcData.ifyes" class="form-check-input" name="optradiothrtytwo" />OR    ONLY ADJUST/REPLACE IN THE NEXT SHIPMENT </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-md-3">DELIVERY MONITORING & CONTROL TOOLS </h4>
                </div>

                <div class="form-group col-md-6">
                    <label for="">
                        DO YOU HAVE ANY DOCUMENT FOR MONITORING DELIVERY?
                    </label>
                    <input type="text" name="anydocfordelivery" [(ngModel)]="qcData.anydocfordelivery" class="form-control" placeholder="Give Details">
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        DO YOU HAVE ANY TOOL FOR INTERNAL TASK MANAGEMENT     
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.toolfortaskmgmt"  class="form-check-input" name="optradiothrtythr" />Yes</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.toolfortaskmgmt" class="form-check-input" name="optradiothrtythr" />No</label>
                    </div>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-md-3">SECURITY CONTROL</h4>
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        DO YOU HAVE ANY SYSTEM FOR CONTROL ON INCOMING AND OUT GOING MATERIAL?     
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.controlonincomingandoutgoing" class="form-check-input" name="optradiothrtyfour" />Yes</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.controlonincomingandoutgoing" class="form-check-input" name="optradiothrtyfour" />No</label>
                    </div>
                </div>

                <div class="form-group col-md-4">
                    <label for="">
                        DO YOU HAVE ANY PROCEDURE FOR REPORTING AND DETECTING SHORTAGES/PILFERAGES?  
                    </label>
                </div>
                <div class="form-group col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.reportingshortages" class="form-check-input" name="optradiothrtyfve" />Yes</label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.reportingshortages" class="form-check-input" name="optradiothrtyfve" />No</label>
                    </div>
                </div>

                <div class="w-100" style="margin-bottom: 32px;"></div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4 text-center mx-auto">
                            <button [routerLink]="['/step-three']" routerLinkActive="active" class="btn btn-secondary d-md-block mx-auto"><i class="fa fa-angle-left"></i> Previous</button>
                        </div>
                        <div class="col-md-4 text-center mx-auto">
                            <button type="submit" class="btn btn-info d-md-block mx-auto">Submit</button>
                        </div>
                        <div class="col-md-4 text-center mx-auto">
                            <button [routerLink]="['/step-five']" routerLinkActive="active" class="btn btn-primary d-md-block mx-auto">Next <i class="fa fa-angle-right"></i></button>
                        </div>
                    </div>
                </div>
                <!-- <button type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button> -->
            </form>
        </div>
    </div>
</div>
</div>
</section>