<style>
    .product-entry {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../assets/img/aerial-view.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: auto;
    }
    .section-form {
        background: #fff;
        display: inline-block;
    }
    .img-preview {
        width: 100px;
        float: left;
        height: 190px;
        margin-right: 5px;
    }
    .img-preview button.btn-sm {
        position: absolute;
        bottom: 35px;
        margin-left: -65px;
    }
</style>

<section class="product-entry section" id="productEntry">
    <div class="container-fluid">
        <div class="row">
            <div class="section-title mb-md-4 col-md-9 float-md-left">
                <h1 class="mb-md-0">Product Entry</h1>
            </div>
            <div class="mb-md-4 col-md-3 float-md-left">
                <div class="section-content">
                    <div *ngIf="empId" class="sign-in">
                        <ul class="d-flex justify-content-around text-white">
                            <li *ngIf="userLogin">
                                <a (click)="myAccount(loginStatus)" class="text-white"><img src="assets/img/user.png" alt="" class="w-18" /> {{loginName}}</a>
                            </li>
                            <li *ngIf="!userLogin">
                                <a [routerLink]="['/login']" class="signinbtn text-white" routerLinkActive="active">Sign In</a>
                            </li>
                            <li><span class="separator">|</span></li>
                            <li *ngIf="!userLogin">
                                <a class="signupbtn text-white" [routerLink]="['/signup']" routerLinkActive="active">Join Us</a>
                            </li>
                            <li *ngIf="userLogin">
                                <a (click)="logout()" href="" class="text-white"><img src="assets/img/logout.png" alt="" class="w-18" />Logout</a>
                            </li>
                        </ul>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
                <div class="col-12">
                    <div class="section-form">
                        <form #prdForm ="ngForm" (ngSubmit)="prdForm.valid && productForm(prdData)">
                            <div class="row p-md-3">
                                <div *ngIf="loginStatus == 3 || loginStatus == 4" class="col-md-3">
                                    <div class="form-group">
                                        <label for="">Vendor Name</label>
                                        <select class="form-control" #venname="ngModel" name="vendor_id" [class.is-invalid]="venname.invalid && prdForm.submitted" [(ngModel)]="prdData.vendor_id" required="">
                                            <!-- <option [ngValue]="null" disabled>Select vendor</option> -->
                                            <option *ngFor="let ven of venderList" value="{{ven.id}}">{{ven.company_name}}</option>
                                        </select>
                                        <div class="invalid-feedback">
                                            Vendor Selection is required.
                                          </div>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="">Item #</label>
                                        <input type="text" #item="ngModel" [class.is-invalid]="item.invalid && prdForm.submitted" class="form-control" name="item_name" [(ngModel)]="prdData.item_name" id="" placeholder="Item #" required=""/>
                                        <div class="invalid-feedback">
                                            Item Name is required.
                                          </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="">Industry</label>
                                    <select [(ngModel)]="prdData.industry" name="industry" (change)="getCatByIndustry($event)" class="custom-select" #ind="ngModel" [class.is-invalid]="ind.invalid && prdForm.submitted" required="">
                                        <!-- <option selected>Industry</option> -->
                                        <option *ngFor="let ind of industry" value="{{ind.id}}">{{ind.industry_name}}</option>
                                        <!-- <option value="2">2</option>
                                        <option value="3">3</option> -->
                                    </select>
                                    <div class="invalid-feedback">
                                        Industry Selection is required.
                                      </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="">Category</label>
                                    <select [(ngModel)]="prdData.category" (change)="getSubCatByCategory($event)" name="category" class="custom-select" #cat="ngModel" [class.is-invalid]="cat.invalid && prdForm.submitted" required="">
                                        <!-- <option value="" selected>Category</option> -->
                                        <option *ngFor="let cat of categoryList" value="{{cat.id}}">{{cat.category_name}}</option>
                                        <!-- <option value="2">2</option>
                                        <option value="3">3</option> -->
                                    </select>
                                    <div class="invalid-feedback">
                                        Category Selection is required.
                                      </div>
                                </div>

                                <div class="form-group col-md-3">
                                    <label for="">Sub Category</label>
                                    <select [(ngModel)]="prdData.sub_category" (change)="getProdBySubCategory($event)" name="sub_category" class="custom-select" #sub="ngModel" [class.is-invalid]="sub.invalid && prdForm.submitted" required="">
                                        <!-- <option value="" selected>Category</option> -->
                                        <option *ngFor="let sub of subCategoryList" value="{{sub.id}}">{{sub.subcategory_name}}</option>
                                        <!-- <option value="2">2</option>
                                        <option value="3">3</option> -->
                                    </select>
                                    <div class="invalid-feedback">
                                        sub category Selection is required.
                                      </div>
                                </div>

                                <div class="form-group col-md-3">
                                    <label for="">Product Name</label>
                                    <select [(ngModel)]="prdData.product" name="product" class="custom-select"
                                    #prdt="ngModel" [class.is-invalid]="prdt.invalid && prdForm.submitted" required=""
                                    >
                                        <option *ngFor="let pro of product" value="{{pro.id}}">{{pro.product_name}}</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        Product Selection is required.
                                      </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="">Description</label>
                                    <textarea [(ngModel)]="prdData.product_desc" name="product_desc" class="form-control" rows="4" id="" placeholder="Description" #desc="ngModel" [class.is-invalid]="desc.invalid && prdForm.submitted" required="" ></textarea>
                                    <div class="invalid-feedback">
                                       Description is required.
                                      </div>
                                </div>
                                
                                <div class="w-100"></div>
                                <div class="form-group col-md-1">
                                    <label for="">
                                        Dimension <br />
                                        (Inches)
                                    </label>
                                </div>
                                <div class="form-group col-md-5">
                                    <div class="form-group col-md-6 float-md-left">
                                        <input type="number" [(ngModel)]="prdData.length_in_inch" (change)="convertLenToCm($event); negativeAlert($event)" name="length_in_inch" class="form-control" placeholder="Length" #lencm="ngModel" [class.is-invalid]="lencm.invalid && prdForm.submitted" required=""/>
                                        <div class="invalid-feedback">
                                            length is required.
                                           </div>
                                    </div>

                                    <div class="form-group col-md-6 float-md-left">
                                        <input type="number" [(ngModel)]="prdData.width_in_inch" (change)="convertWidToCm($event); negativeAlert($event)" name="width_in_inch" class="form-control" placeholder="Width" 
                                        #widcm="ngModel" [class.is-invalid]="widcm.invalid && prdForm.submitted" required=""
                                        />
                                        <div class="invalid-feedback">
                                            Width is required.
                                           </div>
                                    </div>

                                    <div class="form-group col-md-6 float-md-left">
                                        <input type="number" [(ngModel)]="prdData.height_in_inch" (change)="convertHeiToCm($event); negativeAlert($event)" name="heightinInch" class="form-control" placeholder="Height" 
                                        #heicm="ngModel" [class.is-invalid]="heicm.invalid && prdForm.submitted" required=""
                                        />
                                        <div class="invalid-feedback">
                                            Height is required.
                                           </div>
                                    </div>

                                    <div class="form-group col-md-6 float-md-left">
                                        <input type="number" [(ngModel)]="prdData.diameter_in_inch" (change)="convertDiaToCm($event); negativeAlert($event)" name="diameter_in_inch" class="form-control" placeholder="Diameter" 
                                        #diacm="ngModel" [class.is-invalid]="diacm.invalid && prdForm.submitted" required=""
                                        />
                                        <div class="invalid-feedback">
                                            Diameter is required.
                                           </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-1">
                                    <label for="">
                                        Dimension <br />
                                        (cms)
                                    </label>
                                </div>
                                <div class="form-group col-md-5">
                                    <div class="form-group col-md-6 float-md-left">
                                        <input type="number" class="form-control" (change)="convertLenToInch($event); negativeAlert($event)" [(ngModel)]="prdData.length_in_cm" name="length_in_cm" placeholder="Length" 
                                        #leninch="ngModel" [class.is-invalid]="leninch.invalid && prdForm.submitted" required=""
                                        />
                                        <div class="invalid-feedback">
                                            length is required.
                                           </div>
                                    </div>

                                    <div class="form-group col-md-6 float-md-left">
                                        <input type="number" class="form-control" (change)="convertWidToInch($event); negativeAlert($event)" [(ngModel)]="prdData.width_in_cm" name="width_in_cm" placeholder="Width" 
                                        #widinch="ngModel" [class.is-invalid]="widinch.invalid && prdForm.submitted" required=""
                                        />
                                        <div class="invalid-feedback">
                                            Width is required.
                                           </div>
                                    </div>

                                    <div class="form-group col-md-6 float-md-left">
                                        <input type="number" class="form-control" (change)="convertHeiToInch($event); negativeAlert($event)" [(ngModel)]="prdData.height_in_cm" name="height_in_cm" placeholder="Height" 
                                        #heiinch="ngModel" [class.is-invalid]="heiinch.invalid && prdForm.submitted" required=""
                                        />
                                        <div class="invalid-feedback">
                                            Height is required.
                                           </div>
                                    </div>

                                    <div class="form-group col-md-6 float-md-left">
                                        <input type="number" class="form-control" (change)="convertDiaToInch($event); negativeAlert($event)" [(ngModel)]="prdData.diameter_in_cm" name="diameter_in_cm" placeholder="Diameter" 
                                        #diainch="ngModel" [class.is-invalid]="diainch.invalid && prdForm.submitted" required=""
                                        />
                                        <div class="invalid-feedback">
                                            Diameter is required.
                                           </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-1">
                                    <label for="">
                                        Weight 
                                        
                                    </label>
                                </div>
                                <div class="form-group col-md-5">
                                <div class="form-group col-md-6 float-md-left" style="padding: 0 14px;">
                                    <input type="number" [(ngModel)]="prdData.weight_in_kg" class="form-control" (change)="convertToLbs($event); negativeAlert($event)" name="weightinKg" placeholder="kgs" 
                                    #weikg="ngModel" [class.is-invalid]="weikg.invalid && prdForm.submitted" required=""
                                    />
                                    <div class="invalid-feedback">
                                        Weight is required.
                                       </div>
                                </div>

                                <!-- <div class="form-group col-md-1 number-md-center">
                                    <label for="">lbs</label>
                                </div> -->
                                <div class="form-group col-md-6 float-md-left" style="padding: 0 15px;">
                                    <input type="number" [(ngModel)]="prdData.weight_in_lbs" class="form-control" (change)="convertToKg($event); negativeAlert($event)" name="weightinlbs" placeholder="lbs" 
                                    #weilbs="ngModel" [class.is-invalid]="weilbs.invalid && prdForm.submitted" required=""
                                    />
                                    <div class="invalid-feedback">
                                        Weight is required.
                                       </div>
                                </div>
                            </div>
                                <div class="form-group col-md-12">
                                    <div class="form-group col-md-1 p-0 float-md-left">
                                        <label for="">
                                            Material
                                        </label>
                                    </div>
                                    <div class="form-group col-md-11 p-0 float-md-left">
                                        <div class="form-group">
                                            <div *ngFor="let mat of material" class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="checkbox" value="{{mat.id}}" [(ngModel)]="mat.selected"  [checked]="checkMaterial(mat.id)" class="form-check-input" name="optradio{{mat.id}}" 
                                                    />
                                                    {{mat.material_name}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <div class="form-group col-md-2 p-0 float-md-left">
                                        <label for="">
                                            Usage
                                        </label>
                                  
                                    </div>
                                    <div class="form-group col-md-10 float-md-left" style="padding-left: 40px;">
                                        <div class="form-group">
                                            <div class="form-check-inline" *ngFor="let usa of usage" >
                                                <label class="form-check-label">
                                                    <input type="radio" value="{{usa.id}}" [(ngModel)]="prdData.prdusage" class="form-check-input" name="optradiotwo" />
                                                    {{usa.usage_name}}
                                                </label>
                                            </div>
                                          
                                        
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <div class="form-group col-md-6 float-md-left">
                                        <label for="">
                                            Feature : Eco Friendly
                                        </label>
                                    </div>
                                    <div class="form-group col-md-6 float-md-left">
                                        <div class="form-group">
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" value="Yes" [(ngModel)]="prdData.ecoFriendly" class="form-check-input" name="optradiofeat" />
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" value="No" [(ngModel)]="prdData.ecoFriendly" class="form-check-input" name="optradiofeat" />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <div class="form-group col-md-4 float-md-left">
                                        <label for="">
                                            Design
                                        </label>
                                    </div>
                                    <div class="form-group col-md-8 float-md-left">
                                        <div class="form-group">
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" class="form-check-input" value="General" name="general" [(ngModel)]="prdData.design" name="optradiodesig" />
                                                    General
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" name="general" value="Exclusive" [(ngModel)]="prdData.design" class="form-check-input" name="optradiodesig" />
                                                    Exclusive
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="">Finish</label>
                                    <select [(ngModel)]="prdData.finish" name="finish" class="custom-select">
                                        <!-- <option selected>Industry</option> -->
                                        <option *ngFor="let fin of finish" value="{{fin.id}}">{{fin.finish_name}}</option>
                                        <!-- <option value="2">2</option>
                                        <option value="3">3</option> -->
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="">Shape</label>
                                    <select [(ngModel)]="prdData.shape" name="shape" class="custom-select">
                                        <!-- <option selected>shape</option> -->
                                        <option *ngFor="let sha of shape" value="{{sha.id}}">{{sha.shape_name}}</option>
                                        <!-- <option value="2">2</option>
                                        <option value="3">3</option> -->
                                    </select>
                                </div>
                                <!-- <div class="form-group col-md-3">
                                    <label for="">Type</label>
                                    <select name="" id="" class="custom-select">
                                        <option value="">Type</option>
                                    </select>
                                </div> -->
                                <div class="form-group col-md-3">
                                    <label for="">Coating</label>
                                    <select [(ngModel)]="prdData.coating" name="coating" class="custom-select">
                                        <!-- <option selected>coating</option> -->
                                        <option *ngFor="let coa of coating" value="{{coa.id}}">{{coa.coating_name}}</option>
                                        <!-- <option value="2">2</option>
                                        <option value="3">3</option> -->
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <!-- <select name="color" [(ngModel)]="prdData.color" class="custom-select"> -->
                                    <!-- <option value="" selected>Color</option> -->
                                    <!-- <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                    </select> -->
                                    <label for="">Color</label>
                                    <select [(ngModel)]="prdData.color" name="color" class="custom-select">
                                        <!-- <option selected>color</option> -->
                                        <option *ngFor="let col of color" value="{{col.id}}">{{col.color_name}}</option>
                                        <!-- <option value="2">2</option>
                                        <option value="3">3</option> -->
                                    </select>
                                </div>
                                
                                <!-- <select (change)="addRow($event)" class="custom-select">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    </select> -->
                                <div class="w-100"></div>
                                <style>
                                    .btn-remove{

                                    }
                                    .mb-2{

                                    }
                                    
                                </style>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="form-group float-left">
                                            <label for="">Browse Image  <a class="btn btn-primary text-white" (click)="addRow(counterFlag+1)" style="position: absolute;right: 50%;padding: 5px 15px;font-size: 12px;">Add More</a></label>
                                            <input type="file" id="row" (change)="onFileChange($event)" class="form-control mb-2"
                                            />
                                            <div #div class=""></div>
                                        </div>

                                        
                                        <div class="preview-wrapper col-md-12 float-left">
                                            <div class="img-preview" *ngFor="let url of productPhotosArr">
                                                <img [src]="url.shw" width="100px" />
                                                <button type="button" class="btn btn-danger btn-sm" (click)="removeImage(url.shw)">
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="preview-wrapper col-md-12" *ngIf="loginStatus == 4 || loginStatus == 3">
                                        <div class="img-preview" *ngFor="let img of imageList">
                                            <img src="https://onlinesoftware360.com/mila/admin/images/product_image/{{img.image}}" width="100px" />
                                            <a class="btn btn-danger" (click)="deleteImageById(img.id)">Delete</a>  
                                        </div>
                                            
                                    </div>
                                </div>
                                <!-- <div class="col-1 align-self-center">
                                   
                                </div> -->
                                <div class="form-group col-md-3">
                                    <label for="">Style</label>
                                    <select [(ngModel)]="prdData.style" name="style" class="custom-select">
                                        <!-- <option selected>Industry</option> -->
                                        <option *ngFor="let sty of style" value="{{sty.id}}">{{sty.style_name}}</option>
                                        <!-- <option value="2">2</option>
                                        <option value="3">3</option> -->
                                    </select>
                                </div>
                                 <div class="form-group col-md-3">
                                    <div class="form-group">
                                        <label for="">Carton Packing</label>
                                        <input type="number" name="carton_packing" [(ngModel)]="prdData.carton_packing" (change)="negativeAlert($event)" placeholder="Carton Packing" class="form-control" />
                                    </div>
                                </div>
                               
                                <div class="form-group col-md-9 p-0">
                                    <div class="form-group">
                                        <div class="col-md-2 float-md-left">
                                            <label for="">Carton Size (cms)</label>
                                        </div>
                                        <div class="col-md-10 float-md-left">
                                            <div class="col-md-4 form-group float-md-left">
                                                <input type="number" name="carton_length_in_cm" (change)="onCartonLength($event);negativeAlert($event)" [(ngModel)]="prdData.carton_length_in_cm" placeholder="Length" class="form-control" />
                                            </div>
                                            <div class="col-md-4 form-group float-md-left">
                                                <input type="number" name="carton_width_in_cm" (change)="onCartonWidth($event);
                                                negativeAlert($event)" [(ngModel)]="prdData.carton_width_in_cm" placeholder="Width" class="form-control" />
                                            </div>
                                            <div class="col-md-4 form-group float-md-left">
                                                <input type="number" name="carton_height_in_cm" (change)="onCartonHeight($event);
                                                negativeAlert($event)" [(ngModel)]="prdData.carton_height_in_cm" placeholder="Height" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="">
                                        <input type="number" name="volume_per_price" [(ngModel)]="prdData.vol_per_price" placeholder="Volume Per Piece (CBM)" (change)="negativeAlert($event)" class="form-control" />
                                    </div>
                                </div>
                                <!-- <div class="form-group col-md-6">
                                    <div class="form-group col-md-2 float-md-left">
                                        <label for="">
                                            Design
                                        </label>
                                    </div>
                                    <div class="form-group col-md-10 float-md-left">
                                        <div class="form-group">
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" class="form-check-input" value="General" name="general" [(ngModel)]="prdData.design" name="optradiotwo" />
                                                    General
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" name="general" value="Exclusive" [(ngModel)]="prdData.design" class="form-check-input" name="optradiotwo" />
                                                    Exclusive
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <!-- <div class="form-group col-md-6">
                                    &nbsp;
                                </div> -->
                                <div class="form-group col-md-6 p-0">
                                    <div class="form-group col-md-6 float-left">
                                        <input type="number" name="moq" [(ngModel)]="prdData.moq" placeholder="MOQ One" class="form-control" (change)="negativeAlert($event);decimalAlert($event)" />
                                    </div>
                                    <div class="form-group col-md-6 float-left">
                                        <input type="number" name="moq_price" (change)="priceMin($event)" [(ngModel)]="prdData.moq_price" placeholder="Price US$ [FOB] for MOQ One" (change)="negativeAlert($event);twoDecimal($event)" class="form-control" />
                                    </div>
                                </div>

                                <div class="form-group col-md-6 p-0">
                                    <div class="form-group col-md-6 float-left">
                                        <input type="number" name="moq2" [(ngModel)]="prdData.moq2" placeholder="MOQ Two" class="form-control" (change)="negativeAlert($event);decimalAlert($event)" />
                                    </div>
                                    <div class="form-group col-md-6 float-left">
                                        <input type="number" name="moq_price1" [(ngModel)]="prdData.moq_price1" placeholder="Price US$ [FOB] for MOQ Two" class="form-control" (change)="negativeAlert($event);twoDecimal($event)" />
                                    </div>
                                </div>
                                <div class="form-group col-md-6 p-0">
                                    <div class="form-group col-md-6 float-left">
                                        <input type="number" name="moq3" [(ngModel)]="prdData.moq3" placeholder="MOQ Three" class="form-control" (change)="negativeAlert($event);decimalAlert($event)" />
                                    </div>
                                    <div class="form-group col-md-6 float-left">
                                        <input type="number" name="moq_price2" [(ngModel)]="prdData.moq_price2" placeholder="Price US$ [FOB] for MOQ Three" class="form-control" (change)="negativeAlert($event);twoDecimal($event)"/>
                                    </div>
                                </div>

                                <!-- <div class="col-md-12"><hr /></div> -->

                                <!-- <div class="form-group col-md-12">
                                    <div class="form-group col-md-4 float-md-left">
                                        <label for="">
                                            Suitable For Retail Price
                                        </label>
                                    </div>
                                    <div class="form-group col-md-8 float-md-left">
                                        <div class="form-group">
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" name="retailprice" value="$1" [(ngModel)]="prdData.retailPrice" class="form-check-input" name="optradiothree">
                                                    $1
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" name="retailprice" value="$2" [(ngModel)]="prdData.retailPrice" class="form-check-input" name="optradiothree">
                                                    $2
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" name="retailprice" value="$3" [(ngModel)]="prdData.retailprice" class="form-check-input" name="optradiothree">
                                                    $3
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" name="retailprice" value="$5" [(ngModel)]="prdData.retailprice" class="form-check-input" name="optradiothree">
                                                    $5
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" name="retailprice" value="$10" [(ngModel)]="prdData.retailprice" class="form-check-input" name="optradiothree">
                                                    $10
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" name="retailprice" value="$>10" [(ngModel)]="prdData.retailprice" class="form-check-input" name="optradiothree">
                                                    $>10
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="form-group col-md-6">
                                    <div class="form-group">
                                        <input type="number" name="sample_lead_time" [(ngModel)]="prdData.sample_lead_time" placeholder="Sampling Lead - Time(Days)" class="form-control" (change)="negativeAlert($event);decimalAlert($event)" />
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <div class="form-group">
                                        <input type="number" name="moq_price3" [(ngModel)]="prdData.moq_price3" placeholder="MOQ (Pieces/Set) for order" class="form-control" />
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <div class="form-group">
                                        <div class="col-md-3 float-md-left pl-0">
                                            <label for="">Guaranteed Shipment Lead</label>
                                        </div>
                                        <div class="col-md-9 float-md-left pr-0">
                                            <div class="form-group col-md-6 float-md-left">
                                                <input type="number" name="min_days" [(ngModel)]="prdData.min_days" placeholder="min days" class="form-control" (change)="negativeAlert($event);decimalAlert($event)" />
                                            </div>
                                            <div class="form-group col-md-6 float-md-left pr-0">
                                                <input type="number" name="max_days" [(ngModel)]="prdData.max_days" placeholder="max days" class="form-control" (change)="negativeAlert($event);decimalAlert($event)"  />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-3">
                                    <div class="">
                                        <input type="number" name="stock_quantity" [(ngModel)]="prdData.stock_quantity" placeholder="Stock Quantity" class="form-control" />
                                    </div>
                                </div>

                                <div class="form-group col-md-3">
                                    <div class="">
                                        <input type="number" name="stock_price" [(ngModel)]="prdData.stock_price" (change)="onPriceChange($event)" placeholder="Stock Price US$ [FOB Mumbai]" class="form-control" />
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <div class="col-md-3 float-md-left pl-0">
                                        <label for="">Will Pass Food-grade Test</label>
                                    </div>
                                    <div class="col-md-4 float-md-left pr-0"  style="    padding-left: 32px;">
                                        <select name="food_grade_test" [(ngModel)]="prdData.food_grade_test" class="form-control" id="">
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- <div class="form-group col-md-3">
                                    <label for="">Mila Recommended Product</label>
                                    <select name="milaRecommend" [(ngModel)]="prdData.milaRecommend" class="form-control" id="">
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div> -->

                                <div class="col-md-12"></div>

                                <button type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button>
                            </div>
                        </form>
                        <br>
                        <h5 style="    padding: 0 14px;" *ngIf="loginStatus==4">Admin Verification Form</h5>
                        <form *ngIf="loginStatus==4" class="form" #adminForm ngForm="AdminForm" (ngSubmit)="adminVerification(admData)">
                            
                            <div class="form-group col-md-4 float-left">
                                        <div class="form-group col-md-2 p-0 float-md-left">
                                            <label for="">
                                                Verified product
                                            </label>
                                        </div>
                                        <div class="form-group col-md-10 float-md-left" style="">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" value="Yes" [(ngModel)]="admData.verifydprdt" class="form-check-input" name="Verified_product" />
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" value="No" [(ngModel)]="admData.verifydprdt" class="form-check-input" name="Verified_product" />
                                                        No
                                                    </label>
                                                </div>
                                                
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <!-- col-md-4 -->
                                    <div class="form-group col-md-4 float-left">
                                        <div class="form-group col-md-2 p-0 float-md-left">
                                            <label for="">
                                                Bestsellers
                                            </label>
                                        </div>
                                        <div class="form-group col-md-10 float-md-left" style="">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" value="Yes" [(ngModel)]="admData.bestsell" class="form-check-input" name="bestseller" />
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" value="No" [(ngModel)]="admData.bestsell" class="form-check-input" name="bestseller" />
                                                        No
                                                    </label>
                                                </div>
                                                
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <!-- col-md-4 -->
                                    <div class="form-group col-md-4 float-left">
                                        <div class="form-group col-md-2 p-0 float-md-left">
                                            <label for="">
                                                Suitable for Market
                                            </label>
                                        </div>
                                        <div class="form-group col-md-10 float-md-left" style="">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" value="Premium" [(ngModel)]="admData.market" (change)="onChangeMarket($event)" class="form-check-input" name="market" />
                                                        Premium
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" value="Standard" [(ngModel)]="admData.market" (change)="onChangeMarket($event)" class="form-check-input" name="market" />
                                                        Standard
                                                    </label>
                                                </div>
                                                
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <!-- col-md-4 -->
                                    <!-- <div class="form-group col-md-12 float-left">
                                        <div class="form-group col-md-2 p-0 float-md-left">
                                            <label for="">
                                                If standard then below will open				
                                            </label>
                                        </div>
                                    </div> -->
                                    
                                    <div class="form-group col-md-6 p-0 float-left">
                                        <div class="form-group col-md-6 float-left">
                                            <input type="number" name="moqpriceadmin" [(ngModel)]="admData.moqpriceadmin" placeholder="Price US$ [FOB] for MOQ One" class="form-control" />
                                        </div>
                                        <div class="form-group col-md-6 float-left">
                                            <input type="number" name="moqadmin" [(ngModel)]="admData.moqadmin" placeholder="MOQ One" class="form-control" />
                                        </div>
                                       
                                    </div>
                                    <div class="form-group col-md-6 p-0 float-left">
                                        <div class="form-group col-md-6 float-left">
                                            <input type="number" name="moqpriceadmin2" [(ngModel)]="admData.moqpriceadmin2" placeholder="Price US$ [FOB] for MOQ Two" class="form-control" />
                                        </div>
                                        <div class="form-group col-md-6 float-left">
                                            <input type="number" name="moqadmin2" [(ngModel)]="admData.moqadmin2" placeholder="MOQ Two" class="form-control" />
                                        </div>
                                       
                                    </div>
                                    
                                    <div class="form-group col-md-6 p-0  float-left">
                                        <div class="form-group col-md-6 float-left">
                                            <input type="number" name="moqpriceadmin3" [(ngModel)]="admData.moqpriceadmin3" placeholder="Price US$ [FOB] for MOQ Three" class="form-control" />
                                        </div>
                                        <div class="form-group col-md-6 float-left">
                                            <input type="number" name="moqadmin3" [(ngModel)]="admData.moqadmin3" placeholder="MOQ Three" class="form-control" />
                                        </div>
                                       
                                    </div>
                                    <div class="form-group col-md-6 float-left" *ngIf="standCounter">
                                        <div class="form-group col-md-4 p-0 float-md-left">
                                            <label for="">
                                                Suitable for Retail Price
                                            </label>
                                        </div>
                                        <div class="form-group col-md-8 float-md-left" style="">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" value="1" [(ngModel)]="admData.retailprice" class="form-check-input" name="retailprice" />
                                                        $ 1
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" value="1.5" [(ngModel)]="admData.retailprice" class="form-check-input" name="retailprice" />
                                                        $ 1.5
                                                    </label>
                                                </div>
                                                
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" value="2" [(ngModel)]="admData.retailprice" class="form-check-input" name="retailprice" />
                                                        $ 2
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" value="3" [(ngModel)]="admData.retailprice" class="form-check-input" name="retailprice" />
                                                        $ 3
                                                    </label>
                                                </div>
                                                
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" value="5" [(ngModel)]="admData.retailprice" class="form-check-input" name="retailprice" />
                                                        $ 5
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" value="10" [(ngModel)]="admData.retailprice" class="form-check-input" name="retailprice" />
                                                        $ 10
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" value=">10" [(ngModel)]="admData.retailprice" class="form-check-input" name="retailprice" />
                                                        > $10
                                                    </label>
                                                </div>
                                                
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group col-md-6 p-0  float-left" *ngIf="premCounter">
                                        <div class="form-group col-md-6 float-left">
                                            <input type="number" name="moqpriceadminpremium" [(ngModel)]="admData.moqpriceadminpremium" placeholder="Price US $" class="form-control" />
                                        </div>
                                        <div class="form-group col-md-6 float-left">
                                            <input type="number" name="moqadminpremium" [(ngModel)]="admData.moqadminpremium" placeholder="For MOQ " class="form-control" />
                                        </div>
                                       
                                    </div> -->
                                    
                                    <div class="form-group col-md-12 float-left">
                                    <div class="form-group col-md-3 offset-md-1 float-left">
                                        <button type="" (click)="onApprove($event)" value="1" class="btn btn-primary d-md-block mx-auto">Approve</button>
                                    </div>
                                    <div class="form-group col-md-3 float-left">
                                        <button type="" (click)="onApprove($event)" value="2" class="btn btn-primary d-md-block mx-auto">Reject</button>
                                    </div>
                                    
                                    <div class="form-group col-md-3 float-left">
                                        <button type="" (click)="onApprove($event)" value="3" class="btn btn-primary d-md-block mx-auto">Pending</button>
                                    </div>
                                </div>
                                    
                        </form>
                    </div>
            </div>
            <!-- col-12 -->
            <!-- <div class="col-12">
                <div class="section-form">
                   
                </div>
            </div> -->


        </div>
    </div>
</section>

<style>
    
    .loader {
        border: 8px solid #f3f3f3; /* Light grey */
        border-top: 8px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 80px;
        height: 80px;
        animation: spin 2s linear infinite;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      .loader-backdrop {
          background:rgba(0, 0, 0, 0.1);
          position:fixed;
          inset:0;
          z-index: 20;
          display: none;
      }
      .loader-backdrop.active, .loader.active {
         display: block;
      }

    
    </style>
    
    <!-- <div class="class loader-backdrop" [class.active]="!actCounter">
        <div class="loader" [class.active]="!actCounter">
    
        </div>
    </div> -->
