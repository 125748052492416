
<section class="verify">
<div class="container">
<div class="row">
<div class="col offset-3">
    <p>Hi Please Click on the Button Below to verify your Email</p>
    <a (click)="verifyEmail()" class="btn btn-primary">Verify email</a>
</div>
</div>
</div>
</section>

