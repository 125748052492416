<style>
    .product-entry{
        background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url(../../assets/img/aerial-view.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 900px;
         padding-top: 150px;
    }
    .section-form{
        background: #fff;
    }
    .pull-left{
        float: left;
    }
    .card img{
        width: 50px;
    }
    .card{
        display: flex;
        align-items: center;
        padding: 40px;
        color: #212121;
    }
    .card:hover {
    background: #161616;
    color: #fff;
}
</style>

<section class="product-entry section" id="productEntry">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 offset-md-3">
                <div class="section-content">
                    <div class="section-title mb-md-4">
                        <h1 class="mb-md-0">All Products</h1>
                    </div>
                    
                    <div class="col-md-12  bg-white">
                        <br>
                        <table class="table table-bordered">
                            <tr>
                                
                                <th>Code</th>
                                <th>Industry</th>
                                <th>Material</th>
                                <th>Description</th>
                                <th>Action</th>
                                <th *ngIf="loginStatus == 4">Status</th>
                            </tr>
                            <tr *ngFor="let pro of prdData | paginate: { itemsPerPage: 5, currentPage: p }">
                                
                                <td>{{pro.product_details.item_name}}</td>
                                <td>{{pro.industry_name}}</td>
                                <td><span *ngFor="let mat of pro.material_detail"><span>{{mat.material_name}}</span>, </span></td>
                                <td>{{pro.product_details.product_desc}}</td>
                                <td>
                                    <!-- <a [routerLink]="['/product-entry']" [queryParams]="{ id:pro.product_details.id }" class="btn">View products</a> -->
                                    <a href="product-entry?id={{pro.product_details.id}}&vd={{pro.product_details.vendor_id}}" class="btn">View products</a>
                                
                                
                                </td>
                                <td *ngIf="loginStatus == 4">
                                    <select (change)="onStatusCheck($event,pro.id)">
                                        <option disabled [selected]="pro.product_details.admPrdtStatus == 0">Select Staus</option>
                                         
                                        <option [selected]="pro.product_details.admPrdtStatus == 3" value="3">Pending</option>
                                        <option [selected]="pro.product_details.admPrdtStatus == 1" value="1">Approved</option> 
                                        <option [selected]="pro.product_details.admPrdtStatus == 2" value="2">Unapproved</option>
                                          
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <br>
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        <br>
                    </div> 

                    </div> 
 
                    </div> 
    
                    </div>
                    </div>
</section>