import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  productId:any
  productList:any
  prdtName: any;
  constructor(private WebapiService:WebapiService,private activated_Route: ActivatedRoute) { }

  ngOnInit(): void {
    this.productId = this.activated_Route.snapshot.queryParams.id;
    this.getProductById(this.productId);
  }

  getProductById(id:any){
    let val = {
      'id':id
    }
    this.WebapiService.getProductById(val).subscribe((res:any)=>{
      console.log(res);
      this.productList = res;
      this.prdtName = res[0].product_name;
    });
  }

}
