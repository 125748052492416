<style>
   /* .about-section{
      background-image: linear-gradient(to left, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url("../../assets/img/shipment.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 704px;
      width: 100%;
      color: white;
      position: relative;
      animation: slideLeft 1s ease 10ms;
   }
   @keyframes slideLeft{
      from{left: 200%}
      to{left: 0%}
   }
   .about-section .section-content .section-title h1{
      font-weight: var(--f-weight-seven);
   } */
   /* .navlinks:focus #about{
      transform: translateX(-100%);
   } */
   /* .w-40{
       width: 40%;
   } */
</style>


<section class="about-section section" id="about">
   <div class="container-fluid">
       <div class="row align-items-md-center">
           <div class="col-md-9 offset-md-3">
               <div class="section-content">
                   <div class="section-title">
                       <h1>ABOUT MILA</h1>
                   </div>
                   <!-- <div class="section-img text-md-center">
                        <img src="../../assets/img/About us page-pana.svg" alt="" class="w-40">
                   </div> -->
                   <div class="section-text text-justify">
                       <p>
                            MILA MERCHANDIZING CORPORATION, based in New Delhi- India, started operations in October 1992.  We are a professionally managed Buying agency, being India’s first  ISO-9003 certified quality management organization in 1996 providing the finest Product Sourcing & development, Inspection and Quality Control Service to International Buying Organizations and Importers.  
                       </p>
                       <p>
                            Drawing upon our Pioneer’s 35 years experience, our qualified and dedicated personnel take care of your smallest requirement.  Our modern office has the necessary infrastructure to control quality, delivery, packing, shipping & documentation.  We provide weekly feedback on production status.
                       </p>
                       <p>
                            We approach our work with total professionalism. We have an attitude of going into fine details and are proud when we say that we source the finest products that are produced in India. Our strength is our Experienced C.E.O., well qualified and trained Merchandisers and an extremely advanced Quality Control System.
                       </p>
                   </div>
               </div>
           </div>
       </div>
   </div>
</section>