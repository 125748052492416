<style>
    .product-entry{
        background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url(../../assets/img/aerial-view.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 900px;
         padding-top: 150px;
    }
    .section-form{
        background: #fff;
    }
    .form-control{
        font-size: 14px !important;
    }
    .white{
    color: #fff;
}
                                .signin{
                                    display: flex;
    width: 100%;
    background: #052c7d;
    flex-direction: column;
    padding: 20px 20px;
    border-radius: 0px;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    text-align: center;
                                }
</style>

<section class="product-entry section" id="productEntry">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-5 offset-md-3">
                <div class="section-content">
                    <div class="section-title mb-md-4">
                        <h1 class="mb-md-0">Login to your account</h1>
                    </div>
                    <div class="col-md-12">
                        <form class="signin" #loginForm ngForm="signinForm" (ngSubmit)="doLogin(formData)">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Email Id" name="email" [(ngModel)]="formData.email">
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Password" name="password" [(ngModel)]="formData.password">
                            </div>
                            <div class="form-group col-md-12">
                                <div class="col-md-6 float-left text-left">
                                    <a  class="white" [routerLink]="['/forgotpwd']" routerLinkActive="active">Forgot Password?</a>
                                </div>
                                <div class="col-md-6 float-left text-right">
                                    <a class="white" [routerLink]="['/signup']" routerLinkActive="active">Join Us</a>
                                </div>
                               
                            </div>
                            <div class="form-group">
                                <input type="submit" value="Login" class="btn btn-info" >
                            </div>
                        </form>
                    </div>
                    </div> 
                    <!-- content -->
                    </div> 
                    <!-- col-md-9 -->
                    <div class="col-md-4 d-none">
                        <div class="section-content">
                            <div class="section-title mb-md-4">
                                <h1 class="mb-md-0"></h1>
                            </div>
                          
                            <div class="col-md-12 ">
                               
                            </div>
                            </div> 
                            <!-- content -->
                            </div> 
                    </div>
                    </div>
</section>