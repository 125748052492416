<section class="" style="padding-bottom: 50px">
    <div class="aboutHeading">
       <div class="container">
          <div class="row">
             <div class="col-12 text-center">
                <h2 class="text-white">GET A QUOTE</h2>
                <ul class="breadcrumb">
                   <li><a href="index.php">Home</a></li>
                   <li><a href="#">GET A QUOTE</a></li>
                </ul>
             </div>
          </div>
       </div>
    </div>
 </section>
 <!-- <section class="aboutSlide"></section> -->

 <section class="section">
    <div class="whyVMR" style="margin-bottom: 5rem;">
       <div class="container">
          <div class="px-5">
             <div class="row">
                <div class="col-lg-12">
             <form class="get_a_quote_form">
                 <div class="form-group">
                    <h4>Select Services for enquiry?</h4>
                    <label *ngFor="let cat of categoryList">
                       <span>Cat:{{cat.category_name}}</span>
                       <br>
                       <span *ngFor="let data of cat.service">
                       <input type="checkbox" [(ngModel)]="data.selected" [ngModelOptions]="{standalone: true}"> {{data.service_name}}
                     </span>
                     </label>
                    
                 
                </div> <!--  form-group -->
                
                <div class="form-group">
                  <h4>Your Name</h4>
                  <div class="form-group">
                     <input type="text" class="form-control" name="name" [ngModelOptions]="{standalone: true}" [(ngModel)]="getQuote.fname" placeholder="">
                  </div>
               </div>
               <div class="form-group">
                  <h4>Company Name</h4>
                  <div class="form-group">
                     <input type="text" class="form-control" name=""  [ngModelOptions]="{standalone: true}" [(ngModel)]="getQuote.company_name"  placeholder="">
                  </div>
               </div>
               
               <div class="form-group">
                  <h4>Email id</h4>
                  <div class="form-group">
                     <input type="text" class="form-control" name="email"  [ngModelOptions]="{standalone: true}" [(ngModel)]="getQuote.email"  placeholder="">
                  </div>
               </div>
               
               <div class="form-group">
                  <h4>Phone No</h4>
                  <div class="form-group">
                     <input type="text" class="form-control" name="phone"  [ngModelOptions]="{standalone: true}" [(ngModel)]="getQuote.phome"  placeholder="">
                  </div>
               </div>
               <div class="form-group">
                  <!-- <h4>Phone No</h4> -->
                  <div class="form-group">
                     <input type="submit" class="form-control" (click)="submitQuote();" placeholder="">
                  </div>
               </div>
             </form>
            </div>
                
                                                  
             </div>
          </div>
       </div>
    </div>
 </section>