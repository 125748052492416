import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service'; 
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  categoryList:any;
  preServiceList: any;
  postServiceList: any;
  customerList: any;
  formData:any={};
  constructor(private WebapiService:WebapiService,private router: Router) { }

  ngOnInit(): void {

    this.getCategory();
    this.getPostServicesByStatus(2);
    this.getPreServicesByStatus(1);
    this.getCustomer();
  }

  getCategory(){

    this.WebapiService.getCategory().subscribe((res: any) => {
      // console.log(res);
      this.categoryList = res;
    });
  }
  getCustomer(){

    this.WebapiService.getCustomer().subscribe((res: any) => {
      // console.log(res);
      this.customerList = res;
    });
  }

  getPreServicesByStatus(id:any){
    let val = {
      'status':id
    }
    this.WebapiService.getPreServicesByStatus(val).subscribe((res:any)=>{
      // console.log(res);
      this.preServiceList = res;
    });
  }

  getPostServicesByStatus(id:any){
    let val = {
      'status':id
    }
    this.WebapiService.getPostServicesByStatus(val).subscribe((res:any)=>{
      // console.log(res);
      this.postServiceList = res;
    });
  }

  onFileChange(e:any){
   console.log(e.target.files[0]);
   this.formData.file= e.target.files[0];
  }
  queryForm(data:any){
  //  console.log(data);
   this.WebapiService.insertContactData(data).subscribe((res:any)=>{
    console.log(res);
    if(res.msg == 1){
      alert("your details has been submitted");
      this.formData = {};
    }
    else{
      alert("soemthing went wrong");
    }
  });
   
  }
}
