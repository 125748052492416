import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service'; 
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
  filter:any={};
  productList: any;
  materialList: any;
  industryList: any;
  coatingList: any;
  finishList: any;
  colorList: any;
  shapeList: any;
  styleList: any;
  categoryList: any;
  product: any;
  filterArr:any=[];
  prdtId:any
  subCategoryList: any;
  userLogin: any;
  loginStatus:any
  empId: any;
  usageList: any;
  preCounter:boolean = true;
  retailArr:any=[];
  matArr:any
  filterNameArr:any=[];
  material_name: any;


  constructor(private router: Router,
    private WebapiService:WebapiService,private activated_Route: ActivatedRoute) { }

  ngOnInit(): void {
    this.userLogin = localStorage.getItem('loginId');
    this.loginStatus = localStorage.getItem('loginStatus');
    this.empId = localStorage.getItem('loginId');

    // this.getAllProduct();
    this.getCoating();
    this.getColor();
    this.getFinish();
    this.getIndustry();
    this.getShape();
    this.getMaterial();
    this.getStyle();
    this.getUsage();
   this.prdtId = this.activated_Route.snapshot.queryParams.id;
  //  console.log(this.prdtId);
  if(this.prdtId){
    this.getProductByPrdtId(this.prdtId);
  }
  else{
    this.getAllProduct();
  }
  this.retailArr = ["1","1.5","2","3","4","5","10",">10"];

   
  }

  getAllProduct(){
    this.filter = {
      "id":"",
      "start": 0,
      "limit":5
    }
    this.WebapiService.getAllProduct(this.filter).subscribe((res:any)=>{
      console.log(res);
      // this.getMaterialNames(res[0]?.material);
      // this.getCategoryById(res[0].category_id);
      this.productList = res;
    });
  }
  myAccount(e:any){
    // console.log(e);
    if(e==2){
      this.router.navigate(['/vendor-account'])
      sessionStorage.removeItem('editVenId');

    }
    else if(e==3){
      this.router.navigate(['/my-account'])
      sessionStorage.removeItem('editVenId');

    }
    else if(e==4){
      this.router.navigate(['/admin'])
      sessionStorage.removeItem('editVenId');

    }else {
      // console.log("hi there");
      
    }
  }
  
  getMaterialById(id:any){
    this.WebapiService.getMaterial(id).subscribe((res:any)=>{
      // console.log(res);
      this.productList[0].material_name = res[0].material_name
    });
  }
  getCategoryById(id:any){
    this.WebapiService.getCategory(id).subscribe((res:any)=>{
      // console.log(res);
      this.productList[0].category_name = res[0]?.category_name

    });
  }
  getStyle(){
    this.WebapiService.getStyle().subscribe((res:any)=>{
      // console.log(res);
      this.styleList =res;
    });
   }
   getShape(){
    this.WebapiService.getShape().subscribe((res:any)=>{
      // console.log(res);
      this.shapeList =res;
    });
   }
   getColor(){
    this.WebapiService.getColor().subscribe((res:any)=>{
      // console.log(res);
      this.colorList =res;
    });
   }
   getFinish(){
    this.WebapiService.getFinish().subscribe((res:any)=>{
      // console.log(res);
      this.finishList =res;
    })
    
    ;
   }
   getCoating(){
    this.WebapiService.getCoating().subscribe((res:any)=>{
      // console.log(res);
      this.coatingList =res;
    });
   }

  getMaterial(){
    this.WebapiService.getMaterial().subscribe((res:any)=>{
      // console.log(res);
      this.materialList =res;
    });
  }
  logout(){
    localStorage.removeItem('loginId');
    localStorage.removeItem('loginStatus');
    sessionStorage.removeItem('editVenId');
    this.router.navigate(['/']);
  }

  getUsage(){
    this.WebapiService.getUsage().subscribe((res:any)=>{
      // console.log(res);
      this.usageList =res;
    });
   }

   getIndustry(){
    this.WebapiService.getIndustry().subscribe((res:any)=>{
      // console.log(res);
      this.industryList =res;
    });
   }

   getCatByIndustry(e:any){
    let id ={
      "id":e
    };
    this.WebapiService.getCategoryByIndustry(id).subscribe((res:any)=>{
     console.log(res);
     this.categoryList =res;
   });
    
 }
 getSubCatByCategory(e:any){
  let id ={
      "id": e
    };
   this.WebapiService.getSubCatByCategory(id).subscribe((res:any)=>{
    // console.log(res);
    this.subCategoryList =res;
  });
   
 }
 getProdBySubCategory(e:any){
  let id ={
      "id": e
    };
   this.WebapiService.getProdBySubCategory(id).subscribe((res:any)=>{
    // console.log(res);
    this.product =res;
  });
}

  getProductByFilter(name:any,id:any,inp:any){
   console.log(inp);
   
    // this.filterNameArr.push(inp);
     this.filterArr[name] = id;
    // console.log(this.filterArr);
    this.WebapiService.getProductByFilter(this.filterArr).subscribe((res:any)=>{
   console.log(res);
   this.productList = res;
    });
      
  }
  resetFilter(){
    this.filterArr=[];
    this.filterNameArr=[];
    location.reload();
  }

  getProductByPrdtId(id:any){
    let val= {
      "id": id,
    }
    this.WebapiService.getProductByPrdtId(val).subscribe((res:any)=>{
    console.log(res);
    this.productList = res;
    });
  }
 
  changePremium(e:any){
    // console.log(e.srcElement.checked);
    if(e.srcElement.checked == true){
      this.preCounter = false;
    }
    else{
      this.preCounter = true;
    }
    
  }

}
