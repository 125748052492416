import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';  
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CategoryComponent } from './category/category.component';
import { BlogComponent } from './blog/blog.component';
import { BlogdetailsComponent } from './blogdetails/blogdetails.component';
import { ProductsComponent } from './products/products.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { TicketComponent } from './tickets/ticket/ticket.component';
import { NavbarComponent } from './navbar/navbar.component';
//import { TeamComponent } from './team/team.component';
import { TeamdetailsComponent } from './team/teamdetails/teamdetails.component';
import { IndustryComponent } from './industry/industry.component';
import { IndustrydetailComponent } from './industry/industrydetail/industrydetail.component';
import { CareerComponent } from './career/career.component';
import { TeamComponent } from './team/team.component';
import { GetAQuoteComponent } from './get-a-quote/get-a-quote.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { ServicesTriburgComponent } from './services-triburg/services-triburg.component';
import { PostOrderServicesComponent } from './post-order-services/post-order-services.component';
import { VendorRegisterComponent } from './vendor-register/vendor-register.component';
import { VendorContactComponent } from './vendor-contact/vendor-contact.component';
import { VendorDetailsComponent } from './vendor-details/vendor-details.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { AddproductComponent } from './addproduct/addproduct.component';
import { ProductEntryComponent } from './product-entry/product-entry.component';
import { FilterComponent } from './filter/filter.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { EmpVendorListComponent } from './emp-vendor-list/emp-vendor-list.component';
import { LoginComponent } from './login/login.component';
import { VendorProfileComponent } from './vendor-profile/vendor-profile.component';
import { VendorProductsComponent } from './vendor-products/vendor-products.component';
import { VendorAccountComponent } from './vendor-account/vendor-account.component';
import { SteponeComponent } from './vendor-reg/stepone/stepone.component';
import { SteptwoComponent } from './vendor-reg/steptwo/steptwo.component';
import { StepthreeComponent } from './vendor-reg/stepthree/stepthree.component';
import { StepfourComponent } from './vendor-reg/stepfour/stepfour.component';
import { StepfiveComponent } from './vendor-reg/stepfive/stepfive.component';
import { StepsixComponent } from './vendor-reg/stepsix/stepsix.component';
import { StepsevenComponent } from './vendor-reg/stepseven/stepseven.component';
import { StepeightComponent } from './vendor-reg/stepeight/stepeight.component';
import { SignupComponent } from './signup/signup.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    CategoryComponent,
    BlogComponent,
    BlogdetailsComponent,
    ProductsComponent,
    HeaderComponent,
    FooterComponent,
    CartComponent,
    CheckoutComponent,
    AboutComponent,
    ContactComponent,
    TicketComponent,
    NavbarComponent,
    TeamComponent,
   TeamdetailsComponent,
    IndustryComponent,
    IndustrydetailComponent,
    CareerComponent,
    TeamComponent,
    GetAQuoteComponent,
    OurServicesComponent,
    ServicesTriburgComponent,
    PostOrderServicesComponent,
    VendorRegisterComponent,
    VendorContactComponent,
    VendorDetailsComponent,
    AddCustomerComponent,
    AddproductComponent,
    ProductEntryComponent,
    FilterComponent,
    MyAccountComponent,
    EmpVendorListComponent,
    LoginComponent,
    VendorProfileComponent,
    VendorProductsComponent,
    VendorAccountComponent,
    SteponeComponent,
    SteptwoComponent,
    StepthreeComponent,
    StepfourComponent,
    StepfiveComponent,
    StepsixComponent,
    StepsevenComponent,
    StepeightComponent,
    SignupComponent,
    ProductDetailComponent,
    VerifyEmailComponent,
    AdminDashboardComponent,
    // IndustryDetailComponent
    // ServiceComponent
  ],
  
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
