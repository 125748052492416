import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
@Component({
  selector: 'app-stepeight',
  templateUrl: './stepeight.component.html',
  styleUrls: ['./stepeight.component.css']
})
export class StepeightComponent implements OnInit {
  venderId:any
  loginStatus: any;
  userLogin:any;
  empId: any;
  infrastructureData: any={};
  typeUnitCheck: any=[];
  woodList: any;
  steelGradeCheck: any=[];
  glassManufacturerCheck: any=[]; 
  glassprocessCheck: any=[];
  glassColorCheck: any=[];
  material: any;
  companyName: any;
  temp: any=[];
  woodFlag:boolean = false;
  glassFlag: boolean = false;
  steelFlag: boolean = false;
  ironFlag: boolean = false;
  frostingDataCheck: any=[];
  weldingProcessCheck: any=[];
  hardFlag: boolean = false;
  textileFlag: boolean = false;
  epnsFlag: boolean = false;
  brassFlag: boolean = false;
  almFlag: boolean = false;
  copperFlag: boolean = false;
  MelamineFlag: boolean = false;
  RubberFlag: boolean = false;
  PlasticFlag: boolean = false;
  SiliconFlag: boolean = false;

  CeramicFlag: boolean = false;
  ProcelainFlag: boolean = false;
  BonechinaFlag: boolean = false;
  TeracottaFlag: boolean = false;
  PaperFlag: boolean = false;
  BoneFlag : boolean = false;
  HornFlag : boolean = false;
  MOPFlag : boolean = false;
  loginName: any;

  constructor(private router: Router,
    private WebapiService:WebapiService) { }

  ngOnInit(): void {
    this.getMaterial();
    this.venderId = localStorage.getItem('venderId');
    this.userLogin = localStorage.getItem('loginId');
    this.empId = localStorage.getItem('loginId');
    this.loginStatus = localStorage.getItem('loginStatus');
    this.loginName=localStorage.getItem('name');

    if(this.venderId){
      this.getUserById(this.venderId);
      }
      else{
        this.getUserById(sessionStorage.getItem('editVenId'));
      }
    this.woodList=["Mango","Sheesham","Accacia","Teak","Oak","Cherry","Mahagony","Walnut","MDF","HDF","PLY","Veneer","Pine","Cork","Laminate","Other"
  ];
  }
  getUserById(id:any){
    let val ={
      "id":id
    }
    this.WebapiService.getUserById(val).subscribe((res:any)=>{
      console.log(res);
      if(res.length>0){
        this.companyName = res[0].company_name;
          this.infrastructureData = res[0];
      }
    });
 
   }
  logout(){
    localStorage.removeItem('loginId');
    localStorage.removeItem('loginStatus');
    sessionStorage.removeItem('editVenId');
    this.router.navigate(['/']);
  }
  myAccount(e:any){
    // console.log(e);
    if(e==2){
      this.router.navigate(['/vendor-account'])
      sessionStorage.removeItem('editVenId');

    }
    else if(e==3){
      this.router.navigate(['/my-account'])
      sessionStorage.removeItem('editVenId');

    }
    else if(e==4){
      this.router.navigate(['/admin']);
      sessionStorage.removeItem('editVenId');
    }
    else{

    }
  }
    typeUnit(e:any){
     
      if (e.target.checked == true) {
        this.typeUnitCheck.push(e.target.value);
      }else{
        var index = this.typeUnitCheck.indexOf(e.target.value);
        this.typeUnitCheck.splice(index, 1);
      }
      console.log(this.typeUnitCheck);
    this.infrastructureData.glass_type_unit = this.typeUnitCheck;
    
    }
    glassProcess(e:any){
      if (e.target.checked == true) {
        this.glassprocessCheck.push(e.target.value);
      }else{
        var index = this.glassprocessCheck.indexOf(e.target.value);
        this.glassprocessCheck.splice(index, 1);
      }
      console.log(this.glassprocessCheck);
    this.infrastructureData.glass_process = this.glassprocessCheck;
    
    }
    glassManufacturer(e:any){
      if (e.target.checked == true) {
        this.glassManufacturerCheck.push(e.target.value);
      }else{
        var index = this.glassManufacturerCheck.indexOf(e.target.value);
        this.glassManufacturerCheck.splice(index, 1);
      }
      console.log(this.glassManufacturerCheck);
    this.infrastructureData.glass_manufacturer = this.glassManufacturerCheck;
    
    }
    glassColorType(e:any){
      if (e.target.checked == true) {
        this.glassColorCheck.push(e.target.value);
      }else{
        var index = this.glassColorCheck.indexOf(e.target.value);
        this.glassColorCheck.splice(index, 1);
      }
      console.log(this.glassColorCheck);
    this.infrastructureData.glass_color_type = this.glassColorCheck;
    }
    steelGradeQuality(e:any){
      if (e.target.checked == true) {
        this.steelGradeCheck.push(e.target.value);
      }else{
        var index = this.steelGradeCheck.indexOf(e.target.value);
        this.steelGradeCheck.splice(index, 1);
      }
      console.log(this.steelGradeCheck);
    this.infrastructureData.steel_grade_quality = this.steelGradeCheck;
    }
    weldingProcess(e:any){
      if (e.target.checked == true) {
        this.weldingProcessCheck.push(e.target.value);
      }else{
        var index = this.weldingProcessCheck.indexOf(e.target.value);
        this.weldingProcessCheck.splice(index, 1);
      }
      console.log(this.weldingProcessCheck);
    this.infrastructureData.steel_welding_process = this.weldingProcessCheck;
    }
    frostingCheck(e:any){
      if (e.target.checked == true) {
        this.frostingDataCheck.push(e.target.value);
      }else{
        var index = this.frostingDataCheck.indexOf(e.target.value);
        this.frostingDataCheck.splice(index, 1);
      }
      console.log(this.frostingDataCheck);
    this.infrastructureData.glass_frosting = this.frostingDataCheck;
    }

    onSelectMaterial(e:any){
      if (e.target.checked == true) {
        // console.log(e.target.value);


        if(e.target.value == "Bone"){
          this.BoneFlag = true;
        }
        else if(e.target.value == "Horn"){
          this.HornFlag = true;
        }
        else if(e.target.value == "MOP"){
          this.MOPFlag = true;
        }
        else if(e.target.value == "Paper"){
          this.PaperFlag = true;
        }
        else if(e.target.value == "Teracotta"){
  this.TeracottaFlag = true;
}
else if(e.target.value == "Bone china"){
  this.BonechinaFlag = true;
}
else if(e.target.value == "Procelain"){
  this.ProcelainFlag = true;
}
else if(e.target.value == "Ceramic"){
  this.CeramicFlag = true;
}
else if(e.target.value == "Rubber"){
          this.RubberFlag = true;
      }
      else if(e.target.value == "Silicon"){
          this.SiliconFlag = true;
      }
      else if(e.target.value == "Plastic"){
          this.PlasticFlag = true;
      }
      else if(e.target.value == "Melamine"){
          this.MelamineFlag = true;
      }
      else if(e.target.value == "Copper"){
          this.copperFlag = true;
      }
      else if(e.target.value == "Aluminium"){
          this.almFlag = true;
      }
      else if(e.target.value == "Brass"){
          this.brassFlag = true;
      }
      else if(e.target.value == "EPNS"){
          this.epnsFlag = true;
      }
      else if(e.target.value == "Textiles"){
          this.textileFlag = true;
      }
      else if(e.target.value == "hard"){
            this.hardFlag = true;
        }
        else if(e.target.value == "Wood"){
            this.woodFlag = true;
        }
        else if(e.target.value == "Glass"){
          this.glassFlag = true; 
        }
        else if(e.target.value == "Stainless Steel")
        {
          this.steelFlag = true;
        }
        else if(e.target.value == "Iron"){
          this.ironFlag = true;
        }
        else{
          console.log("100");
          
        }
        this.temp.push(e.target.value);
      }else{
        // console.log(e.target.value);
        var name = e.target.value;
        
        
        
        if(e.target.value == "Bone"){
          this.BoneFlag = false;
        }
        else if(e.target.value == "Horn"){
          this.HornFlag = false;
        }
        else if(e.target.value == "MOP"){
          this.MOPFlag = false;
        }
        else if(e.target.value == "Paper"){
          this.PaperFlag = false;
        }
        else if(e.target.value == "Teracotta"){
  this.TeracottaFlag = false;
}
else if(e.target.value == "Bone china"){
  this.BonechinaFlag = false;
}
else if(e.target.value == "Procelain"){
  this.ProcelainFlag = false;
}
else if(e.target.value == "Ceramic"){
  this.CeramicFlag = false;
}
else if(e.target.value == "Rubber"){
          this.RubberFlag = false;
      }
      else if(e.target.value == "Silicon"){
          this.SiliconFlag = false;
      }
      else if(e.target.value == "Plastic"){
          this.PlasticFlag = false;
      }
      else if(e.target.value == "Melamine"){
          this.MelamineFlag = false;
        }
      else if(name == "Copper"){
          this.copperFlag = false;
        }
        else if(name == "Aluminium"){
          this.almFlag = false;
        }
        else if(name == "Brass"){
          this.brassFlag = false;
        }
        else if(name == "EPNS"){
          this.epnsFlag = false;
        }
        else if(name == "Textiles"){
          this.textileFlag = false;
        }
        else if(name == "hard"){
          this.hardFlag = false;
        }
        else if(name == "Wood"){
          this.woodFlag = false;
        }
        else if(name=="Iron"){
          this.ironFlag = false;
        }
        else if(name=="Stainless Steel"){
          this.steelFlag =false;
        }
        else if(name=="Glass"){
          this.glassFlag=false;
        }
        else{
          console.log("100");
          
        }
        var index = this.temp.indexOf(e.target.value);
        this.temp.splice(index, 1);
      }
      // console.log(this.temp);
    this.infrastructureData.material = this.temp;
    }
    getMaterial(){
      this.WebapiService.getMaterial().subscribe((res:any)=>{
        // console.log(res);
        this.material =res;
      });
    }
    insertInfrastructure(data:any){
      console.log(data);
      if(this.loginStatus == 2){
        data.venderId = this.empId;
        // this.router.navigate(['/vendor-list']);
      }
      else if(sessionStorage.getItem('editVenId')){
        data.venderId = sessionStorage.getItem('editVenId');
      }
      else{
        data.venderId = this.venderId;
        // this.router.navigate(['/vendor-account']);
      }
      // console.log(data);
      
      this.WebapiService.insertInfrastructureData(data).subscribe((res:any)=>{
        console.log(res);
        if(res.status == 1){
          alert(res.msg);
           sessionStorage.removeItem('editVen');
        this.router.navigate(['/home']);
        }
        else{
          alert(res.msg);
        }
      });
    }

}
