import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-triburg',
  templateUrl: './services-triburg.component.html',
  styleUrls: ['./services-triburg.component.css']
})
export class ServicesTriburgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
