<style>
      .sign-in a {
    color: #fff !important;
}
.sign-in img {
    width: 30px;
}
.sign-in ul>li{
       display: inline-block;
       vertical-align: -webkit-baseline-middle;
   }
   .sign-in ul>li a{
       color: var(--white);
       margin-left: .25rem;
   }
   .search-box img{
        transform: translate(-6%, -50%);
        position: absolute;
        top: 50%;
        right: 6%;
   }
   .vendor-details {
        background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url(../../assets/img/aerial-view.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: auto;
    }
    
    a {
        color: #fff;
    }
    
    .dropdown-item {
        color: #212529 !important
    }
    
    .r-25 {
        right: 25px;
    }
    .you-deal-in{
        flex-wrap: wrap;
    }
    .you-deal-in>div{
        flex: 0 1 380px;
    }
</style>



<section class="vendor-details section" id="vendorDetails">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                
                <div class="section-title mb-md-4 col-md-12  float-md-left">
                    <div class="col-md-6 float-md-left">
                        <h1 class="mb-md-0">Vendor Registration</h1>
                    </div>
                    <div *ngIf="empId" class="sign-in col-md-6 float-md-left text-right">
                        <ul class="text-md-center">
                            <li>
                               <a (click)="myAccount(loginStatus)"><img src="assets/img/user.png" alt="" class="w-18">My Account</a>
                                <!-- <a  [routerLink]="['/login']" routerLinkActive="active">Sign In / Sign Up</a></li> -->
                                </li>
                            <li >
                                
                                <a (click)="logout()" href=""><img src="assets/img/logout.png" alt="" class="w-18">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="section-content  col-md-12  float-md-left">
                    <!--  -->
                    <ul class="nav nav-tabs " id="myTab" role="tablist">
                        <li *ngIf="loginStatus == 3" class="nav-item">
                            <!-- <a href="#home" class="nav-link active" id="home-tab" role="tab" aria-controls="home" aria-selected="true">Vendor Register</a> -->
                            <a class="nav-link" [ngClass]="{'active' : loginStatus == 3}" id="vreg-tab" data-toggle="tab" href="#ven-reg" role="tab" aria-controls="ven-reg" aria-selected="true">Home</a>
                        </li>
                        <li class="nav-item">
                            <!-- <a href="#profile" class="nav-link" id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="false">Vendor Contact</a> -->
                            <a class="nav-link" [ngClass]="{'active' : loginStatus == 2}" id="vcon-tab" data-toggle="tab" href="#ven-con" role="tab" aria-controls="vcon-reg" aria-selected="false">Vendor Contact</a>
                        </li>
                        <li class="nav-item">
                            <!-- <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Commercial</a> -->
                            <a class="nav-link" id="vcomm-tab" data-toggle="tab" href="#ven-comm" role="tab" aria-controls="vcomm-reg" aria-selected="false">Commercial</a>
                        </li>
                        <li class="nav-item">
                            <!-- <a class="nav-link" id="legalCommercial-tab" data-toggle="tab" href="#legal" role="tab" aria-controls="legal" aria-selected="false">Legal and Commercial</a> -->
                            <a class="nav-link" id="qm-tab" data-toggle="tab" href="#qm-cer" role="tab" aria-controls="qm-reg" aria-selected="false">Quality Management & Certifications</a>
                        </li>
                        <li class="nav-item">
                            <!-- <a class="nav-link" id="legalCommercial-tab" data-toggle="tab" href="#legal" role="tab" aria-controls="legal" aria-selected="false">Legal and Commercial</a> -->
                            <a class="nav-link" id="staff-tab" data-toggle="tab" href="#staff-ing" role="tab" aria-controls="staff-reg" aria-selected="false">Staffing</a>
                        </li>
                        <li class="nav-item">
                            <!-- <a class="nav-link" id="legalCommercial-tab" data-toggle="tab" href="#legal" role="tab" aria-controls="legal" aria-selected="false">Legal and Commercial</a> -->
                            <a class="nav-link" id="lfin-tab" data-toggle="tab" href="#leg-fin" role="tab" aria-controls="lfin-reg" aria-selected="false">Legal & Financial</a>
                        </li>
                        <li class="nav-item">
                            <!-- <a class="nav-link" id="legalCommercial-tab" data-toggle="tab" href="#legal" role="tab" aria-controls="legal" aria-selected="false">Legal and Commercial</a> -->
                            <a class="nav-link" id="scom-tab" data-toggle="tab" href="#soc-com" role="tab" aria-controls="scom-reg" aria-selected="false">Social Compliance</a>
                        </li>
                        <li class="nav-item">
                            <!-- <a class="nav-link" id="legalCommercial-tab" data-toggle="tab" href="#legal" role="tab" aria-controls="legal" aria-selected="false">Legal and Commercial</a> -->
                            <a class="nav-link" id="infra-tab" data-toggle="tab" href="#infra-str" role="tab" aria-controls="infra-reg" aria-selected="false">Infrastructure</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div *ngIf="loginStatus == 3" class="tab-pane fade" [ngClass]="{'show active' : loginStatus == 3}" id="ven-reg" role="tabpanel" aria-labelledby="vreg-tab">
                            <div class="section-content bg-white p-md-3 h-auto clearfix">
                                <div class="section-title mb-md-4">
                                </div>
                                <div class="section-form">
                                    <form #VendorForm ngForm="VendorForm">
                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.cname" class="form-control" id="company" aria-describedby="company" placeholder="Company Name" />
                                            </div>
                                            <div class="col-12"></div>

                                            <div class="col-md-1">
                                                <div class="form-group">
                                                    <select class="custom-select" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.title">
                                                        <option value="Mr." selected>Mr.</option>
                                                        <option value="Mrs">Mrs.</option>
                                                        <option value="Ms">Ms.</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-4">
                                                <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.conpername" class="form-control" id="exampleInputPerson" aria-describedby="person" placeholder="First Name" />
                                            </div>

                                            <div class="form-group col-md-4">
                                                <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.uname" class="form-control" id="exampleInputUser" aria-describedby="user" placeholder="Last Name" />
                                            </div>
                                            <div class="col-12"></div>
                                            <div class="col-md-1">
                                                <select name="" id="" class="custom-select">
                                                    <option value="">+91</option>
                                                    <option value="">+91</option>
                                                </select>
                                            </div>
    
                                            <div class="form-group col-md-4">
                                                <input type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.mob" class="form-control" id="exampleInputMobile" aria-describedby="mobile" placeholder="Mobile" />
                                            </div>

                                            <div class="form-group col-md-4">
                                                <input type="email" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email ID/Username" />
                                            </div>
                                            <div class="col-12"></div>
                                            <div class="form-group col-md-4">
                                                <input type="password" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.password" class="form-control" id="exampleInputPassword" placeholder="Password" />
                                            </div>
                                            <div class="form-group col-md-4">
                                             
                                                <input type="password" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.conpass" class="form-control" id="exampleInputConPassword" placeholder="Confirm Password" />
                                            </div>
                                           
                                        </div>
                                        
                                        <!-- <div class="form-group col-md-6 float-left">
                                            <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.wname" class="form-control" id="exampleInputWebsite" aria-describedby="website" placeholder="Website" />
                                        </div> -->
                                        
                                        
                                        <div class="form-group col-md-6 float-left">
                                            <input type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.offnum" class="form-control" id="exampleInputOffice" aria-describedby="office" placeholder="Office Number" />
                                        </div>
                                        <div class="form-group col-md-6 float-left">
                                            <textarea class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.offadd" id="exampleFormOffAddress" rows="1" placeholder="Office Address"></textarea>
                                        </div>
                                        <div class="form-group col-md-6 float-left">
                                            <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.street" class="form-control" id="exampleInputStreet" aria-describedby="street" placeholder="Street" />
                                        </div>
                                        <div class="form-group col-md-4 float-left">
                                            <select class="custom-select" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.country">
                                                <option selected>India</option>
                                                <option value="1">Nepal</option>
                                                <option value="2">Bhutan</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-4 float-left">
                                            <select class="custom-select" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.city">
                                                <option selected>New Delhi</option>
                                                <option value="1">New Delhi</option>
                                                <option value="2">New Delhi</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-4 float-left">
                                            <select class="custom-select" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.state">
                                                <option selected>Mumbai</option>
                                                <option value="1">Kolkata</option>
                                                <option value="2">Chennai</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-4 float-left">
                                            <input type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.pin" class="form-control" id="exampleInputPin" aria-describedby="pin" placeholder="Pincode" />
                                        </div>
                                        <!-- <div class="form-group col-md-4 float-left">
                                            <div class="dropdown">
                                                <button class="btn bg-white border dropdown-toggle w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                  Material
                                                </button>
                                                <div class="dropdown-menu w-75" aria-labelledby="dropdownMenuButton">
                                                    <label for="" class="dropdown-item" *ngFor = "let mat of material; let i =index">
                                                        {{mat.material_name}}
                                                        <input type="checkbox" (change)="onSelectMaterial($event,mat.material_name)" value="{{mat.material_name}}" class="form-check-input r-25">
                                                    </label>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="form-group col-md-4 float-left" style="margin-bottom: 32px;">
                                            &nbsp;<br>
                                        </div>
                                        
                                        
                                        <div class="form-group col-md-4 float-left" style="margin-bottom: 32px;">
                                            &nbsp;<br>
                                        </div>

                                        <div class="col-md-4 mx-auto">
                                            <button (click)="insertVendor();" type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" [ngClass]="{'show active' : loginStatus == 2}" id="ven-con" role="tabpanel" aria-labelledby="vcon-tab">
                            <div class="section-content bg-white p-md-3 h-auto clearfix">
                                <div class="section-title mb-md-4">
                                    <!-- <h1 class="mb-md-0">Vendor Contact</h1> -->
                                </div>
                                <div class="section-form">
                                    <form #VendorContact ngForm="VendorContact" (ngSubmit)="onVendorContactForm(vencon)">
                                        <div class="form-group col-md-4 offset-md-8">
                                            <label for="exampleInputEmail1">Number of Directors/Partners</label>
                                            <select (change)="onSelectRowNod($event)" class="custom-select">
                                                <!-- <option value="" selected>Number of Directors/Partners</option> -->
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                                <option>6</option>
                                                <option>7</option>
                                                <option>8</option>
                                                <option>9</option>
                                                <option>10</option>
                                            </select>
                                        </div>
                                        <table id ="nod" class="table">
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Name</th>
                                                    <th>Mobile No.</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let nod of vencon.noddetails">
                                                    <td>
                                                        <div class="form-group">
                                                            <select class="custom-select" name="dir_title" [(ngModel)]="nod.dir_title">
                                                                <option value="Mrs">Mr.</option>
                                                                <option value="Mrs">Mrs.</option>
                                                                <option value="Ms.">Ms.</option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-group col-md-6 float-left">
                                                            <input type="text" name="dir_fname" [(ngModel)]="nod.dir_fname" class="form-control" id="exampleInputFname" aria-describedby="fname" placeholder="First Name" />
                                                        </div>
                                                        <!-- <div class="form-group col-md-4 float-left">
                                                            <input type="text" name="dir_mname" [(ngModel)]="nod.dir_mname" class="form-control" id="exampleInputMname" aria-describedby="mname" placeholder="Middle Name" />
                                                        </div> -->
                                                        <div class="form-group col-md-6 float-left">
                                                            <input type="text" name="dir_lname" [(ngModel)]="nod.dir_lname" class="form-control" id="exampleInputLname" aria-describedby="lname" placeholder="Last Name" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <div class="form-row">
                                                                <div class="col-3">
                                                                    <select class="form-control" name="dir_title">
                                                                        <option value="">+91</option>
                                                                        <option value="">+91</option>
                                                                        <option value="">+91</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="number" name="dir_phone" [(ngModel)]="nod.dir_phone"  class="form-control" id="exampleInputMobile" aria-describedby="mobile" placeholder="Mobile" />
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                        <!-- <div class="form-group col-md-12 float-left">
                                                            <div class="form-group">
                                                                <select class="" name="dir_title">
                                                                    <option value="">+91</option>
                                                                    <option value="">+91</option>
                                                                    <option value="">+91</option>
                                                                </select>
                                                            </div>
                                                            <input type="number" name="dir_phone" [(ngModel)]="nod.dir_phone"  class="form-control" id="exampleInputMobile" aria-describedby="mobile" placeholder="Mobile" />
                                                        </div> -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <h4>First Point of Contact</h4>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Name</th>
                                                    <th>Designation</th>
                                                    <th>Email</th>
                                                    <th>Mobile</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="form-row">
                                                            <div class="col">
                                                                <select name="" id="" class="custom-select">
                                                                    <option value="">Mr.</option>
                                                                    <option value="">Mrs.</option>
                                                                    <option value="">Ms.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-row">
                                                            <div class="col">
                                                                <input type="text" name="fname-point" class="form-control" placeholder="First Name">
                                                            </div>
                                                            <div class="col">
                                                                <input type="text" name="lname-point" class="form-control" placeholder="Last Name">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" name="desig-point" class="form-control" placeholder="Designation">
                                                    </td>
                                                    <td>
                                                        <input type="email" name="email-point" class="form-control" placeholder="Email">
                                                    </td>
                                                    <td>
                                                        <div class="form-row">
                                                            <div class="col-4">
                                                                <select class="form-control" name="">
                                                                    <option value="">+91</option>
                                                                    <option value="">+91</option>
                                                                    <option value="">+91</option>
                                                                </select>
                                                            </div>
                                                            <div class="col">
                                                                <input type="number" name="" class="form-control" id="" aria-describedby="" placeholder="Mobile" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div class="form-group col-md-4 offset-md-8">
                                            <label for="exampleInputEmail1">Number of Offices</label>
                                            <select (change)="onSelectRowNoof($event)" class="custom-select">
                                                <!-- <option value="" selected>Number of Directors/Partners</option> -->
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <!-- <th>Street</th>
                                                    <th>Country</th>
                                                    <th>Pincode</th>
                                                    <th>Office Phone</th>
                                                    <th>Fax</th> -->
                                                    <th>Building No. (office address)</th>
                                                    <th>Street</th>
                                                    <th>Pincode</th>
                                                    <th>City</th>
                                                    <th>State</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let noof of vencon.noofdetails">
                                                    <td>
                                                        <div class="form-group">
                                                            <input type="text" name="offc_street" [(ngModel)]="noof.offc_street"  class="form-control" id="exampleInputStreet" aria-describedby="street" placeholder="Building no." />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <input type="text" name="offc_country" [(ngModel)]="noof.offc_country"  class="form-control" id="exampleInputCountry" aria-describedby="country" placeholder="Street" />
                                                        </div>
                                                        <!-- <div class="form-group col-md-4 float-left">
                                                            <input type="number" name="offc_state" [(ngModel)]="noof.offc_street"  class="form-control" id="exampleInputState" aria-describedby="state" placeholder="Pincode" />
                                                        </div> -->
                                                        <!-- <div class="form-group col-md-4 float-left">
                                                            <input type="text"  name="offc_city" [(ngModel)]="noof.offc_city"  class="form-control" id="exampleInputCity" aria-describedby="city" placeholder="City" />
                                                        </div> -->
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <input type="number" name="offc_state" [(ngModel)]="noof.offc_street"  class="form-control" id="exampleInputState" aria-describedby="state" placeholder="Pincode" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <input type="text"  name="offc_city" [(ngModel)]="noof.offc_city"  class="form-control" id="exampleInputCity" aria-describedby="city" placeholder="City" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <input type="text"  name="offc_pin" [(ngModel)]="noof.offc_pin"  class="form-control" id="exampleInputOfficePin" aria-describedby="offpin" placeholder="State" />
                                                        </div>
                                                    </td>
                                                    <!-- <td>
                                                        <div class="form-group">
                                                            <input type="number"  name="offc_phone" [(ngModel)]="noof.offc_phone"  class="form-control" id="exampleInputOfficeNum" aria-describedby="offnum" placeholder="" />
                                                        </div>
                                                    </td> -->
                                                    <!-- <td>
                                                        <div class="form-group">
                                                            <input type="number"  name="offc_fax" [(ngModel)]="noof.offc_fax"  class="form-control" id="exampleInputFaxNum" aria-describedby="faxnum" placeholder="" />
                                                        </div>
                                                    </td> -->
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div class="form-group col-md-4 offset-md-8">
                                            <!-- <label for="exampleInputEmail1">Number of Offices</label> -->
                                            <label for="exampleInputEmail1">Number of Factories</label>
                                            <select (change)="onSelectRowNof($event)" class="custom-select">
                                                <!-- <option value="" selected>Number of Directors/Partners</option> -->
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <!-- <th>Street</th>
                                                    <th>Country</th>
                                                    <th>Pincode</th>
                                                    <th>Factory Phone</th>
                                                    <th>Fax</th> -->
                                                    <th>Building No. (office address)</th>
                                                    <th>Street</th>
                                                    <th>Pincode</th>
                                                    <th>City</th>
                                                    <th>State</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let nof of vencon.nofdetails">
                                                    <td>
                                                        <div class="form-group">
                                                            <input type="text"  name="factory_street" [(ngModel)]="nof.factory_street"  class="form-control" id="exampleInputStreet" aria-describedby="street" placeholder="Building No." />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <input type="text"  name=""  class="form-control" id="" aria-describedby="street" placeholder="Street" />
                                                        </div>
                                                        <!-- <div class="form-group col-md-4 float-left">
                                                            <label for="">Country</label> -->
                                                            <!-- <select class="custom-select" (change)="venreg.country=$event.target.value"> 
                                        -->
                                                            <!-- <select class="custom-select" name="factory_country" [(ngModel)]="nof.factory_country">
                                                                <option selected>India</option>
                                                                <option value="1">Nepal</option>
                                                                <option value="2">Bhutan</option>
                                                            </select>
                                                        </div> -->
                                                        <!-- <div class="form-group col-md-4 float-left">
                                                            <label for="">State</label>
                                                            <select class="custom-select" name="factory_state" [(ngModel)]="nof.factory_state" >
                                                                <option selected>New Delhi</option>
                                                                <option value="1">New Delhi</option>
                                                                <option value="2">New Delhi</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-4 float-left">
                                                            <label for="">City</label>
                                                            <select class="custom-select" name="factory_city" [(ngModel)]="nof.factory_country">
                                                                <option selected>Mumbai</option>
                                                                <option value="1">Kolkata</option>
                                                                <option value="2">Chennai</option>
                                                            </select>
                                                        </div> -->
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <input type="number" name="" class="form-control" id="" aria-describedby="facpin" placeholder="Pincode" />
                                                        </div>
                                                        <!-- <div class="form-group">
                                                            <input type="number" name="factory_pincode" [(ngModel)]="nof.factory_pincode" class="form-control" id="exampleInputFactoryPin" aria-describedby="facpin" placeholder="" />
                                                        </div> -->
                                                    </td>
                                                    <td>
                                                        <select name="" id="" class="custom-select">
                                                            <option value="">New Delhi</option>
                                                            <option value="">New Delhi</option>
                                                            <option value="">New Delhi</option>
                                                        </select>
                                                        <!-- <div class="form-group">
                                                            <input type="number" name="factory_phone" [(ngModel)]="nof.factory_phone" class="form-control" id="exampleInputFactoryNum" aria-describedby="facnum" placeholder="" />
                                                        </div> -->
                                                    </td>
                                                    <td>
                                                        <select name="" id="" class="custom-select">
                                                            <option value="">New Delhi</option>
                                                            <option value="">New Delhi</option>
                                                            <option value="">New Delhi</option>
                                                        </select>
                                                        <!-- <div class="form-group">
                                                            <input type="number" name="factory_fax" [(ngModel)]="nof.factory_fax" class="form-control" id="exampleInputFaxNum" aria-describedby="faxnum" placeholder="" />
                                                        </div> -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.wname" class="form-control" id="exampleInputWebsite" aria-describedby="website" placeholder="Website" />
                                                </div>
                                                <div class="col-md-10 px-0">
                                                    <div class="form-group">
                                                        <label for="" class="dropdown-item" *ngFor = "let mat of material; let i =index">
                                                            {{mat.material_name}}
                                                            <input type="checkbox" (change)="onSelectMaterial($event,mat.material_name)" value="{{mat.material_name}}" class="form-check-input r-25">
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Landline No.</label>
                                                    <div class="form-row">
                                                        <div class="col-2">
                                                            <select name="" id="" class="custom-select">
                                                                <option value="">+91</option>
                                                                <option value="">+91</option>
                                                                <option value="">+91</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-2">
                                                            <select name="" id="" class="custom-select">
                                                                <option value="">+011</option>
                                                                <option value="">+011</option>
                                                                <option value="">+011</option>
                                                            </select>
                                                        </div>
                                                        <div class="col">
                                                            <input type="number" placeholder="Office No." class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="comment">Company profile :</label>
                                                    <textarea class="form-control" name="cprofile" [(ngModel)]="vencon.cprofile" rows="5" id="" placeholder="Minimum 100 words"></textarea>
                                                </div>
                                                <div class="form-group">
                                                    <label for="">Upload Company Profile</label><br>
                                                    <input type="file" value="select" class="btn btn-sm">
                                                </div>
                                            </div>

                                            <!-- <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="dropdown">
                                                        <button class="btn bg-white border dropdown-toggle w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                          Material
                                                        </button>
                                                        <div class="dropdown-menu w-75" aria-labelledby="dropdownMenuButton">
                                                            <label for="" class="dropdown-item" *ngFor = "let mat of material; let i =index">
                                                                {{mat.material_name}}
                                                                <input type="checkbox" (change)="onSelectMaterial($event,mat.material_name)" value="{{mat.material_name}}" class="form-check-input r-25">
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
    
                                            <!-- <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="comment">Company profile :</label>
                                                    <textarea class="form-control" name="cprofile" [(ngModel)]="vencon.cprofile" rows="5" id=""></textarea>
                                                </div>
                                            </div> -->
                                            <div class="col-md-12">
                                                <label for="">Main Products you deal in</label><br>
                                                <div class="form-group d-flex you-deal-in">
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""></label>
                                                        <input class="form-check-input" type="checkbox" id="" value="Barware" />Barware
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""></label>
                                                        <input class="form-check-input" type="checkbox" id="" value="Kitchenware" />Kitchenware
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""></label>
                                                        <input class="form-check-input" type="checkbox" id="" value="Cookware" />Cookware
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""></label><input class="form-check-input" type="checkbox" id="" value="Storage" />Storage
                                                        
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""></label><input class="form-check-input" type="checkbox" id="" value="Horeca" />Horeca
                                                        
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""></label><input class="form-check-input" type="checkbox" id="" value="Flatware" />Flatware
                                                        
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""></label><input class="form-check-input" type="checkbox" id="" value="Bakware" />Bakware
                                                        
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""></label><input class="form-check-input" type="checkbox" id="" value="Petware" />Petware
                                                        
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""></label><input class="form-check-input" type="checkbox" id="" value="Tableware" />Tableware
                                                        
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""></label><input class="form-check-input" type="checkbox" id="" value="Tea/Coffee/Breakfast" />Tea/Coffee/Breakfast
                                                        
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""></label><input class="form-check-input" type="checkbox" id="" value="Texttile" />Texttile
                                                        
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""></label><input class="form-check-input" type="checkbox" id="" value="others" />others
                                                        
                                                    </div>
                                                </div>
                                                <!-- <div class="form-group">
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="Horeca" />Horeca
                                                        </label>
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="Flatware" />Flatware
                                                        </label>
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="Bakware" />Bakware
                                                        </label>
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="Petware" />Petware
                                                        </label>
                                                    </div>
                                                </div> -->
                                                <!-- <div class="form-check-inline">
                                                    <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="Tableware" />Tableware
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="Tea/Coffee/Breakfast" />Tea/Coffee/Breakfast
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="Texttile" />Texttile
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label class="form-check-label" for=""><input class="form-check-input" type="checkbox" id="" value="others" />others
                                                    </label>
                                                </div> -->
                                                
                                                <!-- <div class="form-group">
                                                    <label for="comment">Main products you deal in: 50 words :</label>
                                                    <textarea class="form-control" name="productdesc" [(ngModel)]="vencon.productdesc" rows="5" id=""></textarea>
                                                </div> -->
                                                <br><br>
                                                <div class="form-group">
                                                    <label for="">Factory Photos</label><br>
                                                    <input type="file" class="btn btn-sm">
                                                </div>
                                            </div>
                                        </div>

                                        

                                        <!-- <div class="offset-md-4 col-md-2 float-md-left">
                                            <input type="file" class="btn-sm" name="doc" (change)="onContactFile($event)">
                                        </div> -->

                                        <!-- <div class="col-md-2 float-md-left ml-md-3">
                                            <button class="btn btn-sm">Add More</button>
                                        </div> -->

                                        <div class="col-md-8 clearfix" style="margin-bottom: 32px;"></div>

                                        <button type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="ven-comm" role="tabpanel" aria-labelledby="vcomm-tab">
                            <div class="section-content bg-white p-md-3 h-auto clearfix">
                                <div class="section-title mb-md-4">
                                    <!-- <h1 class="mb-md-0">Vendor Registration</h1> -->
                                </div>
                                <div class="section-form">
                                    <form #VendorForm ngForm="VendorForm" (ngSubmit)="onVendorCommForm(vencomm)" class="row">
                                        <div class="col-md-12">
                                            <label for="">
                                                PAYMENT TERMS ACCEPTABLE TO YOU
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <label for="">
                                                L/C at sight
                                            </label>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="checkbox" value="L/C at sight" name="payterm1" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">
                                                L/C 30 days
                                            </label>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="checkbox" name="payterm2" value="L/C 30 days" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">
                                                L/C 60 days
                                            </label>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="checkbox" name="payterm3" value="L/C 60 days" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">
                                                Advance and Balance by T/T
                                            </label>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="checkbox" name="payterm4" value="Advance and Balance by T/T" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">
                                                D/P with Advance
                                            </label>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="checkbox" name="payterm5" value="D/P with Advance" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">
                                                D/P without Advance
                                            </label>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="checkbox" name="payterm6" value="D/P without Advance" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">
                                                D/A 30 Days
                                            </label>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="checkbox" name="payterm7" value="D/A 30 Days" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">
                                                D/A 60 Days
                                            </label>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="checkbox" name="payterm8" value="D/A 60 Days" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                                                </label>
                                            </div>
                                        </div>

                                        <!-- <div class="col-md-2">
                                            <label for="">
                                                Escrow Account
                                            </label>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="checkbox" name="payterm9" value="Escrow Account" (change)="onCheckPayTerm($event)" aria-label="Checkbox for following text input"/>
                                                </label>
                                            </div>
                                        </div> -->

                                        <div class="col-12 my-3"></div>
                                        <div class="form-group col-md-4">
                                            <label for="">
                                                RESPONSIBILITY OF QUALITY AND QUANTITY OF SHIPMENT SENT FROM THE DATE OF B/L
                                            </label>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="radio" aria-label="Radio button for following text input" value="none" name="optradio"  [(ngModel)]="vencomm.shipmentfromdate" class="form-check-input" />None 
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                     <input type="radio" aria-label="Radio button for following text input" value="3 months" name="optradio" [(ngModel)]="vencomm.shipmentfromdate" class="form-check-input"/>3 Months
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="radio" aria-label="Radio button for following text input" value="6 months" name="optradio" [(ngModel)]="vencomm.shipmentfromdate" class="form-check-input" />6 Months 
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="radio" aria-label="Radio button for following text input" value="9 months" name="optradio" [(ngModel)]="vencomm.shipmentfromdate" class="form-check-input" />9 Months 
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="radio" aria-label="Radio button for following text input" value="12 months" name="optradio" [(ngModel)]="vencomm.shipmentfromdate" class="form-check-input" />12 Months 
                                                </label>
                                            </div>
                                        </div>


                                        <!-- <div class="col-md-2 float-md-left">
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        3 Months <input type="radio" aria-label="Radio button for following text input" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.rdOne" name="optradio">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-2 float-md-left">
                                                6 Months <input type="radio" aria-label="Radio button for following text input" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.rdTwo">
                                            </div>
                                            <div class="col-md-2 float-md-left">
                                                9 Months <input type="radio" aria-label="Radio button for following text input" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.rdThree">
                                            </div>
                                            <div class="col-md-2 float-md-left">
                                                12 Months <input type="radio" aria-label="Radio button for following text input" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.rdFour">
                                            </div> -->

                                        <!-- <input type="radio" aria-label="Radio button for following text input" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.rdFive"> -->

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                DO YOU KEEP RECORD FOR PHYSICAL MASTER SAMPLES:
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="radio" class="form-check-input" value="yes" [(ngModel)]="vencomm.keeprecord" name="optradiotwo" /> Yes
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="radio" class="form-check-input"  value="no" [(ngModel)]="vencomm.keeprecord" name="optradiotwo" /> No
                                                </label>
                                            </div>
                                        </div>

                                        <!-- Yes
                                            <input type="radio" aria-label="Radio button for following text input" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.rdSix"> No
                                            <input type="radio" aria-label="Radio button for following text input" [ngModelOptions]="{standalone: true}" [(ngModel)]="venreg.rdSeven"> -->

                                        <div class="form-group col-md-6">
                                            <label for="" class="col-md-2 float-md-left">If Yes, How?</label>
                                            <input type="text" [(ngModel)]="vencomm.desc" class="form-control col-md-10 float-md-left" name="desc" id="exampleInputPerson" aria-describedby="person" placeholder="" />
                                        </div>

                                        <div class="form-group col-md-6 pl-md-0">
                                            <label for="" class="col-md-10 float-md-left">
                                                DELIVERY: WHAT % OF SHIPMENT WAS? DELIVERED ON DUE DATE: (IN LAST 12 MONTHS)
                                            </label>
                                            <input type="number" [(ngModel)]="vencomm.avgship" name="avgship" class="form-control col-md-2 float-md-left" id="exampleInputPerson" aria-describedby="person" placeholder="%" />
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label for="" class="col-md-9 float-md-left">
                                                MIN SHIPMENT INVOICE VALUE ACCEPTABLE US$:
                                            </label>
                                            <input type="number" [(ngModel)]="vencomm.minship" name="minship" class="form-control col-md-3 float-md-left" id="exampleInputPerson" aria-describedby="person" placeholder="$" />
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                MIN SHIPMENT VOLUME ACCEPTABLE:
                                            </label>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="vencomm.minshipvol" value="LCL" class="form-check-input" name="optradiothree" />LCL </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="vencomm.minshipvol" value="FCL" class="form-check-input" name="optradiothree" />FCL </label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-4">
                                            WILL YOU SIGN A PENALTY CLAUSE FOR LATE DELIVERY?
                                        </div>

                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="vencomm.latedelivery" class="form-check-input" name="optradiofour" value="yes" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="vencomm.latedelivery" class="form-check-input" name="optradiofour" value="no" />No </label>
                                            </div>
                                        </div>

                                        <button type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="qm-cer" role="tabpanel" aria-labelledby="qm-tab">
                            <div class="section-content bg-white p-md-3 h-auto clearfix">
                                <div class="section-title mb-md-4">
                                    <!-- <h1 class="mb-md-0">Vendor Registration</h1> -->
                                </div>
                                <div class="section-form">
                                    <form #VendorForm ngForm="VendorForm" class="row" (ngSubmit)="qualityCertification(qcData)">
                                        <div class="form-group col-md-6 float-md-left">
                                            ISO-9001 <br /> (SYSTEM & PROCEDURE / QUALITY MANAGEMENT)
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check-inline">
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" value="yes" (change)="onCheckIso($event)" name="optradiofive" />Yes </label>
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" value="no" (change)="onCheckIso($event)"  name="optradiofive" />No </label>
                                                <label class="col-md-2 float-md-left form-check-label">
                                               <input (change)="onFileUpload($event,'iso_9001')" type="file" name="iso_9001" class="form-check-input btn-sm">
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12 float-md-left"></div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for="">
                                                ISO-14001 <br />
                                                (ENVIRONMENTAL MANAGEMENT)
                                            </label>
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check-inline">
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiosix" />Yes </label>
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiosix" />No </label>
                                                <label class="col-md-2 float-md-left form-check-label">
                                                    <input (change)="onFileUpload($event,'iso14001')" type="file" class="form-check-input btn-sm" />
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12 float-md-left"></div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for="">
                                                AN OHSAS-18001 <br />
                                                (HEALTH AND SAFETY MANAGEMENT)
                                            </label>
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check-inline">
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioseven" />Yes </label>
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioseven" />No </label>
                                                <label class="col-md-2 float-md-left form-check-label">
                                                    <input (change)="onFileUpload($event,'ohsas')" type="file" class="form-check-input btn-sm" />
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12 float-md-left"></div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for="">
                                                SA-8000 <br />
                                                (SOCIAL ACCOUNTABILITY MANAGEMENT)
                                            </label>
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check-inline">
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioeight" />Yes </label>
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioeight" />No </label>
                                                <label class="col-md-2 float-md-left form-check-label">
                                                    <input (change)="onFileUpload($event,'sa_8000')" type="file" class="form-check-input btn-sm" />
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12 float-md-left"></div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for="">FAIR TRADE CERTIFICATE</label>
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check-inline">
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradionine" />Yes </label>
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradionine" />No </label>
                                                <label class="col-md-2 float-md-left form-check-label">
                                                    <input (change)="onFileUpload($event,'ftc')" type="file" class="form-check-input btn-sm" />
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12 float-md-left"></div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for="">BRC STANDARD</label>
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check-inline">
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioten" />Yes </label>
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioten" />No </label>
                                                <label class="col-md-2 float-md-left form-check-label">
                                                    <input (change)="onFileUpload($event,'brc')" type="file" class="form-check-input btn-sm" />
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12 float-md-left"></div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for="">WRAP</label>
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check-inline">
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioelev" />Yes </label>
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradioelev" />No </label>
                                                <label class="col-md-2 float-md-left form-check-label">
                                                    <input (change)="onFileUpload($event,'wrap')" type="file" class="form-check-input btn-sm" />
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12 float-md-left"></div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for="">CE</label>
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check-inline">
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiotwel" />Yes </label>
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiotwel" />No </label>
                                                <label class="col-md-2 float-md-left form-check-label">
                                                    <input (change)="onFileUpload($event,'ce')" type="file" class="form-check-input btn-sm" />
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12 float-md-left"></div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for="">UL</label>
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check-inline">
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiothirt" />Yes </label>
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiothirt" />No </label>
                                                <label class="col-md-2 float-md-left form-check-label">
                                                    <input (change)="onFileUpload($event,'ul')" type="file" class="form-check-input btn-sm" />
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12 float-md-left"></div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for="">ROHS</label>
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check-inline">
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiofourt" />Yes </label>
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" class="form-check-input" name="optradiofourt" />No </label>
                                                <label class="col-md-2 float-md-left form-check-label">
                                                    <input (change)="onFileUpload($event,'rohs')" type="file" class="form-check-input btn-sm" />
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12 float-md-left"></div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for="">ANY ADDITIONAL CERTIFICATION [IF YES]</label>
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check-inline">
                                                <label class="col-md-2 float-md-left form-check-label">
                                                    <input (change)="onFileUpload($event,'add_c')" type="file" class="form-check-input btn-sm" />
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12 float-md-left"></div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for="">ANY SOFTWARE FOR DOCUMENTATION & MARKETING</label>
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check">
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.anysoftware" class="form-check-input" name="optradiofif" />Yes </label>
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.anysoftware" class="form-check-input" name="optradiofif" />No </label>

                                                <!-- <label class="col-md-2 float-md-left form-check-label">
                                                    <input type="file" class="form-check-input btn-sm">
                                                </label> -->
                                            </div>
                                        </div>

                                        <div class="col-md-12 float-md-left"></div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for="">ANY ERP SYSTEM</label>
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check">
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.anyerp" class="form-check-input" name="optradiosixt" />Yes </label>
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.anyerp" class="form-check-input" name="optradiosixt" />No </label>

                                                <!-- <label class="col-md-2 float-md-left form-check-label">
                                                    <input type="file" class="form-check-input btn-sm">
                                                </label> -->
                                            </div>
                                        </div>

                                        <div class="col-md-12 float-md-left"></div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for=""><strong>
                                                DO YOU MAINTAIN AN INTERNAL QUALITY MANAGEMENT SYSTEM:
                                            </strong></label>
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check">
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.iqms" class="form-check-input" name="optradiosevnt" />Yes </label>
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.iqms" class="form-check-input" name="optradiosevnt" />No </label>

                                                <!-- <label class="col-md-2 float-md-left form-check-label">
                                                    <input type="file" class="form-check-input btn-sm">
                                                </label> -->
                                            </div>
                                        </div>

                                        <div class="col-md-12 float-md-left">
                                            <h4 class="mt-md-3">QUALITY CONTROL PROCEDURES AND RECORD :</h4>
                                        </div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for="">
                                                DO YOU HAVE QUALITY MAINTAINING/INSPECTION TOOLS TO MEET THE REQUIRED QUALITY STANDARD
                                            </label>
                                        </div>
                                        <div class="form-group col-md-6 float-md-left">
                                            <div class="form-check">
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.inspectiontool" class="form-check-input" name="optradioeighteen" />Yes </label>
                                                <label class="col-md-2 float-md-left form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.inspectiontool" class="form-check-input" name="optradioeighteen" />No </label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6 float-md-left">
                                            <label for="">
                                                Give Details if yes
                                            </label>
                                            <input type="text" name="detail" [(ngModel)]="qcData.detail" class="form-control">
                                        </div>

                                        <div class="form-group col-md-4 float-md-left">
                                            <label for="">
                                                DO YOU KEEP DOCUMENTARY RECORD OF INTERNAL INSPECTION DONE OF PRODUCTS
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2 float-md-left">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.docrecord" class="form-check-input" name="optradioninteen" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.docrecord" class="form-check-input" name="optradioninteen" />No </label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                AT WHAT STAGE IS QUALITY CHECKED:
                                            </label>
                                        </div>
                                        <div class="form-group col-md-8">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="checkbox" (change)="qcCheck($event)" value="RAW MATERIAL IN HOUSE" class="form-check-input"/>RAW MATERIAL IN HOUSE</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="checkbox" (change)="qcCheck($event)" value="HANDWORK DONE" class="form-check-input"/>HANDWORK DONE</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="checkbox" (change)="qcCheck($event)" value="ASSEMBLY COMPLETE" class="form-check-input"/>ASSEMBLY COMPLETE</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="checkbox" (change)="qcCheck($event)" value="AFTER FINISHING" class="form-check-input"/>AFTER FINISHING</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="checkbox" (change)="qcCheck($event)" value="PACKING & LABELLING CHECK" class="form-check-input"/>PACKING & LABELLING CHECK</label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                ARE QA/QC INSPECTORS INDEPENDENT OF PRODUCTION?
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.inspectorindependent" class="form-check-input" name="optradiotwnty" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.inspectorindependent" class="form-check-input" name="optradiotwnty" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <label for="">HOW MANY QA/QC IN TOTAL?</label>
                                            <input type="text" name="qaTotal" [(ngModel)]="qcData.qaTotal" class="form-control d-inline-block w-25 ml-3">
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">WHAT DOES THE QA/QC MANAGER/SUPERVISOR REPORT TO?</label>
                                                <input type="text" name="qaReport" class="form-control">
                                            </div>
                                        </div>
                                        
                                        <div class="form-group col-md-4">
                                            <label for="">
                                                DO YOU TEST FUNCTIONALITY OF PRODUCT?
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.functest" class="form-check-input" name="optradiotwntyone" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.functest" class="form-check-input" name="optradiotwntyone" />No </label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label for="">
                                                TYPES OF FINAL INSPECTION
                                            </label>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="100% INSPECTION" [(ngModel)]="qcData.typeofinspection" class="form-check-input" name="optradiotwntytwo" />100% INSPECTION </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="RANDOM INSPECTION" [(ngModel)]="qcData.typeofinspection" class="form-check-input" name="optradiotwntytwo" />RANDOM INSPECTION </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">
                                                STAFF TRAINING & EVALUATION
                                            </h4>
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                DO YOU HAVE ANY STAFF EVALUATION PROCESS?
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.staffEvaluation" class="form-check-input" name="optradiotwntythree" />Yes</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.staffEvaluation"class="form-check-input" name="optradiotwntythree" />No</label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <div class="form-group">
                                                <label for="">
                                                    WHAT ARE YOUR EVALUATION PARAMETERS, GIVE
                                                </label>
                                                <input type="text" class="form-control" placeholder="Give Details">
                                            </div>
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                DO YOU GIVE ANY PERIODIC TRAINING TO THE STAFF?
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.anyperiodictraining" class="form-check-input" name="optradiotwntyfour" />Yes</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.anyperiodictraining" class="form-check-input" name="optradiotwntyfour" />No</label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">
                                                DOCUMENTATION RECORD
                                            </h4>
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                DO YOU VERIFY ALL DATA IN INVOICE / P.LIST /WT.VOL SHEET AS PER ORDER?   
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.verifyinvoice" class="form-check-input" name="optradiotwntyfive" />Yes</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.verifyinvoice" class="form-check-input" name="optradiotwntyfive" />No</label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                DO YOU NOTIFY BUYER ON SHIPMENT BEFORE DISPATCH? 	
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.notifybeforedispatch" class="form-check-input" name="optradiotwntysix" />Yes</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.notifybeforedispatch" class="form-check-input" name="optradiotwntysix" />No</label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                DO YOU NOTIFY BUYER ON SHIPMENT DISPATCH BEFORE SAILING DISPATCH?	
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.notifybeforesailing" class="form-check-input" name="optradiotwntyseven" />Yes</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.notifybeforesailing" class="form-check-input" name="optradiotwntyseven" />No</label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label for="">
                                                HOW LONG DO YOU TAKE TO SEND ORIGINAL BL/GSP AND ORIGINAL SHIPPING DOCUMENTS AFTER SAILING ?(no. of days)                  	
                                            </label>
                                            <input type="text" name="" class="form-control" placeholder="">
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label for="">
                                                FOR HOW LONG DO YOU MAINTAIN THE SHIPMENT RECORD?                    	
                                            </label>
                                            <input type="text" name="noofyear" class="form-control" [(ngModel)]="qcData.noofyear" placeholder="Number of Years">
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label for="">
                                                WHAT OTHER RECORDS DO YOU MAINTAIN?                    	
                                            </label>
                                            <input type="text" name="" class="form-control" placeholder="">
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">
                                                SAMPLE RECORD
                                            </h4>
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                DO YOU MAINTAIN ANY RECORD OF CUSTOMER SAMPLING 
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio"  class="form-check-input" value="yes" [(ngModel)]="qcData.maintainrecord" name="optradiotwntyeht" />Yes</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" class="form-check-input" value="no" [(ngModel)]="qcData.maintainrecord" name="optradiotwntyeht" />No</label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label for="">
                                                HOW DO YOU MAINTAIN THE SAMPLE RECORD?                  
                                            </label>
                                            <input type="text" name="howmaintain" class="form-control" [(ngModel)]="qcData.howmaintain" placeholder="Give Details">
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                DO YOU RETAIN A PHYSICAL COUNTER SAMPLE OF SAMPLE SENT TO BUYER? 
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.samplesentbuyer" class="form-check-input" name="optradiotwntynne" />Yes</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.samplesentbuyer" class="form-check-input" name="optradiotwntynne" />No</label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label for="">
                                                HOW LONG DO YOU MAINTAIN THE SAMPLE RECORD?                  
                                            </label>
                                            <input type="text" name="howlong" class="form-control" [(ngModel)]="qcData.howlong" placeholder="No. of Years">
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">
                                                CUSTOMER COMPLAINTS
                                            </h4>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label for="">
                                                HOW DO YOU MANAGE CUSTOMER COMPLAINTS?                  
                                            </label>
                                            <input type="text" name="custcomplain" class="form-control" [(ngModel)]="qcData.custcomplain" placeholder="Give Details">
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                DO YOU MAINTAIN RECORD FOR COMPLAINTS?
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.maintainrecordofcomplain" class="form-check-input" name="optradiothrty" />Yes</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.maintainrecordofcomplain" class="form-check-input" name="optradiothrty" />No</label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                DO YOU HONOR CLAIMS?       
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.honorclaim" class="form-check-input" name="optradiothrtyone" />Yes</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.honorclaim" class="form-check-input" name="optradiothrtyone" />No</label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-1">
                                            <label for="">
                                                IF YES       
                                            </label>
                                        </div>
                                        <div class="form-group col-md-5">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="DO YOU REFUND MONEY IN CASE OF CLAIM" [(ngModel)]="qcData.ifyes" class="form-check-input" name="optradiothrtytwo" />DO YOU REFUND MONEY IN CASE OF CLAIM</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="OR ONLY ADJUST/REPLACE IN THE NEXT SHIPMENT" [(ngModel)]="qcData.ifyes" class="form-check-input" name="optradiothrtytwo" />OR    ONLY ADJUST/REPLACE IN THE NEXT SHIPMENT </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">DELIVERY MONITORING & CONTROL TOOLS </h4>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label for="">
                                                DO YOU HAVE ANY DOCUMENT FOR MONITORING DELIVERY?
                                            </label>
                                            <input type="text" name="anydocfordelivery" [(ngModel)]="qcData.anydocfordelivery" class="form-control" placeholder="Give Details">
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                DO YOU HAVE ANY TOOL FOR INTERNAL TASK MANAGEMENT     
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.toolfortaskmgmt"  class="form-check-input" name="optradiothrtythr" />Yes</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.toolfortaskmgmt" class="form-check-input" name="optradiothrtythr" />No</label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">SECURITY CONTROL</h4>
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                DO YOU HAVE ANY SYSTEM FOR CONTROL ON INCOMING AND OUT GOING MATERIAL?     
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.controlonincomingandoutgoing" class="form-check-input" name="optradiothrtyfour" />Yes</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.controlonincomingandoutgoing" class="form-check-input" name="optradiothrtyfour" />No</label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                DO YOU HAVE ANY PROCEDURE FOR REPORTING AND DETECTING SHORTAGES/PILFERAGES?  
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" [(ngModel)]="qcData.reportingshortages" class="form-check-input" name="optradiothrtyfve" />Yes</label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" [(ngModel)]="qcData.reportingshortages" class="form-check-input" name="optradiothrtyfve" />No</label>
                                            </div>
                                        </div>

                                        <button type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="staff-ing" role="tabpanel" aria-labelledby="staff-tab">
                            <div class="section-content bg-white p-md-3 h-auto clearfix">
                                <div class="section-title mb-md-4">
                                    <!-- <h1 class="mb-md-0">Vendor Registration</h1> -->
                                </div>
                                <div class="section-form">
                                    <form #VendorForm ngForm="VendorForm" class="row" (ngSubmit)="staffing(staffData)">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="text" class="form-control" name="nof_factory" [(ngModel)]="staffData.no_of_factory" placeholder="TOTAL NO. OF FACTORY UNITS">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control" name="factory_land_area" [(ngModel)] = "staffData.total_fac_land_area" placeholder="TOTAL FACTORIES LAND AREA (SQ. MTS.)">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <label for="">DO YOU HAVE A SHOWROOM</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                  <input type="radio" class="form-check-input" [(ngModel)]="staffData.have_showroom" value="yes" name="optradioShowR">Yes
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                  <input type="radio" class="form-check-input" [(ngModel)]="staffData.have_showroom" value="no" name="optradioShowR">No
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <label for="">BUILT UP AREA* (SQ. FT.):</label>
                                        </div>
                                        <div class="col-md-9">
                                            <div class="form-check-inline col-md-2">
                                                <label class="form-check-label">
                                                  <input type="radio" class="form-check-input" [(ngModel)]="staffData.built_up_area" value="<=3000" name="optradioBuilt"><=3000
                                                </label>
                                            </div>
                                            <div class="form-check-inline col-md-2">
                                                <label class="form-check-label">
                                                  <input type="radio" class="form-check-input" [(ngModel)]="staffData.built_up_area" value="3001 to 5000" name="optradioBuilt">3001 to 5000
                                                </label>
                                            </div>
                                            <div class="form-check-inline col-md-2">
                                                <label class="form-check-label">
                                                  <input type="radio" class="form-check-input" [(ngModel)]="staffData.built_up_area" value="5001 to 10000" name="optradioBuilt">5001 to 10000
                                                </label>
                                            </div>
                                            <div class="form-check-inline col-md-2">
                                                <label class="form-check-label">
                                                  <input type="radio" class="form-check-input" [(ngModel)]="staffData.built_up_area" value="10001 to 25000" name="optradioBuilt">10001 to 25000
                                                </label>
                                            </div>
                                            <div class="form-check-inline col-md-2">
                                                <label class="form-check-label">
                                                  <input type="radio" class="form-check-input" [(ngModel)]="staffData.built_up_area" value=">25000" name="optradioBuilt">>25000
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="text" class="form-control" name="area_of_showroom" [(ngModel)]="staffData.area_of_showroom" placeholder="AREA OF SHOWROOM (SQ. FT.)">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control" name="no_of_item" [(ngModel)]="staffData.no_of_item" placeholder="NO. OF ITEMS DISPLAYED (APPROX.)">
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">SENIOR STAFF STRENGTH</h4>
                                        </div>
                                        <div class="col-md-10">
                                            <label for="">SALES MANAGER/MERCHANDIZER*:</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <select class="form-control" (change)="onSelectSalesManager($event)">
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>YEAR(S) IN POSITON</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- <tr *ngFor="let sales of staffData.salesManager">
                                                        <td><div class="form-group">
                                                            <input type="text"  name="name" [(ngModel)]="sales.name"  class="form-control" placeholder="" />
                                                        </div></td>
                                                        <td><div class="form-group">
                                                            <input type="text"  name="year" [(ngModel)]="sales.year_in_position"  class="form-control" placeholder="" />
                                                        </div></td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="col-md-10">
                                            <label for="">PURCHASE MANAGER:</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <select class="form-control" (change)="onSelectPurchaseManager($event)" id="sel1">
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>YEAR(S) IN POSITON</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- <tr *ngFor="let p of staffData.purchaseManager">
                                                        <td><div class="form-group">
                                                            <input type="text"  name="name1" [(ngModel)]="p.name"  class="form-control" placeholder="" />
                                                        </div></td>
                                                        <td><div class="form-group">
                                                            <input type="text"  name="year1" [(ngModel)]="p.year_in_position"  class="form-control" placeholder="" />
                                                        </div></td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="col-md-10">
                                            <label for="">PRODUCTION MANAGERS:</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <select class="form-control" (change)="onSelectProductionManager($event)" id="sel1">
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>YEAR(S) IN POSITON</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- <tr *ngFor="let pro of staffData.prodManager">
                                                        <td><div class="form-group">
                                                            <input type="text"  name="name2" [(ngModel)]="pro.name"  class="form-control" placeholder="" />
                                                        </div></td>
                                                        <td><div class="form-group">
                                                            <input type="text"  name="year2" [(ngModel)]="pro.year_in_position"  class="form-control" placeholder="" />
                                                        </div></td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="col-md-10">
                                            <label for="">PRODUCTION SUPERVISOR:</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <select class="form-control" (change)="onSelectProductionSupervisor($event)" id="sel1">
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>YEAR(S) IN POSITON</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- <tr *ngFor="let prod of staffData.prodSupervisor">
                                                        <td><div class="form-group">
                                                            <input type="text"  name="name3" [(ngModel)]="prod.name"  class="form-control" placeholder="" />
                                                        </div></td>
                                                        <td><div class="form-group">
                                                            <input type="text"  name="year3" [(ngModel)]="prod.year_in_position"  class="form-control" placeholder="" />
                                                        </div></td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="col-md-10">
                                            <label for="">Q.A. MANAGER:</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <select class="form-control" (change)="onSelectQaManager($event)" id="sel1">
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>YEAR(S) IN POSITON</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- <tr *ngFor="let q of staffData.qaManager">
                                                        <td><div class="form-group">
                                                            <input type="text"  name="name4" [(ngModel)]="q.name"  class="form-control" placeholder="" />
                                                        </div></td>
                                                        <td><div class="form-group">
                                                            <input type="text"  name="year4" [(ngModel)]="q.year_in_position"  class="form-control" placeholder="" />
                                                        </div></td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="col-md-10">
                                            <label for="">Q.A. SUPERVISOR:</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <select class="form-control" (change)="onSelectQaSupervisor($event)" id="sel1">
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>YEAR(S) IN POSITON</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- <tr *ngFor="let a of staffData.qaSupervisor">
                                                        <td><div class="form-group">
                                                            <input type="text"  name="name5" [(ngModel)]="a.name"  class="form-control" placeholder="" />
                                                        </div></td>
                                                        <td><div class="form-group">
                                                            <input type="text"  name="year5" [(ngModel)]="a.year_in_position"  class="form-control" placeholder="" />
                                                        </div></td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="col-md-10">
                                            <label for="">R & D / DESIGNERS:</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <select class="form-control" (change)="onSelectRDDesigner($event)" id="sel1">
                                                  <option value="1">1</option>
                                                  <option value="1">2</option>
                                                  <option value="1">3</option>
                                                  <option value="1">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>YEAR(S) IN POSITON</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- <tr *ngFor="let r of staffData.rdDesigner">
                                                        <td><div class="form-group">
                                                            <input type="text"  name="name6" [(ngModel)]="r.name"  class="form-control" placeholder="" />
                                                        </div></td>
                                                        <td><div class="form-group">
                                                            <input type="text"  name="year6" [(ngModel)]="r.year_in_position"  class="form-control" placeholder="" />
                                                        </div></td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="col-md-10">
                                            <label for="">PACKING SUPERVISOR:</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <select class="form-control" (change)="onSelectPackingSupervisor($event)" id="sel1">
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>YEAR(S) IN POSITON</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- <tr *ngFor="let pack of staffData.packingSupervisor">
                                                        <td><div class="form-group">
                                                            <input type="text"  name="name7" [(ngModel)]="pack.name"  class="form-control" placeholder="" />
                                                        </div></td>
                                                        <td><div class="form-group">
                                                            <input type="text"  name="year7" [(ngModel)]="pack.year_in_position"  class="form-control" placeholder="" />
                                                        </div></td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-md-10">
                                            <label for="">DOCUMENTATION STAFF:</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <select class="form-control" (change)="onSelectDocStaff($event)" id="sel1">
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>YEAR(S) IN POSITON</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- <tr *ngFor="let doc of staffData.docStaff">
                                                        <td><div class="form-group">
                                                            <input type="text"  name="name8" [(ngModel)]="doc.name"  class="form-control" placeholder="" />
                                                        </div></td>
                                                        <td><div class="form-group">
                                                            <input type="text"  name="year8" [(ngModel)]="doc.year_in_position"  class="form-control" placeholder="" />
                                                        </div></td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-md-10">
                                            <label for="">ACCOUNTS STAFF:</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <select class="form-control" (change)="onSelectAccStaff($event)" id="sel1">
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>YEAR(S) IN POSITON</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- <tr *ngFor="let acc of staffData.accStaff">
                                                        <td><div class="form-group">
                                                            <input type="text"  name="name9" [(ngModel)]="acc.name"  class="form-control" placeholder="" />
                                                        </div></td>
                                                        <td><div class="form-group">
                                                            <input type="text"  name="year9" [(ngModel)]="acc.year_in_position"  class="form-control" placeholder="" />
                                                        </div></td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-md-10">
                                            <label for="">ADMINISTRATION / HR / MAINTENANCE:</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <select class="form-control" (change)="onSelectAdmin($event)" id="sel1">
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <!-- <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>YEAR(S) IN POSITON</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let adm of staffData.adminHr">
                                                        <td><div class="form-group">
                                                            <input type="text"  name="name10" [(ngModel)]="adm.name"  class="form-control" placeholder="" />
                                                        </div></td>
                                                        <td><div class="form-group">
                                                            <input type="text"  name="year10" [(ngModel)]="adm.year_in_position"  class="form-control" placeholder="" />
                                                        </div></td>
                                                    </tr>
                                                </tbody>
                                            </table> -->
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Production staff</th>
                                                        <th colspan="2">On Salary</th>
                                                        <th colspan="2">On Contract</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Sampling</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Production</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>QC department</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Packaging</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">TOTAL FACTORY WORKERS*: </label>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text" name="permanent_worker" [(ngModel)]="staffData.permanent_worker" placeholder="PERMANENT" class="form-control">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text" name="contract_worker" [(ngModel)]="staffData.contract_worker" placeholder="CONTRACT" class="form-control">
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="">
                                                <strong>DO YOU EMPLOY LABOUR BELOW 18 YEAR AGE ** </strong>
                                            </label>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="radio" class="form-check-input" value="yes" [(ngModel)]="staffData.labour_below_18" name="optradioFactoWrkrs">
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label for="" class="form-check-label">
                                                    <input type="radio" class="form-check-input" value="no" [(ngModel)]="staffData.labour_below_18" name="optradioFactoWrkrs">
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <input type="text" class="form-control" name="prod_capacity_pieces" [(ngModel)]="staffData.prod_capacity_pieces" placeholder="PRODUCTION CAPACITY (MONTHLY) PIECES">
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" name="prod_capaity_value" [(ngModel)]="staffData.prod_capacity_value" placeholder="PRODUCTION CAPACITY (MONTHLY) VALUES US$">
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="">NO. OF GENERATORS/POWER BACKUP</label>
                                                <input type="number" name="no_of_generator" [(ngModel)]="staffData.no_of_generator" class="form-control" placeholder="">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>CAPACITY</th>
                                                        <th>QUANTITY</th>
                                                        <th>YEAR MADE</th>
                                                        <th>WORKING OR IDLE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">No. of plant & Machinery</label>
                                                        <input type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">No. of contractor / associate</label>
                                                        <input type="text" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <button type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="leg-fin" role="tabpanel" aria-labelledby="lfin-tab">
                            <div class="section-content bg-white p-md-3 h-auto clearfix">
                                <div class="section-title mb-md-4">
                                    <!-- <h1 class="mb-md-0">Vendor Registration</h1> -->
                                </div>
                                <div class="section-form">
                                    <form #VendorForm ngForm="VendorForm" class="row" (ngSubmit)="legalData(lfData)">
                                        <div class="form-group col-md-4">
                                            <select [(ngModel)]="lfData.company_type" name="company_type" class="custom-select">
                                                <option value="" disabled selected="selected">Type of Company</option>
                                                <option value="Pvt">Pvt.</option>
                                                <option value="Ltd">Ltd.</option>
                                            </select>
                                        </div>

                                        <div class="form-group col-md-4">
                                            <select [(ngModel)]="lfData.year_of_establishment" name="year_of_establishment" class="custom-select">
                                                <option selected>Year of Establishment</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                            </select>
                                        </div>

                                        <div class="col-md-4 float-md-left">
                                            <div class="form-group">
                                                <input type="text" [(ngModel)]="lfData.export" name="export" class="form-control" id="usr" placeholder="Year Export Started " />
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="col-md-3 float-md-left">
                                                <label for="">Mode of Sale</label>
                                            </div>
                                            <div class="col-md-3 float-md-left form-check">
                                                <label class="form-check-label"> <input type="checkbox" class="form-check-input" value="" />Export </label>
                                            </div>
                                            <div class="col-md-6 float-md-left form-check">
                                                <label class="form-check-label"> <input type="checkbox" class="form-check-input" value="" />Domestic within India </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="number" class="form-control" [(ngModel)]="lfData.tin" name="tin" id="usr" placeholder="TIN #" />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="number" class="form-control" id="usr" [(ngModel)]="lfData.rcmc_code" name="rcmc_code" placeholder="RCMC Code #" />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="usr" [(ngModel)]="lfData.council_name" name="council_name" placeholder="Council Name" />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="number" class="form-control" id="usr" [(ngModel)]="lfData.ie_code" name="ie_code" placeholder="IE Code #" />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="number" class="form-control" id="usr" [(ngModel)]="lfData.pan_no" name="pan_no" placeholder="PAN No. #" />
                                            </div>
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label for="">
                                                    Banker's Name & Address
                                                </label>
                                                <div class="form-row">
                                                    <div class="col">
                                                        <input type="text" placeholder="Banker's Name" class="form-control">
                                                    </div>
                                                    <div class="col">
                                                        <input type="text" placeholder="Bander's Address" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="form-group">
                                                <input type="text" class="form-control" id="usr" [(ngModel)]="lfData.banker_name_and_address" name="banker_name_and_address" placeholder="Banker's Name & Address" />
                                            </div> -->
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="text" class="form-control" [(ngModel)]="lfData.street" name="street" id="usr" placeholder="Street" />
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="usr" [(ngModel)]="lfData.city" name="city" placeholder="City" />
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="number" class="form-control" id="usr" [(ngModel)]="lfData.pincode" name="pincode" placeholder="Pincode" />
                                            </div>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <select name="state" [(ngModel)]="lfData.state" class="custom-select">
                                                <option selected>State</option>
                                                <option value="Delhi">Delhi</option>
                                                <option value="Noida">Noida</option>
                                            </select>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="number" class="form-control" name="phone" [(ngModel)]="lfData.phone"  id="usr" placeholder="Phone" />
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="number" class="form-control" placeholder="Bank Swift No.">
                                                <!-- <input type="number" class="form-control" name="fax" [(ngModel)]="lfData.fax"  id="usr" placeholder="Fax" /> -->
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="number" class="form-control" name="banker_ad_code" [(ngModel)]="lfData.banker_ad_code"  id="usr" placeholder="Bankers A.D. Code No" />
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="number" name="ac_no" [(ngModel)]="lfData.ac_no"  class="form-control" id="usr" placeholder="A/C No." />
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" name="usa_routing_bank_name" [(ngModel)]="lfData.usa_routing_bank_name"  id="usr" placeholder="USA Routing Bank Name" />
                                            </div>
                                            <div class="form-row">
                                                <div class="col">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" id="usr" name="bank_ac_no" [(ngModel)]="lfData.bank_ac_no"  placeholder="Routing Bank A/c No." />
                                                    </div>
                                                </div>
        
                                                <div class="col">
                                                    <div class="form-group">
                                                        <input type="number" class="form-control" name="bank_swift_no" [(ngModel)]="lfData.bank_swift_no"  id="usr" placeholder="Bank Swift No" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="number" class="form-control" name="bank_swift_no" [(ngModel)]="lfData.bank_swift_no"  id="usr" placeholder="Bank Swift No." />
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="number" class="form-control" name="iban_no" [(ngModel)]="lfData.iban_no"  id="usr" placeholder="IBAN #" />
                                            </div>
                                        </div> -->

                                        <!-- <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="usr" name="euro_routing_bank_name" [(ngModel)]="lfData.euro_routing_bank_name"  placeholder="Euro Routing Bank Name" />
                                            </div>
                                        </div> -->

                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <label for="comment">Routing Bank Address</label>
                                                <textarea class="form-control" rows="3" name="routing_bank_address" [(ngModel)]="lfData.routing_bank_address"  id="comment"></textarea>
                                            </div>
                                        </div>

                                        <!-- <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control" name="routing_bank_iban" [(ngModel)]="lfData.routing_bank_iban"  id="usr" placeholder="Routing Bank IBAN" />
                                            </div>
                                        </div> -->

                                        <!-- <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="usr" name="bank_ac_no" [(ngModel)]="lfData.bank_ac_no"  placeholder="Routing Bank A/c No." />
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="number" class="form-control" name="bank_swift_no" [(ngModel)]="lfData.bank_swift_no"  id="usr" placeholder="Bank Swift No" />
                                            </div>
                                        </div> -->

                                        <div class="col-md-12"></div>

                                        <!-- <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" name="usa_routing_bank_name" [(ngModel)]="lfData.usa_routing_bank_name"  id="usr" placeholder="USA Routing Bank Name" />
                                            </div>
                                        </div> -->

                                        <!-- <div class="col-md-5">
                                            <div class="form-group">
                                                <label for="comment">Routing Bank Address</label>
                                                <textarea class="form-control" name="usa_routing_bank_address" [(ngModel)]="lfData.usa_routing_bank_address"  rows="3" id="comment"></textarea>
                                            </div>
                                        </div> -->

                                        <!-- <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control" name="usa_routing_bank_iban" [(ngModel)]="lfData.usa_routing_bank_iban"  id="usr" placeholder="Routing Bank IBAN" />
                                            </div>
                                        </div> -->

                                        <!-- <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="text" class="form-control" name="usa_routing_bank_ac_no" [(ngModel)]="lfData.usa_routing_bank_ac_no"  id="usr" placeholder="Routing Bank A/c No." />
                                            </div>
                                        </div> -->

                                        <!-- <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="number" class="form-control" name="usa_bank_swift_no" [(ngModel)]="lfData.usa_bank_swift_no"  id="usr" placeholder="Bank Swift No" />
                                            </div>
                                        </div> -->

                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="usr" name="reg_with_custom" [(ngModel)]="lfData.reg_with_custom"  placeholder="Registered With Customs (Port / Container Depot) At" />
                                                <small>THIS IS CONFIDENTIAL INFORMATION AND WILL NOT BE SHARED WITH ANY ONE EXCEPT A VERIFIED CUSTOMER WHO IS SEEKING TO WORK WITH YOU. </small>
                                            </div>
                                        </div>
                                        <div class="col-12"></div>

                                        <!-- <div class="col-md-6">
                                            <div class="">Wish to Disclose</div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="lfData.disclose" value="yes" class="form-check-input" name="discradio" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="lfData.disclose" value="no" class="form-check-input" name="discradio" />No </label>
                                            </div>
                                        </div> -->

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control"  name="export_turnover" [(ngModel)]="lfData.export_turnover" id="usr" placeholder="Export Turnover" />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="number" class="form-control" id="usr" name="total_capital_invested" [(ngModel)]="lfData.total_capital_invested" placeholder="TOTAL CAPITAL INVESTED" />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="number" class="form-control" id="usr" name="current_net_worth" [(ngModel)]="lfData.current_net_worth" placeholder="CURRENT NET WORTH" />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="number" class="form-control" id="usr" name="growth" [(ngModel)]="lfData.growth" placeholder="GROWTH" />
                                            </div>
                                        </div>

                                        <div class="col-md-8">
                                            <label for="">Debt/Equity</label>
                                            <input (change)="debt($event)" type="range" min="1" max="100" value="50" class="w-100">
                                        </div>

                                        <button type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="soc-com" role="tabpanel" aria-labelledby="scom-tab">
                            <div class="section-content bg-white p-md-3 h-auto clearfix">
                                <div class="section-title mb-md-4">
                                    <!-- <h1 class="mb-md-0">Vendor Registration</h1> -->
                                </div>
                                <div class="section-form">
                                    <form #VendorForm ngForm="VendorForm" class="row" (ngSubmit)="socialCompliance(socialData)">
                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">
                                                ENVIRONMENTAL
                                            </h4>
                                        </div>
                                        <div class="col-md-12">
                                            <label for="">
                                                Do you have any pollution control on following?
                                            </label>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="">
                                                <strong>CHEMICAL</strong>
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.pollution_control" value="yes" class="form-check-input" name="optradioPoll" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.pollution_control" value="no" class="form-check-input" name="optradioPoll" />No </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="">
                                                Are you working with chemicals in your unit?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_with_chemical" value="yes" class="form-check-input" name="optradioChe" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_with_chemical" value="no" class="form-check-input" name="optradioChe" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have a chemical list in every relevant department?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.chemical_list" value="yes" class="form-check-input" name="optradioCheList" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.chemical_list" value="no" class="form-check-input" name="optradioCheList" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have MSDS (Material Safety Data Sheet)?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.msds" value="yes" class="form-check-input" name="optradioMate" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.msds" value="no" class="form-check-input" name="optradioMate" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are you working with high-toxic chemicals in your unit?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.high_toxic_chemical" value="yes" class="form-check-input" name="optradioTox" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.high_toxic_chemical" value="no" class="form-check-input" name="optradioTox" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are all high-toxic chemicals recorded at the Local public security bureau?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.chemical_record_local_psb" value="yes" class="form-check-input" name="optradioLoc" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.chemical_record_local_psb" value="no" class="form-check-input" name="optradioLoc" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are all chemicals and flammable goods stored Properly?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.chemical_goods_stored_properly" value="yes" class="form-check-input" name="optradioFla" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.chemical_goods_stored_properly" value="no" class="form-check-input" name="optradioFla" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have control mechanism for chemical and effluent out flow?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.mech_for_chemical_outflow" value="yes"  class="form-check-input" name="optradioMech" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.mech_for_chemical_outflow" value="no" class="form-check-input" name="optradioMech" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for=""><strong>SMOKE</strong></label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have smoke detectors?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.have_smoke_detector" value="yes" class="form-check-input" name="optradioSmo" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.have_smoke_detector" value="no" class="form-check-input" name="optradioSmo" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have them checked periodically?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.check_smoke_det_perodically" value="yes" class="form-check-input" name="optradioChec" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.check_smoke_det_perodically" value="no" class="form-check-input" name="optradioChec" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for=""><strong>SOUND / NOISE</strong></label>
                                        </div>

                                        <div class="col-md-12">
                                            <label for=""><strong>NOISE LEVELS</strong></label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you provide workers with ear plugs in case of high noise levels in your factory?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.ear_plugs_for_worker" value="yes" class="form-check-input" name="optradioWrk" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.ear_plugs_for_worker" value="no" class="form-check-input" name="optradioWrk" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for=""><strong>WATER HARVESTING</strong></label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have the environmental certificate (Air, water)?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.have_env_certificate" value="yes" class="form-check-input" name="optradioEnv" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.have_env_certificate" value="no" class="form-check-input" name="optradioEnv" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you need it for your unit?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.need_env_certificate_for_unit" value="yes" class="form-check-input" name="optradioNeed" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.need_env_certificate_for_unit" value="no" class="form-check-input" name="optradioNeed" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Have you applied for it?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.applied_for_env_certificate" value="yes" class="form-check-input" name="optradioApp" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.applied_for_env_certificate" value="no" class="form-check-input" name="optradioApp" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for=""><strong>FACTORY WASTAGE/SCRAP</strong></label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Is waste disposed correctly as per Indian law and regulations?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.waste_disposed_correctly" value="yes" class="form-check-input" name="optradioWst" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.waste_disposed_correctly" value="no" class="form-check-input" name="optradioWst" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">
                                                <strong>CARBON CREDIT</strong>
                                            </label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.carbon_credit" value="Positive" class="form-check-input" name="optradioCarCre" />Positive </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.carbon_credit" value="Negative" class="form-check-input" name="optradioCarCre" />Negative </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.carbon_credit" value="Don't know" class="form-check-input" name="optradioCarCre" />Don't Know </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for=""><strong>FACTORY RATING (automatic)</strong></label>
                                        </div>

                                        <div class="col-md-8">
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="1" class="form-check-input" name="optradioRat" />1 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="2" class="form-check-input" name="optradioRat" />2 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="3" class="form-check-input" name="optradioRat" />3 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="4" class="form-check-input" name="optradioRat" />4 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="5" class="form-check-input" name="optradioRat" />5 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="6" class="form-check-input" name="optradioRat" />6 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="7" class="form-check-input" name="optradioRat" />7 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="8" class="form-check-input" name="optradioRat" />8 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="9" class="form-check-input" name="optradioRat" />9 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="10" class="form-check-input" name="optradioRat" />10 </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">Health And Safety</h4>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="">Do you provide for health and safety of staff & infrastructure?</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                    <input type="radio" value="Yes" class="form-check-input" name="radio-health" />Yes 
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> 
                                                    <input type="radio" value="No" class="form-check-input" name="radio-health" />No 
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label for=""><strong>FIRE SAFETY</strong></label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have a fire safety certificate for your building?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.fire_saftey_certificate" value="yes" class="form-check-input" name="optradioSafe" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.fire_saftey_certificate" value="no" class="form-check-input" name="optradioSafe" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Have you applied for it?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.applied_for_fire_certificate" value="yes" class="form-check-input" name="optradioAppl" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.applied_for_fire_certificate" value="no" class="form-check-input" name="optradioAppl" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have enough fire exits and are there signs for the exits?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.fire_exit_and_signs" value="yes" class="form-check-input" name="optradioExi" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.fire_exit_and_signs" value="no" class="form-check-input" name="optradioExi" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have enough and working emergency lights?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_emergency_light" value="yes" class="form-check-input" name="optradioEmerg" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_emergency_light" value="no" class="form-check-input" name="optradioEmerg" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are all emergency exits unobstructed all the time?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.unobstructed_emergency_exits" value="yes" class="form-check-input" name="optradioUno" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.unobstructed_emergency_exits" value="no" class="form-check-input" name="optradioUno" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have enough and working fire extinguisher?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_fire_extinguisher" value="yes" class="form-check-input" name="optradioExtin" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_fire_extinguisher" value="no" class="form-check-input" name="optradioExtin" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have evacuation plot plans on every floor/every department?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.plot_plan_for_floor_and_dept" value="yes" class="form-check-input" name="optradioPlans" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.plot_plan_for_floor_and_dept" value="no" class="form-check-input" name="optradioPlans" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you held up fire drills/fire safety training in the required intervals?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.fire_drill_and_training" value="yes" class="form-check-input" name="optradioInter" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.fire_drill_and_training" value="no" class="form-check-input" name="optradioInter" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                And do you keep pictures of it as proof?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.keep_pic_for_proof" value="yes" class="form-check-input" name="optradioKeep" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.keep_pic_for_proof" value="no" class="form-check-input" name="optradioKeep" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for=""><strong>MEDICAL CARE/ACCIDENTS</strong></label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU MAINTAIN A FULLY STOCKED AND CURRENT DATE FIRST AID KIT?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.current_date_first_aid_kit" value="yes" class="form-check-input" name="optradioStock" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.current_date_first_aid_kit" value="no" class="form-check-input" name="optradioStock" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are there first aid boxes in every department?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.first_aid_every_dept" value="yes" class="form-check-input" name="optradioAid" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.first_aid_every_dept" value="no" class="form-check-input" name="optradioAid" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Is any medicine overdue in the first aid boxes?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.medicine_overdue" value="yes" class="form-check-input" name="optradioOdue" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.medicine_overdue" value="no" class="form-check-input" name="optradioOdue" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Is there anything missing in the first aid boxes?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.missing_in_first_aid_box" value="yes" class="form-check-input" name="optradioMiss" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.missing_in_first_aid_box" value="no" class="form-check-input" name="optradioMiss" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are the first aid boxes easily accessible?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.first_aid_easily_accessible" value="yes" class="form-check-input" name="optradioBox" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.first_aid_easily_accessible" value="no" class="form-check-input" name="optradioBox" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are workers trained for first aid?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_trained" value="yes" class="form-check-input" name="optradioTrain" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_trained" value="no" class="form-check-input" name="optradioTrain" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU USE SAFETY HELMETS AND GLOVES
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.use_saftey_helmet_gloves" value="yes" class="form-check-input" name="optradioSaf" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.use_saftey_helemt_gloves" value="no" class="form-check-input" name="optradioSaf" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do your workers need protective clothing such as safety glasses, protective gloves or ear protectors etc. for their work?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.need_protective_clothing" value="yes" class="form-check-input" name="optradioProt" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.need_protective_clothing" value="no" class="form-check-input" name="optradioProt" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you provide these protective clothes to your workers?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.provide_protective_clothes_to_worker" value="yes" class="form-check-input" name="optradioClo" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.provide_protective_clothes_to_worker" value="no" class="form-check-input" name="optradioClo" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for=""><strong>CLEANLINESS</strong></label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU HAVE HYGENIC CONDITIONS IN AND AROUND YOUR FACILITY?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.hygenic_condition" value="yes" class="form-check-input" name="optradioHyg" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.hygenic_condition" value="no" class="form-check-input" name="optradioHyg" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU HAVE STAFF AND WORKER TOILETS?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.staff_and_worker_toilet" value="yes" class="form-check-input" name="optradioStaf" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.staff_and_worker_toilet" value="no" class="form-check-input" name="optradioStaf" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                (ARE THEY SEPERATE FOR WOMEN)
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.seprate_toilet_for_women" value="yes" class="form-check-input" name="optradioSep" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.seprate_toilet_for_women" value="no" class="form-check-input" name="optradioSep" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU MAKE AVAILABLE POTABLE DRINKING WATER TO ALL STAFF AND WORKERS?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.portable_drinking_water_for_worker_staff" value="yes" class="form-check-input" name="optradioPot" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.portable_drinking_water_for_worker_staff" value="no" class="form-check-input" name="optradioPot" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU HAVE A CLEAN AND SANITIZED EATING AREA FOR STAFF AND EMPLOYEES (FOR FACTORIES WITH OVER 25 STAFF)
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.clean_eating_area" value="yes" class="form-check-input" name="optradioSani" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.clean_eating_area" value="no" class="form-check-input" name="optradioSani" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">Working Conditions </h4>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you provide quality working conditions?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" class="form-check-input" name="radio-qual-con" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" class="form-check-input" name="radio-qual-con" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for=""><strong>AIR QUALITY AND VENTILATION</strong></label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU HAVE ADEQUATE VENTILITATION ON THE SHOP FLOOR
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.adequate_ventilation" value="yes" class="form-check-input" name="optradioAde" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.adequate_ventilation" value="no" class="form-check-input" name="optradioAde" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Is there enough space for all workers in the unit?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.space_for_all_worker" value="yes" class="form-check-input" name="optradioEno" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.space_for_all_worker" value="no" class="form-check-input" name="optradioEno" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU MAINTAIN COMFORTABLE WORKING TEMPERATURE ON THE SHOP FLOOR
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.comf_working_temprature" value="yes" class="form-check-input" name="optradioComf" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.comf_working_temprature" value="no" class="form-check-input" name="optradioComf" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for="">
                                                <strong>LIGHTING</strong>
                                            </label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU MAINTAIN ADEQUATE LIGHTING IN THE FACTORY AREA
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.adequate_lighting" value="yes" class="form-check-input" name="optradioLig" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.adequate_lighting" value="no" class="form-check-input" name="optradioLig" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Is all electric wiring covered with non-flammable material?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.electric_wire_coverd" value="yes" class="form-check-input" name="optradioWir" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.electric_wire_coverd" value="no" class="form-check-input" name="optradioWir" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Is there any loose wiring in the unit?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.loose_wiring_in_unit" value="yes" class="form-check-input" name="optradioLoos" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.loose_wiring_in_unit" value="no" class="form-check-input" name="optradioLoos" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Is there sufficient illumination in all areas of the unit?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.sufficient_illumination" value="yes" class="form-check-input" name="optradioIllu" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.sufficient_illumination" value="no" class="form-check-input" name="optradioIllu" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for=""><strong>PERSONNEL SECURITY</strong></label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU HAVE AN INITIAL CHECK OF EMPLOYEE BACK GROUND AND APPLICATION VERIFICATION?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.employee_app_verification" value="yes" class="form-check-input" name="optradioVri" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.employee_app_verfication" value="no" class="form-check-input" name="optradioVri" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for=""><strong>EDUCATION AND TRAINING</strong></label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU HAVE ANY PROGRAMME FOR UPGADING SKILL SETS OF EMPLOYEES?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.program_for_skill_of_emp" value="yes" class="form-check-input" name="optradioProg" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.program_for_skill_of_emp" value="no" class="form-check-input" name="optradioProg" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU HAVE ANY PRODUCT TRAINING PROGRAMME FOR EMPLOYEES?  
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.prdt_training_program" value="yes" class="form-check-input" name="optradioTrai" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.prdt_training_program" value="no" class="form-check-input" name="optradioTrai" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU HAVE ANY POLICY DOCUMENT/MANUAL FOR THE COMPANY / FACTORY?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.company_manual" value="yes" class="form-check-input" name="optradioDocum" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.company_manual" value="no" class="form-check-input" name="optradioDocum" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12"></div>

                                        <div class="col-md-4">
                                            <label for=""><strong>FACTORY RATING (automatic)</strong></label>
                                        </div>

                                        <div class="col-md-8">
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="1" class="form-check-input" name="optradioFacRat" />1 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="2" class="form-check-input" name="optradioFacRat" />2 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="3" class="form-check-input" name="optradioFacRat" />3 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="4" class="form-check-input" name="optradioFacRat" />4 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="5" class="form-check-input" name="optradioFacRat" />5 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="6" class="form-check-input" name="optradioFacRat" />6 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="7" class="form-check-input" name="optradioFacRat" />7 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="8" class="form-check-input" name="optradioFacRat" />8 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="9" class="form-check-input" name="optradioFacRat" />9 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="10" class="form-check-input" name="optradioFacRat" />10 </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">Social Accountability</h4>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you comply with govt statutory requirements?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="yes" class="form-check-input" name="radio-govt-stat" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="no" class="form-check-input" name="radio-govt-stat" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU COMPLY WITH GOVT LABOUR LAWS?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.comply_with_govt_laws" value="yes" class="form-check-input" name="optradioLabo" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.comply_with_govt_laws" value="no" class="form-check-input" name="optradioLabo" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for="">
                                                <strong>Documentation / Certificates / Insurances</strong>
                                            </label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have business license (Factory license, Shops registration etc.)?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.business_license" value="yes" class="form-check-input" name="optradioBusi" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.business_license" value="no" class="form-check-input" name="optradioBusi" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have labor contracts for all your workers?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.labor_contract_for_all_worker" value="yes" class="form-check-input" name="optradioContr" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.labor_contract_for_all_worker" value="no" class="form-check-input" name="optradioContr" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Is the time in / out of visitors kept correctly?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.time_of_visitor" value="yes" class="form-check-input" name="optradioVisi" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.time_of_visitor" value="no" class="form-check-input" name="optradioVisi" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for="">
                                                <strong>Working & Overtime Hours / Extended Working Hours</strong>
                                            </label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are normal working hours exceeding 8 hours per day?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_hour_exceed" value="yes" class="form-check-input" name="optradioExce" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_hour_exceed" value="no" class="form-check-input" name="optradioExce" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do workers get at least one day off per week? (According to legal requirements)?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_get_one_day_off" value="yes" class="form-check-input" name="optradioOneD" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_get_one_day_off" value="no" class="form-check-input" name="optradioOneD" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU PAY OVER TIME?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.pay_over_time" value="yes" class="form-check-input" name="optradioOverT" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.pay_over_time" value="no" class="form-check-input" name="optradioOverT" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are workers forced to do overtime work?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.forced_for_overtime" value="yes" class="form-check-input" name="optradioForc" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.forced_for_overtime" value="no" class="form-check-input" name="optradioForc" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are overtime hours more than 2 hours per day?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.overtime_hour_more_than_2hr" value="yes" class="form-check-input" name="optradioOverT" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.overtime_hour_more_than_2hr" value="no" class="form-check-input" name="optradioOverT" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are overtime hours more than 12 hours per week?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.overtime_hour_more_than_12hr_week" value="yes" class="form-check-input" name="optradioTweHrs" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.overtime_hour_more_than_12hr_week" value="no" class="form-check-input" name="optradioTweHrs" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are overtime hours more than 50 hours per quarter?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.overtime_hour_more_than_50hr_quater" value="yes" class="form-check-input" name="optradioFifHrs" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.overtime_hour_more_than_50hr_quater" value="no" class="form-check-input" name="optradioFifHrs" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do workers get paid the overtime hours at premium rate?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_for_overtime" value="yes" class="form-check-input" name="optradioPaid" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_for_overtime" value="no" class="form-check-input" name="optradioPaid" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for="">
                                                <strong>Wage Compensation & Compensation Benefits</strong>
                                            </label>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">
                                                Are workers paid per?
                                            </label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="day" class="form-check-input" name="radio-work" />day </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="month" class="form-check-input" name="radio-work" />month </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" value="fortnightly" class="form-check-input" name="radio-work" />fortnightly </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are workers paid per piece rate?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_per_piece_rate" value="yes" class="form-check-input" name="optradioPiec" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_per_piece_rate" value="no" class="form-check-input" name="optradioPiec" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do workers get paid the local minimum wage?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_local_min_Wage" value="yes" class="form-check-input" name="optradioMini" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_local_min_Wage" value="no" class="form-check-input" name="optradioMini" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do workers get paid statutory holiday compensation?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_holiday_comp" value="yes" class="form-check-input" name="optradioHoli" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_holiday_comp" value="no" class="form-check-input" name="optradioHoli" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do workers get their payment in time?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_payment_in_time" value="yes" class="form-check-input" name="optradioPamt" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_payment_in_time" value="no" class="form-check-input" name="optradioPamt" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do workers get paid annual leave or do they get the according number of holidays off?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_annual_leave" value="yes" class="form-check-input" name="optradioAnnu" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_annual_leave" value="no" class="form-check-input" name="optradioAnnu" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Will you pay maternity leave to the workers in case they don't resign from the company?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_maternity_leave" value="yes" class="form-check-input" name="optradioMate" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_maternity_leave" value="no" class="form-check-input" name="optradioMate" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do workers get paid casual and sick leave?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_casual_leave" value="yes" class="form-check-input" name="optradioSic" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_casual_leave" value="no" class="form-check-input" name="optradioSic" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do workers get paid casual and sick leave?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_sick_leave" value="yes" class="form-check-input" name="optradioSic" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_sick_leave" value="no" class="form-check-input" name="optradioSic" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for=""><strong>Involuntary Labour / Discrimination & Harassment</strong></label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do workers have to give you financial deposits to work for your unit?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.deposit_to_work_in_unit" value="yes" class="form-check-input" name="optradioDepo" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.deposit_to_work_in_unit" value="no" class="form-check-input" name="optradioDepo" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do workers have to leave their original ID at your unit?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.leave_id_card_at_unit" value="yes" class="form-check-input" name="optradioLea" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.leave_id_card_at_unit" value="no"class="form-check-input" name="optradioLea" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are any wages held as security deposit until workers stop working for your unit?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_security_deposit" value="yes" class="form-check-input" name="optradioSecur" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_security_deposit" value="no" class="form-check-input" name="optradioSecur" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do workers have to pay for their tools or equipment?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_pay_for_tool" value="yes" class="form-check-input" name="optradioToo" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_pay_for_tool" value="no" class="form-check-input" name="optradioToo" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-10">
                                            <label for="">
                                                Is there any discrimination based on gender, age: religion, race, social background, family responsibility, marital status, disability, sexual orientation, union membership or  political opinions?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.any_discrimination" value="yes" class="form-check-input" name="optradioDisa" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.any_discrimination" value="no" class="form-check-input" name="optradioDisa" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for=""><strong>Social Insurance & Liability Insurance</strong></label>
                                        </div>

                                        <div class="col-md-10">
                                            <label for="">
                                                Does the unit pay social security (PF) for their workers?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.pf_for_worker" value="yes" class="form-check-input" name="optradioSoci" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.pf_for_worker" value="no" class="form-check-input" name="optradioSoci" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-10">
                                            <label for="">
                                                For how many workers do you pay social security (PF)?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.how_many_worker_get_pf" value="yes" class="form-check-input" name="optradioWrke" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.how_many_worker_get_pf" value="no" class="form-check-input" name="optradioWrke" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-10">
                                            <label for="">
                                                Does the unit have social insurance (ESI) for some of their workers?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.esi_for_worker" value="yes" class="form-check-input" name="optradioInsr" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.esi_for_worker" value="no" class="form-check-input" name="optradioInsr" />No </label>
                                            </div>
                                        </div>

                                        <!-- <div class="col-md-10">
                                            <label for="">
                                                For how many workers do they have social insurance (ESI)?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.how_many_worker_get_esi" value="yes" class="form-check-input" name="optradioScil" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.how_many_worker_get_esi" value="no" class="form-check-input" name="optradioScil" />No </label>
                                            </div>
                                        </div> -->

                                        <div class="col-md-12">
                                            <label for=""><strong>Freedom of Association</strong></label>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Can workers choose to associate with any legal groups of their own choosing?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.asso_with_legal_group" value="yes" class="form-check-input" name="optradioGro" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.asso_with_legal_group" value="no" class="form-check-input" name="optradioGro" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have any unions?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.any_union_in_unit" value="yes" class="form-check-input" name="optradioUnio" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.any_union_in_unit" value="no" class="form-check-input" name="optradioUnio" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-10"><label for="">
                                            <strong>Do your employee worker below 18 years</strong>
                                        </label></div>

                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_below_18" value="yes" class="form-check-input" name="optradioBlw" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_below_18" value="no" class="form-check-input" name="optradioBlw" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Do you have copies of proof age documents?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.copy_for_age_doc" value="yes" class="form-check-input" name="optradioCop" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.copy_for_age_doc" value="no" class="form-check-input" name="optradioCop" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Are young workers 15-18 years employed in this unit?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.younger_worker_employed" value="yes" class="form-check-input" name="optradioYrs" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.younger_worker_employed" value="no" class="form-check-input" name="optradioYrs" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                How many hours do they work In a day?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <input type="number" [(ngModel)]="socialData.worker_hour_work_in_day" name="worker_hour_work_in_day" class="form-control" placeholder="Hours">
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Is a regular health check provided for them?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.regular_health_check" value="yes" class="form-check-input" name="optradioHeal" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.regular_health_check" value="no" class="form-check-input" name="optradioHeal" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                Does the underage worker work with hazardous materials / under hazardous conditions?
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.underage_worker_with_hazardous_material" value="yes" class="form-check-input" name="optradioUndag" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.underage_worker_with_hazardous_material" value="no" class="form-check-input" name="optradioUndag" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU HAVE MININUM CASUAL/ MEDICAL LEAVES FOR WORKERS                       
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.min_causal_leave_worker" value="yes" class="form-check-input" name="optradioMinim" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.min_causal_leave_worker" value="no" class="form-check-input" name="optradioMinim" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">
                                                DO YOU HAVE A MINIMUM EDUCATION CRITERION FOR YOUR STAFF/ EMPLOYEES
                                            </label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.min_edu_criterion" value="yes" class="form-check-input" name="optradioEduc" />Yes </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.min_edu_criterion" value="no" class="form-check-input" name="optradioEduc" />No </label>
                                            </div>
                                        </div>

                                        <div class="col-md-12"></div>

                                        <div class="col-md-4">
                                            <label for=""><strong>FACTORY RATING (automatic)</strong></label>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="1" name="optradioFtRat" class="form-check-input" />1 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="2" name="optradioFtRat" class="form-check-input" />2 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="3" name="optradioFtRat" class="form-check-input" />3 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="4" name="optradioFtRat" class="form-check-input" />4 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="5" name="optradioFtRat" class="form-check-input" />5 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="6" name="optradioFtRat" class="form-check-input" />6 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="7" name="optradioFtRat" class="form-check-input" />7 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="8" name="optradioFtRat" class="form-check-input" />8 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="9" name="optradioFtRat" class="form-check-input" />9 </label>
                                            </div>
                                            <div class="col-md-1 form-check-inline">
                                                <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="10" name="optradioFtRat" class="form-check-input" />10 </label>
                                            </div>
                                        </div>


                                        <button type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="infra-str" role="tabpanel" aria-labelledby="infra-tab">
                            <div class="section-content bg-white p-md-3 h-auto clearfix">
                                <div class="section-title mb-md-4">
                                    <!-- <h1 class="mb-md-0">Vendor Registration</h1> -->
                                </div>
                                <div class="section-form">
                                    <form #VendorForm ngForm="VendorForm" class="row" (ngSubmit)="insertInfrastructure(infrastructureData)">

                                        <div class="form-group col-md-4 float-left">
                                            <div class="dropdown">
                                                <button class="btn bg-white border dropdown-toggle w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                  Material
                                                </button>
                                                <div class="dropdown-menu w-75" aria-labelledby="dropdownMenuButton">
                                                    <label for="" class="dropdown-item" *ngFor = "let mat of material; let i =index">
                                                        {{mat.material_name}}
                                                        <input type="checkbox" (change)="onSelectMaterial($event,mat.material_name)" value="{{mat.material_name}}" class="form-check-input r-25">
                                                    </label>
                                                </div>
                                            </div>
                                        </div> 
                                        
                                        <div class="col-md-12">
                                            <h4 class="mb-md-3">
                                                Factory Information - Wooden Furniture/Artware (Life Like Art)
                                            </h4>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Type of Factory</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Rented" name="woodfacradio" [(ngModel)]="infrastructureData.wood_factory_type">
                                                        Rented
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Owned" name="woodfacradio" [(ngModel)]="infrastructureData.wood_factory_type">
                                                        Owned
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Outsourced" name="woodfacradio" [(ngModel)]="infrastructureData.wood_factory_type">
                                                        Outsourced
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Wood chemical treatment</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="woodradio" [(ngModel)]="infrastructureData.wood_treatment">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="woodradio" [(ngModel)]="infrastructureData.wood_treatment">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Seasoning Chamber</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="seasonradio" [(ngModel)]="infrastructureData.seasoning_chamber">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="seasonradio" [(ngModel)]="infrastructureData.seasoning_chamber">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Dry chamber</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="dryradio" [(ngModel)]="infrastructureData.dry_chamber">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="dryradio" [(ngModel)]="infrastructureData.dry_chamber">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Rip saw</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="ripradio" [(ngModel)]="infrastructureData.rip_saw">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="ripradio" [(ngModel)]="infrastructureData.rip_saw">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Double Surface Plainer</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="doubleradio" [(ngModel)]="infrastructureData.double_face_plainer">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="doubleradio" [(ngModel)]="infrastructureData.double_face_plainer">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Tenoning machine</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="tenoningradio" [(ngModel)]="infrastructureData.tenoning_machine">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="tenoningradio" [(ngModel)]="infrastructureData.tenoning_machine">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Mortiser machine</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="mortiser" [(ngModel)]="infrastructureData.mortiser_machine">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="mortiser" [(ngModel)]="infrastructureData.mortiser_machine">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Copy turning lathe</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="latheradio" [(ngModel)]="infrastructureData.copy_turning_lathe">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="latheradio" [(ngModel)]="infrastructureData.copy_turning_lathe">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Pneumatic clamp carrier</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="carrierradio" [(ngModel)]="infrastructureData.pneumatic_clamp_carrier">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="carrierradio" [(ngModel)]="infrastructureData.pneumatic_clamp_carrier">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Wide belt sander</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="beltradio" [(ngModel)]="infrastructureData.wide_belt_sander">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="beltradio" [(ngModel)]="infrastructureData.wide_belt_sander">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Sliding panel saw</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="sawradio" [(ngModel)]="infrastructureData.sliding_panel_saw">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="sawradio" [(ngModel)]="infrastructureData.sliding_panel_saw">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Auto copy shaper</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="shaperradio" [(ngModel)]="infrastructureData.auto_copy_shaper">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="shaperradio" [(ngModel)]="infrastructureData.auto_copy_shaper">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Modern spindle molder</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="molderradio" [(ngModel)]="infrastructureData.modern_spindle_molder">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="molderradio" [(ngModel)]="infrastructureData.modern_spindle_molder">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Dovetail machine</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="doveradio" [(ngModel)]="infrastructureData.dovetail_machine">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="doveradio" [(ngModel)]="infrastructureData.dovetail_machine">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Oscillating belt sander</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="oscillateradio" [(ngModel)]="infrastructureData.oscillationg_belt_sander">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="oscillateradio" [(ngModel)]="infrastructureData.oscillationg_belt_sander">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Horizontal & vertical sponge sanders</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="hvradio" [(ngModel)]="infrastructureData.hv_sponge_sander">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="hvradio" [(ngModel)]="infrastructureData.hv_sponge_sander">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <label for="">Please indicate other equipment present in above factory premises</label>
                                            <input type="text" class="form-control" name="other_equipment" [(ngModel)]="infrastructureData.other_equipment">
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Inhouse carving/inlay work</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="carvingradio" [(ngModel)]="infrastructureData.inhouse_carving" >
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="carvingradio" [(ngModel)]="infrastructureData.inhouse_carving">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Inhouse caning</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="caningradio" [(ngModel)]="infrastructureData.inhouse_caning">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="caningradio" [(ngModel)]="infrastructureData.inhouse_caning">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Inhouse spray painting</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="sprayradio" [(ngModel)]="infrastructureData.inhouse_spray">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="sprayradio" [(ngModel)]="infrastructureData.inhouse_spray">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Inhouse upholstery</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="upholsteryradio" [(ngModel)]="infrastructureData.inhouse_upholstery">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="upholsteryradio" [(ngModel)]="infrastructureData.inhouse_upholstery">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Which type of wood do you use</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline" *ngFor="let wood of woodList; let i=index">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="{{wood}}" (change)="woodType($event)">
                                                       {{wood}}
                                                    </label>
                                                </div>
                                                <!-- <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                                        Sheesham
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                                        Accacia
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                                        Teak
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                                        Oak
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                                        Cherry
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                                        Mahagony
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                                        Walnut
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                                        MDF
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                                        HDF
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                                        PLY
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Mango" (change)="woodType($event)">
                                                        Other
                                                    </label>
                                                </div> -->
                                            </div>
                                        </div>

                                        <div class="col-md-5">
                                            <label for="">If others, please specify</label>
                                            <input type="text" class="form-control" name="other_wood" [(ngModel)]="infrastructureData.other_wood">
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">
                                                Factory Information - Hard Goods
                                            </h4>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Type of Factory</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input"  value="Rented" name="hardgoodfacradio" [(ngModel)]="infrastructureData.hard_good_factory_type">
                                                        Rented
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Owned" name="hardgoodfacradio" [(ngModel)]="infrastructureData.hard_good_factory_type">
                                                        Owned
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Outsourced" name="hardgoodfacradio" [(ngModel)]="infrastructureData.hard_good_factory_type">
                                                        Outsourced
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12"></div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">
                                                    No. of polishing machines
                                                </label>
                                                <select class="form-control" id="" name="hard_good_no_of_polishing_machine" [(ngModel)]="infrastructureData.hard_good_no_of_polishing_machine">
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-12"></div>

                                        <div class="col-md-3">
                                            <label for="">Ultrasonic cleaing machine</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Yes" name="hardgoodultraradio" [(ngModel)]="infrastructureData.hard_good_ultrasonic_cleaning_machine" >
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="hardgoodultraradio" [(ngModel)]="infrastructureData.hard_good_ultrasonic_cleaning_machine">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Regular chemical cleaning</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="hardgoodchemicalradio" [(ngModel)]="infrastructureData.hard_good_regular_chemical_cleaning">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="hardgoodchemicalradio" [(ngModel)]="infrastructureData.hard_good_regular_chemical_cleaning">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Copper plating tank</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="copperradio" [(ngModel)]="infrastructureData.copper_plating_tank">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="copperradio" [(ngModel)]="infrastructureData.copper_plating_tank">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Nickel plating tank</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="nickelradio" [(ngModel)]="infrastructureData.nickel_plating_tank">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="nickelradio" [(ngModel)]="infrastructureData.nickel_plating_tank">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Chrome plating tank</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="chromeradio" [(ngModel)]="infrastructureData.chrome_plating_tank">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="chromeradio" [(ngModel)]="infrastructureData.chrome_plating_tank">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Strike silver tank</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="strikeradio" [(ngModel)]="infrastructureData.strike_silver_tank">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="strikeradio" [(ngModel)]="infrastructureData.strike_silver_tank">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Micron silver tank</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="micronradio" [(ngModel)]="infrastructureData.micron_silver_tank">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="micronradio" [(ngModel)]="infrastructureData.micron_silver_tank">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Anti cyanide/anti-tarnish tank</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="cyanideradio" [(ngModel)]="infrastructureData.anti_cyanide_tank">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="cyanideradio" [(ngModel)]="infrastructureData.anti_cyanide_tank">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">
                                                    Oven with blower - nos.
                                                </label>
                                                <select class="form-control" id="" name="hard_good_nos_oven_with_blower" [(ngModel)]="infrastructureData.hard_good_nos_oven_with_blower">
                                                    <option value="">1</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-12"></div>

                                        <div class="col-md-3">
                                            <label for="">Lacquering plant</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label" value="yes" name="hardgoodlacqueringradioplant" [(ngModel)]="infrastructureData.hard_good_lacquering_plant">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="hardgoodlacqueringradioplant" [(ngModel)]="infrastructureData.hard_good_lacquering_plant">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Powder coating plant</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="powderradio" [(ngModel)]="infrastructureData.powder_coating_plant">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="powderradio" [(ngModel)]="infrastructureData.powder_coating_plant">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">P U Coating plant</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="puradio" [(ngModel)]="infrastructureData.p_u_coating_plant">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="puradio" [(ngModel)]="infrastructureData.p_u_coating_plant">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Do you have inhouse casting facility?</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="castingradio" [(ngModel)]="infrastructureData.inhouse_casting">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="castingradio" [(ngModel)]="infrastructureData.inhouse_casting">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Do you have inhouse metal pressing unit?</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="metalradio" [(ngModel)]="infrastructureData.inhouse_metal_pressing">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="metalradio" [(ngModel)]="infrastructureData.inhouse_metal_pressing">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Do you have inhouse coloring unit?</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="colorradio" [(ngModel)]="infrastructureData.inhouse_color">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="colorradio" [(ngModel)]="infrastructureData.inhouse_color">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Do you do antique colour finish?</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="antiqueradio" [(ngModel)]="infrastructureData.antique_color">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="antiqueradio" [(ngModel)]="infrastructureData.antique_color">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Do you have working electrophoretic plant?</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="electrophorecticradio" [(ngModel)]="infrastructureData.electrophoretic_plant">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="electrophorecticradio" [(ngModel)]="infrastructureData.electrophoretic_plant">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Sand casting</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Inhouse" (change)="hgSandcasting($event)">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value=" Outsourced" (change)="hgSandcasting($event)">
                                                        Outsourced
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Pressure die casting</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Inhouse" (change)="hgPressureDieCasting($event)">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Outsourced" (change)="hgPressureDieCasting($event)">
                                                        Outsourced
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Low wax casting</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Inhouse" (change)="hgLowWaxCasting($event)">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Outsourced" (change)="hgLowWaxCasting($event)">
                                                        Outsourced
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Forging</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Inhouse" (change)="hgForging($event)">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Outsourced" (change)="hgForging($event)">
                                                        Outsourced
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">Factory Information - Glass</h4>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Type of Factory</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Rented" name="glassfacradio" [(ngModel)]="infrastructureData.glass_factory_type">
                                                        Rented
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Owned" name="glassfacradio" [(ngModel)]="infrastructureData.glass_factory_type">
                                                        Owned
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Outsourced" name="glassfacradio" [(ngModel)]="infrastructureData.glass_factory_type">
                                                        Outsourced
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="">
                                                    <strong>Furnance Capacity</strong>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">No. of pots</label>
                                                <select id="" class="form-control" name="no_of_pots" [(ngModel)]="infrastructureData.no_of_pots">
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-12"></div>

                                        <div class="col-md-2">
                                            <label for="">Type of Unit</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" value="Coal" class="form-check-input" (change)="typeUnit($event)">
                                                        Coal
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Diesel" (change)="typeUnit($event)">
                                                        Diesel
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input"  value="Gas" (change)="typeUnit($event)">
                                                        Gas
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input"  value="Electric" (change)="typeUnit($event)">
                                                        Electric
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Type of glass process</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="BLowing" (change)="glassProcess($event)">
                                                        Blowing
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Pressed" (change)="glassProcess($event)">
                                                        Pressed
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Type of glass manufactured</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Tank" (change)="glassManufacturer($event)">
                                                        Tank
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Pot"  (change)="glassManufacturer($event)">
                                                        Pot
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Crystal" (change)="glassManufacturer($event)">
                                                        Crystal
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">
                                                    Color of glass specify
                                                </label>
                                                <select class="form-control" id="" name="glass_color" [(ngModel)]="infrastructureData.glass_color">
                                                    <option value="Blue">Blue</option>
                                                    <option value="Blue">Blue</option>
                                                    <option value="Blue">Blue</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-1">
                                            <label for="">Color Type</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="printed" (change)="glassColorType($event)">
                                                        Printed
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Pot" (change)="glassColorType($event)">
                                                        Pot
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">
                                                    No. of glass grinding machine
                                                </label>
                                                <select name="" id="" class="form-control" name="no_of_glass_griding_machine" [(ngModel)]="infrastructureData.no_of_glass_griding_machine">
                                                    <option value="1">1</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">
                                                    No. of glass cutting machine
                                                </label>
                                                <select name="" id="" class="form-control" name="no_of_glass_cutting_machine" [(ngModel)]="infrastructureData.no_of_glass_cutting_machine">
                                                    <option value="1">1</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-1">
                                            <label for="">Frosting</label>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-ten">
                                                        Chemical
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-ten">
                                                        Sand
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Crackle</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="crckl" value="yes" [(ngModel)]="infrastructureData.crackle">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="crckl" value="no" [(ngModel)]="infrastructureData.crackle">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Mosaic</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="msac" value="yes" [(ngModel)]="infrastructureData.mosaic">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="msac" value="no" [(ngModel)]="infrastructureData.mosaic">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">No. of ovens</label>
                                                <select name="" id="" class="form-control" name="no_of_ovens" [(ngModel)]="infrastructureData.no_of_ovens">
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">Ovens Capacity</label>
                                                <select name="" id="" class="form-control" name="oven_capacity" [(ngModel)]="infrastructureData.oven_capacity">
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">Type of other machines</label>
                                                <input type="text" class="form-control" placeholder="" name="other_machine" [(ngModel)]="infrastructureData.other_machine">
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">
                                                Factory Information - Stainless Steel Goods
                                            </h4>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Type of Factory</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="steelfacradio" value="Rented" [(ngModel)]="infrastructureData.steel_factory_type">
                                                        Rented
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="steelfacradio" value="Owned" [(ngModel)]="infrastructureData.steel_factory_type">
                                                        Owned
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="steelfacradio" value="Outsourced" [(ngModel)]="infrastructureData.steel_factory_type">
                                                        Outsourced
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Grade Quality of steel used</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="AIS203" (change)="steelGradeQuality($event)">
                                                        AIS203
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="AIS304" (change)="steelGradeQuality($event)">
                                                        AIS304
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" value="Other" (change)="steelGradeQuality($event)">
                                                        Other
                                                    </label>
                                                </div>
                                                <input type="text" class="form-control" name="other_steel_quality" [(ngModel)]="infrastructureData.other_steel_quality" placeholder="If other, please specify">
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Pressing machine</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="pressradio" value="yes" [(ngModel)]="infrastructureData.pressing_machine">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="pressradio" value="no" [(ngModel)]="infrastructureData.pressing_machine">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">
                                                    No. of polishing machines
                                                </label>
                                                <select name="" id="" class="form-control" name="no_of_polishing_machine" [(ngModel)]="infrastructureData.no_of_polishing_machine">
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Ultrasonic cleaing machine</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="ultraradio" value="yes" [(ngModel)]="infrastructureData.ultrasonic_cleaning_machine">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="ultraradio" value="no" [(ngModel)]="infrastructureData.ultrasonic_cleaning_machine">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Shearing machine</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="shearradio" value="yes" [(ngModel)]="infrastructureData.shearing_machine">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="shearradio" value="no" [(ngModel)]="infrastructureData.shearing_machine">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Cup rolling machine</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="cupradio" value="yes" [(ngModel)]="infrastructureData.cup_rollig_machine">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="cupradio" value="no" [(ngModel)]="infrastructureData.cup_rollig_machine">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Chemical machine</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="chemicalradio" value="yes" [(ngModel)]="infrastructureData.chemical_machine">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="chemicalradio" value="no" [(ngModel)]="infrastructureData.chemical_machine">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">
                                                    Oven with blower - nos.
                                                </label>
                                                <select name="" id="" class="form-control" name="nos_oven_with_blower" [(ngModel)]="infrastructureData.nos_oven_with_blower">
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <label for="">Welding process used</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-twelve">
                                                        Argon
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-twelve">
                                                        Normal
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">Please indicate other equipment present in above factory premises</label>
                                                <select name="" id="" class="form-control" name="other_equipment" [(ngModel)]="infrastructureData.other_equipment">
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Do you make items in combination with brass or other metal</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="comboradio" [(ngModel)]="infrastructureData.combo_with_brass">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="comboradio" [(ngModel)]="infrastructureData.combo_with_brass">
                                                        No
                                                    </label>
                                                </div>
                                                <input type="text" name="combo_detail" [(ngModel)]="infrastructureData.combo_detail" class="form-control" placeholder="if yes, please specify">
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mt-md-3">
                                                Factory Information - Iron & Wrought Iron Goods & Furniture
                                            </h4>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Type of Factory</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Rented" name="ironfacradio" [(ngModel)]="infrastructureData.iron_factory_type">
                                                        Rented
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Owned" name="ironfacradio" [(ngModel)]="infrastructureData.iron_factory_type">
                                                        Owned
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Outsourced" name="ironfacradio" [(ngModel)]="infrastructureData.iron_factory_type">
                                                        Outsourced
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">No. of polishing machines</label>
                                                <select id="" class="form-control" name="no_of_polishing_machines" [(ngModel)]="infrastructureData.no_of_polishing_machines">
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">Regular chemical cleaing</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="chemicalradio" [(ngModel)]="infrastructureData.regular_chemical_cleaning">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="chemicalradio" [(ngModel)]="infrastructureData.regular_chemical_cleaning">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">Ultrasonic cleaing machine</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="ultraradio" [(ngModel)]="infrastructureData.ultrasonic_cleaning_machine">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="ultraradio" [(ngModel)]="infrastructureData.ultrasonic_cleaning_machine">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">
                                                    Oven with blower - nos.
                                                </label>
                                                <select class="form-control" id="" name="nos_oven_with_blower" [(ngModel)]="infrastructureData.nos_oven_with_blower">
                                                    <option value="1">1</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-12"></div>

                                        <div class="col-md-4">
                                            <label for="">Iron casting</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="ironradio" [(ngModel)]="infrastructureData.iron_casting">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="ironradio" [(ngModel)]="infrastructureData.iron_casting">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">Iron Forging</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Outsourced" name="ironforgingradio" [(ngModel)]="infrastructureData.iron_forging">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Outsourced" name="ironforgingradio" [(ngModel)]="infrastructureData.iron_forging">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">Galvanizing process</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="galradio" [(ngModel)]="infrastructureData.galvanizing_process">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="galradio" [(ngModel)]="infrastructureData.galvanizing_process">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">Phosphating process</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="phosradio" [(ngModel)]="infrastructureData.phosphating_process">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="phosradio" [(ngModel)]="infrastructureData.phosphating_process">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">Electroplating process</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="electroradio" [(ngModel)]="infrastructureData.electroplating_plant">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="electroradio" [(ngModel)]="infrastructureData.electroplating_plant">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">Powder coating process</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="ironpowderradio" [(ngModel)]="infrastructureData.iron_powder_coating_process">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="ironpowderradio" [(ngModel)]="infrastructureData.iron_powder_coating_process">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">Spray painting process</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="ironsprayradio" [(ngModel)]="infrastructureData.iron_spray_painting_process">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="ironsprayradio" [(ngModel)]="infrastructureData.iron_spray_painting_process">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">Lacquering process</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="ironlacqueringradioprocess" [(ngModel)]="infrastructureData.iron_lacquering_process" >
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="ironlacqueringradioprocess" [(ngModel)]="infrastructureData.iron_lacquering_process">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">Sand blasting process</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="ironsandradio" [(ngModel)]="infrastructureData.iron_sand_blasting">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="ironsandradio" [(ngModel)]="infrastructureData.iron_sand_blasting">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">Artistic hand painting is done in your premises?</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="artisticradio" [(ngModel)]="infrastructureData.artistic_hand_painting">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="artisticradio" [(ngModel)]="infrastructureData.artistic_hand_painting">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="">Do you have your own sheet metal pressing unit?</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Outsourced" name="sheetradio" [(ngModel)]="infrastructureData.sheet_metal_pressing">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Outsourced" name="sheetradio" [(ngModel)]="infrastructureData.sheet_metal_pressing">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4 class="mb-md-3">
                                                Factory Information
                                            </h4>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Type of Factory</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Rented" name="facradio" [(ngModel)]="infrastructureData.factory_type">
                                                        Rented
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Owned" name="facradio" [(ngModel)]="infrastructureData.factory_type">
                                                        Owned
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="Outsourced" name="facradio" [(ngModel)]="infrastructureData.factory_type">
                                                        Outsourced
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Do you manufacture your own fabric?</label>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="yes" name="fabricradio" [(ngModel)]="infrastructureData.own_fabric_manufacture">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="radio" class="form-check-input" value="no" name="fabricradio" [(ngModel)]="infrastructureData.own_fabric_manufacture">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Do you do printing?</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" >
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-thirteen">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Which dye/colour you use?</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-fourteen">
                                                        Vegetable
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-fourteen">
                                                        Chemical
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-fourteen">
                                                        Other
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Fabrication</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-fifteen">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-fifteen">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">No. of stitching machine</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="number" class="form-control" placeholder="Inhouse" name="inhouse_stitching_machine" [(ngModel)]="infrastructureData.inhouse_stitching_machine">
                                            </div>
                                            <div class="form-group">
                                                <input type="number" class="form-control" placeholder="Outsource" name="outsource_stitching_machine" [(ngModel)]="infrastructureData.outsource_stitching_machine ">
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">No. of inter lock machine</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="number" class="form-control" placeholder="Inhouse" name="inhouse_interlock_machine" [(ngModel)]="infrastructureData.inhouse_interlock_machine">
                                            </div>
                                            <div class="form-group">
                                                <input type="number" class="form-control" placeholder="Outsource" name="outsource_interlock_machine" [(ngModel)]="infrastructureData.outsource_interlock_machine">
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">No. of over lock machine</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="number" class="form-control" placeholder="Inhouse" name="inhouse_overlook_machine" [(ngModel)]="infrastructureData.inhouse_overlook_machine">
                                            </div>
                                            <div class="form-group">
                                                <input type="number" class="form-control" placeholder="Outsource" name="outsource_overlook_machine" [(ngModel)]="infrastructureData.outsource_overlook_machine">
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Other machines, please specify</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="" name="other_machine" [(ngModel)]="infrastructureData.other_machine">
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">No. of other machine</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="number" class="form-control" placeholder="Inhouse" name="inhouse_other_machine" [(ngModel)]="infrastructureData.inhouse_other_machine">
                                            </div>
                                            <div class="form-group">
                                                <input type="number" class="form-control" placeholder="Outsource" name="outsource_other_machine" [(ngModel)]="infrastructureData.outsource_other_machine">
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Finishing Unit</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-sixteen">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-sixteen">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="">Details of Finishing machinery</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <textarea class="form-control" name="detail_finishing_machinery" [(ngModel)]="infrastructureData.detail_finishing_machinery" rows="4" id=""></textarea>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for="">
                                                <strong>Do you have cushion's filler?</strong>
                                            </label>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Vaccum packed arrangement</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-seventeen">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-seventeen">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Computer Embroidery</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-eighteen">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-eighteen">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Hand Embroidery</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-nineteen">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-nineteen">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <label for=""><strong>For carpet/rugs manufacture</strong></label>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Roller beam loom</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-twenty">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-twenty">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Hand loom frame</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-one">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-one">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Loom dobby</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-two">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-two">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Hand loom jacquard</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-three">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-three">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Pit Loom</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-four">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-four">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Punja loom</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-five">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-five">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Power loom dobby</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-six">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-six">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Power loom jacquard</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-seven">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-seven">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <label for="">Braiding machine</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-eight">
                                                        Inhouse
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label for="" class="form-check-label">
                                                        <input type="checkbox" class="form-check-input" name="check-tw-eight">
                                                        Outsource
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12"></div>

                                        <button  type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button>

                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="pro-ent" role="tabpanel" aria-labelledby="prodent-tab">
                            <div class="section-content bg-white p-md-3 h-auto clearfix">
                                <div class="section-title mb-md-4">
                                    <!-- <h1 class="mb-md-0">Vendor Registration</h1> -->
                                </div>
                                <div class="section-form">
                                    <form #VendorForm ngForm="VendorForm" class="row">
                                        <div class="col-md-12">
                                            <h4 class="mb-md-3">
                                                Product Entry
                                            </h4>
                                        </div>

                                        <button type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>












<!-- <section class="vendor-regis section" id="vendorRegis">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 offset-md-3">
                <div class="section-content">
                    <div class="section-title mb-md-4">
                        <h1 class="mb-md-0">Vendor Registration</h1>
                    </div>
                    <div class="section-form">
                        <form #VendorForm ngForm="VendorForm">
                            <div class="form-group col-md-6 float-left">
                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.cname"  class="form-control" id="company" aria-describedby="company" placeholder="Company Name" />
                            </div>
                            <div class="form-group col-md-6 float-left">
                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.wname" class="form-control" id="exampleInputWebsite" aria-describedby="website" placeholder="Website" />
                            </div>
                            <div class="form-group col-md-4 float-left">
                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.conpername" class="form-control" id="exampleInputPerson" aria-describedby="person" placeholder="Contact Person" />
                            </div>
                            <div class="form-group col-md-4 float-left">
                                <select class="custom-select">
                                    <option selected>Mr.</option>
                                    <option value="1">Mrs.</option>
                                    <option value="2">Ms.</option>
                                </select>
                            </div>
                            <div class="form-group col-md-4 float-left">
                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.uname" class="form-control" id="exampleInputUser" aria-describedby="user" placeholder="Username" />
                            </div>
                            <div class="form-group col-md-6 float-left">
                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.mob" class="form-control" id="exampleInputMobile" aria-describedby="mobile" placeholder="Mobile" />
                            </div>
                            <div class="form-group col-md-6 float-left">
                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.offnum" class="form-control" id="exampleInputOffice" aria-describedby="office" placeholder="Office Number" />
                            </div>
                            <div class="form-group col-md-6 float-left">
                                <textarea class="form-control" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.offadd" id="exampleFormOffAddress" rows="3" placeholder="Office Address"></textarea>
                            </div>
                            <div class="form-group col-md-3 float-left">
                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.street" class="form-control" id="exampleInputStreet" aria-describedby="street" placeholder="Street" />
                            </div>
                            <div class="form-group col-md-3 float-left">
                                <select class="custom-select">
                                    <option selected>India</option>
                                    <option value="1">Nepal</option>
                                    <option value="2">Bhutan</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6 float-left">
                                <select class="custom-select">
                                    <option selected>New Delhi</option>
                                    <option value="1">New Delhi</option>
                                    <option value="2">New Delhi</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6 float-left">
                                <select class="custom-select">
                                    <option selected>Mumbai</option>
                                    <option value="1">Kolkata</option>
                                    <option value="2">Chennai</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6 float-left">
                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.pin" class="form-control" id="exampleInputPin" aria-describedby="pin" placeholder="Pincode" />
                            </div>
                            <div class="form-group col-md-4 float-left">
                                
                                <input type="email" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.mail" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email ID" />
                                
                            </div>
                            <div class="form-group col-md-4 float-left">
                                
                                <input type="password" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.pass" class="form-control" id="exampleInputPassword" placeholder="Password" />
                            </div>
                            <div class="form-group col-md-4 float-left">
                                
                                <input type="password" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.conpass" class="form-control" id="exampleInputConPassword" placeholder="Confirm Password" />
                            </div>
                            
                            <button (click)="insertVendor(); " type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button>
                        </form>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="vendor-contact section" id="vendorContact">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 offset-md-3">
                <div class="section-content">
                    <div class="section-title mb-md-4">
                        <h1 class="mb-md-0">Vendor Contact</h1>
                    </div>
                    <div class="section-form">
                        <form #VendorContact ngForm="VendorContact">
                            <div class="form-group col-md-4 offset-md-8">
                                <label for="exampleInputEmail1">Number of Directors/Partners</label>
                                <select class="custom-select">
                                    
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                </select>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Name</th>
                                        <th>Mobile No.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="form-group">
                                                <select class="custom-select">
                                                    <option selected>Mr.</option>
                                                    <option value="1">Mrs.</option>
                                                    <option value="2">Ms.</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group col-md-4 float-left">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.fname" class="form-control" id="exampleInputFname" aria-describedby="fname" placeholder="First Name" />
                                            </div>
                                            <div class="form-group col-md-4 float-left">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.mname" class="form-control" id="exampleInputMname" aria-describedby="mname" placeholder="Middle Name" />
                                            </div>
                                            <div class="form-group col-md-4 float-left">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.lname" class="form-control" id="exampleInputLname" aria-describedby="lname" placeholder="Last Name" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.mobnum" class="form-control" id="exampleInputMobile" aria-describedby="mobile" placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="form-group col-md-4 offset-md-8">
                                <label for="exampleInputEmail1">Number of Offices</label>
                                <select class="custom-select">
                                    
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Street</th>
                                        <th>Country</th>
                                        <th>Office Phone</th>
                                        <th>Fax</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.street" class="form-control" id="exampleInputStreet" aria-describedby="street" placeholder="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group col-md-4 float-left">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.coun" class="form-control" id="exampleInputCountry" aria-describedby="country" placeholder="Country" />
                                            </div>
                                            <div class="form-group col-md-4 float-left">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.state" class="form-control" id="exampleInputState" aria-describedby="state" placeholder="State" />
                                            </div>
                                            <div class="form-group col-md-4 float-left">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.city" class="form-control" id="exampleInputCity" aria-describedby="city" placeholder="City" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.offnum" class="form-control" id="exampleInputOfficeNum" aria-describedby="offnum" placeholder="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.fnum" class="form-control" id="exampleInputFaxNum" aria-describedby="faxnum" placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="form-group col-md-4 offset-md-8">
                                <label for="exampleInputEmail1">Number of Offices</label>
                                <select class="custom-select">
                                    
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Street</th>
                                        <th>Country</th>
                                        <th>Pincode</th>
                                        <th>Factory Phone</th>
                                        <th>Fax</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.facstreet" class="form-control" id="exampleInputStreet" aria-describedby="street" placeholder="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group col-md-4 float-left">
                                                <label for="">Country</label>
                        
                            <select class="custom-select">
                                                    <option selected>India</option>
                                                    <option value="1">Nepal</option>
                                                    <option value="2">Bhutan</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-4 float-left">
                                                <label for="">State</label>
                                                <select class="custom-select">
                                                    <option selected>New Delhi</option>
                                                    <option value="1">New Delhi</option>
                                                    <option value="2">New Delhi</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-4 float-left">
                                                <label for="">City</label>
                                                <select class="custom-select">
                                                    <option selected>Mumbai</option>
                                                    <option value="1">Kolkata</option>
                                                    <option value="2">Chennai</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.facpin" class="form-control" id="exampleInputFactoryPin" aria-describedby="facpin" placeholder="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="number"[ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.facnum" class="form-control" id="exampleInputFactoryNum" aria-describedby="facnum" placeholder="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="venreg.ffaxnum" class="form-control" id="exampleInputFaxNum" aria-describedby="faxnum" placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <button type="submit" (click)="insertVendor();" class="btn btn-primary d-md-block mx-auto">Submit</button>
                        </form>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->