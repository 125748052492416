<section class="vendor-contact section" id="vendorContact">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 offset-md-3">
                <div class="section-content">
                    <div class="section-title mb-md-4">
                        <h1 class="mb-md-0">Vendor Contact</h1>
                    </div>
                    <div class="section-form">
                        <form #VendorContact ngForm="VendorContact">
                            <div class="form-group col-md-4 offset-md-8">
                                <label for="exampleInputEmail1">Number of Directors/Partners</label>
                                <select class="custom-select">
                                    <!-- <option value="" selected>Number of Directors/Partners</option> -->
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                </select>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Name</th>
                                        <th>Mobile No.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="form-group">
                                                <select class="custom-select">
                                                    <option selected>Mr.</option>
                                                    <option value="1">Mrs.</option>
                                                    <option value="2">Ms.</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group col-md-4 float-left">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="vencon.fname" class="form-control" id="exampleInputFname" aria-describedby="fname" placeholder="First Name" />
                                            </div>
                                            <div class="form-group col-md-4 float-left">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="vencon.mname" class="form-control" id="exampleInputMname" aria-describedby="mname" placeholder="Middle Name" />
                                            </div>
                                            <div class="form-group col-md-4 float-left">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="vencon.lname" class="form-control" id="exampleInputLname" aria-describedby="lname" placeholder="Last Name" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="vencon.mobnum" class="form-control" id="exampleInputMobile" aria-describedby="mobile" placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="form-group col-md-4 offset-md-8">
                                <label for="exampleInputEmail1">Number of Offices</label>
                                <select class="custom-select">
                                    <!-- <option value="" selected>Number of Directors/Partners</option> -->
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Street</th>
                                        <th>Country</th>
                                        <th>Office Phone</th>
                                        <th>Fax</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="vencon.street" class="form-control" id="exampleInputStreet" aria-describedby="street" placeholder="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group col-md-4 float-left">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="vencon.coun" class="form-control" id="exampleInputCountry" aria-describedby="country" placeholder="Country" />
                                            </div>
                                            <div class="form-group col-md-4 float-left">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="vencon.state" class="form-control" id="exampleInputState" aria-describedby="state" placeholder="State" />
                                            </div>
                                            <div class="form-group col-md-4 float-left">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="vencon.city" class="form-control" id="exampleInputCity" aria-describedby="city" placeholder="City" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="vencon.offnum" class="form-control" id="exampleInputOfficeNum" aria-describedby="offnum" placeholder="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="vencon.fnum" class="form-control" id="exampleInputFaxNum" aria-describedby="faxnum" placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="form-group col-md-4 offset-md-8">
                                <label for="exampleInputEmail1">Number of Offices</label>
                                <select class="custom-select">
                                    <!-- <option value="" selected>Number of Directors/Partners</option> -->
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Street</th>
                                        <th>Country</th>
                                        <th>Pincode</th>
                                        <th>Factory Phone</th>
                                        <th>Fax</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="form-group">
                                                <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="vencon.facstreet" class="form-control" id="exampleInputStreet" aria-describedby="street" placeholder="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group col-md-4 float-left">
                                                <label for="">Country</label>
                                                <select class="custom-select">
                                                    <option selected>India</option>
                                                    <option value="1">Nepal</option>
                                                    <option value="2">Bhutan</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-4 float-left">
                                                <label for="">State</label>
                                                <select class="custom-select">
                                                    <option selected>New Delhi</option>
                                                    <option value="1">New Delhi</option>
                                                    <option value="2">New Delhi</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-4 float-left">
                                                <label for="">City</label>
                                                <select class="custom-select">
                                                    <option selected>Mumbai</option>
                                                    <option value="1">Kolkata</option>
                                                    <option value="2">Chennai</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="vencon.facpin" class="form-control" id="exampleInputFactoryPin" aria-describedby="facpin" placeholder="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="number"[ngModelOptions]="{standalone: true}"  [(ngModel)]="vencon.facnum" class="form-control" id="exampleInputFactoryNum" aria-describedby="facnum" placeholder="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <input type="number" [ngModelOptions]="{standalone: true}"  [(ngModel)]="vencon.ffaxnum" class="form-control" id="exampleInputFaxNum" aria-describedby="faxnum" placeholder="" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <button type="submit" (click)="vendorContact();" class="btn btn-primary d-md-block mx-auto">Submit</button>
                        </form>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

