import { Component, OnInit } from '@angular/core';

import { WebapiService } from '../services/webapi.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  formData:any={};
  vendorData:any={};
  countryFlag: boolean=false;
  countryList: any;
  phoneCode:any;
  titleList:any;
  business:any=[]

  constructor(private router: Router,
    private WebapiService:WebapiService) { }

  ngOnInit(): void {
    this.titleList=["Mrs", "Mr", "Ms"];
    this.business = ["Select Business Type","Importer","Wholesaler","Retailer","Buying Agent","Chain Store","Corporate Buyer"];
    this.vendorData.business_type = this.business[0];
    this.getCountry();
    this.vendorData.title = this.titleList[1];
  }

  doLogin(data:any){
    // console.log(data);
    this.WebapiService.doLogin(data).subscribe((res:any)=>{
      console.log(res);
      if(res.status == 1 && res.data[0].loginStatus == 2){
        alert(res.msg);
        localStorage.setItem('loginId',res.data[0].id);
        localStorage.setItem('loginStatus',res.data[0].loginStatus);
        this.router.navigate(['/step-two']);
      }
      else if(res.status == 1 && res.data[0].loginStatus == 3){
        alert(res.msg);
        localStorage.setItem('loginId',res.data[0].id);
        localStorage.setItem('loginStatus',res.data[0].loginStatus);
        this.router.navigate(['/my-account']);
      }
      else{
        alert(res.msg);
      }

    });
  }

  getCountry(){

    this.WebapiService.getCountry().subscribe((res: any) => {
    //  console.log(res);
      this.countryList = res;
    });
  }

  insertVendor(data:any){

    if(data.password == data.cpassword){
      data.phoneCode = this.phoneCode;
      // console.log(data);
      
      this.WebapiService.insertVendor(data).subscribe((res:any)=>{
        console.log(res);
        if(res.status == 1){
          alert(res.msg);
          location.reload();
        }
        else{
          alert(res.msg);
        }
     });
    }
    else{
      alert("please enter same password");
    }

  }
  onChangeBuyer(e:any){
  //  console.log(e);
   if(e.isTrusted == true){
     this.countryFlag = true;
   }   
  }
  onChangeVendor(e:any){
    // console.log(e);
    if(e.isTrusted == true){
      this.countryFlag = false;
    }  
  }
  getCode(name:any){
    // console.log(name);
    let temp = {"name":name}; 
    this.WebapiService.getCountryById(temp).subscribe((res: any) => {
      // console.log(res);
      this.phoneCode = res[0].phonecode;
      // console.log(this.phoneCode);
      
    });
  }
}
