import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service'; 
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  categoryList:any;
  serviceList:any;
  locationList:any;
  userLogin:any;
  loginStatus:any
  venEditId:any;
  searchResults: any;
  searchDiv: boolean=false;
  loginName:any;
  constructor(private WebapiService:WebapiService,private router: Router) { 
  }

  ngOnInit(): void {
    //console.log('test');
    this.userLogin = localStorage.getItem('loginId');
    this.loginStatus=localStorage.getItem('loginStatus');
    this.loginName=localStorage.getItem('name');
    
  }

  logout(){
    localStorage.removeItem('loginId');
    localStorage.removeItem('loginStatus');
    this.router.navigate(['/']);
  }
  myAccount(e:any){
    // console.log(e);
    if(e==2){
      this.router.navigate(['/vendor-account'])
    }
    else if(e==3){
      this.router.navigate(['/my-account'])
    }
    else if(e==4){
      this.router.navigate(['/admin'])
    }
    else{
      console.log("hi there");
      
    }
  }
  closeList(){
    this.searchDiv = false;
  }
  
  searchProduct(e:any){
  // console.log(e.target.value);
  // this.searchDiv = false;
    let value = {'search':e.target.value}
    // this.value = search.target.value;
     this.WebapiService.getRelatedSearch(value).subscribe((res:any)=>{
       this.searchResults=res;
       if(this.searchResults.length>0){

         this.searchDiv = true;
       }
      //  res.forEach(function(data:any){
      //    arr2.push(data.product_name);
      //  });
      //  console.log(arr2);
      //  console.log(this.searchResults);
     });
  }
}
