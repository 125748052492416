<style>
    input[type="number"] {
       -moz-appearance: textfield;
       appearance: textfield;
       margin: 0;
   }
   input[type="number"]::-webkit-inner-spin-button,
   input[type="number"]::-webkit-outer-spin-button {
       -webkit-appearance: none;
       margin: 0;
   }
   .sign-in a {
  color: #fff !important;
}
.sign-in img {
  width: 30px;
}
.sign-in ul>li{
     display: inline-block;
     vertical-align: -webkit-baseline-middle;
 }
 .sign-in ul>li a{
     color: var(--white);
     margin-left: .25rem;
 }
 .search-box img{
      transform: translate(-6%, -50%);
      position: absolute;
      top: 50%;
      right: 6%;
 }
 .vendor-details {
      background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.5), rgba(0, 0, 0, .5)), url(../../assets/img/aerial-view.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
  }
  
  a {
      color: #fff;
  }
  
  .dropdown-item {
      color: #212529 !important
  }
  
  .r-25 {
      right: 25px;
  }
  .you-deal-in{
      flex-wrap: wrap;
  }
  .you-deal-in>div{
      flex: 0 1 380px;
  }
</style>

<section class="vendor-details section" id="vendorDetails">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title mb-md-4 col-md-12  float-md-left">
                    <div class="col-md-6 float-md-left">
                        <h1 class="mb-md-0">Social Compliance</h1>
                        <h2 style="color: #fff;font-size: 20px;margin-top: 10px;">{{companyName}}</h2>
                    </div>
                    <div *ngIf="empId" class="sign-in col-md-6 float-md-left text-right">
                        <ul class="text-md-center">
                            <li *ngIf="userLogin" >
                                <a (click)="myAccount(loginStatus)"><img src="assets/img/user.png" alt="" class="w-18">{{loginName}}</a>
                            </li>
                            <li  *ngIf="!userLogin">
                                <a [routerLink]="['/login']" class="signinbtn" routerLinkActive="active">Sign In</a>
                            </li>  
                            <li><span class="separator">|</span></li>
                            <li *ngIf="!userLogin" >
                                <a class="signupbtn" [routerLink]="['/signup']" routerLinkActive="active">Join Us</a>
                            </li>
                            <li *ngIf="userLogin">
                                <a (click)="logout()" href=""><img src="assets/img/logout.png" alt="" class="w-18">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div><!-- row -->
        <div class="row">
            <div class="col-12">
                <div class="step-menu-wrapper">
                    <ul class="d-flex">
                        <li class="flex-fill"><a [routerLink]="['/step-one']" routerLinkActive="active">Basic Information</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-two']" routerLinkActive="active">Company Profile</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-three']" routerLinkActive="active">Commercial</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-four']" routerLinkActive="active">Quality Management & Certifications</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-five']" routerLinkActive="active">Staffing</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-six']" routerLinkActive="active">Legal & Financial</a></li>
                        <li class="flex-fill"><a class="active">Social Compliance</a></li>
                        <li class="flex-fill"><a [routerLink]="['/step-eight']" routerLinkActive="active" >Infrastructure</a></li>
                    </ul>
                </div>
            </div>
        </div>
<div class="" id="soc-com" role="tabpanel" aria-labelledby="scom-tab">
    <div class="section-content bg-white p-md-3 h-auto clearfix">
        <div class="section-title mb-md-4">
            <!-- <h1 class="mb-md-0">Vendor Registration</h1> -->
        </div>
        <div class="section-form">
            <form #VendorForm ngForm="VendorForm" class="row" (ngSubmit)="socialCompliance(socialData)">
                <div class="col-md-12">
                    <h4 class="mt-md-3">
                        Environmental
                    </h4>
                </div>
                <div class="col-md-12">
                    <label for="">
                        Do you have any pollution control on following?
                    </label>
                </div>
                <div class="col-md-4">
                    <label for="">
                        <strong>CHEMICAL</strong>
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.pollution_control" value="yes" class="form-check-input" name="optradioPoll" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.pollution_control" value="no" class="form-check-input" name="optradioPoll" />No </label>
                    </div>
                </div>
                <div class="col-md-4">
                    <label for="">
                        Are you working with chemicals in your unit?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_with_chemical" value="yes" class="form-check-input" name="optradioChe" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_with_chemical" value="no" class="form-check-input" name="optradioChe" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you have a chemical list in every relevant department?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.chemical_list" value="yes" class="form-check-input" name="optradioCheList" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.chemical_list" value="no" class="form-check-input" name="optradioCheList" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you have MSDS (Material Safety Data Sheet)?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.msds" value="yes" class="form-check-input" name="optradioMate" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.msds" value="no" class="form-check-input" name="optradioMate" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are you working with high-toxic chemicals in your unit?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.high_toxic_chemical" value="yes" class="form-check-input" name="optradioTox" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.high_toxic_chemical" value="no" class="form-check-input" name="optradioTox" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are all high-toxic chemicals recorded at the Local public security bureau?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.chemical_record_local_psb" value="yes" class="form-check-input" name="optradioLoc" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.chemical_record_local_psb" value="no" class="form-check-input" name="optradioLoc" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are all chemicals and flammable goods stored Properly?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.chemical_goods_stored_properly" value="yes" class="form-check-input" name="optradioFla" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.chemical_goods_stored_properly" value="no" class="form-check-input" name="optradioFla" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you have control mechanism for chemical and effluent out flow?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.mech_for_chemical_outflow" value="yes"  class="form-check-input" name="optradioMech" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.mech_for_chemical_outflow" value="no" class="form-check-input" name="optradioMech" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for=""><strong>SMOKE</strong></label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you have smoke detectors?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.have_smoke_detector" value="yes" class="form-check-input" name="optradioSmo" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.have_smoke_detector" value="no" class="form-check-input" name="optradioSmo" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you have them checked periodically?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.check_smoke_det_perodically" value="yes" class="form-check-input" name="optradioChec" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.check_smoke_det_perodically" value="no" class="form-check-input" name="optradioChec" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for=""><strong>SOUND / NOISE</strong></label>
                </div>

                <div class="col-md-12">
                    <label for=""><strong>NOISE LEVELS</strong></label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you provide workers with ear plugs in case of high noise levels in your factory?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.ear_plugs_for_worker" value="yes" class="form-check-input" name="optradioWrk" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.ear_plugs_for_worker" value="no" class="form-check-input" name="optradioWrk" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for=""><strong>WATER HARVESTING</strong></label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you have the environmental certificate (Air, water)?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.have_env_certificate" value="yes" class="form-check-input" name="optradioEnv" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.have_env_certificate" value="no" class="form-check-input" name="optradioEnv" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you need it for your unit?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.need_env_certificate_for_unit" value="yes" class="form-check-input" name="optradioNeed" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.need_env_certificate_for_unit" value="no" class="form-check-input" name="optradioNeed" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Have you applied for it?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.applied_for_env_certificate" value="yes" class="form-check-input" name="optradioApp" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.applied_for_env_certificate" value="no" class="form-check-input" name="optradioApp" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for=""><strong>FACTORY WASTAGE/SCRAP</strong></label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Is waste disposed correctly as per Indian law and regulations?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.waste_disposed_correctly" value="yes" class="form-check-input" name="optradioWst" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.waste_disposed_correctly" value="no" class="form-check-input" name="optradioWst" />No </label>
                    </div>
                </div>

                <div class="col-md-2">
                    <label for="">
                        <strong>CARBON CREDIT</strong>
                    </label>
                </div>
                <div class="col-md-4">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.carbon_credit" value="Positive" class="form-check-input" name="optradioCarCre" />Positive </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.carbon_credit" value="Negative" class="form-check-input" name="optradioCarCre" />Negative </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.carbon_credit" value="Don't know" class="form-check-input" name="optradioCarCre" />Don't Know </label>
                    </div>
                </div>

                <!-- <div class="col-md-4">
                    <label for=""><strong>FACTORY RATING (automatic)</strong></label>
                </div>

                <div class="col-md-8">
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="1" class="form-check-input" name="optradioRat" />1 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="2" class="form-check-input" name="optradioRat" />2 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="3" class="form-check-input" name="optradioRat" />3 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="4" class="form-check-input" name="optradioRat" />4 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="5" class="form-check-input" name="optradioRat" />5 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="6" class="form-check-input" name="optradioRat" />6 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="7" class="form-check-input" name="optradioRat" />7 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="8" class="form-check-input" name="optradioRat" />8 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="9" class="form-check-input" name="optradioRat" />9 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating" value="10" class="form-check-input" name="optradioRat" />10 </label>
                    </div>
                </div> -->

                <div class="col-md-12">
                    <h4 class="mt-md-3">Health And Safety</h4>
                </div>
                <div class="col-md-5">
                    <label for="" class="flex-fill">Do you provide for health and safety of staff & infrastructure?</label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label">
                            <input type="radio" value="Yes" class="form-check-input" name="radio-health" />Yes 
                        </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> 
                            <input type="radio" value="No" class="form-check-input" name="radio-health" />No 
                        </label>
                    </div>
                </div>
                <div class="col-md-12">
                    <label for=""><strong>FIRE SAFETY</strong></label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you have a fire safety certificate for your building?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.fire_saftey_certificate" value="yes" class="form-check-input" name="optradioSafe" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.fire_saftey_certificate" value="no" class="form-check-input" name="optradioSafe" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Have you applied for it?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.applied_for_fire_certificate" value="yes" class="form-check-input" name="optradioAppl" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.applied_for_fire_certificate" value="no" class="form-check-input" name="optradioAppl" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you have enough fire exits and are there signs for the exits?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.fire_exit_and_signs" value="yes" class="form-check-input" name="optradioExi" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.fire_exit_and_signs" value="no" class="form-check-input" name="optradioExi" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you have enough and working emergency lights?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_emergency_light" value="yes" class="form-check-input" name="optradioEmerg" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_emergency_light" value="no" class="form-check-input" name="optradioEmerg" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are all emergency exits unobstructed all the time?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.unobstructed_emergency_exits" value="yes" class="form-check-input" name="optradioUno" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.unobstructed_emergency_exits" value="no" class="form-check-input" name="optradioUno" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you have enough and working fire extinguisher?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_fire_extinguisher" value="yes" class="form-check-input" name="optradioExtin" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_fire_extinguisher" value="no" class="form-check-input" name="optradioExtin" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you have evacuation plot plans on every floor/every department?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.plot_plan_for_floor_and_dept" value="yes" class="form-check-input" name="optradioPlans" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.plot_plan_for_floor_and_dept" value="no" class="form-check-input" name="optradioPlans" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you held up fire drills/fire safety training in the required intervals?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.fire_drill_and_training" value="yes" class="form-check-input" name="optradioInter" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.fire_drill_and_training" value="no" class="form-check-input" name="optradioInter" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        And do you keep pictures of it as proof?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.keep_pic_for_proof" value="yes" class="form-check-input" name="optradioKeep" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.keep_pic_for_proof" value="no" class="form-check-input" name="optradioKeep" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for=""><strong>MEDICAL CARE/ACCIDENTS</strong></label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU MAINTAIN A FULLY STOCKED AND CURRENT DATE FIRST AID KIT?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.current_date_first_aid_kit" value="yes" class="form-check-input" name="optradioStock" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.current_date_first_aid_kit" value="no" class="form-check-input" name="optradioStock" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are there first aid boxes in every department?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.first_aid_every_dept" value="yes" class="form-check-input" name="optradioAid" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.first_aid_every_dept" value="no" class="form-check-input" name="optradioAid" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Is any medicine overdue in the first aid boxes?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.medicine_overdue" value="yes" class="form-check-input" name="optradioOdue" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.medicine_overdue" value="no" class="form-check-input" name="optradioOdue" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Is there anything missing in the first aid boxes?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.missing_in_first_aid_box" value="yes" class="form-check-input" name="optradioMiss" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.missing_in_first_aid_box" value="no" class="form-check-input" name="optradioMiss" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are the first aid boxes easily accessible?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.first_aid_easily_accessible" value="yes" class="form-check-input" name="optradioBox" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.first_aid_easily_accessible" value="no" class="form-check-input" name="optradioBox" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are workers trained for first aid?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_trained" value="yes" class="form-check-input" name="optradioTrain" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_trained" value="no" class="form-check-input" name="optradioTrain" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU USE SAFETY HELMETS AND GLOVES
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.use_saftey_helmet_gloves" value="yes" class="form-check-input" name="optradioSaf" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.use_saftey_helemt_gloves" value="no" class="form-check-input" name="optradioSaf" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do your workers need protective clothing such as safety glasses, protective gloves or ear protectors etc. for their work?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.need_protective_clothing" value="yes" class="form-check-input" name="optradioProt" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.need_protective_clothing" value="no" class="form-check-input" name="optradioProt" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you provide these protective clothes to your workers?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.provide_protective_clothes_to_worker" value="yes" class="form-check-input" name="optradioClo" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.provide_protective_clothes_to_worker" value="no" class="form-check-input" name="optradioClo" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for=""><strong>CLEANLINESS</strong></label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU HAVE HYGENIC CONDITIONS IN AND AROUND YOUR FACILITY?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.hygenic_condition" value="yes" class="form-check-input" name="optradioHyg" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.hygenic_condition" value="no" class="form-check-input" name="optradioHyg" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU HAVE STAFF AND WORKER TOILETS?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.staff_and_worker_toilet" value="yes" class="form-check-input" name="optradioStaf" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.staff_and_worker_toilet" value="no" class="form-check-input" name="optradioStaf" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        (ARE THEY SEPERATE FOR WOMEN)
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.seprate_toilet_for_women" value="yes" class="form-check-input" name="optradioSep" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.seprate_toilet_for_women" value="no" class="form-check-input" name="optradioSep" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU MAKE AVAILABLE POTABLE DRINKING WATER TO ALL STAFF AND WORKERS?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.portable_drinking_water_for_worker_staff" value="yes" class="form-check-input" name="optradioPot" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.portable_drinking_water_for_worker_staff" value="no" class="form-check-input" name="optradioPot" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU HAVE A CLEAN AND SANITIZED EATING AREA FOR STAFF AND EMPLOYEES (FOR FACTORIES WITH OVER 25 STAFF)
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.clean_eating_area" value="yes" class="form-check-input" name="optradioSani" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.clean_eating_area" value="no" class="form-check-input" name="optradioSani" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-md-3">Working Conditions </h4>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you provide quality working conditions?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" class="form-check-input" name="radio-qual-con" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" class="form-check-input" name="radio-qual-con" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for=""><strong>AIR QUALITY AND VENTILATION</strong></label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU HAVE ADEQUATE VENTILITATION ON THE SHOP FLOOR
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.adequate_ventilation" value="yes" class="form-check-input" name="optradioAde" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.adequate_ventilation" value="no" class="form-check-input" name="optradioAde" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Is there enough space for all workers in the unit?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.space_for_all_worker" value="yes" class="form-check-input" name="optradioEno" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.space_for_all_worker" value="no" class="form-check-input" name="optradioEno" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU MAINTAIN COMFORTABLE WORKING TEMPERATURE ON THE SHOP FLOOR
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.comf_working_temprature" value="yes" class="form-check-input" name="optradioComf" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.comf_working_temprature" value="no" class="form-check-input" name="optradioComf" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for="">
                        <strong>LIGHTING</strong>
                    </label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU MAINTAIN ADEQUATE LIGHTING IN THE FACTORY AREA
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.adequate_lighting" value="yes" class="form-check-input" name="optradioLig" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.adequate_lighting" value="no" class="form-check-input" name="optradioLig" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Is all electric wiring covered with non-flammable material?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.electric_wire_coverd" value="yes" class="form-check-input" name="optradioWir" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.electric_wire_coverd" value="no" class="form-check-input" name="optradioWir" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Is there any loose wiring in the unit?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.loose_wiring_in_unit" value="yes" class="form-check-input" name="optradioLoos" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.loose_wiring_in_unit" value="no" class="form-check-input" name="optradioLoos" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Is there sufficient illumination in all areas of the unit?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.sufficient_illumination" value="yes" class="form-check-input" name="optradioIllu" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.sufficient_illumination" value="no" class="form-check-input" name="optradioIllu" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for=""><strong>PERSONNEL SECURITY</strong></label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU HAVE AN INITIAL CHECK OF EMPLOYEE BACK GROUND AND APPLICATION VERIFICATION?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.employee_app_verification" value="yes" class="form-check-input" name="optradioVri" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.employee_app_verfication" value="no" class="form-check-input" name="optradioVri" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for=""><strong>EDUCATION AND TRAINING</strong></label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU HAVE ANY PROGRAMME FOR UPGADING SKILL SETS OF EMPLOYEES?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.program_for_skill_of_emp" value="yes" class="form-check-input" name="optradioProg" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.program_for_skill_of_emp" value="no" class="form-check-input" name="optradioProg" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU HAVE ANY PRODUCT TRAINING PROGRAMME FOR EMPLOYEES?  
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.prdt_training_program" value="yes" class="form-check-input" name="optradioTrai" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.prdt_training_program" value="no" class="form-check-input" name="optradioTrai" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU HAVE ANY POLICY DOCUMENT/MANUAL FOR THE COMPANY / FACTORY?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.company_manual" value="yes" class="form-check-input" name="optradioDocum" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.company_manual" value="no" class="form-check-input" name="optradioDocum" />No </label>
                    </div>
                </div>

                <div class="col-md-12"></div>

                <!-- <div class="col-md-4">
                    <label for=""><strong>FACTORY RATING (automatic)</strong></label>
                </div>

                <div class="col-md-8">
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="1" class="form-check-input" name="optradioFacRat" />1 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="2" class="form-check-input" name="optradioFacRat" />2 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="3" class="form-check-input" name="optradioFacRat" />3 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="4" class="form-check-input" name="optradioFacRat" />4 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="5" class="form-check-input" name="optradioFacRat" />5 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="6" class="form-check-input" name="optradioFacRat" />6 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="7" class="form-check-input" name="optradioFacRat" />7 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="8" class="form-check-input" name="optradioFacRat" />8 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="9" class="form-check-input" name="optradioFacRat" />9 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.factory_rating1" value="10" class="form-check-input" name="optradioFacRat" />10 </label>
                    </div>
                </div> -->

                <div class="col-md-12">
                    <h4 class="mt-md-3">Social Accountability</h4>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you comply with govt statutory requirements?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="yes" class="form-check-input" name="radio-govt-stat" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="no" class="form-check-input" name="radio-govt-stat" />No </label>
                    </div>
                </div>
                <div class="w-100"></div>
                <div class="col-md-4">
                    <label for="">
                        DO YOU COMPLY WITH GOVT LABOUR LAWS?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.comply_with_govt_laws" value="yes" class="form-check-input" name="optradioLabo" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.comply_with_govt_laws" value="no" class="form-check-input" name="optradioLabo" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for="">
                        <strong>Documentation / Certificates / Insurances</strong>
                    </label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you have business license (Factory license, Shops registration etc.)?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.business_license" value="yes" class="form-check-input" name="optradioBusi" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.business_license" value="no" class="form-check-input" name="optradioBusi" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you have labor contracts for all your workers?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.labor_contract_for_all_worker" value="yes" class="form-check-input" name="optradioContr" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.labor_contract_for_all_worker" value="no" class="form-check-input" name="optradioContr" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Is the time in / out of visitors kept correctly?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.time_of_visitor" value="yes" class="form-check-input" name="optradioVisi" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.time_of_visitor" value="no" class="form-check-input" name="optradioVisi" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for="">
                        <strong>Working & Overtime Hours / Extended Working Hours</strong>
                    </label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are normal working hours exceeding 8 hours per day?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_hour_exceed" value="yes" class="form-check-input" name="optradioExce" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.working_hour_exceed" value="no" class="form-check-input" name="optradioExce" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do workers get at least one day off per week? (According to legal requirements)?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_get_one_day_off" value="yes" class="form-check-input" name="optradioOneD" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_get_one_day_off" value="no" class="form-check-input" name="optradioOneD" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU PAY OVER TIME?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.pay_over_time" value="yes" class="form-check-input" name="optradioOverT" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.pay_over_time" value="no" class="form-check-input" name="optradioOverT" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are workers forced to do overtime work?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.forced_for_overtime" value="yes" class="form-check-input" name="optradioForc" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.forced_for_overtime" value="no" class="form-check-input" name="optradioForc" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are overtime hours more than 2 hours per day?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.overtime_hour_more_than_2hr" value="yes" class="form-check-input" name="optradioOverT" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.overtime_hour_more_than_2hr" value="no" class="form-check-input" name="optradioOverT" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are overtime hours more than 12 hours per week?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.overtime_hour_more_than_12hr_week" value="yes" class="form-check-input" name="optradioTweHrs" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.overtime_hour_more_than_12hr_week" value="no" class="form-check-input" name="optradioTweHrs" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are overtime hours more than 50 hours per quarter?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.overtime_hour_more_than_50hr_quater" value="yes" class="form-check-input" name="optradioFifHrs" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.overtime_hour_more_than_50hr_quater" value="no" class="form-check-input" name="optradioFifHrs" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do workers get paid the overtime hours at premium rate?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_for_overtime" value="yes" class="form-check-input" name="optradioPaid" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_for_overtime" value="no" class="form-check-input" name="optradioPaid" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for="">
                        <strong>Wage Compensation & Compensation Benefits</strong>
                    </label>
                </div>

                <div class="col-md-3">
                    <label for="">
                        Are workers paid per?
                    </label>
                </div>
                <div class="col-md-3">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="day" class="form-check-input" name="radio-work" />day </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="month" class="form-check-input" name="radio-work" />month </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" value="fortnightly" class="form-check-input" name="radio-work" />fortnightly </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are workers paid per piece rate?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_per_piece_rate" value="yes" class="form-check-input" name="optradioPiec" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_per_piece_rate" value="no" class="form-check-input" name="optradioPiec" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do workers get paid the local minimum wage?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_local_min_Wage" value="yes" class="form-check-input" name="optradioMini" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_local_min_Wage" value="no" class="form-check-input" name="optradioMini" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do workers get paid statutory holiday compensation?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_holiday_comp" value="yes" class="form-check-input" name="optradioHoli" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_holiday_comp" value="no" class="form-check-input" name="optradioHoli" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do workers get their payment in time?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_payment_in_time" value="yes" class="form-check-input" name="optradioPamt" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_payment_in_time" value="no" class="form-check-input" name="optradioPamt" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do workers get paid annual leave or do they get the according number of holidays off?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_annual_leave" value="yes" class="form-check-input" name="optradioAnnu" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_annual_leave" value="no" class="form-check-input" name="optradioAnnu" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Will you pay maternity leave to the workers in case they don't resign from the company?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_maternity_leave" value="yes" class="form-check-input" name="optradioMate" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_maternity_leave" value="no" class="form-check-input" name="optradioMate" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do workers get paid casual and sick leave?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_casual_leave" value="yes" class="form-check-input" name="optradioSic" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_casual_leave" value="no" class="form-check-input" name="optradioSic" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do workers get paid casual and sick leave?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_sick_leave" value="yes" class="form-check-input" name="optradioSic" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_paid_sick_leave" value="no" class="form-check-input" name="optradioSic" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for=""><strong>Involuntary Labour / Discrimination & Harassment</strong></label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do workers have to give you financial deposits to work for your unit?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.deposit_to_work_in_unit" value="yes" class="form-check-input" name="optradioDepo" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.deposit_to_work_in_unit" value="no" class="form-check-input" name="optradioDepo" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do workers have to leave their original ID at your unit?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.leave_id_card_at_unit" value="yes" class="form-check-input" name="optradioLea" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.leave_id_card_at_unit" value="no"class="form-check-input" name="optradioLea" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are any wages held as security deposit until workers stop working for your unit?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_security_deposit" value="yes" class="form-check-input" name="optradioSecur" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_security_deposit" value="no" class="form-check-input" name="optradioSecur" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do workers have to pay for their tools or equipment?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_pay_for_tool" value="yes" class="form-check-input" name="optradioToo" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_pay_for_tool" value="no" class="form-check-input" name="optradioToo" />No </label>
                    </div>
                </div>

                <div class="col-md-10">
                    <label for="">
                        Is there any discrimination based on gender, age: religion, race, social background, family responsibility, marital status, disability, sexual orientation, union membership or  political opinions?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.any_discrimination" value="yes" class="form-check-input" name="optradioDisa" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.any_discrimination" value="no" class="form-check-input" name="optradioDisa" />No </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for=""><strong>Social Insurance & Liability Insurance</strong></label>
                </div>

                <div class="col-md-10">
                    <label for="">
                        Does the unit pay social security (PF) for their workers?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.pf_for_worker" value="yes" class="form-check-input" name="optradioSoci" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.pf_for_worker" value="no" class="form-check-input" name="optradioSoci" />No </label>
                    </div>
                </div>

                <div class="col-md-10">
                    <label for="">
                        For how many workers do you pay social security (PF)?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.how_many_worker_get_pf" value="yes" class="form-check-input" name="optradioWrke" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.how_many_worker_get_pf" value="no" class="form-check-input" name="optradioWrke" />No </label>
                    </div>
                </div>

                <div class="col-md-10">
                    <label for="">
                        Does the unit have social insurance (ESI) for some of their workers?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.esi_for_worker" value="yes" class="form-check-input" name="optradioInsr" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.esi_for_worker" value="no" class="form-check-input" name="optradioInsr" />No </label>
                    </div>
                </div>

                <!-- <div class="col-md-10">
                    <label for="">
                        For how many workers do they have social insurance (ESI)?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.how_many_worker_get_esi" value="yes" class="form-check-input" name="optradioScil" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.how_many_worker_get_esi" value="no" class="form-check-input" name="optradioScil" />No </label>
                    </div>
                </div> -->

                <div class="col-md-12">
                    <label for=""><strong>Freedom of Association</strong></label>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Can workers choose to associate with any legal groups of their own choosing?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.asso_with_legal_group" value="yes" class="form-check-input" name="optradioGro" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.asso_with_legal_group" value="no" class="form-check-input" name="optradioGro" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Do you have any unions?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.any_union_in_unit" value="yes" class="form-check-input" name="optradioUnio" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.any_union_in_unit" value="no" class="form-check-input" name="optradioUnio" />No </label>
                    </div>
                </div>

                <div class="col-md-4"><label for="">
                    <strong>Do your employee worker below 18 years</strong>
                </label></div>

                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_below_18" value="yes" class="form-check-input" name="optradioBlw" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.worker_below_18" value="no" class="form-check-input" name="optradioBlw" />No </label>
                    </div>
                </div>
                <div class="w-100"></div>
                <div class="col-md-4">
                    <label for="">
                        Do you have copies of proof age documents?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.copy_for_age_doc" value="yes" class="form-check-input" name="optradioCop" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.copy_for_age_doc" value="no" class="form-check-input" name="optradioCop" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Are young workers 15-18 years employed in this unit?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.younger_worker_employed" value="yes" class="form-check-input" name="optradioYrs" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.younger_worker_employed" value="no" class="form-check-input" name="optradioYrs" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        How many hours do they work In a day?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <input type="number" [(ngModel)]="socialData.worker_hour_work_in_day" name="worker_hour_work_in_day" class="form-control" placeholder="Hours">
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Is a regular health check provided for them?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.regular_health_check" value="yes" class="form-check-input" name="optradioHeal" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.regular_health_check" value="no" class="form-check-input" name="optradioHeal" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        Does the underage worker work with hazardous materials / under hazardous conditions?
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.underage_worker_with_hazardous_material" value="yes" class="form-check-input" name="optradioUndag" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.underage_worker_with_hazardous_material" value="no" class="form-check-input" name="optradioUndag" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU HAVE MININUM CASUAL/ MEDICAL LEAVES FOR WORKERS                       
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.min_causal_leave_worker" value="yes" class="form-check-input" name="optradioMinim" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.min_causal_leave_worker" value="no" class="form-check-input" name="optradioMinim" />No </label>
                    </div>
                </div>

                <div class="col-md-4">
                    <label for="">
                        DO YOU HAVE A MINIMUM EDUCATION CRITERION FOR YOUR STAFF/ EMPLOYEES
                    </label>
                </div>
                <div class="col-md-2">
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.min_edu_criterion" value="yes" class="form-check-input" name="optradioEduc" />Yes </label>
                    </div>
                    <div class="form-check-inline">
                        <label class="form-check-label"> <input type="radio" [(ngModel)]="socialData.min_edu_criterion" value="no" class="form-check-input" name="optradioEduc" />No </label>
                    </div>
                </div>

                <div class="col-md-12"></div>

                <!-- <div class="col-md-4">
                    <label for=""><strong>FACTORY RATING (automatic)</strong></label>
                </div>
                <div class="col-md-8">
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="1" name="optradioFtRat" class="form-check-input" />1 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="2" name="optradioFtRat" class="form-check-input" />2 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="3" name="optradioFtRat" class="form-check-input" />3 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="4" name="optradioFtRat" class="form-check-input" />4 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="5" name="optradioFtRat" class="form-check-input" />5 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="6" name="optradioFtRat" class="form-check-input" />6 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="7" name="optradioFtRat" class="form-check-input" />7 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="8" name="optradioFtRat" class="form-check-input" />8 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="9" name="optradioFtRat" class="form-check-input" />9 </label>
                    </div>
                    <div class="col-md-1 form-check-inline">
                        <label class="form-check-label"><input type="radio" [(ngModel)]="socialData.factory_rating2" value="10" name="optradioFtRat" class="form-check-input" />10 </label>
                    </div>
                </div> -->

                <div class="w-100" style="margin-bottom: 32px;"></div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4 text-center mx-auto">
                            <button [routerLink]="['/step-six']" routerLinkActive="active" class="btn btn-secondary d-md-block mx-auto"><i class="fa fa-angle-left"></i> Previous</button>
                        </div>
                        <div class="col-md-4 text-center mx-auto">
                            <button type="submit" class="btn btn-info d-md-block mx-auto">Submit</button>
                        </div>
                        <div class="col-md-4 text-center mx-auto">
                            <button [routerLink]="['/step-eight']" routerLinkActive="active" class="btn btn-primary d-md-block mx-auto">Next <i class="fa fa-angle-right"></i></button>
                        </div>
                    </div>
                </div>

                <!-- <button type="submit" class="btn btn-primary d-md-block mx-auto">Submit</button> -->
            </form>
        </div>
    </div>
</div>
</div>
</section>